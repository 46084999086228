const checkAuthorizationService = (userPermissionList: any[], requiredPermissionList: any[]) => {
  try {
    // check if userPermission is available in required permission list
    return userPermissionList.some((permission) => requiredPermissionList.includes(permission));
  } catch (error) {
    return false;
  }
};

export default checkAuthorizationService;
