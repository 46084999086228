import React, { FC } from "react";
import Modal from "../../../../component/common/Modal";
import CloseIcon from "../../../../assets/icons/gallery/close.svg";
import dayjs from "dayjs";
import calenderIcon from "../../../../assets/icons/allcomplaints/calender.svg";
import statusHistoryIcon from "../../../../assets/icons/allcomplaints/history.svg";

interface ModalProps {
  showStatusHistory: boolean;
  setShowStatusHistory: any;
  selectedComplaint: any;
}

const StatusHistory: FC<ModalProps> = ({ showStatusHistory, setShowStatusHistory, selectedComplaint }) => {
  return (
    <Modal showModal={showStatusHistory} onClose={() => setShowStatusHistory(false)} className="relative w-[50%] p-8">
      <img src={CloseIcon} className="absolute text-3xl text-gray-400 right-[-15px] top-[-20px] cursor-pointer" onClick={() => setShowStatusHistory(false)} />
      <div>
        <p className="text-2xl font-bold">Status History</p>
        {selectedComplaint?.userId && (
          <div>
            <p className="text-xl  font-bold my-3">User Details</p>
            <p className="text-gray-600 font-semibold">
              ID :<span className="text-black font-bold text-sm ml-2">{selectedComplaint.userId?._id}</span>
            </p>
            <p className="text-gray-600 font-semibold">
              Name :<span className="text-black font-bold text-sm ml-2">{selectedComplaint.userId?.name}</span>
            </p>
            <p className="text-gray-600 font-semibold">
              Email :<span className="text-black font-bold text-sm ml-2">{selectedComplaint.userId?.email}</span>
            </p>
          </div>
        )}

        <div className="custom-polifyx-scrollbar overflow-y-scroll m-5 shadow-all h-[27rem] px-4">
          <div className="flex gap-2 items-center my-3">
            <img src={statusHistoryIcon} />
            <p className="text-xl font-bold">Status History</p>
          </div>
          <table className="w-full">
            <thead>
              <tr className="border-b-2 border-t-2 whitespace-nowrap">
                <th className=" py-2 md:py-4 text-sm pl-4 md:text-base text-start ">Status</th>
                <th className=" pr-4 py-2 md:py-4 text-sm md:text-base text-end">Date</th>
              </tr>
            </thead>
            <tbody className="text-gray-600 w-full text-center">
              {selectedComplaint?.statusHistory &&
                selectedComplaint?.statusHistory.map((obj: any, i: number) => {
                  return (
                    <tr key={i} className="border-b-2">
                      <td className="py-4 pl-4 text-start">{obj.currStatus}</td>
                      <td>
                        <div className="flex justify-end gap-2 items-center h-full pr-4">
                          <img src={calenderIcon} />
                          <span className="pt-1">{dayjs(obj.date).format("D MMMM, YYYY - hh:mm A")}</span>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </Modal>
  );
};

export default StatusHistory;
