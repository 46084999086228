import { components } from "react-select";
import Typography from "../../../component/semantic/Typography";
import CustomInput from "../../../component/semantic/Input";
import ButtonOutline from "../../../component/semantic/Button";
import { useState } from "react";

export const CustomDateOption = (props: any) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  return (
    <components.Menu {...props}>
      <div className="flex bg-white flex-col gap-4 px-4 py-6">
        <div>
          <Typography className="mb-1">Start Date</Typography>
          <CustomInput value={startDate} onChange={(e) => setStartDate(e.target.value)} type="date" className="mt-2" />
        </div>
        <div>
          <Typography>End Date</Typography>
          <CustomInput max="today" value={endDate} onChange={(e) => setEndDate(e.target.value)} type="date" className="mt-2" />
        </div>
        <div className="w-full ">
          <ButtonOutline
            onClick={() => {
              props.closeMenu();
              props.customDateHandler(startDate, endDate);
            }}
            title="Apply"
            className={`bg-polyfixTheme  w-full ${(!startDate || !endDate) && "opacity-50 pointer-events-none"} `}
          />
        </div>
      </div>
    </components.Menu>
  );
};
