import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../helpers/axios";
import { baseUrl } from "../../helpers/constants/defaultUrls";

interface PasswordArgs {
  oldPassword: string;
  newPassword: string;
}

export const passwordThunk = createAsyncThunk("password", async (payload: PasswordArgs) => {
  const response = await axios.post(baseUrl + "v1/auth/password/change", payload);
  return response.data;
});
