import React, { useState } from "react";
import { actionModalTypes } from "../../../helpers/constants/interfaceTypes";
import { ActionModalHandler } from "../../../component/table/TableFunctions";
import StatusHandler from "./StatusHandler";

interface TableProps {
  tableHeading: string[];
  tableKeys: string[];
  tableData: any[];
  actions: actionModalTypes;
  selectedRow: any;
  currentPage?: any;
  pageSize: number;
}

const ActionTable: React.FC<TableProps> = ({ tableHeading, tableKeys, currentPage, pageSize, tableData, actions, selectedRow }) => {
  const [showTooltip, setShowToolTip] = useState<any>([-1, -1]);
  const [showMenu, setShowMenu] = useState(-1);

  return (
    <div
      className="overflow-x-scroll w-full mr-6"
      style={{
        scrollbarWidth: "thin",
        scrollbarColor: "#D7DBEC transparent",
        msOverflowStyle: "none",
        overflowX: "scroll",
        overflowY: "hidden",
        borderRadius: "16px"
      }}
    >
      <table className="w-full">
        <thead>
          <tr className="border-b-2 whitespace-nowrap">
            {tableHeading.map((data: any, i: number) => {
              if (i == 0) return;
              return (
                <th key={i} className="pr-1 md:pr-3 py-2 md:py-4 text-sm md:text-base ">
                  <div className="flex gap-2 px-4 min-w-32">
                    <p className="font-extrabold">{data}</p>
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="text-gray-600 w-full ">
          {tableData && tableData.length > 0 ? (
            tableData.map((data, index) => (
              <tr key={index} className="border-b-2">
                {tableKeys.map((key, i) => {
                  if (i === 0) return null;
                  if (key == "action") return <ActionModalHandler index={index} actions={actions} data={data} selectedRow={selectedRow} setShowToolTip={setShowToolTip} showTooltip={showTooltip} showMenu={showMenu} setShowMenu={setShowMenu} />;

                  if (tableKeys[0] === "companyTable" && key === "isActive") return <StatusHandler currentPage={currentPage} pageSize={pageSize} data={data[key]} id={data._id} />;
                  if (key === "companyEmail" || key === "companyLevel1Email")
                    return (
                      <td>
                        <div className="flex flex-wrap justify-start">{data[key] ? <div className="w-48 mx-7 break-words">{data[key].join(", ")}</div> : "-"}</div>
                      </td>
                    );

                  let cellValue = data[key];

                  if (key.includes(".")) {
                    const nestedKeys = key.split(".");
                    let nestedData = data;
                    for (const nestedKey of nestedKeys) {
                      nestedData = nestedData[nestedKey];
                      if ([undefined, null].includes(nestedData)) {
                        cellValue = "-";
                        break;
                      } else {
                        cellValue = nestedData;
                      }
                    }
                  }
                  return (
                    <td key={i} className="text-left pr-6 py-4 ">
                      {cellValue || "-"}
                    </td>
                  );
                })}
              </tr>
            ))
          ) : (
            <td colSpan={tableHeading.length} className="text-center text-xl pt-2">
              No data found
            </td>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ActionTable;
