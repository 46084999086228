import React, { FC, useEffect, useState } from "react";
import Container from "../../../component/common/Container";
import { LuRefreshCw } from "react-icons/lu";
import ButtonOutline from "../../../component/semantic/Button";
import Divide from "../../../component/semantic/Divide";
import LegalStats from "./stats/legal.stats";
import { useAppSelector } from "../../../hooks/redux_toolkit";
import InsuranceStats from "./stats/Insurance.stats";
import { settledCaseHeading, settledCaseKeys } from "../../../component/table/TableConstants";
import DashboardTable from "./stats/DashboardTable";
import SimpleTableDashboard from "./stats/SimpleTable.dashboard";

interface StatsProps {
  refreshHandler: any;
  selectedStatus: string;
  selectedFilter: { name: string };
  customDate: { startDate: string; endDate: string };
}

const Stats: FC<StatsProps> = ({ selectedStatus, refreshHandler, selectedFilter, customDate }) => {
  const [statsData, setStatsData] = useState("");
  const [tableData, setTableData] = useState([]);

  const registrationData = useAppSelector((state) => state.rootReducer.newDashboard.getPartnerRegistrationCount.data);
  const resolutionData = useAppSelector((state) => state.rootReducer.newDashboard.getPartnerResolvedCount.data);
  const invoiceData = useAppSelector((state) => state.rootReducer.newDashboard.invoiceData.data);
  const settledData = useAppSelector((state) => state.rootReducer.newDashboard.settledData.data);
  const resentCaseData = useAppSelector((state) => state.rootReducer.newDashboard.resendCasesCount.data);
  const tableDataOmbudsman = useAppSelector((state) => state.rootReducer.newDashboard.getOmdCountTable.data);
  const tableDataMailing = useAppSelector((state) => state.rootReducer.newDashboard.getMailingCountTable.data);

  useEffect(() => {
    if (selectedStatus === "Partner Registration Dashboard") setStatsData(registrationData);
    else if (selectedStatus === "Partner Resolution Dashboard") setStatsData(resolutionData);
    else if (selectedStatus === "Partner Settled Cases") setStatsData(settledData ? settledData?.countData : "");
    else if (selectedStatus === "Partner Invoice Cases") setStatsData(invoiceData ? invoiceData?.countData : "");
  }, [selectedStatus, resolutionData, registrationData, settledData, invoiceData]);

  useEffect(() => {
    if (selectedStatus === "Partner Ombudsman Dashboard") setTableData(tableDataOmbudsman);
    else if (selectedStatus === "Partner Mailing Dashboard") setTableData(tableDataMailing);
  }, [tableDataOmbudsman, tableDataMailing]);

  const insuranceSections = ["Partner Registration Dashboard", "Partner Resolution Dashboard"];
  return (
    <Container customClassName="my-6 ">
      <div className="flex justify-between items-center">
        <p className="text-base md:text-2xl font-bold md:font-extrabold">
          {selectedStatus} {customDate.startDate ? `(${customDate.startDate} ➤ ${customDate.endDate})` : selectedFilter?.name ? `(${selectedFilter?.name})` : ""}
        </p>
        <ButtonOutline title="Refresh" onClick={refreshHandler} icon={<LuRefreshCw />} className={`hover:bg-[#00733B] bg-white text-[#00733B] hover:text-white border-2 border-[#00733B] w-32 mb-2 `} />
      </div>

      <Divide className="my-4" />

      {insuranceSections.includes(selectedStatus) && <InsuranceStats statsData={statsData} selectedStatus={selectedStatus} />}

      {/* Ombudsman Section */}
      {(selectedStatus === "Partner Ombudsman Dashboard" || selectedStatus === "Partner Mailing Dashboard") && <DashboardTable tableData={tableData} />}
      {selectedStatus === "Partner Resend Cases" && <SimpleTableDashboard tableData={resentCaseData} tableHeading={settledCaseHeading} tableKeys={settledCaseKeys} />}

      {/* Legal Section */}
      {selectedStatus === "Partner Dashboard" && <LegalStats />}

      {/* Resolution Section */}
      {selectedStatus === "Partner Settled Cases" && (
        <>
          <SimpleTableDashboard tableData={settledData?.sendData} tableHeading={settledCaseHeading} tableKeys={settledCaseKeys} />
          <InsuranceStats statsData={statsData} selectedStatus={selectedStatus} />
        </>
      )}

      {selectedStatus === "Partner Invoice Cases" && (
        <>
          <SimpleTableDashboard tableData={invoiceData?.sendData} tableHeading={settledCaseHeading} tableKeys={settledCaseKeys} />
          <InsuranceStats statsData={statsData} selectedStatus={selectedStatus} />
        </>
      )}
    </Container>
  );
};

export default Stats;
