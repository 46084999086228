import { createSlice } from "@reduxjs/toolkit";
import { getInitUserThunk, deactivateInitUserThunk, approveUserThunk, updateAppUserThunk } from "../thunk/initUser.thunk";
import { toast } from "react-toastify";

interface ApiState {
  data: any;
  loading: boolean;
  error: string | null;
  usersList: responseData;
}

interface responseData {
  data: any;
}

const initialState: ApiState = {
  data: null,
  loading: false,
  error: null,
  usersList: { data: "" }
};

const InitUsers = createSlice({
  name: "initUserSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getInitUserThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getInitUserThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.usersList = action.payload;
        state.error = null;
      })
      .addCase(getInitUserThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "An error occurred";
      })
      .addCase(deactivateInitUserThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(deactivateInitUserThunk.fulfilled, (state, action) => {
        if (action.payload.success) toast.success(action.payload.message);
        state.loading = false;
        state.error = null;
      })
      .addCase(deactivateInitUserThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "An error occurred";
      })
      .addCase(approveUserThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(approveUserThunk.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(approveUserThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "An error occurred";
      })
      .addCase(updateAppUserThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateAppUserThunk.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(updateAppUserThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "An error occurred";
      });
  }
});

export default InitUsers.reducer;
