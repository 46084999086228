import React, { FC } from "react";
import Modal from "../../../component/common/Modal";
import Typography from "../../../component/semantic/Typography";
import ButtonOutline from "../../../component/semantic/Button";

interface ModalProps {
  showDeleteModal: boolean;
  setDeleteModal: any;
  deleteCompanyHandler: any;
}

const DeleteCompany: FC<ModalProps> = ({ showDeleteModal, setDeleteModal, deleteCompanyHandler }) => {
  return (
    <Modal showModal={showDeleteModal} onClose={() => setDeleteModal(false)} className="flex flex-col justify-center z-50 items-center gap-4">
      <Typography variant="h2">Are you sure you want to delete this company ?</Typography>
      <div className="flex justify-center gap-3">
        <ButtonOutline title="Cancel" color="black" className="hover:bg-black hover:text-white" onClick={() => setDeleteModal(false)} />
        <ButtonOutline title="Delete" className=" bg-polyfixTheme" onClick={deleteCompanyHandler} />
      </div>
    </Modal>
  );
};

export default DeleteCompany;
