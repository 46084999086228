import React, { FC, useState } from "react";
import view from "../../../assets/icons/gallery/view.svg";
import download from "../../../assets/icons/gallery/download.svg";
import Typography from "../../../component/semantic/Typography";
import SendToPartner from "./Dialogs/SendToPartner";
import ViewImg from "./Dialogs/ViewImg";
import phoneIcon from "../../../assets/icons/gallery/phone.svg";
import { getCurrentUser } from "../../../helpers/currentUser";
import { downloadTemplateThunk } from "../../../store/thunk/Gallery.thunk";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux_toolkit";
import Spinner from "../../../component/common/Spinner";
import { PERMISSIONS } from "../../../helpers/constants/constants";

interface ImgProps {
  imgSrc: string;
  title: string;
  obj: any;
}

const FestivalCard: FC<ImgProps> = ({ imgSrc, title, obj }) => {
  const [showAction, setShowAction] = useState(false);
  const [sendToPartnerOpen, setsendToPartnerOpen] = useState(false);
  const [viewImgModal, setViewImgModal] = useState(false);
  const [isLoading, setLoading] = useState("");

  const partnerLogoUrl: string = useAppSelector((state) => state.rootReducer.common.partnerLogo);

  const dispatch = useAppDispatch();

  const userInfo = getCurrentUser();
  const handleDownload = (id: any) => {
    setLoading(id);
    dispatch(downloadTemplateThunk({ id, modify: true }))
      .unwrap()
      .then((data) => {
        if (data) {
          const blob = new Blob([data], { type: "image/png" });
          const url = window.URL.createObjectURL(blob);

          const a = document.createElement("a");
          a.href = url;
          a.download = "template.png"; // Specify the filename
          document.body.appendChild(a);
          a.click();
          a.remove();

          // Optional: revoke the URL object to free up memory
          window.URL.revokeObjectURL(url);
          setLoading("");
        }
      });
  };
  const currentUser = getCurrentUser();

  if (isLoading == obj._id) {
    return (
      <div key={"download" + obj._id} className="h-full bg-gray-300 relative flex justify-center items-center">
        <img src={obj?.mediaUrl} className="opacity-50 w-full h-auto" />
        <div className="absolute inset-0 flex justify-center items-center">
          <Spinner className="text-3xl" />
        </div>
      </div>
    );
  }

  return (
    <div className={`w-full border-2 shadow-lg relative ${showAction && ""}`} onMouseEnter={() => setShowAction(true)} onMouseLeave={() => setShowAction(false)}>
      <img src={imgSrc} className="w-full h-80" />
      {!currentUser?.roles.includes(PERMISSIONS.ADMIN) ? (
        <>
          {partnerLogoUrl && <img src={partnerLogoUrl} className="absolute top-[2%] left-[4%] w-[20%] h-[10%]" />}
          <div className="absolute bottom-12 w-full py-2 bg-[#00000066]  pl-8">
            <p className="font-bold text-white">{userInfo?.name}</p>
            <div className="flex gap-2  ">
              <img src={phoneIcon} className="w-3" />
              <p className="font-bold text-white">{userInfo?.mobile}</p>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      <p className="text-center p-3 font-bold">{title}</p>
      <div className={`absolute transition-all w-full h-[86%] top-0  brightness-100 backdrop-brightness-50 ${!showAction && "hidden"}`}>
        <div className="flex gap-4 absolute items-center justify-center h-full w-full">
          <div className="flex gap-3 items-start">
            <div className="flex flex-col justify-center items-center gaps-1 cursor-pointer">
              <img src={view} onClick={() => setViewImgModal(true)} className="w-6 h-6  md:h-10 md:w-10" />
              <Typography className="text-white">View</Typography>
            </div>
            <div className="flex flex-col justify-center items-center gap-1 cursor-pointer">
              <img src={download} onClick={() => handleDownload(obj?._id)} className="w-6 h-6  md:h-10 md:w-10" />
              <Typography className="text-white">Download</Typography>
            </div>
            {currentUser?.roles.includes(PERMISSIONS.ADMIN) && (
              <div className="flex flex-col justify-center items-center gap-1 w-24 cursor-pointer ">
                <img src={download} onClick={() => setsendToPartnerOpen(true)} className="w-6 h-6  md:h-10 md:w-10" />
                <Typography className="text-white text-center">Download For Partners</Typography>
              </div>
            )}
          </div>
        </div>
      </div>
      <SendToPartner addLogoModal={sendToPartnerOpen} setShowAddLogoModal={setsendToPartnerOpen} imgSrc={imgSrc} />
      <ViewImg setShowImg={setViewImgModal} showImg={viewImgModal} imgSrc={imgSrc} />
    </div>
  );
};

export default FestivalCard;
