import React, { FC } from "react";
import Modal from "../../../../component/common/Modal";
import Typography from "../../../../component/semantic/Typography";
import CloseIcon from "../../../../assets/icons/gallery/close.svg";
import Select from "react-select";
import { polyfixSelect } from "../../../../helpers/constants/styles";
import ButtonOutline from "../../../../component/semantic/Button";

interface ModalProps {
  updateDetails: boolean;
  setUpdateDetails: any;
  selectedComplaint: any;
}

const UpdateDetails: FC<ModalProps> = ({ updateDetails, setUpdateDetails }) => {
  return (
    <Modal showModal={updateDetails} onClose={() => setUpdateDetails(false)} className="relative w-[35%] p-8">
      <img src={CloseIcon} className="absolute text-3xl text-gray-400 right-[-15px] top-[-20px] cursor-pointer" onClick={() => setUpdateDetails(false)} />
      <Typography className="text-2xl font-semibold">Update Details</Typography>
      <div className="mt-8 mb-8">
        <Typography className="font-extrabold">Update Multiple Details</Typography>
      </div>
      <div className="flex justify-between items-start mt-2 gap-2">
        <Select options={[{ label: "Email Wise", value: "Email Wise" }]} styles={polyfixSelect} className="w-[50%]" placeholder="Select Type" />
        <Select options={[{ label: "Select Users", value: "Select Users" }]} styles={polyfixSelect} className="w-[50%]" placeholder="Select Multiple Users" />
      </div>
      <div className="flex justify-start items-start mt-2">
        <Select options={[{ label: "Pending", value: "Pending" }]} styles={polyfixSelect} className="w-[50%] mt-4" placeholder="Select The Section" />
      </div>
      <div className="flex justify-end items-center pt-8">
        <ButtonOutline className="whitespace-nowrap bg-[#FAAA52]" title="Update Case" />
      </div>
    </Modal>
  );
};

export default UpdateDetails;
