import React from "react";
import { Tooltip } from "react-tooltip";

const ActionHandler = ({ selectedRow, actions, data, index }: any) => {
  return (
    <td className="" key={index}>
      <div className="flex justify-center w-[50%]">
        {actions.map((x: any, i: number) => {
          const { actionPlaceholder = "-" } = x;
          let d = 2;
          if (x?.isDisplay && x?.isDisplay(data)) {
            d = 1;
          }
          return d == 1 ? (
            actionPlaceholder
          ) : (
            <>
              <div
                className=""
                onClick={() => {
                  selectedRow(data);
                  x.onClickFunction();
                }}
                data-tooltip-id="my-tooltip"
                data-tooltip-content={x.tooltip}
              >
                {x.image}
              </div>
              <Tooltip delayShow={1} key={i} id="my-tooltip" place="bottom" />
            </>
          );
        })}
      </div>
    </td>
  );
};

export default ActionHandler;
