import { createSlice } from "@reduxjs/toolkit";
import { dashboardBarStats, dashboardCardStats, userList } from "../thunk/Dashboard.thunk";

interface ApiState {
  data: any;
  loading: "idle" | "pending" | "fulfilled" | "rejected";
  error: string | null;
  barData: responseData;
  cardData: any;
  cardLoading: boolean;
  graphLoading: boolean;
  userList: responseData;
  userProfile: {
    data?: object;
    message?: string;
    status?: number;
    success?: true;
  };
}

interface responseData {
  data: any;
}

const initialState: ApiState = {
  data: null,
  loading: "idle",
  cardLoading: false,
  graphLoading: false,
  error: null,
  userList: { data: "" },
  barData: { data: "" },
  cardData: { data: "" },
  userProfile: {}
};

const dashboardSlice = createSlice({
  name: "dashboardSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(userList.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(userList.fulfilled, (state, action) => {
        state.loading = "fulfilled";
        state.userList = action.payload;
        state.error = null;
      })
      .addCase(userList.rejected, (state, action) => {
        state.loading = "rejected";
        state.error = action.error.message ?? "An error occurred";
      })

      .addCase(dashboardBarStats.pending, (state) => {
        state.graphLoading = true;
      })
      .addCase(dashboardBarStats.fulfilled, (state, action) => {
        state.graphLoading = false;
        state.barData = action.payload;
        state.error = null;
      })
      .addCase(dashboardBarStats.rejected, (state, action) => {
        state.graphLoading = false;
        state.error = action.error.message ?? "An error occurred";
      })

      .addCase(dashboardCardStats.pending, (state) => {
        state.cardLoading = true;
      })
      .addCase(dashboardCardStats.fulfilled, (state, action) => {
        state.cardLoading = false;
        state.cardData = action.payload;
        state.error = null;
      })
      .addCase(dashboardCardStats.rejected, (state, action) => {
        state.cardLoading = false;
        state.error = action.error.message ?? "An error occurred";
      });
  }
});

export default dashboardSlice.reducer;
