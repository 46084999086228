import { FormikTouched, FormikValues } from "formik";
import React from "react";

export type InputProps = {
  label?: string;
  name?: string;
  placeholder?: string;
  type?: string;
  className?: string;
  onClick?: (event: React.MouseEvent) => void;
  disabled?: boolean;
  max?: string;
  parentClassName?: string;
  value?: any;
  limit?: number;
  min?: string;
  formProps: {
    handleChange: {
      (e: React.ChangeEvent<any>): void;
      <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void;
    };
    errors: any;
    touched: FormikTouched<FormikValues>;
    handleBlur: {
      (e: React.FocusEvent<any, Element>): void;
      <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
    };
    values: FormikValues;
  };
  onBlur?: any;
  onChange?: any;
  onKeyDown?: any;
};

const FormInput: React.FC<InputProps> = ({ label, limit, onKeyDown, parentClassName, name = "", placeholder, value, onBlur, onChange, min, formProps, type, disabled, max }) => {
  const currentDate = new Date().toISOString().split("T")[0];
  const { handleChange, handleBlur, values, touched, errors } = formProps;

  const inputChangeHandler = (e: any) => {
    if (limit && e.target.value.length > limit) return;
    if (type == "number" && !(e.target.value === "" || /^[+]?\d*\.?\d*$/.test(e.target.value))) return;
    handleChange(e);
  };

  return (
    <div className={`space-y-1 ${parentClassName}`}>
      {label && (
        <label className="font-semibold " htmlFor={name}>
          {label}
        </label>
      )}
      <input onKeyDown={onKeyDown} onChange={onChange || inputChangeHandler} onBlur={onBlur || handleBlur} name={name} max={max && currentDate} min={min} disabled={disabled} type={type} placeholder={placeholder} value={value || values?.[name]} className={`appearance-none border  focus:ring-2 focus:outline-none ${touched?.[name] && errors?.[name] ? "focus:ring-red-200 border-red-400" : "focus:ring-blue-300"}  rounded w-full py-2 px-3 text-gray-700 leading-tight focus:shadow-outline`} />
      {touched?.[name] && errors?.[name] && <p className=" text-red-600 font-semibold text-sm">{errors?.[name]}</p>}
    </div>
  );
};

export default FormInput;
