import React, { FC, useEffect, useRef, useState } from "react";
import Modal from "../../../../component/common/Modal";
import Typography from "../../../../component/semantic/Typography";
import Select from "react-select";
import { polyfixSelect } from "../../../../helpers/constants/styles";
import CustomInput from "../../../../component/semantic/Input";
import { useAppSelector } from "../../../../hooks/redux_toolkit";
import FileUpload from "../../../../component/common/FileUpload";
import { FaFile } from "react-icons/fa";
import CloseIcon from "../../../../assets/icons/gallery/close.svg";
import ButtonOutline from "../../../../component/semantic/Button";
import DownloadIcon from "../../../../assets/icons/gallery/Download2.svg";
import phoneIconSrc from "../../../../assets/icons/gallery/phone.svg";
import axios from "../../../../helpers/axios";
import { baseUrl } from "../../../../helpers/constants/defaultUrls";

interface SendToPartnerProps {
  addLogoModal: boolean;
  setShowAddLogoModal: any;
  imgSrc: string;
}

const SendToPartner: FC<SendToPartnerProps> = ({ addLogoModal, setShowAddLogoModal, imgSrc }) => {
  const [selectedFile, setSelectedFiles] = useState<any>();
  const [formValue, setFormValue] = useState<any>({ name: "", phone: "" });
  const [selectedPartner, setSelectedPartner] = useState<any>();

  const canvasRef = useRef<any>(null);
  const highResCanvasRef = useRef<any>(null);

  useEffect(() => {
    if (addLogoModal) {
      const highResCanvas = highResCanvasRef.current;
      // const canvas = canvasRef.current;
      const ctx = highResCanvas.getContext("2d");

      if (!highResCanvas || !ctx) {
        console.error("Canvas or context not found");
        return;
      }

      const highResWidth = 1280;
      const highResHeight = 1280;
      highResCanvas.width = highResWidth;
      highResCanvas.height = highResHeight;

      ctx.clearRect(0, 0, highResCanvas.width, highResCanvas.height);

      const fetchData = async () => {
        try {
          const response = await axios.get(baseUrl + "v1/galleryv2/getImage", { params: { query: imgSrc }, responseType: "blob" });
          const blob = response.data;
          const url = URL.createObjectURL(blob);

          const backgroundImage = new Image();
          backgroundImage.crossOrigin = "anonymous";
          backgroundImage.src = url;

          backgroundImage.onload = () => {
            const scaleFactor = Math.min(highResCanvas.width / backgroundImage.width, highResCanvas.height / backgroundImage.height);
            const scaledWidth = backgroundImage.width * scaleFactor;
            const scaledHeight = backgroundImage.height * scaleFactor;

            ctx.drawImage(backgroundImage, 0, 0, scaledWidth, scaledHeight);

            if (selectedFile) {
              const additionalImage = new Image();
              additionalImage.src = URL.createObjectURL(selectedFile);
              additionalImage.onload = () => {
                ctx.drawImage(additionalImage, 40, 40, 200, 120); // Decreased logo size here
                URL.revokeObjectURL(additionalImage.src);
                drawTextAndIcons();
                copyHighResToDisplay();
              };
            } else {
              drawTextAndIcons();
              copyHighResToDisplay();
            }
          };

          backgroundImage.onerror = () => {
            console.error("Failed to load background image");
            URL.revokeObjectURL(url);
          };
        } catch (error) {
          console.error("Failed to fetch image", error);
        }
      };

      fetchData();

      const drawTextAndIcons = () => {
        const overlayHeight = 320;
        ctx.fillStyle = "rgba(0, 0, 0, 0.4)";
        ctx.fillRect(0, 1000, highResCanvas.width, overlayHeight);

        ctx.font = `bold 60px 'Nunito'`;
        ctx.fillStyle = "white";
        ctx.fillText(formValue.name, 80, 1120);

        const phoneIcon = new Image();
        phoneIcon.src = phoneIconSrc;
        ctx.drawImage(phoneIcon, 80, 1156, 48, 48);
        ctx.fillStyle = "white";
        ctx.fillText(formValue.phone, 148, 1196);
      };

      const copyHighResToDisplay = () => {
        const displayCanvas = canvasRef.current;
        const displayCtx = displayCanvas.getContext("2d");

        if (!displayCanvas || !displayCtx) {
          console.error("Display canvas or context not found");
          return;
        }

        const displayWidth = 320;
        const displayHeight = 320;
        displayCanvas.width = displayWidth;
        displayCanvas.height = displayHeight;

        displayCtx.clearRect(0, 0, displayWidth, displayHeight);

        displayCtx.drawImage(highResCanvas, 0, 0, highResWidth, highResHeight, 0, 0, displayWidth, displayHeight);
      };
    }
  }, [addLogoModal, imgSrc, formValue, selectedFile, phoneIconSrc]);

  useEffect(() => {
    return () => {
      setSelectedFiles("");
      setSelectedPartner("");
      setFormValue({ name: "", phone: "" });
    };
  }, [addLogoModal]);

  useEffect(() => {
    if (selectedPartner) setFormValue({ name: selectedPartner?.name, phone: selectedPartner?.mobile });
  }, [selectedPartner]);

  const handleDownload = () => {
    const highResCanvas = highResCanvasRef.current;
    const dataURL = highResCanvas.toDataURL("image/png");

    const downloadLink = document.createElement("a");
    downloadLink.href = dataURL;
    downloadLink.download = "image.png";
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);

    downloadLink.click();

    document.body.removeChild(downloadLink);
  };

  const userList = useAppSelector((state) => state.rootReducer.common.userListAll);
  return (
    <Modal showModal={addLogoModal} onClose={() => setShowAddLogoModal(false)} className="w-5/12 mt-10 xl:mt-0 xl:w-7/12  h-full xl:h-[75%] pb-0 pl-0 pt-0 pr-0 relative ">
      <img src={CloseIcon} className="absolute text-3xl text-gray-400 right-[-15px] top-[-20px] cursor-pointer" onClick={() => setShowAddLogoModal(false)} />
      <div className="overflow-scroll h-full custom-polifyx-scrollbar flex flex-col xl:flex-row">
        <div className="w-full p-8 ">
          <Typography>Select Partner</Typography>
          <Select options={userList.data || []} getOptionLabel={(option: any) => option.name} getOptionValue={(option: any) => option._id} className="w-full my-2" name="userId" value={selectedPartner} onChange={(option) => setSelectedPartner(option)} styles={polyfixSelect} placeholder="Select User" />
          <Typography className="my-8 text-lg">Partners Details</Typography>
          <Typography className="mb-2">Name</Typography>
          <CustomInput value={formValue.name} onChange={(e) => setFormValue((prev: any) => ({ ...prev, name: e.target.value }))} />
          <Typography className="my-2">Phone</Typography>
          <CustomInput type="number" limit={10} value={formValue.phone} onChange={(e) => setFormValue((prev: any) => ({ ...prev, phone: e.target.value }))} />
          <span className="flex justify-between">
            <Typography className="my-3">Add logo</Typography>
            <span className="items-center flex ">
              {selectedFile && (
                <div className="text-gray-700 bg-uploadTheme flex px-4 rounded-lg py-1 gap-1 items-center">
                  <FaFile className="text-gray-400 opacity-50" />
                  {selectedFile.name}
                </div>
              )}
            </span>
          </span>
          <FileUpload accept=".jpg, .jpeg, .png ,.svg" setSelectedFile={setSelectedFiles} />
        </div>
        <div className="px-8 py-12 w-full flex-col gap-5 flex items-center justify-center shadow-2xl border-l-2">
          <canvas ref={canvasRef} width="320" height="320" />
          <canvas ref={highResCanvasRef} style={{ display: "none" }} />
          <ButtonOutline title="DOWNLOAD" onClick={handleDownload} className="bg-polyfixTheme hover:text-black font-bold px-6" icon={<img src={DownloadIcon} className=" text-customgray " />} />
        </div>
      </div>
    </Modal>
  );
};

export default SendToPartner;
