import React, { useState } from "react";

// import ReelModal from "../../gallery/component/Dialogs/ReelModal";
import Typography from "../../../component/semantic/Typography";

import Image1 from "../../../assets/images/login/1d.png";
import Image2 from "../../../assets/images/login/2d.png";
import Image3 from "../../../assets/images/login/3d.png";
import Image4 from "../../../assets/images/login/4d.png";
import PlayIcon from "../../../assets/icons/home/playIcon.svg";
import Modal from "../../../component/common/Modal";
import ReactPlayer from "react-player";

function VideosTestimonial() {
  const [playVideo, setPlayVideo] = useState(false);
  const [videoLink, setVideoLink] = useState("");

  const handleOpenVideo = (url: string) => {
    setPlayVideo(true);
    setVideoLink(url);
  };

  return (
    <div className="bg-white p-2 pt-8 md:pt-6 md:p-20 pb-[150px] md:pb-6">
      <div className="flex justify-center md:w-full w-[75%] mx-auto text-center">
        <Typography className="text-2xl md:text-5xl" variant="h2">
          Hear from our partners
        </Typography>
      </div>
      <div className="mx-auto w-[95%] md:w-[40%] mt-4">
        <Typography className="text-sm md:text-lg text-center">We are proud to have worked with various partners whose support has been valuable to us. Take a look at how we made a difference.</Typography>
      </div>
      <div className="flex flex-wrap w-[90%] justify-center m-auto gap-4 pt-8">
        <div className="rounded-lg relative">
          <img alt="image" src={Image1} />
          <div className="absolute flex justify-center m-auto top-[40%] w-full">
            <div className="flex justify-center mx-auto w-full ">
              <img
                alt="play-icon"
                src={PlayIcon}
                className="hover:scale-125 transition-all cursor-pointer"
                onClick={() => {
                  handleOpenVideo("https://youtube.com/shorts/rghkyQ-Rz6w?feature=share");
                }}
              />
            </div>
          </div>
        </div>
        <div className="rounded-lg relative">
          <img alt="image" src={Image2} />
          <div className="absolute flex justify-center m-auto top-[40%] w-full">
            <div className="flex justify-center mx-auto w-full ">
              <img
                alt="play-icon"
                src={PlayIcon}
                className="hover:scale-125 transition-all cursor-pointer"
                onClick={() => {
                  handleOpenVideo("https://www.youtube.com/shorts/AhmtWoiFJZo");
                }}
              />
            </div>
          </div>
        </div>
        <div className="rounded-lg relative">
          <img alt="image" src={Image3} />
          <div className="absolute flex justify-center m-auto top-[40%] w-full">
            <div className="flex justify-center mx-auto w-full ">
              <img
                alt="play-icon"
                src={PlayIcon}
                className="hover:scale-125 transition-all cursor-pointer"
                onClick={() => {
                  handleOpenVideo("https://youtu.be/XLYAUBV3GmE");
                }}
              />
            </div>
          </div>
        </div>
        <div className="rounded-lg relative">
          <img alt="image" src={Image4} />
          <div className="absolute flex justify-center m-auto top-[40%] w-full">
            <div className="flex justify-center mx-auto w-full ">
              <img
                alt="play-icon"
                src={PlayIcon}
                className="hover:scale-125 transition-all cursor-pointer"
                onClick={() => {
                  handleOpenVideo("https://www.youtube.com/shorts/nimDPzT1eTI");
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        showModal={playVideo}
        onClose={() => {
          setPlayVideo(!playVideo);
        }}
        className="w-[50%] h-[60%]"
      >
        {playVideo && <ReactPlayer url={videoLink} width={"100%"} height={"100%"} controls />}
      </Modal>
    </div>
  );
}
export default VideosTestimonial;
