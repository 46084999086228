import React, { FC, useEffect, useState } from "react";
import Modal from "../../../../component/common/Modal";
import CloseIcon from "../../../../assets/icons/gallery/close.svg";
import Typography from "../../../../component/semantic/Typography";
import ButtonOutline from "../../../../component/semantic/Button";
import ShowFile from "./ShowFile";
import Divide from "../../../../component/semantic/Divide";
import { useAppDispatch } from "../../../../hooks/redux_toolkit";
import { downloadLeadDocThunk, uploadLeadDocThunk } from "../../../../store/thunk/NewCase.thunk";
import { docDynamicField } from "../../../../helpers/constants/constants";
import { toast } from "react-toastify";
import DownloadDoc from "../../../../assets/icons/newCase/downloadDoc.svg";
import FileUploadLead from "../FileUpload";
import { getSingleLeadInfo } from "../../../../store/thunk/AllLead.thunk";

interface UploadDocsProps {
  showModal: boolean;
  setShowModal: any;
  createdLeadInfo: any;
  setSelectedLead: any;
}

const UploadDocs: FC<UploadDocsProps> = ({ showModal, setShowModal, createdLeadInfo, setSelectedLead }) => {
  const initialGeneralDoc: any = {
    policyBook: [],
    rejectionLetter: [],
    claimBill: [],
    surveyorReport: [],
    settlementLetter: [],
    communicationCompany: [],
    callRecording: [],
    deathCertificateGi: [],
    dischargeSummGi: [],
    FIRGi: [],
    postMortemReportGi: [],
    drReportGi: []
  };

  const initialHealthDoc: any = {
    dischargeSummary: [],
    rejectionLetter: [],
    claimBill: [],
    policyDocHi: [],
    settlementLetterHi: [],
    communicationCompanyHi: [],
    callRecordingHi: [],
    previousPolicyHi: [],
    drCertificateHi: [],
    RT_PCRTestHi: [],
    DeathCertificateHi: [],
    FIRHi: [],
    PostMortemReportHi: []
  };

  const initialLifeDoc: any = {
    policySchedule: [],
    proofOfRecording: [],
    rejectionLetterLi: [],
    claimFormLi: [],
    communicationCompanyLi: [],
    callRecordingLi: [],
    dischargeSummLi: [],
    drReportLi: [],
    deathCertificate: [],
    FIR: [],
    postMortemReport: []
  };
  const dispatch = useAppDispatch();

  const [generalDoc, setGeneralDoc] = useState<any>({ ...initialGeneralDoc });

  const [healthDoc, setHealthDoc] = useState<any>({ ...initialHealthDoc });

  const [lifeDoc, setLifeDoc] = useState<any>({ ...initialLifeDoc });
  const uploadHandler = (file: any, field: string, label: string, setLoading: any) => {
    const fileName = label + "_" + new Date().getTime();

    const formData = new FormData();
    formData.append(field, file, fileName);
    formData.append("docCreationSrc", "partner-web");
    dispatch(uploadLeadDocThunk({ leadId: createdLeadInfo?._id, formData }))
      .unwrap()
      .then((data: any) => {
        if (data.status == 200) {
          dispatch(getSingleLeadInfo(createdLeadInfo._id))
            .unwrap()
            .then((data) => {
              if (data?.data?.success) {
                setSelectedLead(data?.data?.data);
              }
            });
          setLoading(false);
          toast.success(data.msg);
        }
      });
  };

  const downloadDocHandler = () => {
    dispatch(downloadLeadDocThunk(createdLeadInfo?._id))
      .unwrap()
      .then((data) => {
        if (data) {
          const blob = new Blob([data], { type: "image/png" });
          const url = window.URL.createObjectURL(blob);

          const a = document.createElement("a");
          a.href = url;
          a.download = "allDocs.zip";
          document.body.appendChild(a);
          a.click();
          a.remove();

          window.URL.revokeObjectURL(url);
        } else {
          // eslint-disable-next-line
          console.error("Invalid data object for createObjectURL:", data);
        }
      });
  };

  useEffect(() => {
    if (createdLeadInfo) {
      const docFields = docDynamicField(createdLeadInfo?.complaintTypeId?._id);
      if (createdLeadInfo?.doc?.length <= 0) return;
      const policyType = createdLeadInfo?.policyTypeId?.name;
      if (policyType === "General Insurance") {
        const temp = { ...initialGeneralDoc };
        createdLeadInfo.doc.map((obj: any) => {
          let docName: any;
          if (obj.includes("__")) {
            const parts = obj.split("__");
            docName = [parts[0] + "_"];
          } else {
            docName = obj.split("_");
          }
          const key = docFields[createdLeadInfo?.policyTypeId?.name].find((doc: any) => doc?.fileName === docName[0]);
          temp[key?.name]?.push(obj);
        });
        setGeneralDoc(temp);
      } else if (policyType === "Health Insurance") {
        const temp = { ...initialHealthDoc };
        createdLeadInfo.doc.map((obj: any) => {
          let docName: any;
          if (obj.includes("__")) {
            const parts = obj.split("__");
            docName = [parts[0] + "_"];
          } else {
            docName = obj.split("_");
          }
          const key = docFields[createdLeadInfo?.policyTypeId?.name].find((doc: any) => doc?.fileName === docName[0]);
          temp[key?.name]?.push(obj);
        });
        setHealthDoc(temp);
      } else if (policyType === "Life Insurance") {
        const temp = { ...initialLifeDoc };
        createdLeadInfo.doc.map((obj: any) => {
          let docName: any;
          if (obj.includes("__")) {
            const parts = obj.split("__");
            docName = [parts[0] + "_"];
          } else {
            docName = obj.split("_");
          }
          const key = docFields[createdLeadInfo?.policyTypeId?.name].find((doc: any) => doc?.fileName === docName[0]);
          temp[key?.name]?.push(obj);
        });
        setLifeDoc(temp);
      }
    }

    return () => {
      setGeneralDoc(initialGeneralDoc);
      setLifeDoc(initialLifeDoc);
      setHealthDoc(initialHealthDoc);
    };
  }, [createdLeadInfo]);
  const docFields = docDynamicField(createdLeadInfo?.complaintTypeId?._id);

  return (
    <Modal showModal={showModal} onClose={() => setShowModal(false)} className={` w-[50%] py-10 px-8 relative `}>
      <img src={CloseIcon} className="absolute text-3xl text-gray-400 -right-3 -top-3 cursor-pointer" onClick={() => setShowModal(false)} />
      <header>
        <Typography variant="h1">Upload Documents</Typography>
        <span className="flex flex-col lg:flex-row w-full gap-2 justify-between items-center">
          <Typography className="text-lg font-bold w-full lg:w-[60%]">Upload necessary documents for the lead so that our experts can evaluate your case.</Typography>
          <div className="flex">
            <ButtonOutline title="Download All Docs" className="hover:bg-black hover:text-white w-full " onClick={downloadDocHandler} icon={<img src={DownloadDoc} />} />
          </div>
        </span>
      </header>
      <div className="w-full md:w-[80%] pt-2 h-80 space-y-1 overflow-y-scroll custom-polifyx-scrollbar mt-6 ">
        {createdLeadInfo &&
          docFields[createdLeadInfo?.policyTypeId?.name]?.map((obj: any) => {
            return (
              <>
                <Typography>{obj.label}</Typography>
                {createdLeadInfo?.policyTypeId?.name == "General Insurance" && obj.name && <FileUploadLead id={obj.name} apiFunction={(file: any, setLoading: any) => uploadHandler(file, obj.name, obj.fileName, setLoading)} />}
                {createdLeadInfo?.policyTypeId?.name == "Health Insurance" && obj.name && <FileUploadLead id={obj.name} apiFunction={(file: any, setLoading: any) => uploadHandler(file, obj.name, obj.fileName, setLoading)} />}
                {createdLeadInfo?.policyTypeId?.name == "Life Insurance" && obj.name && <FileUploadLead id={obj.name} apiFunction={(file: any, setLoading: any) => uploadHandler(file, obj.name, obj.fileName, setLoading)} />}

                {createdLeadInfo?.policyTypeId?.name == "General Insurance" && <ShowFile createdLeadInfo={createdLeadInfo} fileArr={generalDoc} docName={obj.name} setFileArr={setGeneralDoc} />}
                {createdLeadInfo?.policyTypeId?.name == "Health Insurance" && <ShowFile createdLeadInfo={createdLeadInfo} fileArr={healthDoc} docName={obj.name} setFileArr={setHealthDoc} />}
                {createdLeadInfo?.policyTypeId?.name == "Life Insurance" && <ShowFile createdLeadInfo={createdLeadInfo} fileArr={lifeDoc} docName={obj.name} setFileArr={setLifeDoc} />}
              </>
            );
          })}
      </div>
      <Divide className="my-3 -mx-8  shadow-above-border" />
      <div className="block md:flex flex-row-reverse">
        <ButtonOutline className={`bg-polyfixTheme w-full `} title="Submit Case" type="submit" onClick={() => setShowModal(false)} />
      </div>
    </Modal>
  );
};

export default UploadDocs;
