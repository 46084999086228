import React, { FC, Fragment } from "react";
import Modal from "../../../../component/common/Modal";
import CloseIcon from "../../../../assets/icons/gallery/close.svg";
import Typography from "../../../../component/semantic/Typography";
import invoiceIcon from "../../../../assets/icons/allcomplaints/viewInvoice.svg";
import pdfIcon from "../../../../assets/icons/allcomplaints/pdf.svg";
import ButtonOutline from "../../../../component/semantic/Button";
import { FaEye } from "react-icons/fa";
import { MdDownloadForOffline } from "react-icons/md";
import { backendUrl } from "../../../../helpers/constants/defaultUrls";

interface ModalProps {
  viewInvoice: boolean;
  setInvoice: any;
  selectedComplaint: any;
}

const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

const ViewInvoice: FC<ModalProps> = ({ viewInvoice, setInvoice, selectedComplaint }) => {
  return (
    <Modal showModal={viewInvoice} onClose={() => setInvoice(false)} className="relative w-[55%] p-8">
      <img src={CloseIcon} className="absolute text-3xl text-gray-400 right-[-15px] top-[-20px] cursor-pointer" onClick={() => setInvoice(false)} />
      <div>
        <Typography variant="h1" className="mb-5">
          View Invoice
        </Typography>
        <div className="h-[27rem] shadow-all overflow-y-auto">
          <div>
            <div className="relative shadow-all px-6 py-4 flex gap-4 items-center">
              <img src={invoiceIcon} />
              <div className="flex gap-1 flex-col">
                <Typography variant="h2">Proforma Invoices</Typography>
              </div>
            </div>
            <div className="grid grid-cols-2 grid-rows-2 gap-3 items-center p-5">
              <div className="p-1">Invoice Name</div>
              <div className="p-1">Invoice Link</div>
              {selectedComplaint?.proformaInvoiceName?.length
                ? selectedComplaint?.proformaInvoiceName.map((data: any, i: number) => {
                    return (
                      <Fragment key={i}>
                        <div className="p-2 flex bg-[#FFF7EE] rounded">
                          <img src={pdfIcon} />
                          <p className="">
                            <span>{data}</span>
                          </p>
                        </div>
                        <div className="p-1 flex items-center gap-2">
                          <a target="_blank" rel="noreferrer" href={`${backendUrl}/app/paymentPdf/payment_document/${selectedComplaint.finalPaymentPdfPath}/${data}`}>
                            <ButtonOutline title="View" icon={<FaEye />} className="hover:bg-black hover:text-white border-2 border-[#00733B] w-full py-4 h-8" />
                          </a>
                          <a target="_blank" rel="noreferrer noopener" href={`${backendUrl}/app/paymentPdf/payment_document/${selectedComplaint.finalPaymentPdfPath}/${data}`} download>
                            <ButtonOutline title="Download" icon={<MdDownloadForOffline />} className="hover:bg-[#00733B] hover:text-white bg-white text-[#00733B] border-2 border-[#00733B] w-full py-4 h-8" />
                          </a>
                        </div>
                      </Fragment>
                    );
                  })
                : ""}
            </div>
          </div>

          <div>
            <div className="relative shadow-all px-6 py-4 flex gap-4 items-center p-5">
              <img src={invoiceIcon} />
              <div className="flex gap-1 flex-col">
                <Typography variant="h2">Tax Invoices</Typography>
              </div>
            </div>
            <div className="grid grid-cols-2 grid-rows-2 gap-3 items-center p-5">
              <div className=" p-1">Invoice Name</div>
              <div className=" p-1">Invoice Link</div>
              {selectedComplaint?.invoiceHistory?.length
                ? selectedComplaint?.invoiceHistory.map((data: any, i: number) => {
                    if (data?.invoiceNumber && data?.paidAt && selectedComplaint?.finalPaymentPdfPath) {
                      return (
                        <Fragment key={i}>
                          <div className=" p-2 flex gap-3 bg-[#FFF7EE] rounded">
                            <img src={pdfIcon} />
                            <p className="w-3/4">{data?.invoiceNumber}</p>
                          </div>
                          <div className="p-1 flex gap-2">
                            <a target="_blank" rel="noreferrer" href={`${backendUrl}/app/paymentPdf/paidInvoices/${monthNames[new Date(data?.paidAt * 1000).getMonth()]}_${new Date(data?.paidAt * 1000).getFullYear()}/${selectedComplaint?.finalPaymentPdfPath}/paidIn_${data?.invoiceNumber}.pdf`}>
                              <ButtonOutline title="View" icon={<FaEye />} className="hover:bg-black hover:text-white border-2 border-[#00733B] w-full h-8 mb-2" />
                            </a>
                            <a target="_blank" rel="noreferrer noopener" href={`${backendUrl}/app/paymentPdf/paidInvoices/${monthNames[new Date(data?.paidAt * 1000).getMonth()]}_${new Date(data?.paidAt * 1000).getFullYear()}/${selectedComplaint?.finalPaymentPdfPath}/paidIn_${data?.invoiceNumber}.pdf`} download>
                              <ButtonOutline title="Download" icon={<MdDownloadForOffline />} className="hover:bg-[#00733B] hover:text-white bg-white text-[#00733B] border-2 border-[#00733B] w-full h-8 mb-2" />
                            </a>
                          </div>
                        </Fragment>
                      );
                    }
                  })
                : ""}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ViewInvoice;
