import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../helpers/axios";
import { baseUrl } from "../../helpers/constants/defaultUrls";

interface pendingContractArgs {
  page: number;
  size: number;
  search: string;
  startDate: string | any;
  endDate: string | any;
}

export const getAllUserPendingContractThunk = createAsyncThunk("pendingContract/getPendingContractUsers", async (payload: pendingContractArgs) => {
  try {
    const response = await axios.get<{ data: any }>(baseUrl + `v1/user/getAllUsersPending?search=${payload.search}&page=${payload.page - 1}&size=${payload.size}&startDate=${payload.startDate}&endDate=${payload.endDate}`);
    return response.data;
  } catch (error: any) {
    return error;
  }
});

export const updateAndSendContractThunk = createAsyncThunk("pendingContract/updateAndSendContractThunk", async (payload: any) => {
  try {
    const response = await axios.post<{ data: any }>(baseUrl + `v1/user/updateAndSendContract`, payload);
    return response.data;
  } catch (error: any) {
    return error;
  }
});

export const sendPartnerContractThunk = createAsyncThunk("pendingContract/sendPartnerContractThunk", async (payload: any) => {
  try {
    const response = await axios.post<{ data: any }>(baseUrl + `v1/user/sendPartnerContract`, payload);
    return response.data;
  } catch (error: any) {
    return error;
  }
});

export const signPartnerContractThunk = createAsyncThunk("pendingContract/signPartnerContractThunk", async (payload: any) => {
  try {
    const response = await axios.put<{ data: any }>(baseUrl + `v1/user/signpartnercontract/${payload.userId}`, {});
    return response.data;
  } catch (error: any) {
    return error;
  }
});
