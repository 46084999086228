import React, { useState } from "react";
import uploadIcon from "../../../assets/images/profile/upload.svg";
import Spinner from "../../../component/common/Spinner";

interface FileProps {
  apiFunction?: any;
  accept?: string;
  id?: string;
}

const FileUploadLead: React.FC<FileProps> = ({ apiFunction, accept, id = "fileInput" }) => {
  const [loading, setLoading] = useState(false);
  const handleFileChange = (event: any) => {
    setLoading(true);
    const files = event.target.files[0];
    apiFunction(files, setLoading);
  };

  return (
    <div className={`${loading && "opacity-50 pointer-events-none"}`}>
      <input type="file" className="hidden" id={id} accept={accept ? accept : ".jpg, .jpeg, .png, .pdf"} onChange={handleFileChange} />
      <div className="flex gap-8 flex-col lg:flex-row">
        <label htmlFor={id} className="bg-uploadTheme border-[2px]  rounded-md py-4 gap-4 border-polyfixTheme border-dashed w-full flex justify-center items-center cursor-pointer font-semibold text-gray-400">
          {loading ? <Spinner className="text-xl -mt-4" /> : <img src={uploadIcon} className="w-8" />} <span>{loading ? "Uploading" : "Select File to upload"}</span>
        </label>
      </div>
    </div>
  );
};

export default FileUploadLead;
