import React, { FC } from "react";
import Modal from "../../../component/common/Modal";
import Typography from "../../../component/semantic/Typography";
import ButtonOutline from "../../../component/semantic/Button";
interface ModalProps {
  confirmationModal: boolean;
  setConfirmationModal: any;
  text: string;
  successButtonHandler: any;
}
const ConfirmationModal: FC<ModalProps> = ({ setConfirmationModal, confirmationModal, text, successButtonHandler }) => {
  return (
    <Modal showModal={confirmationModal} onClose={() => setConfirmationModal(false)} className="relative w-[35%] p-4 pb-0">
      <div className="pl-9">
        <Typography>{text}</Typography>
      </div>
      <div className="flex justify-around items-center w-full mt-6 px-7">
        <ButtonOutline onClick={successButtonHandler} className="text-white bg-[#1BA785] outline-[none]" type="button" title="YES" />
        <ButtonOutline
          onClick={() => {
            setConfirmationModal(false);
          }}
          className=" bg-[#FF3C3CED] outline-[none]"
          type="button"
          title="NO"
        />
      </div>
    </Modal>
  );
};
export default ConfirmationModal;
