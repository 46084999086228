import React, { ComponentType, useState } from "react";
import Navbar from "../layout/navbar";
import Sidebar from "../layout/sidebar";

const withNavbarSidebar = <P extends object>(WrappedComponent: ComponentType<P>, title: string) => {
  const WithNavbarSidebarMain: React.FC<P> = ({ ...props }) => {
    const [showSidebar, setShowSidebar] = useState(false);

    const toggleSidebar = () => {
      setShowSidebar(!showSidebar);
    };

    return (
      <>
        <Navbar title={title} toggleSidebar={toggleSidebar} />
        <Sidebar showSidebar={showSidebar}>
          <WrappedComponent {...(props as P)} />
        </Sidebar>
      </>
    );
  };
  return WithNavbarSidebarMain;
};

export default withNavbarSidebar;
