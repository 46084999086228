import { IoIosArrowDown } from "react-icons/io";
import Typography from "../../../component/semantic/Typography";
import ButtonOutlineIcon from "../../../component/semantic/Button";
import { handleCopy } from "../../../helpers/utils";
import { IoCopySharp } from "react-icons/io5";
import arrow from "../../../assets/icons/requirement/arrow.svg";
import React from "react";

interface Policy {
  policyName: string;
  documentNames: string[];
}

interface CollapseProps {
  policy: Policy;
  toggleCollapse: (policyName: string) => void;
  collapse: { id: string };
}

const Collapse: React.FC<CollapseProps> = ({ policy, toggleCollapse, collapse }) => {
  return (
    <>
      <div onClick={() => toggleCollapse(policy.policyName)} className="w-full bottom-1 bg-[#F4F5FA] transition-all duration-500 cursor-pointer flex justify-between px-4 items-center py-2 lg:py-4 mt-4 rounded border-[#E4E6F2] border-2">
        <Typography variant="h2">{policy.policyName}</Typography>
        <IoIosArrowDown className={`${collapse?.id === policy.policyName ? "-rotate-180" : ""} transition-all`} />
      </div>
      <div className={`bg-[#FAFBFC] transition-all ${collapse?.id !== policy.policyName ? "hidden" : "block"} flex justify-between items-start overflow-scroll custom-scrollbar   w-full p-5 px-5 lg:px-10 border-2 border-t-0`}>
        <div>
          <Typography variant="reg" className="my-4 text-sm lg:text-lg font-bold lg:font-bold">{`Documents required for ${policy.policyName.toLowerCase()}`}</Typography>
          {policy.documentNames.map((name, i) => {
            return (
              <span key={i} className="flex gap-3 items-center mb-2" id={i + ""}>
                <img src={arrow} alt="arrow" />
                <Typography className="text-xs font-semibold lg:text-base">{name}</Typography>
              </span>
            );
          })}
        </div>
        <div className="mt-4" onClick={() => handleCopy(policy.documentNames)}>
          <ButtonOutlineIcon title="Copy All" isCopy={true} color="black" className="hover:bg-black hover:text-white text-xs lg:text-base -mr-2 lg:mr-0 px-0 w-[6rem] lg:w-full" icon={<IoCopySharp className="-mt-1" />} />
        </div>
      </div>
    </>
  );
};

export default Collapse;
