import React from "react";
import Divide from "../../../component/semantic/Divide";
import { FaRegImage } from "react-icons/fa6";

const arr = [1, 2, 3, 4, 5];

const ChatSkeleton = () => {
  return (
    <>
      <div className="mb-4 -pt-1"></div>
      {arr.map((obj, i) => (
        <>
          <div key={obj} className="px-3 animate-pulse flex items-center gap-2 ">
            <div className="flex items-center justify-center w-16 h-12 bg-gray-300 rounded-lg ">
              <FaRegImage className="w-5 h-5 text-gray-200" />
            </div>
            <div className="w-full">
              <div className="flex justify-between">
                <div className="h-2.5 bg-gray-200 rounded-full w-40 mb-4"></div>
                <div className="h-2.5 bg-gray-200 rounded-full w-10 mb-4"></div>
              </div>
              <div className="h-2 bg-gray-200 rounded-full  w-32"></div>
            </div>
          </div>
          {i !== 4 && <Divide className="my-4" />}
        </>
      ))}
    </>
  );
};

export default ChatSkeleton;
