import React, { useEffect, useState } from "react";
import Container from "../../component/common/Container";
import ChatList from "./component/ChatList";
import ChatScreen from "./component/ChatScreen";
import { useAppDispatch, useAppSelector } from "../../hooks/redux_toolkit";
import { getAllContactsThunk, getLastMsgThunk } from "../../store/thunk/Chat.thunk";
import useSocketService from "../../services/Socket.service";
import { socketIOUrl } from "../../helpers/constants/defaultUrls";
import { setOnlineUser } from "../../store/slices/Common.slice";

const Chat = () => {
  const { on } = useSocketService(socketIOUrl);

  const [contactDetails, setContactDetail] = useState();
  const [lastChatList, setLastChatList] = useState("");
  const [selectedChat, setSelectedChat] = useState("");
  const [showMobChat, setMobChat] = useState(false);

  const dispatch = useAppDispatch();

  const chatSlice = useAppSelector((state) => state.rootReducer.chat);
  useEffect(() => {
    dispatch(getAllContactsThunk());
    dispatch(getLastMsgThunk());
  }, []);

  useEffect(() => {
    if (chatSlice?.contactList?.data) setContactDetail(chatSlice.contactList.data);
    if (chatSlice?.lastMsg?.data) setLastChatList(chatSlice.lastMsg.data);
  }, [chatSlice]);

  useEffect(() => {
    on("online-users-list", (data) => {
      dispatch(setOnlineUser(data));
    });
  }, [selectedChat]);

  return (
    <>
      <p className={`font-extrabold text-xl px-2 mx-4 mb-4 block md:hidden ${showMobChat && "hidden"}`}>Chat</p>
      <Container customClassName="flex  h-[38rem]">
        <ChatList contactList={contactDetails} lastChatList={lastChatList} setSelectedChat={setSelectedChat} setMobChat={setMobChat} showMobChat={showMobChat} selectedChat={selectedChat} loading={chatSlice.loading} />
        <ChatScreen selectedChat={selectedChat} showMobChat={showMobChat} setMobChat={setMobChat} />
      </Container>
    </>
  );
};

export default Chat;
