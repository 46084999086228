import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../helpers/axios";
import { baseUrl } from "../../helpers/constants/defaultUrls";

interface contentArgs {
  category: string;
  subcategory?: string;
}

export const uploadMediaThunk = createAsyncThunk("gallery/uploadMedia", async (payload: any) => {
  const response = await axios.post(baseUrl + "v1/galleryv2/upload", payload, {
    headers: { "Content-Type": "multipart/form-data" }
  });
  return response.data;
});

export const UploadHistoryThunk = createAsyncThunk("gallery/UploadHistoryThunk", async (payload: any) => {
  const response = await axios.get(baseUrl + `v1/galleryv2/getHistory?pageSize=${payload.pageSize}&pageIndex=${payload.pageIndex}`);
  return response.data;
});

export const clearHistoryThunk = createAsyncThunk("gallery/UploadHistoryThunk", async () => {
  const response = await axios.get(baseUrl + `v1/galleryv2/clearHistory`);
  return response.data;
});

export const getGalleryContentThunk = createAsyncThunk("gallery/getGalleryContentThunk", async (payload: contentArgs) => {
  const response = await axios.get(baseUrl + `v1/galleryv2/getContent?category=${payload.category}${payload.subcategory ? "&subcategory=" + payload.subcategory : ""}`);
  return response.data;
});

export const uploadLogoThunk = createAsyncThunk("gallery/logoUpload", async (payload: any) => {
  const response = await axios.post(baseUrl + "v1/galleryv2/uploadLogo", payload, {
    headers: { "Content-Type": "multipart/form-data" }
  });
  return response.data;
});

export const downloadTemplateThunk = createAsyncThunk("gallery/downloadTemplateThunk", async (payload: { id: string; modify?: boolean }) => {
  const response = await axios.get(
    baseUrl + `v1/galleryv2/downloadTemplate?id=${payload.id}${payload.modify ? "&modify=" + payload.modify : ""}`,
    { responseType: "arraybuffer" } // Ensure responseType is set to 'arraybuffer'
  );
  return response.data;
});
