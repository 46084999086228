import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";
import Container from "../../../component/common/Container";
import Typography from "../../../component/semantic/Typography";
import Select from "react-select";
import { GoDotFill } from "react-icons/go";
import { polyfixSelect } from "../../../helpers/constants/styles";
import { formatNumberIntoLK } from "../../../helpers/utils";
import { useAppSelector } from "../../../hooks/redux_toolkit";

interface BarChartData {
  labels: string;
  values: number;
}

interface BarChartProps {
  data: BarChartData[];
  color: string;
  count: number;
  barGraphOption: OptionType;
  setBarGraphOption: (selectedOptions: OptionType) => void;
  title: string;
}

interface OptionType {
  value: string;
  label: string;
}

const options: OptionType[] = [
  { value: "", label: "Total" },
  { value: "PENDING", label: "Pending" },
  { value: "REGISTERED", label: "Registered" },
  { value: "REJECTED", label: "Rejected" }
];

const BarChart: React.FC<BarChartProps> = ({ data, color, count, barGraphOption, setBarGraphOption, title }) => {
  const chartRef = useRef<HTMLCanvasElement>(null);
  const [isMobile, setIsMobile] = useState<boolean>(false);

  const isLoading = useAppSelector((state) => state.rootReducer.dashboard.graphLoading);

  const handleChange = (barGraphOption: OptionType | null) => {
    if (barGraphOption !== null) {
      setBarGraphOption(barGraphOption);
    }
  };

  useEffect(() => {
    const resizeHandler = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", resizeHandler);
    resizeHandler();
    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);

  useEffect(() => {
    if (!chartRef.current) return;

    const ctx = chartRef.current.getContext("2d");
    if (!ctx) return;

    const myChart = new Chart(ctx, {
      type: "bar",
      data: {
        labels: data.map((x) => x.labels),
        datasets: [
          {
            data: data.map((x) => x.values),
            backgroundColor: color,
            borderWidth: 1,
            barThickness: isMobile ? 20 : 50,
            borderRadius: 5
          }
        ]
      },
      options: {
        plugins: {
          legend: {
            display: false
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            grid: {
              display: false
            }
          },
          y: {
            grid: {
              color: "rgba(0, 0, 0, 0.1)"
            },
            ticks: {
              callback: function (value) {
                const num = Number(value);
                if (num >= 10000000) {
                  return Math.floor(num / 10000000) + " Cr";
                } else if (num >= 100000) {
                  return Math.floor(num / 100000) + " L";
                }
                return num.toString();
              }
            }
          }
        }
      }
    });

    return () => {
      myChart.destroy();
    };
  }, [data, color, isMobile]);

  return (
    <Container customClassName="h-[30rem] mt-6 lg:mx-0 mx-4 px-0 pl-4 pr-4 lg:px-8">
      <div className="flex justify-between">
        <Typography variant="h3">{title}</Typography>
        <Select value={barGraphOption} onChange={handleChange} options={options} styles={polyfixSelect} placeholder="Status" className="lg:w-44 w-32 cursor-pointer " isSearchable={false} />
      </div>
      <div className="h-[18rem]">
        <Typography className="flex items-center">
          <GoDotFill className={color === "#7FA4F3" ? "text-[#7FA4F3]" : "text-[#AF7AB3]"} />
          <span className="mx-2">{title == "Lead Count" ? "Lead Count" : "Claim Amount"}: </span>
          {isLoading ? <div className="h-4 bg-gray-400 rounded-full dark:bg-gray-700 w-10  animate-pulse"></div> : <span className="font-bold">{count}</span>}
        </Typography>
        <canvas ref={chartRef} />
      </div>
      <Container className="mt-6 shadow-lg -mx-[1rem] lg:-mx-[2.3rem]  p-5 px-2 flex overflow-scroll  lg:overflow-hidden">
        <Typography className="flex  w-16 justify-center items-center">
          <GoDotFill className={color === "#7FA4F3" ? "text-[#7FA4F3]" : "text-[#AF7AB3]"} />
          <span className="ml-1 text-gray-400 text-xs">{title == "Lead Count" ? "Lead Count" : "Claim Amt."} </span>
        </Typography>
        <div className="flex overflow-x-scroll overflow-y-hidden custom-scrollbar">
          {data.map((x, i) => {
            return (
              <div className=" mx-[0.9rem] lg:mr-[2.55rem] text-center" key={i}>
                <span key={i} className=" text-customgray whitespace-nowrap text-xs ">
                  {x.labels}
                  <br></br>
                </span>
                <span key={i} className=" font-bold ">
                  {(title = "Claim Amount" && formatNumberIntoLK(x.values))}
                </span>
              </div>
            );
          })}
        </div>
      </Container>
    </Container>
  );
};

export default BarChart;
