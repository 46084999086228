import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { baseUrl } from "../helpers/constants/defaultUrls";
import Spinner from "../component/common/Spinner";

const ValidToken = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!token) {
      navigate("/error"); // Redirect to an error page if token is missing
      return;
    }

    const fetchData = async () => {
      try {
        const data = await userDetailsFetcher(token);
        if (data?.isAgreed) {
          navigate("/login");
        } else {
          navigate(`/user/contractv2/${data?._id}`);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [token, navigate]);

  const userDetailsFetcher = async (token: string) => {
    try {
      const response = await axios.get(`${baseUrl}v1/auth/verify/${token}`);
      return response.data?.data;
    } catch (error) {
      console.error("Error fetching user details:", error);
      throw error; // Re-throw the error to be caught in useEffect
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return null;
};

export default ValidToken;
