import React, { FC, useEffect, useState } from "react";
import Modal from "../../../../component/common/Modal";
import CloseIcon from "../../../../assets/icons/gallery/close.svg";
import Typography from "../../../../component/semantic/Typography";
import dayjs from "dayjs";
import ButtonOutline from "../../../../component/semantic/Button";
import CustomInput from "../../../../component/semantic/TextArea";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux_toolkit";
import addCommentImage from "../../../../assets/icons/lead/addComment.svg";
import { toast } from "react-toastify";
import { addCommentComplaint, commentHistoryComplaint } from "../../../../store/thunk/AllComplaint.thunk";

interface ModalProps {
  showAddCommentModal: boolean;
  setShowAddCommentModal: any;
  selectedComplaint: any;
}

const AddComment: FC<ModalProps> = ({ showAddCommentModal, setShowAddCommentModal, selectedComplaint }) => {
  const [commentInput, setCommentInput] = useState("");
  const dispatch = useAppDispatch();
  const commentHistoryData = useAppSelector((state) => state.rootReducer.allComplaint.commentHistory);
  const addCommentHandler = async () => {
    if (commentInput.trim()) {
      await dispatch(addCommentComplaint({ id: selectedComplaint._id, body: { comment: commentInput, notificationsTo: [], private: false, type: "Complaint" } }))
        .unwrap()
        .then((data: any) => {
          if (data.success) {
            toast.success(data.message);
            dispatch(commentHistoryComplaint(selectedComplaint?._id));
            setCommentInput("");
            // setShowAddCommentModal(false);
          } else {
            toast.error(data.message);
          }
        });
    }
  };
  useEffect(() => {
    if (selectedComplaint?._id) dispatch(commentHistoryComplaint(selectedComplaint?._id));
  }, [selectedComplaint]);

  return (
    <Modal showModal={showAddCommentModal} className="relative w-[40%] p-8" onClose={() => setShowAddCommentModal(false)}>
      <img src={CloseIcon} className="absolute text-3xl text-gray-400 right-[-15px] top-[-20px] cursor-pointer" onClick={() => setShowAddCommentModal(false)} />
      <Typography variant="h1">Add Comment</Typography>

      <div id="commentBox" className="mt-4">
        <div className="flex justify-between items-end mb-3">
          <Typography>Comment</Typography>
          <ButtonOutline className={`bg-polyfixTheme w-full  `} title="Add Comment" type="submit" onClick={addCommentHandler} />
        </div>
        <CustomInput className="h-40" placeholder="Add your comments here..." value={commentInput} onChange={(e: any) => setCommentInput(e.target.value)} />
        <Typography className="text-end text-xs text-gray-400">0/3 comment character count</Typography>
      </div>
      <Typography variant="h2">Comment history for Lead ID: {selectedComplaint?.leadId ? selectedComplaint?.leadId.leadId : "-"}</Typography>

      <div className="custom-polifyx-scrollbar overflow-y-scroll h-80 px-4">
        {commentHistoryData &&
          (commentHistoryData?.data || []).map((comment: any, i: number) => {
            const nameAndDate = comment.name + " | " + dayjs(comment.createdAt).format("ddd, MMM D, YYYY, h:mm A");
            return (
              <div key={i} className="my-6 relative shadow-all px-6 py-4 flex gap-4 items-start rounded-xl">
                <img src={addCommentImage} className="mt-1" />
                <div className="flex gap-1 flex-col">
                  <Typography variant="h2">{comment?.comment}</Typography>
                  <Typography className="text-xs text-gray-400">{nameAndDate}</Typography>
                </div>
              </div>
            );
          })}
      </div>
    </Modal>
  );
};

export default AddComment;
