import React from "react";
import Header from "./component/Header";
import ProfileDetails from "./component/Details";

function Profile() {
  return (
    <>
      <Header />
      <ProfileDetails />
    </>
  );
}

export default Profile;
