import React from "react";

interface CircleProps {
    content: string;
}

const Circle: React.FC<CircleProps> = ({ content }) => {
    return <p className="bg-gray-200 w-5 h-5 rounded-full  font-bold flex items-center justify-center text-xs">{content}</p>;
};

export default Circle;
