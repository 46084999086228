import React, { useEffect, useRef } from "react";
import { ImDownload2 } from "react-icons/im";
import jsPDF from "jspdf";

interface QRCodeGeneratorProps {
  base64String: string;
  className?: string;
}

const QRCodeGenerator: React.FC<QRCodeGeneratorProps> = ({ base64String, className }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (!canvasRef.current) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    if (!ctx) return;

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    const img = new Image();
    img.src = base64String;

    img.onload = () => {
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    };
  }, [base64String]);

  const handleDownload = () => {
    const pdf = new jsPDF();
    const img = new Image();
    img.src = base64String;
    img.onload = () => {
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imageWidth = pdfWidth * 0.8;
      const imageHeight = (img.height / img.width) * imageWidth;
      const x = (pdfWidth - imageWidth) / 2;
      const y = (pdfHeight - imageHeight) / 2;
      pdf.addImage(img, "PNG", x, y, imageWidth, imageHeight);
      pdf.save(`qrcode.pdf`);
    };
  };

  return (
    <span className="border-2 border-black p-[2px] rounded">
      <canvas ref={canvasRef} className={`p-0 m-0  border-none ${className}`} />
      <p className="gap-2 pt-1 items-center font-bold flex justify-center cursor-pointer" onClick={handleDownload}>
        <span>
          <ImDownload2 />
        </span>
        <span>QR Code</span>
      </p>
    </span>
  );
};

export default QRCodeGenerator;
