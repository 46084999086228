import React from "react";
import { BsThreeDots } from "react-icons/bs";
import Menu from "../../../component/common/Menu";
import Typography from "../../../component/semantic/Typography";
import { Tooltip } from "react-tooltip";

export const ActionModalHandler = ({ index, actions, data, selectedRow, showMenu, setShowMenu }: any) => {
  return (
    <td className="relative" key={index}>
      <div className={`md:flex hidden flex-row items-center w-full  h-full justify-evenly  gap-3 py-4 `}>
        {actions.map((x: any, i: number) => {
          return (
            <>
              <div
                className="flex justify-center items-center h-full"
                onClick={() => {
                  selectedRow(data);
                  x.onClickFunction();
                }}
                data-tooltip-id="my-tooltip2"
                data-tooltip-content={x.tooltip}
              >
                {x.image}
              </div>
              <Tooltip className="z-50" key={i} id="my-tooltip2" place="bottom" />
            </>
          );
        })}
      </div>
      <div className=" flex md:hidden justify-center ">
        <BsThreeDots className="text-3xl cursor-pointer" onClick={() => setShowMenu(index)} />
        <Menu showMenu={showMenu == index} onClose={() => setShowMenu(-2)} className="w-48 top-[3.5rem] left-10 right-0 z-50">
          <ul>
            {actions.map((x: any, i: number) => {
              return (
                <li
                  key={i}
                  onClick={() => {
                    selectedRow(data);
                    x.onClickFunction();
                  }}
                  className="flex items-center gap-3 py-1 pl-2 rounded-md cursor-pointer hover:bg-[#fff9f3] hover:text-polyfixTheme "
                >
                  {x.image}
                  <Typography>{x.tooltip}</Typography>
                </li>
              );
            })}
          </ul>
        </Menu>
      </div>
    </td>
  );
};
