import React, { ReactNode, useEffect, useRef } from "react";

interface ModalProps {
  showModal: boolean;
  children: ReactNode;
  className?: string;
  onClose?: () => void;
}

const Modal: React.FC<ModalProps> = ({ showModal, children, className, onClose }) => {
  const outerRef = useRef<any>(null);
  const innerRef = useRef<any>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (showModal) {
        if (outerRef.current && innerRef.current && outerRef.current.contains(event.target as Node) && !innerRef.current.contains(event.target as Node)) {
          onClose && onClose();
        }
      }
    };

    const handleKeyDown = (event: any) => {
      if (event.key === "Escape" && showModal) {
        onClose && onClose();
      }
    };

    if (showModal) {
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [showModal, onClose]);

  return (
    <div style={{ zIndex: 200 }} ref={outerRef} className={`${showModal ? "flex" : "hidden"} backdrop-blur-md backdrop-brightness-100 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full min-w-36 md:inset-0 h-full`}>
      <div ref={innerRef} className={`modal-content bg-white min-w-96 min-h-40 rounded-lg p-3 border-2 border-gray-400 shadow-lg ${className}`}>
        {children}
      </div>
    </div>
  );
};

export default Modal;
