import { createSlice } from "@reduxjs/toolkit";
import { responseData } from "./Common.slice";
import { getAllContactsThunk, getLastMsgThunk, getUnreadCount, getUserChatThunk, searchContactsThunk, searchLastMsgThunk } from "../thunk/Chat.thunk";

interface ApiState {
  data: any;
  loading: boolean;
  loadingChat: boolean;
  error: string | null;
  contactList: responseData;
  lastMsg: responseData;
  userChat: responseData;
  unreadCount: responseData;
}

const initialState: ApiState = {
  data: null,
  loading: false,
  error: null,
  contactList: { data: "" },
  lastMsg: { data: "" },
  userChat: { data: "" },
  loadingChat: false,
  unreadCount: { data: "" }
};

const chatSlice = createSlice({
  name: "chatSlice",
  initialState,
  reducers: {
    chatLoader: (state, action) => {
      state.loadingChat = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder

      .addCase(getAllContactsThunk.pending, (state) => {
        state.loading = false;
      })
      .addCase(getAllContactsThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.contactList = action.payload;
        state.error = null;
      })
      .addCase(getAllContactsThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "An error occurred";
      })

      .addCase(searchContactsThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(searchContactsThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.contactList = action.payload;
        state.error = null;
      })
      .addCase(searchContactsThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "An error occurred";
      })

      .addCase(getLastMsgThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLastMsgThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.lastMsg = action.payload;
        state.error = null;
      })
      .addCase(getLastMsgThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "An error occurred";
      })

      // .addCase(getUserChatThunk.pending, (state) => {
      //     state.loadingChat = false;
      // })
      .addCase(getUserChatThunk.fulfilled, (state, action) => {
        state.loadingChat = false;
        state.userChat = action.payload;
        state.error = null;
      })
      .addCase(getUserChatThunk.rejected, (state, action) => {
        state.loadingChat = false;
        state.error = action.error.message ?? "An error occurred";
      })

      .addCase(searchLastMsgThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(searchLastMsgThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.lastMsg = action.payload;
        state.error = null;
      })
      .addCase(searchLastMsgThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "An error occurred";
      })

      .addCase(getUnreadCount.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUnreadCount.fulfilled, (state, action) => {
        state.loading = false;
        state.unreadCount = action.payload;
        state.error = null;
      })
      .addCase(getUnreadCount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "An error occurred";
      });
  }
});

export const { chatLoader } = chatSlice.actions;

export default chatSlice.reducer;
