import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { ActionModalHandler } from "./TableFunctions";
import { actionModalTypes } from "../../helpers/constants/interfaceTypes";
import SortArrow from "../../assets/icons/basic/sortArrow.svg";
import { hierarchicalLevelHandler, sortTableData } from "../../helpers/utils";
import { useAppDispatch } from "../../hooks/redux_toolkit";

interface TableProps {
  tableHeading: string[];
  tableKeys: string[];
  tableData: any[];
  actions: actionModalTypes;
  selectedRow: any;
  sortedDataAction?: any;
  sortKey?: string[];
}

const ActionTable: React.FC<TableProps> = ({ tableHeading, tableKeys, tableData, actions, selectedRow, sortedDataAction, sortKey }) => {
  const [showTooltip, setShowToolTip] = useState<any>([-1, -1]);
  const [showMenu, setShowMenu] = useState(-1);
  const [sortData, setSortData] = useState<{ field: string; sort: string }[]>([]);

  useEffect(() => {
    if (sortKey) {
      const temp: any = [];
      sortKey.map((obj) => {
        temp.push({ field: obj, sort: "asc" });
      });
      setSortData(temp);
    }
  }, [sortKey]);
  const getIndexFromSortDataByKey = (field: any) => {
    return sortData.findIndex((e) => e.field == field);
  };

  const dispatch = useAppDispatch();

  const sortHandler = (field: string) => {
    const sortDataIndex = getIndexFromSortDataByKey(field);
    const sortDataCopy = [...sortData];
    if (sortData[sortDataIndex].sort == "dsc") {
      sortDataCopy[sortDataIndex] = { field, sort: "asc" };
      setSortData([...sortDataCopy]);
      //find index of keys
      const index = tableHeading.findIndex((e) => e == field);
      const keyName = tableKeys[index];
      const sortedData = sortTableData(tableData as [], keyName, "asc");
      dispatch(sortedDataAction(sortedData));
    } else {
      sortDataCopy[sortDataIndex] = { field, sort: "dsc" };
      setSortData([...sortDataCopy]);
      //find index of keys
      const index = tableHeading.findIndex((e) => e == field);
      const keyName = tableKeys[index];
      const sortedData = sortTableData(tableData as [], keyName, "dsc");

      dispatch(sortedDataAction(sortedData));
    }
  };
  return (
    <div
      className="overflow-x-scroll w-full mr-6"
      style={{
        scrollbarWidth: "thin",
        scrollbarColor: "#D7DBEC transparent",
        msOverflowStyle: "none",
        overflowX: "scroll",
        overflowY: "hidden",
        borderRadius: "16px"
      }}
    >
      <table className="w-full">
        <thead>
          <tr className="border-b-2 whitespace-nowrap">
            {tableHeading.map((data: any, i: number) => {
              if (i == 0) return;
              return (
                <th key={i} className="pr-1 md:pr-3 py-2 md:py-4 text-sm md:text-base ">
                  <div className="flex gap-2 px-4 min-w-32">
                    <p className="font-extrabold">{data}</p>
                    {sortKey ? sortKey.includes(data) && <img className={`cursor-pointer transition-all ${sortData[getIndexFromSortDataByKey(data)]?.sort == "dsc" ? "rotate-0" : "rotate-180"}`} onClick={() => sortHandler(data)} src={SortArrow} /> : ""}
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="text-gray-600 w-full text-center">
          {tableData && tableData.length > 0 ? (
            tableData.map((data, index) => (
              <tr key={index} className="border-b-2">
                {tableKeys.map((key, i) => {
                  if (i === 0) return null;
                  if (key == "action") return <ActionModalHandler index={index} actions={actions} data={data} selectedRow={selectedRow} setShowToolTip={setShowToolTip} showTooltip={showTooltip} showMenu={showMenu} setShowMenu={setShowMenu} />;

                  if (tableKeys[0] === "lead" && key === "createdAt") return <td className="whitespace-nowrap">{dayjs.unix(data[key]).format("MMM D, YYYY")}</td>;

                  if (tableKeys[0] === "lead" && key === "claim_amount")
                    return (
                      <td>
                        {data[key] ? (
                          <p className="font-bold text-green-500 items-center justify-center flex gap-1">
                            <span className=" text-gray-600">₹</span>
                            <span>{data[key]}</span>
                          </p>
                        ) : (
                          "-"
                        )}
                      </td>
                    );

                  if (tableKeys[0] === "lead" && key === "src")
                    return (
                      <td className="text-left pr-6 py-4 ">
                        {" "}
                        {data.src || "-"} | {data.med || "-"} | {data.cpn || "-"}{" "}
                      </td>
                    );
                  if (tableKeys[0] === "allUsers" && key === "contractHistory") return <td> {data?.contractHistory && data?.contractHistory.length > 0 ? dayjs(data.contractHistory[0]?.agreedOn).format("MMM D, YYYY, h:mm:ss A") : "-"}</td>;
                  if (tableKeys[0] === "allUsers" && key === "createdAt") return <td> {data?.createdAt ? dayjs(data?.createdAt).format("MMM D, YYYY") : "-"}</td>;

                  if (tableKeys[0] === "allUsers" && key === "hierarchicalLevel") return <td> {data[key] ? hierarchicalLevelHandler(data[key]) : "-"} </td>;
                  if (tableKeys[0] === "initUsers" && key === "isActive") return <td> {data[key] ? <span className="bg-[#D9F4EE] text-[#1BA785] p-1 px-2 rounded">Active</span> : <span className="bg-[#F1EAEA] text-[#8C7072] p-1 px-2 rounded">Not Active</span>} </td>;

                  if (tableKeys[0] === "lead" && key === "status")
                    return (
                      <td className={`font-bold ${data?.status === "REGISTERED" && "text-green-600"}`}>
                        <div className="flex justify-start"> {data[key]}</div>{" "}
                      </td>
                    );

                  let cellValue = data[key];

                  if (key.includes(".")) {
                    const nestedKeys = key.split(".");
                    let nestedData = data;
                    for (const nestedKey of nestedKeys) {
                      nestedData = nestedData[nestedKey];
                      if ([undefined, null].includes(nestedData)) {
                        cellValue = "-";
                        break;
                      } else {
                        cellValue = nestedData;
                      }
                    }
                  }
                  return (
                    <td key={i} className="text-left pr-6 py-4 ">
                      {cellValue || "-"}
                    </td>
                  );
                })}
              </tr>
            ))
          ) : (
            <td colSpan={tableHeading.length} className="text-center text-xl pt-2">
              No data found
            </td>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ActionTable;
