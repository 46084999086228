import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../helpers/axios";
import { baseUrl } from "../../helpers/constants/defaultUrls";

interface createCaseArgs {
  name: string;
  email: string;
  phone: string;
  problemStatement: string;
  policyTypeId: string;
  complaintTypeId: string;
  policyTypeName: string;
  complaintTypeName: string;
}

interface UploadDocArgs {
  leadId: string;
  formData: FormData;
}

export const createCaseThunk = createAsyncThunk("newCase/createCaseThunk", async (payload: createCaseArgs) => {
  const response = await axios.post(baseUrl + "v2/lead/create/newLead", payload);
  return response;
});

export const uploadLeadDocThunk = createAsyncThunk("newCase/uploadLeadDocThunk", async (payload: UploadDocArgs) => {
  const response = await axios.put(baseUrl + `v1/lead/upload/docs/${payload.leadId}`, payload.formData, {
    headers: { "Content-Type": "multipart/form-data" }
  });
  return response.data;
});

export const downloadLeadDocThunk = createAsyncThunk("newCase/downloadLeadDocThunk", async (id: string) => {
  const response = await axios.get(baseUrl + "v1/lead/docs/download/all?leadId=" + id, { responseType: "arraybuffer" });
  return response.data;
});

export const removeLeadDocThunk = createAsyncThunk("newCase/removeLeadDocThunk", async (payload: { id: string; file: string }) => {
  const response = await axios.get(baseUrl + `v1/lead/remove/docsV2?leadId=${payload.id}&file=${payload.file}`);
  return response.data;
});
