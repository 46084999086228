import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { useAppDispatch } from "../../hooks/redux_toolkit";
import { verifyExecutiveAgentThunk, verifyExecutiveOTPThunk, updateAgentThunk, softDeleteAgentThunk, activateAgentThunk } from "../../store/thunk/UserManagement.thunk";
import { updateAndSendContractThunk, sendPartnerContractThunk } from "../../store/thunk/PendingContract.thunk";
import { approveUserThunk } from "../../store/thunk/initUser.thunk";
import Modal from "../../component/common/Modal";
import Typography from "../../component/semantic/Typography";
import ButtonOutline from "../../component/semantic/Button";
import CloseIcon from "../../assets/icons/gallery/close.svg";
import Spinner from "../../component/common/Spinner";

const Signup = () => {
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState<boolean>(true);
  const [verificationData, setVerificationData] = useState<any>(null);
  const [mainModal, setMainModal] = useState<boolean>(true);
  const [otpModal, setOtpModal] = useState<boolean>(false);
  const [otp, setOtp] = useState<string>("");

  const [partnerType, setPartnerType] = useState<string>("");
  const [revenueType, setRevenueType] = useState<string>("");
  const [invoiceTo, setInvoiceTo] = useState<string>("Customer");
  const [isRevenueSharing, setIsRevenueSharing] = useState<boolean>(false);
  const [isCustomerContractSigning, setIsCustomerContractSigning] = useState<boolean>(false);
  const [isLeadCost, setIsLeadCost] = useState<boolean>(false);
  const [leadAmount, setLeadAmount] = useState<string>("0");
  const [customerContractSignedRate, setCustomerContractSignedRate] = useState<string>("0");
  const [partnerContractSignedRate, setPartnerContractSignedRate] = useState<string>("0");
  const [isCustomerRegistrationFees, setIsCustomerRegistrationFees] = useState<boolean>(false);
  const [isCustomerCommunicationEmail, setIsCustomerCommunicationEmail] = useState<boolean>(false);
  const [isCustomerCalling, setIsCustomerCalling] = useState<boolean>(false);
  const [successFeeRate, setSuccessFeeRate] = useState("0");
  const [disabled, setdisabled] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");
  const executive = queryParams.get("executive");
  const agent = queryParams.get("agent");
  const src = queryParams.get("src");

  useEffect(() => {
    dispatch(verifyExecutiveAgentThunk({ token, executive, agent, src }))
      .unwrap()
      .then((data: any) => {
        if (data?.success) {
          setVerificationData(data);
          if (data.data?.isAgreed) {
            setMainModal(false);
          }
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const handleSave = async () => {
    try {
      setOtp("");

      const handleSuccess = async (userData: any) => {
        const formData = {
          id: userData.data?._id,
          heirarchy: userData.data?.hierarchicalLevel,
          partnerType,
          revenueType,
          isInvoiceRaisedToCustomer: invoiceTo === "Customer",
          isRevenueSharing,
          isCustomerContractSigning,
          isCustomerRegistrationFees,
          isLeadCost,
          customerContractSignedRate,
          partnerContractSignedRate,
          isCustomerCommunicationEmail,
          isCustomerCalling,
          leadAmount,
          successFeeRate,
        };

        const updateData = await dispatch(updateAgentThunk(formData)).unwrap();
        if (updateData?.success) {
          toast.success(updateData?.message);

          const contractData = await dispatch(updateAndSendContractThunk({ userId: userData.data?._id })).unwrap();
          if (contractData?.success) {
            toast.success(contractData?.message);
            await dispatch(sendPartnerContractThunk({ userId: userData.data?._id }));
            setMainModal(false);
          }
        }
      };

      const verifyAndHandle = async () => {
        const verifyData = await dispatch(verifyExecutiveOTPThunk({ userId: agent, otp, src })).unwrap();
        if (verifyData?.success) {
          toast.success(verifyData?.message);
          setOtpModal(false);
          return true;
        }
        return false;
      };

      if (await verifyAndHandle()) {
        if (src === "web") {
          await handleSuccess(verificationData);
        } else {
          verificationData.data.address = verificationData.data?.address?.fullAddress;
          verificationData.data.referral = verificationData.data?.referredBy ? verificationData.data?.referredBy : "INSA_PARTNER";
          verificationData.data.hashPassword = verificationData.data?.password;

          const approveUserData = await dispatch(approveUserThunk({ ...verificationData.data, src })).unwrap();
          if (approveUserData?.success) {
            setVerificationData(approveUserData);

            const activateAgentData = await dispatch(activateAgentThunk({ mobile: verificationData.data?.mobile })).unwrap();
            if (activateAgentData?.success) {
              await dispatch(softDeleteAgentThunk({ mobile: verificationData.data?.mobile })).unwrap();
              await handleSuccess(approveUserData);
            }
          }
        }
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    }
  };

  useEffect(() => {
    setOtp("")
    if (verificationData?.data) {
      setPartnerType(verificationData.data?.partnerType || "");
      setRevenueType(verificationData.data?.revenueType || "");
      setInvoiceTo(verificationData.data?.isInvoiceRaisedToCustomer ? "Customer" : "Partner/Company");
      setIsRevenueSharing(verificationData.data?.isRevenueSharing);
      setIsCustomerContractSigning(verificationData.data?.isCustomerContractSigning);
      setIsLeadCost(verificationData.data?.isLeadCost);
      setLeadAmount(verificationData.data?.leadAmount || "0");
      setCustomerContractSignedRate(verificationData.data?.customerContractSignedRate || "0");
      if (verificationData.data?.hierarchicalLevel == 0) {
        setPartnerContractSignedRate(verificationData.data?.partnerPercent || "0");
      } else if (verificationData.data?.hierarchicalLevel == 1) {
        setPartnerContractSignedRate(verificationData.data?.isRevenueSharing !== "revenueSharing" ? verificationData.data?.associatePercent : "0");
      } else if (verificationData.data?.hierarchicalLevel == 2) {
        setPartnerContractSignedRate(verificationData.data?.advisorPercent || "0");
      }
      setIsCustomerRegistrationFees(verificationData.data?.isCustomerRegistrationFees);
      setIsCustomerCommunicationEmail(verificationData.data?.isCustomerCommunicationEmail);
      setIsCustomerCalling(verificationData.data?.isCustomerCalling);
      setSuccessFeeRate(verificationData.data?.successFeeRate || "0");
      setdisabled(verificationData.data?.isProfileCompleted && verificationData.data?.isAgreed);
      if (verificationData.data?.hierarchicalLevel != 0 && verificationData.data?.parentData?.partnerType && verificationData.data?.parentData?.partnerType == "revenueSharing") {
        setPartnerType("noRevenueSharing");
        setPartnerContractSignedRate("0");
        setIsRevenueSharing(false);
        setIsLeadCost(false);
      }
    }
  }, [verificationData?.data]);

  if (loading) return <Spinner />;
  if (!mainModal) {
    return (
      <div className="flex justify-center">
        <p className="bg-green-400 text-black p-4 text-2xl text-center w-[50%] mt-5 rounded">
          {verificationData.data?.isAgreed ? "User has already signed contract, You are not able to edit any details !" : "THANK YOU ..."}
        </p>
      </div>
    );
  }
  if (!verificationData) {
    return (
      <div className="flex justify-center">
        <p className="bg-red-400 text-white p-4 text-2xl text-center w-[50%] mt-5 rounded">Verification Failed !</p>
      </div>
    );
  }
  return (
    <>
      <Modal showModal={true} className="relative w-[50%] z-[99]">
        <Typography variant="h1" className="px-8 pt-4">
          Edit User Details of {verificationData.data?.name}
        </Typography>
        <div className="relative h-[60vh] pb-8 overflow-y-scroll custom-scrollbar2 pr-4 p-8">
          <div className="mt-4">
            <label className="block mb-2 font-bold">Partner Type</label>
            <select
              value={partnerType}
              onChange={(e) => {
                setPartnerType(e.target.value);
                if (e.target.value != "noRevenueSharing") {
                  setRevenueType("");
                } else {
                  setInvoiceTo("Partner/Company");
                  setIsRevenueSharing(false);
                  setIsCustomerContractSigning(false);
                  setIsLeadCost(false);
                }
              }}
              className="w-full p-2 border border-gray-300 rounded"
              disabled={disabled}
            >
              <option value="">Select</option>
              <option value="noRevenueSharing">No Revenue Sharing</option>
              <option value="revenueSharing">Revenue sharing (Dynamic %) + Lead cost sharing</option>
              <option value="leadCost">Only Lead Cost</option>
              <option value="hierarchyWise">Hierarchy Wise</option>
            </select>
          </div>
          {partnerType != "" && partnerType == "noRevenueSharing" && (
            <div className="mt-4">
              <label className="block mb-2 font-bold">No Revenue Sharing Type</label>
              <select
                value={revenueType}
                onChange={(e) => {
                  setRevenueType(e.target.value);
                  if (e.target.value == "firstType") {
                    setInvoiceTo("Customer");
                    setIsCustomerContractSigning(true);
                  } else if (e.target.value == "secondType") {
                    setInvoiceTo("Partner/Company");
                    setIsCustomerContractSigning(false);
                  }
                }}
                className="w-full p-2 border border-gray-300 rounded"
                disabled={disabled}
              >
                <option value="">Select</option>
                <option value="firstType">First Type</option>
                <option value="secondType">Second Type</option>
              </select>
            </div>
          )}
          {partnerType != "" && (
            <div className="mt-4">
              <label className="block mb-2 font-bold">Invoice to be raised to</label>
              <div className="flex space-x-8">
                <label className="flex items-center w-[40%]">
                  <input disabled={disabled} type="radio" name="invoiceTo" value="Customer" checked={invoiceTo === "Customer"} onChange={() => setInvoiceTo("Customer")} className="text-gray-500 accent-[black] p-2" />
                  <span className="ml-2">Customer</span>
                </label>
                <label className="flex items-center">
                  <input disabled={disabled} type="radio" name="invoiceTo" value="Partner/Company" checked={invoiceTo === "Partner/Company"} onChange={() => setInvoiceTo("Partner/Company")} className="text-gray-500 accent-[black] p-2" />
                  <span className="ml-2">Partner/Company</span>
                </label>
              </div>
            </div>
          )}

          {partnerType != "" && (
            <div className="mt-4">
              <label className="block mb-2 font-bold">Revenue Sharing</label>
              <div className="flex space-x-8">
                <label className="flex items-center w-[40%]">
                  <input disabled={disabled} type="radio" name="revenueSharing" value="Yes" checked={isRevenueSharing === true} onChange={() => setIsRevenueSharing(true)} className="text-gray-500 accent-[black] p-2" />
                  <span className="ml-2">Yes</span>
                </label>
                <label className="flex items-center">
                  <input disabled={disabled} type="radio" name="revenueSharing" value="No" checked={isRevenueSharing === false} onChange={() => setIsRevenueSharing(false)} className="text-gray-500 accent-[black] p-2" />
                  <span className="ml-2">No</span>
                </label>
              </div>
            </div>
          )}

          {partnerType != "" && (
            <div className="mt-4">
              <label className="block mb-2 font-bold">Customer Contract to be signed</label>
              <div className="flex space-x-8">
                <label className="flex items-center w-[40%]">
                  <input disabled={disabled} type="radio" name="contractSigned" value="Yes" checked={isCustomerContractSigning === true} onChange={() => setIsCustomerContractSigning(true)} className="text-gray-500 accent-[black] p-2" />
                  <span className="ml-2">Yes</span>
                </label>
                <label className="flex items-center">
                  <input disabled={disabled} type="radio" name="contractSigned" value="No" checked={isCustomerContractSigning === false} onChange={() => setIsCustomerContractSigning(false)} className="text-gray-500 accent-[black] p-2" />
                  <span className="ml-2">No</span>
                </label>
              </div>
            </div>
          )}

          {partnerType != "" && (
            <div className="mt-4">
              <label className="block mb-2 font-bold">Customer Registration Fee</label>
              <div className="flex space-x-8">
                <label className="flex items-center w-[40%]">
                  <input disabled={disabled} type="radio" name="regFee" value="Yes" checked={isCustomerRegistrationFees === true} onChange={() => setIsCustomerRegistrationFees(true)} className="text-gray-500 accent-[black] p-2" />
                  <span className="ml-2">Yes</span>
                </label>
                <label className="flex items-center">
                  <input disabled={disabled} type="radio" name="regFee" value="No" checked={isCustomerRegistrationFees === false} onChange={() => setIsCustomerRegistrationFees(false)} className="text-gray-500 accent-[black] p-2" />
                  <span className="ml-2">No</span>
                </label>
              </div>
            </div>
          )}

          {partnerType != "" && (
            <div className="mt-4">
              <label className="block mb-2 font-bold">Lead Cost to be given to Partner (only after successful lead generation)</label>
              <div className="flex space-x-8">
                <label className="flex items-center w-[40%]">
                  <input disabled={disabled} type="radio" name="leadCost" value="Yes" checked={isLeadCost} onChange={() => setIsLeadCost(true)} className="text-gray-500 accent-[black] p-2" />
                  <span className="ml-2 text-gray-500">Yes</span>
                </label>
                <label className="flex justify-end items-center">
                  <input disabled={disabled} type="radio" name="leadCost" value="No" checked={!isLeadCost} onChange={() => setIsLeadCost(false)} className="text-gray-500 accent-[black] p-2" />
                  <span className="ml-2 text-gray-500">No</span>
                </label>
              </div>
            </div>
          )}

          {partnerType != "" && (
            <div className="mt-4">
              <label className="block mb-2 font-bold">Customer Communication Email</label>
              <div className="flex space-x-8">
                <label className="flex items-center w-[40%]">
                  <input type="radio" name="communicationEmail" value="Yes" checked={isCustomerCommunicationEmail === true} onChange={() => setIsCustomerCommunicationEmail(true)} className="text-gray-500 accent-[black] p-2" />
                  <span className="ml-2">Yes</span>
                </label>
                <label className="flex items-center">
                  <input type="radio" name="communicationEmail" value="No" checked={isCustomerCommunicationEmail === false} onChange={() => setIsCustomerCommunicationEmail(false)} className="text-gray-500 accent-[black] p-2" />
                  <span className="ml-2">No</span>
                </label>
              </div>
            </div>
          )}

          {partnerType != "" && (
            <div className="mt-4">
              <label className="block mb-2 font-bold">Customer Calling</label>
              <div className="flex space-x-8">
                <label className="flex items-center w-[40%]">
                  <input type="radio" name="customerCalling" value="Yes" checked={isCustomerCalling === true} onChange={() => setIsCustomerCalling(true)} className="text-gray-500 accent-[black] p-2" />
                  <span className="ml-2">Yes</span>
                </label>
                <label className="flex items-center">
                  <input type="radio" name="customerCalling" value="No" checked={isCustomerCalling === false} onChange={() => setIsCustomerCalling(false)} className="text-gray-500 accent-[black] p-2" />
                  <span className="ml-2">No</span>
                </label>
              </div>
            </div>
          )}

          {partnerType != "" && isCustomerContractSigning && (
            <div className="mt-4">
              <label className="block mb-2 font-bold">Customer Contract Signed %</label>
              <input
                disabled={disabled}
                type="number"
                value={customerContractSignedRate}
                onChange={(e: any) => {
                  const value = e.target.value;
                  if (value < 0) {
                    toast("Customer Contract rate cannot be less than zero.");
                    return;
                  }
                  setCustomerContractSignedRate(value);
                }}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>
          )}

          {partnerType != "" && isRevenueSharing && (
            <div className="mt-4">
              <label className="block mb-2 font-bold">Partner Contract Signed %</label>
              <input
                disabled={disabled}
                type="number"
                value={partnerContractSignedRate}
                onChange={(e: any) => {
                  const value = e.target.value;
                  if (value < 0) {
                    toast("Parnter Contract rate cannot be less than zero.");
                    return;
                  }
                  setPartnerContractSignedRate(value);
                }}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>
          )}

          {partnerType != "" && isLeadCost && (
            <div className="mt-4">
              <label className="block mb-2 font-bold">Lead Cost Amount</label>
              <input
                disabled={disabled}
                type="number"
                value={leadAmount}
                onChange={(e: any) => {
                  const value = e.target.value;
                  if (value < 0) {
                    toast("Lead Amount cannot be less than zero.");
                    return;
                  }
                  setLeadAmount(value);
                }}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>
          )}

          {partnerType != "" && revenueType == "secondType" && (
            <div className="mt-4">
              <label className="block mb-2 font-bold">Success Fee Chargable</label>
              <input
                disabled={disabled}
                type="number"
                value={successFeeRate}
                onChange={(e: any) => {
                  const value = e.target.value;
                  if (value < 0) {
                    toast("Success Fee cannot be less than zero.");
                    return;
                  }
                  setSuccessFeeRate(value);
                }}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>
          )}
        </div>
        <div className="flex justify-end items-center w-full shadow-above-border pt-4">
          <ButtonOutline onClick={() => { if (partnerType) setOtpModal(true); }} className="text-white bg-[#1BA785] outline-[none]" type="button" title="Save" />
        </div>
      </Modal>

      {otpModal && (
        <Modal showModal={true} onClose={() => setOtpModal(false)} className="flex flex-col relative items-center">
          <img src={CloseIcon} className="absolute z-50 text-3xl text-gray-400 -right-3 -top-3 cursor-pointer" onClick={() => setOtpModal(false)} />
          <Typography variant="h1" className="px-8 pt-4">
            Please verify OTP
          </Typography>
          <p className="text-gray-500">We have sent a verification code to your email</p>
          <input
            type="number"
            name="otp"
            className="text-2xl my-4 p-1 text-center border-4 rounded outline-0"
            value={otp}
            onChange={(e: any) => {
              if (e.target.value.length > 6) {
                setOtp(otp);
              } else {
                setOtp(e.target.value);
              }
            }}
          />
          <ButtonOutline onClick={handleSave} className="text-white bg-[#6495ED] outline-[none]" type="button" title="Save" />
        </Modal>
      )}
    </>
  );
};

export default Signup;
