import React, { useState } from "react";
import { actionModalTypes } from "../../../helpers/constants/interfaceTypes";
import SortArrow from "../../../assets/icons/basic/sortArrow.svg";
import { ActionModalHandler } from "./TableFunctions";
import { sortTableData } from "../../../helpers/utils";
import { useAppDispatch } from "../../../hooks/redux_toolkit";
import { setSortedData } from "../../../store/slices/PendingContractSlice";
import dayjs from "dayjs";

interface TableProps {
  tableHeading: string[];
  tableKeys: string[];
  tableData: any[];
  actions: actionModalTypes;
  selectedRow: any;
  setTableData?: any;
  updateZeroPercentManualFunction?: any;
  updatePromoteLevelManualFunction?: any;
  paginationData?: any;
}

const ActionTable: React.FC<TableProps> = ({ updateZeroPercentManualFunction, updatePromoteLevelManualFunction, tableHeading, tableKeys, tableData, actions, selectedRow, paginationData }) => {
  const [showTooltip, setShowToolTip] = useState<any>([-1, -1]);
  const [showMenu, setShowMenu] = useState(-1);
  const [sortData, setSortData] = useState<{ field: string; sort: string }[]>([
    { field: "Date", sort: "asc" },
    { field: "Name", sort: "asc" }
  ]);

  const dispatch = useAppDispatch();

  const getIndexFromSortDataByKey = (field: any) => {
    return sortData.findIndex((e) => e.field == field);
  };

  const sortHandler = (field: string) => {
    const sortDataIndex = getIndexFromSortDataByKey(field);
    const sortDataCopy = [...sortData];
    if (sortData[sortDataIndex].sort == "dsc") {
      sortDataCopy[sortDataIndex] = { field, sort: "asc" };
      setSortData([...sortDataCopy]);
      //find index of keys
      const index = tableHeading.findIndex((e) => e == field);
      const keyName = tableKeys[index];
      const sortedData = sortTableData(tableData as [], keyName, "asc");
      dispatch(setSortedData(sortedData));
    } else {
      sortDataCopy[sortDataIndex] = { field, sort: "dsc" };
      setSortData([...sortDataCopy]);
      //find index of keys
      const index = tableHeading.findIndex((e) => e == field);
      const keyName = tableKeys[index];
      const sortedData = sortTableData(tableData as [], keyName, "dsc");
      dispatch(setSortedData(sortedData));
    }
  };

  const sortKeys = ["Date", "Name"];

  return (
    <div
      className="overflow-x-scroll w-full mr-6"
      style={{
        scrollbarWidth: "thin",
        scrollbarColor: "#D7DBEC transparent",
        msOverflowStyle: "none",
        overflowX: "scroll",
        overflowY: "hidden",
        borderRadius: "16px"
      }}
    >
      <table className="w-full">
        <thead>
          <tr className="border-b-2 whitespace-nowrap">
            {tableHeading.map((data: any, i: number) => {
              if (i == 0) return;
              return (
                <th key={i} className="pr-1 md:pr-3 py-2 md:py-4 text-sm md:text-base  ">
                  <div className="flex gap-2 px-4 min-w-32">
                    <p className="font-extrabold">{data}</p>
                    {sortKeys.includes(data) && tableHeading[0] == "pendingContract" && <img className={`cursor-pointer transition-all ${sortData[getIndexFromSortDataByKey(data)].sort == "dsc" ? "rotate-0" : "rotate-180"}`} onClick={() => sortHandler(data)} src={SortArrow} />}
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="text-gray-600 w-full ">
          {tableData && tableData.length > 0 ? (
            tableData.map((data, index) => (
              <tr key={index} className="border-b-2">
                {tableKeys.map((key, i) => {
                  if (i === 0) return null;
                  if (key == "action") return <ActionModalHandler index={index} actions={actions} data={data} selectedRow={selectedRow} setShowToolTip={setShowToolTip} showTooltip={showTooltip} showMenu={showMenu} setShowMenu={setShowMenu} />;
                  if (tableKeys[0] === "pendingContract" && key === "createdOn") return <td className="whitespace-nowrap">{data[key] ? dayjs(data[key]).format("MMM D, YYYY") : "-"}</td>;
                  if (key == "hierarchicalLevel") {
                    let level = "";
                    if (data[key] == 0) {
                      level = "Partner";
                    } else if (data[key] == 1) {
                      level = "Associate";
                    } else {
                      level = "Advisor";
                    }
                    return (
                      <td key={i} className="text-center pr-6 py-4 ">
                        {level || "-"}
                      </td>
                    );
                  }

                  if (key == "sn") {
                    return (
                      <td key={i} className=" pr-6 py-4 pl-4">
                        {index + (paginationData.page - 1) * paginationData.limit + 1 || "-"}
                      </td>
                    );
                  }

                  if (key == "partnerContractSignedRate") {
                    return (
                      <td key={i} className="text-center pr-6 py-4 ">
                        <input key={i} disabled type="number" className="border-zinc-700 border-2" value={data.partnerContractSignedRate}></input>
                      </td>
                    );
                  }

                  if (key == "zeroPercentPartner" && i == 7) {
                    if (data[key] === true) {
                      return (
                        <td key={i} className="text-center pr-6 py-4 ">
                          <div className="inline-flex items-left" key={key}>
                            <label className="relative flex items-left p-3 rounded-full cursor-pointer" htmlFor="check">
                              <input disabled checked type="checkbox" className="before:content[''] border-zinc-700 peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-400 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-gray-900 checked:before:bg-gray-900 hover:before:opacity-10" id="check" />
                              <span className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor" stroke="currentColor" strokeWidth="1">
                                  <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path>
                                </svg>
                              </span>
                            </label>
                          </div>
                        </td>
                      );
                    } else {
                      return (
                        <td key={i} className="text-center pr-6 py-4 ">
                          <div className="inline-flex items-left" key={key}>
                            <label className="relative flex items-left p-3 rounded-full cursor-pointer" htmlFor="check">
                              <input
                                onChange={() => {
                                  updateZeroPercentManualFunction(index);
                                }}
                                checked={data["zeroPercentManual"] == true ? true : false}
                                type="checkbox"
                                className="before:content[''] border-zinc-700 peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-400 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-gray-900 checked:before:bg-gray-900 hover:before:opacity-10"
                                id="check"
                              />
                              <span className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor" stroke="currentColor" strokeWidth="1">
                                  <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path>
                                </svg>
                              </span>
                            </label>
                          </div>
                        </td>
                      );
                    }
                  }

                  if (key == "zeroPercentPartner" && i == 8) {
                    return data["hierarchicalLevel"] == 2 ? (
                      <td key={i} className="text-center pr-6 py-4 ">
                        <div className="inline-flex items-left" key={key}>
                          <label className="relative flex items-left p-3 rounded-full cursor-pointer" htmlFor="check">
                            <input
                              onChange={() => {
                                updatePromoteLevelManualFunction(index);
                              }}
                              checked={data["promoteLevelManual"] == true ? true : false}
                              type="checkbox"
                              className="before:content[''] border-zinc-700 peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-400 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-gray-900 checked:before:bg-gray-900 hover:before:opacity-10"
                              id="check"
                            />
                            <span className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor" stroke="currentColor" strokeWidth="1">
                                <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path>
                              </svg>
                            </span>
                          </label>
                        </div>
                      </td>
                    ) : (
                      <td key={i} className="text-center pr-6 py-4 ">
                        -
                      </td>
                    );
                  }

                  let cellValue = data[key];

                  if (key.includes(".")) {
                    const nestedKeys = key.split(".");
                    let nestedData = data;
                    for (const nestedKey of nestedKeys) {
                      nestedData = nestedData[nestedKey];
                      if ([undefined, null].includes(nestedData)) {
                        cellValue = "-";
                        break;
                      } else {
                        cellValue = nestedData;
                      }
                    }
                  }
                  return (
                    <td key={i} className="text-left pr-6 py-4 ">
                      {cellValue || "-"}
                    </td>
                  );
                })}
              </tr>
            ))
          ) : (
            <td colSpan={tableHeading.length} className="text-center text-xl pt-2">
              No data found
            </td>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ActionTable;
