import React from "react";
import { FaFilePdf } from "react-icons/fa";

const arr = [1, 2, 3, 4, 5, 6];

const BlogSkeleton = () => {
  return (
    <>
      {arr.map((obj: any, i: number) => (
        <div key={i} className=" border-2 shadow-lg rounded-xl">
          <div key={obj} role="status" className="flex items-center w-full justify-center h-44  bg-gray-300 animate-pulse ">
            <FaFilePdf className="w-16 h-16 text-bg-gray-300" />
          </div>
        </div>
      ))}
    </>
  );
};

export default BlogSkeleton;
