import React, { useState } from "react";
import LogoIcon from "../../../assets/icons/dashboard/logo.svg";
import { MdKeyboardArrowDown, MdMenu } from "react-icons/md";
import Menu from "../../../component/common/Menu";
import Typography from "../../../component/semantic/Typography";

function Navbar() {
  const [activeTab, setActiveTab] = useState("");

  const baseUrl = process.env.REACT_APP_ENV !== "prod" ? `https://www.${process.env.REACT_APP_ENV}.insurancesamadhan.com/` : "https://www.insurancesamadhan.com/";

  const navbarList: any = [
    { name: "Home", menuList: [], route: baseUrl, isMenu: false },
    {
      name: "Services",
      menuList: [
        { name: "Mis-selling of Insurance Policy", route: baseUrl + "misselling-of-insurance-policy" },
        { name: "Claim Rejection", route: baseUrl + "claim-rejection" },
        { name: "Delay In Claim Process", route: baseUrl + "delay-in-claim-process" },
        { name: "Claim Short-settled", route: baseUrl + "claim-short-settled" },
        { name: "Health Claim Reimbursement", route: baseUrl + "health-claim-reimbursement" },
        { name: "Know your policy", route: baseUrl + "know-your-policy" }
      ],
      isMenu: true
    },
    { name: "About us", menuList: [], route: baseUrl + "about", isMenu: false },
    {
      name: "Insights",
      menuList: [
        { name: "Customer Review", route: baseUrl + "testimonials" },
        { name: "Media Coverage", route: baseUrl + "media-coverage" }
      ],
      isMenu: true
    },
    { name: "Blogs", menuList: [], route: baseUrl + "blog", isMenu: false },
    { name: "Career", menuList: [], route: baseUrl + "career", isMenu: false }
    // { name: "Partnership", menuList: [] }
  ];

  const [openDropdown, setOpenDropdown] = useState<any>(false);
  return (
    <div className="bg-white w-full px-4 md:px-[8%] py-3 flex justify-between md:justify-between relative">
      <a href={baseUrl}>
        <img className="cursor-pointer" alt="INSA logo" src={LogoIcon} />
      </a>
      <div className="block md:hidden">
        <button className="text-4xl" onClick={() => setOpenDropdown(!openDropdown)}>
          <MdMenu />
        </button>
        <Menu className="w-full z-50 left-0 top-[100%] px-5 overflow-scroll" showMenu={openDropdown}>
          <ul>
            {navbarList?.map((res: { name: string; menuList: any }, index: number) => {
              return (
                <li key={index} className={`mr-3 w-auto cursor-pointer hover:text-[#F26F21] ${activeTab === res.name ? "text-[#F26F21]" : ""} p-0`} onClick={() => setActiveTab(activeTab && res.name === activeTab ? "" : res.name)}>
                  <div className="flex">
                    <Typography>{res.name}</Typography>
                    <span className="my-auto">{res.menuList?.length ? <MdKeyboardArrowDown /> : null}</span>
                  </div>
                  ,
                  {res.menuList?.length && activeTab === res.name ? (
                    <div className="w-full left-0 bg-[#fff6d7] px-2 block">
                      <React.Fragment>
                        {res.menuList?.length
                          ? res.menuList?.map((menuItem: { name: string; route: string }, index: number) => {
                              return (
                                <li onClick={() => window.open(menuItem.route)} key={index} className="text-black hover:text-[#F26F21] hover:bg-gray-100 px-4 py-2">
                                  {menuItem?.name}
                                </li>
                              );
                            })
                          : null}
                      </React.Fragment>
                    </div>
                  ) : null}
                </li>
              );
            })}
          </ul>
        </Menu>
      </div>

      {navbarList?.length ? (
        <div className="my-auto hidden md:block">
          <ul className="flex justify- items-center">
            {navbarList?.map((res: { name: string; menuList: any; route: string; isMenu: boolean }, index: number) => {
              return (
                <li
                  key={index}
                  className={`mr-3 cursor-pointer hover:text-[#F26F21] ${activeTab === res.name ? "text-[#F26F21]" : ""}`}
                  onClick={() => {
                    res?.isMenu && setActiveTab(res.name);
                  }}
                >
                  <div
                    className="flex"
                    onClick={() => {
                      !res.isMenu && window.open(res?.route);
                    }}
                  >
                    <Typography>{res.name}</Typography>
                    <span className="my-auto">{res.menuList?.length ? <MdKeyboardArrowDown /> : null}</span>
                  </div>
                  <Menu showMenu={activeTab === res.name} onClose={() => setActiveTab("")} className="z-50">
                    <React.Fragment>
                      {res.menuList?.length
                        ? res.menuList?.map((menuItem: { name: string; route: string }, index: number) => {
                            return (
                              <li key={index} onClick={() => window.open(menuItem.route)} className="text-black hover:text-[#F26F21] hover:bg-gray-100 px-4 py-2">
                                {menuItem.name}
                              </li>
                            );
                          })
                        : null}
                    </React.Fragment>
                  </Menu>
                </li>
              );
            })}
          </ul>
        </div>
      ) : null}
      <div className="md:flex justify-between gap-4 font-bold invisible hidden">
        <button className="bg-black text-white px-6 rounded-3xl">File Complaint </button>
        <button className="bg-white text-black border-2 border-black px-4 rounded-3xl">Login</button>
      </div>
    </div>
  );
}

export default Navbar;
