import React, { FC, useState } from "react";
import Modal from "../../../component/common/Modal";
import CloseIcon from "../../../assets/icons/gallery/close.svg";
import InsaLogo from "../../../assets/logo/insa-logo.png";
import InvoiceSign from "../../../assets/logo/invoice-sign.png";

interface ModalProps {
  confirmationModal: boolean;
  setConfirmationModal: any;
  selectedRow: any;
  signPartnerContract: any;
}

const ViewContract: FC<ModalProps> = ({ confirmationModal, setConfirmationModal, selectedRow }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const onClose = () => setIsDialogOpen(false);

  return (
    <Modal showModal={confirmationModal} onClose={() => setConfirmationModal(false)} className="relative h-[90%]  w-[80%] p-6 ">
      <img src={CloseIcon} className="absolute z-50 text-3xl text-gray-400 -right-3 -top-3 cursor-pointer" onClick={() => setConfirmationModal(false)} />
      <section className="section overflow-scroll h-[90%] custom-polifyx-scrollbar">
        <div className="container mx-auto py-10">
          <div className="flex justify-center">
            <div className="md:w-10/12 sm:w-full mx-auto">
              {isDialogOpen && (
                <button onClick={onClose} type="button" className="bg-red-500 text-white p-2 rounded">
                  Close
                </button>
              )}

              <div className="card bg-white shadow-lg rounded-lg">
                <div className="card-header bg-green-500 text-white p-4">
                  <h2 className="text-2xl">CONTRACT TERMS</h2>
                  <p className="card-category">Please check below and submit the contract to proceed.</p>
                </div>
                <div className="flex justify-between">
                  <div className="w-1/3">
                    <div className="logo">
                      <img src={InsaLogo} alt="logo" />
                    </div>
                  </div>
                  <div className="w-1/3">
                    <p>Fastsurance Consultants Private Limited</p>
                    <p>
                      Corporate Address: A-31, 2<sup>nd</sup> Floor, Infraline Tower, Sector 3, Noida - 201301
                    </p>
                    <p>Registered Address: R-104, Basement, GK-1, New Delhi - 110048</p>
                    <p>Tel: +91-9513631312</p>
                    <p>Email: corporate@insurancesamadhan.com</p>
                    <p>
                      Website: <a href="mailto: www.insurancesamadhan.com">www.insurancesamadhan.com</a>
                    </p>
                    <p>CIN: U65100DL2016PTC305722</p>
                  </div>
                </div>

                {selectedRow && selectedRow.zeroPercentPartner && selectedRow.hierarchicalLevel == 2 && <ZeroPercentPartnerForLevelTwo selectedRow={selectedRow} />}
                {selectedRow && selectedRow.zeroPercentPartner && selectedRow.hierarchicalLevel == 1 && <ZeroPercentPartnerForLevelOne selectedRow={selectedRow} />}
                {selectedRow && selectedRow.zeroPercentPartner && selectedRow.hierarchicalLevel == 0 && <ZeroPercentPartnerForLevelZero selectedRow={selectedRow} />}
                {selectedRow && selectedRow.partnerType == "revenueSharing" && selectedRow.isLeadCost == false && <RevenueSharingWithoutLeadCost selectedRow={selectedRow} />}
                {selectedRow && selectedRow.partnerType == "revenueSharing" && selectedRow.isLeadCost == true && <RevenueSharingWithLeadCost selectedRow={selectedRow} />}
                {selectedRow && selectedRow.partnerType == "leadCost" && <LeadCostSharing selectedRow={selectedRow} />}
                {selectedRow && selectedRow.partnerType == "noRevenueSharing" && selectedRow.revenueType == "firstType" && <NoRevenueSharingFirstType selectedRow={selectedRow} />}
                {selectedRow && selectedRow.partnerType == "noRevenueSharing" && selectedRow.revenueType == "secondType" && <NoRevenueSharingSecondType selectedRow={selectedRow} />}
                {selectedRow && selectedRow.partnerType == "hierarchyWise" && selectedRow.hierarchicalLevel == "0" && <HierarchyWiseLevelZero selectedRow={selectedRow} />}
                {selectedRow && selectedRow.partnerType == "hierarchyWise" && selectedRow.hierarchicalLevel == "1" && <HierarchyWiseLevelOne selectedRow={selectedRow} />}
                {selectedRow && selectedRow.partnerType == "hierarchyWise" && selectedRow.hierarchicalLevel == 2 && <HierarchyWiseLevelTwo selectedRow={selectedRow} />}
              </div>

              {/* {selectedRow && !selectedRow.isAgreed && (
                <form onSubmit={signPartnerContract} className="mt-4">
                  <div className="form-group">
                    <label className="inline-flex items-center">
                      <input type="checkbox" className="form-checkbox" required />
                      <span className="ml-2">I agree to all of the above terms and conditions</span>
                    </label>
                  </div>
                  <button type="submit" className="bg-green-500 text-white p-2 rounded">
                    Proceed To Sign
                  </button>
                </form>
              )} */}
              {selectedRow && selectedRow.isAgreed && (
                <div className="sign mt-4">
                  <p className="text-success text-green-500">SUCCESSFULLY SIGNED</p>
                  <p className="signed-on">Signed on: {new Date(selectedRow.agreedOn).toLocaleDateString()}</p>
                </div>
              )}
              <div className="space my-4"></div>
            </div>
          </div>
        </div>
      </section>
    </Modal>
  );
};

export default ViewContract;

const ZeroPercentPartnerForLevelTwo: FC<any> = ({ selectedRow }) => {
  return (
    <div className="px-6 py-8 text-gray-800 text-sm leading-6">
      <h2 className="text-center text-2xl font-bold mb-4">Agreement for Business Support Services</h2>
      <div className="policies text-sm leading-6 px-4 py-4 font-sans text-gray-800">
        <p>
          This AGREEMENT FOR BUSINESS SUPPORT SERVICES is made between <strong className="font-semibold">“The Partner/User/You”</strong>
          (which expression shall unless repugnant to the context or meaning thereof include its successors, legal representatives and permitted assigns) of the <strong className="font-semibold">First Part</strong>;
        </p>
        <p className="text-center">AND</p>
        <p>
          <strong className="font-semibold">FASTSURANCE CONSULTANTS PVT. LTD.</strong>, a company incorporated in India and having its registered office at R - 104, Basement Greater Kailash I, New Delhi, South Delhi, DL-110048, India (hereinafter referred to as the “Company”, which expression shall, unless repugnant to or inconsistent with the context, mean and include any successors or permitted assigns) of the <strong className="font-semibold">SECOND PART</strong>
        </p>
        <p>
          <strong className="font-semibold">“The Company”</strong> and <strong className="font-semibold">“the Partner”</strong> are jointly referred to as <strong className="font-semibold">“Parties”</strong> and individually as <strong className="font-semibold">“Party”</strong>.
        </p>
        <p>
          <strong className="font-semibold">BY CLICKING THE ACCEPTANCE BUTTON, THE PARTNER EXPRESSLY AGREES TO AND CONSENTS TO BE BOUND BY ALL OF THE TERMS OF THIS AGREEMENT.</strong>
        </p>
        <p>
          <strong className="font-semibold">WHEREAS</strong>
        </p>

        <ol className="list-decimal pl-5">
          <li>The Company is interalia engaged in the business of providing services of various nature in the field of Life, Health and General Insurance, such as assistance in resolving consumer grievances by representing clients at various forums like Insurance Companies, IRDA, Ombudsman, Consumer Forums, Legal Courts. The overall mission of Insurance Samadhan is centered towards social cause and the betterment/upliftment of the general impression of the insurance companies.</li>
          <li>The Partner has approached the Company for providing its services and to work with the Company for mutual benefits. The Parties have agreed to enter into this Agreement on the terms as set out herein under.</li>
        </ol>

        <p>
          <strong className="font-semibold">NOW, IN CONSIDERATION OF THE ABOVE PREMISES AND MUTUAL COVENANTS CONTAINED HEREINAFTER THIS AGREEMENT WITNESSETH AS UNDER:-</strong>
        </p>

        <ol className="list-decimal pl-5">
          <li>
            <strong className="font-semibold">SERVICES</strong>
            <ol className="list-decimal pl-5">
              <li>The Company has communicated its requirement to the Partner and after understanding all the requirements of the Company, Partner has agreed to work with the Company.</li>
            </ol>
          </li>
          <li>
            <strong className="font-semibold">TERM</strong>
            <ol className="list-decimal pl-5">
              <li>This Agreement shall become effective from the date of execution of this Agreement (“Effective Date”) and shall remain valid and binding on the Parties for a period of 1 (one) year from the Effective Date (“Term”), unless terminated earlier in accordance with the terms hereof.</li>
              <li>Upon expiry of this Agreement, the Parties may extend the duration of this Agreement subject to mutually agreed terms and conditions.</li>
            </ol>
          </li>
          <li>
            <strong className="font-semibold">STANDARD OF PERFORMANCE</strong>
            <ol className="list-decimal pl-5">
              <li>
                PARTNER shall:
                <ol className="list-decimal pl-5">
                  <li>perform the Services in a professional manner and in accordance with the terms and conditions of this Agreement, Applicable Laws, Applicable Permits (as defined hereinafter), Good Industry Practices, directions, guidelines and instructions issued by the Company from time to time;</li>
                  <li>make efforts to prevent any delay in the performance of its obligations hereunder and shall work together with the Company for the effective and timely provisions of the Services;</li>
                  <li>maintain, preserve and keep in safe custody any and all records in relation to this Agreement for a period of twenty four months;</li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <strong className="font-semibold">REPRESENTATIONS AND WARRANTIES</strong>
            <ol className="list-decimal pl-5">
              <li>
                Each Party represents and warrants to the other Party that:
                <ol className="list-decimal pl-5">
                  <li>It is a company duly and legally organized and validly existing under the laws of India;</li>
                  <li>This Agreement constitutes its valid and legally binding obligations, enforceable in accordance with its terms;</li>
                  <li>It has full corporate power and authority to execute and deliver this Agreement in accordance with its terms and to perform all its duties and obligations arising or created under or pursuant to this Agreement and all requisite corporate approvals, as applicable, have been obtained by it prior to the execution of this Agreement;</li>
                  <li>The execution of this Agreement and delivery and performance by it of its obligations hereunder do not and shall not violate or conflict in any manner with or result in a breach or default under Applicable Law or any of its duties or obligations under any agreement, understanding or arrangement, written or oral, to which it is a party.</li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <strong className="font-semibold">TERMS OF CONSIDERATION</strong>
            <ol className="list-decimal pl-5">
              <li>The Company will share pre decided commercials only in case of genuine leads which are coming through Partner as source. Any other method is not viable under current scenario and can be discussed and amended as and when it arises.</li>
              <li>The Company will not pay for promotional campaigns of any done by Partner for generating leads.</li>
              <li>Partner shall not charge any registration fee for the customers registering through them.</li>
              <li>The Company is not responsible for resolution of any case even after acceptance as Insurance resolution is a result of many factors which cannot be deemed as final at any stage of the case.</li>
              <li>The compensation shall be paid in terms of the mutual agreement between the Parties post the successful completion of work. The mode of payment shall be through money transfer once the company receives the credit from the customer.</li>
            </ol>
          </li>
          <li>
            <strong className="font-semibold">TERMINATION</strong>
            <ol className="list-decimal pl-5">
              <li>This Agreement may be terminated by either Party without assigning any reason at any time by giving 1 (one) month prior written notice to the other Party.</li>
              <li>
                Without prejudice to any other rights or remedies available in law or under this Agreement, either Party may terminate this Agreement forthwith on or at any time after the occurrence of any of the events specified herein below:
                <ol className="list-decimal pl-5">
                  <li>Insolvency of the other Party;</li>
                  <li>Material breach of any of the terms or conditions of this Agreement by the other Party which breach is not remedied by such other Party to the satisfaction of the non-breaching Party within three (3) days of notice of the breach;</li>
                  <li>If a petition for winding up of the other Party is decreed in any Court or a resolution is passed for winding-up the business of such other Party or if any receiver, manager, liquidator, administrator or other similar official is appointed to the other Party or a substantial part of the other Party’s property.</li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <strong className="font-semibold">INDEPENDENT CONTRACTOR</strong>
            <ol className="list-decimal pl-5">
              <li>It is hereby acknowledged that Partner shall perform the services in the capacity of an independent contractor and not as an employee, servant or agent of the Company. Nothing contained herein shall be construed to create a relationship of employer-employee and master-servant between Company and Partner.</li>
            </ol>
          </li>
          <li>
            <strong className="font-semibold">INTELLECTUAL PROPERTY</strong>
            <ol className="list-decimal pl-5">
              <li>The Company does not grant to Partner any right, title or interest in any of its intellectual property except as expressly authorised in writing by the Company and Partner shall not have any right, title or interest in Company’s intellectual property other than the right to use it for purposes of this Agreement for the Term hereof. Partner shall comply with any and all instructions issued by Company in relation to the display of any logo, trademark, copyright or any other intellectual property of the Company. Upon expiry or earlier termination of this Agreement, Partner shall immediately cease and desist for all times from any use of or reference to Company’s intellectual property and shall return to Company copies or materials containing such intellectual property.</li>
              <li>
                Parties hereby acknowledge and confirm that:
                <ol className="list-decimal pl-5">
                  <li>All Intellectual Property Rights in or relating to the Services are and shall at all times remain the property of Company and / or its licensors;</li>
                  <li>Partner shall notify Company immediately if Partner becomes aware of any illegal or unauthorized use of any the intellectual property therein or relating thereto and will assist Company in taking all steps necessary to protect and defend Company’s rights therein.</li>
                </ol>
              </li>
              <li>The provisions of this Clause 8 shall survive the termination of this Agreement.</li>
            </ol>
          </li>
          <li>
            <strong className="font-semibold">CONFIDENTIALITY</strong>
            <ol className="list-decimal pl-5">
              <li>The Parties acknowledge that in the course of performing their obligations under this Agreement, each of the Parties may get access to confidential information (“Confidential Information”) of the other Party. Each of the Parties (“Receiving Party”) agrees not to disclose Confidential Information received (whether in writing, verbally or by any other means and whether directly or indirectly) by the other Party (“Disclosing Party”) including in relation to matters contemplated by this Agreement or its performance or in any agreement in furtherance of the performance of this Agreement, to any Third Party without the prior written consent of the Disclosing Party or use the Confidential Information other than for carrying out the purposes of this Agreement.</li>
              <li>The Receiving Party shall keep confidential any Confidential Information it receives from the Disclosing Party and shall employ all such reasonable steps that it would have taken to protect its own Confidential Information. The Receiving Party shall disclose Confidential Information received from the Disclosing Party to its officers, employees, agents or representatives only for the purposes of carrying out its obligations under this Agreement and strictly on a “need to know” basis only. The Parties shall also cause their respective directors, employees, officers and any other Persons to whom the above mentioned information is disclosed to be bound by confidentiality obligations similar to those provided in this Clause.</li>
              <li>
                Notwithstanding anything contained in Clause 9.1 and Clause 9.2, a Receiving Party’s obligation of confidentiality shall not extend to any Confidential Information:
                <ol className="list-decimal pl-5">
                  <li>To the extent that the Confidential Information received is in the public domain other than by breach of this Agreement;</li>
                  <li>To the extent that the Confidential Information is lawfully acquired by the Receiving Party from a Third Party who owes the Disclosing Party no obligation of confidence in respect of such Confidential Information;</li>
                  <li>To the extent that the Confidential Information was previously known or already in the lawful possession of the Receiving Party prior to receipt from the Disclosing Party;</li>
                  <li>To the extent that the Confidential Information received is required to be disclosed by any applicable Law or by any Authority to whose jurisdiction the Receiving Party is subject or with whose instructions it is customary to comply under notice to the Disclosing Party; or</li>
                  <li>In so far as it is disclosed to the employees, directors or professional advisers of the Receiving Party, provided that the Receiving Party shall procure that such persons treat the Confidential Information received as confidential.</li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <strong className="font-semibold">GOVERNING LAW</strong>
            <ol className="list-decimal pl-5">
              <li>This Agreement shall be governed by and construed in accordance with the laws of India, without giving effect to any choice of law or conflict of law provisions. The Parties consent to the exclusive jurisdiction of courts of Delhi with respect to any matter or claim, suit, action or proceeding arising under or related to this Agreement.</li>
            </ol>
          </li>
          <li>
            <strong className="font-semibold">MISCELLANEOUS</strong>
            <ol className="list-decimal pl-5">
              <li>
                <strong className="font-semibold">Severability:</strong> The provisions of this Agreement are severable and the validity of remaining Articles, provisions, terms and parts of this Agreement shall not be affected by a court, administrative board or other proceeding of competent jurisdiction deciding that a Clause, provision, term or part of this Agreement is illegal, unenforceable, void, in conflict with any law or contrary to public policy. In such event the Parties shall, by amendment of this Agreement, properly replace such provision by a reasonable new provision or provisions which, as far as legally possible, shall approximate to the closest possible extent what the Parties intended by such original provision and the purpose thereof.
              </li>
              <li>
                <strong className="font-semibold">Assignment of Agreement:</strong> Except as permitted or required by this Agreement or as otherwise agreed in writing between the Parties:
                <ol className="list-decimal pl-5">
                  <li>This Agreement may not be assigned, novated or transferred; and</li>
                  <li>Neither Party shall create or permit to subsist any encumbrance over all or any of its rights and benefits under this Agreement.</li>
                </ol>
              </li>
              <li>
                <strong className="font-semibold">Amendment:</strong> No oral or written modification, amendment, rescission, waiver or other change of this Agreement or any of its terms or provisions shall be valid or legally binding on the Parties unless made in writing and duly executed by or on behalf of all the Parties, including without limitation, any purported modification, amendment, rescission, waiver or other change of this itself. No amendments, supplements or modifications to this Agreement shall be made except by written agreement between the Parties.
              </li>
              <li>
                <strong className="font-semibold">No Waiver:</strong> A waiver by any Party of any default or defaults by the other Party in the performance of any of the provisions of this Agreement shall be effective only when executed in writing by duly authorized representatives of all Parties. A waiver shall never operate as or be construed as a waiver of any other or further default or defaults whether of a like or different character. Neither the failure by a Party to insist on any occasion upon the performance of the terms, conditions, and provisions of this Agreement nor time or other indulgence granted by a Party to the other or others shall act as a waiver of such breach of acceptance of any variation or the relinquishment of any such right or any other right under this Agreement, which shall remain in full force and effect.
              </li>
              <li>
                <strong className="font-semibold">Counterparts:</strong> This Agreement may be executed in one or more counterparts, each of which shall be deemed an original and all of which collectively shall be deemed one and the same instrument.
              </li>
              <li>
                <strong className="font-semibold">Notices:</strong> All communication, notices or information to be given under this Agreement shall be so given in writing. All notices must be delivered personally, by registered or certified mail.
              </li>
              <li>
                <strong className="font-semibold">Language:</strong> The language of this Agreement shall be English and all documents, notices, waivers and all other written communication or otherwise between the Parties in connection with this Agreement shall be in English.
              </li>
              <li>
                <strong className="font-semibold">Remedies:</strong> Parties acknowledge that damages will not be a sufficient remedy for breach of this Agreement and the Parties shall be entitled to seek specific performance of each other’s obligations pursuant to this Agreement.
              </li>
              <li>
                <strong className="font-semibold">Further Assurances:</strong> Each Party agrees to perform (or procure the performance of) all further acts and things, and execute and deliver (or procure the execution and delivery of) such further documents, as may be required by Law or as the other Party may reasonably require, whether on or after Effective Date, to implement and/or give effect to this Agreement.
              </li>
            </ol>
          </li>
        </ol>

        <br />

        <div className="border p-4 my-4">
          <div>
            Signed and delivered for and on behalf of <strong>Fastsurance Consultants Pvt. Ltd</strong>
          </div>
          <br />
          <div>Signatures:</div>
          <div className="sign">
            <img src={InvoiceSign} alt="signature" className="w-10" />
          </div>
          <br />
          <div>Date: {selectedRow?.agreedOn ? new Date(selectedRow?.agreedOn).toLocaleDateString() : new Date().toLocaleDateString()}</div>
          <hr />
          <div>Signed and delivered for and on behalf of partner/agent</div>
          <div>Name: {selectedRow?.name || ""}</div>
          <div>Signatures:</div>
          <br />
          <div>Authorized Signatory</div>
          <div>Date: {selectedRow?.agreedOn ? new Date(selectedRow?.agreedOn).toLocaleDateString() : new Date().toLocaleDateString()}</div>
        </div>
        <p>Note: The above terms may change by time without prior notice.</p>
        <br />
        <p>*Disclaimer: Your details may be used by Insurance Samadhan for promotion on its various platforms.</p>
      </div>
    </div>
  );
};

const ZeroPercentPartnerForLevelOne: FC<any> = ({ selectedRow }) => {
  return (
    <div className="px-6 py-8 text-gray-800 text-sm leading-6">
      <h2 className="text-center text-2xl font-bold mb-4">Agreement for Business Support Services</h2>
      <div className="policies text-sm leading-6 px-4 py-4 font-sans text-gray-800">
        <p>
          This Agreement for Business Support Services (“<strong>Agreement</strong>”) is made between- <br />
          <strong>“The Partner/User/You”</strong> (which expression shall unless repugnant to the context or meaning thereof include its successors, legal representatives and permitted assigns) of the <strong>First Part</strong>;<br />
          <strong>AND</strong>
          <br />
          <strong>FASTSURANCE CONSULTANTS PVT. LTD.</strong>, a company incorporated in India and having its office at A-31, 2nd floor, Infraline Tower, Sector 3, Noida - 201301, India (hereinafter referred to as the “Company”, which expression shall, unless repugnant to or inconsistent with the context, mean and include any successors or permitted assigns) of the <strong>SECOND PART</strong>.<br />
          <strong>“The Company”</strong> and <strong>“the Partner”</strong> are jointly referred to as <strong>“Parties”</strong> and individually as <strong>“Party”</strong>.<br />
          <strong>BY CLICKING THE ACCEPTANCE BUTTON, THE PARTNER EXPRESSLY AGREES TO AND CONSENTS TO BE BOUND BY ALL OF THE TERMS OF THIS AGREEMENT.</strong> <br />
          <strong>WHEREAS</strong>
        </p>
        <ol className="list-decimal pl-5">
          <li>
            The Company is <em>inter-alia</em> engaged in the business of providing services of various nature in the field of Life, Health and General Insurance, such as assistance in resolving consumer grievances by representing clients at various forums like Insurance Companies, IRDA, Ombudsman, Consumer Forums, Legal Courts (“Services”). The overall mission of Company is centred towards social cause and the betterment/upliftment of the general impression of the insurance companies.
          </li>
          <li>The Partner has approached the Company for providing its services and to work with the Company for mutual benefits. The Parties have agreed to enter into this Agreement on the terms as set out herein under.</li>
        </ol>
        <br />
        <p>
          <strong>NOW, IN CONSIDERATION OF THE ABOVE PREMISES AND MUTUAL COVENANTS CONTAINED HEREINAFTER THIS AGREEMENT WITNESSETH AS UNDER:</strong>
        </p>
        <br />
        <ol className="list-decimal pl-5">
          <li>
            <strong>SERVICES</strong>
          </li>
          <ul className="list-disc pl-10">
            <li>The Company has communicated its requirement to the Partner and after understanding all the requirements of the Company, Partner has agreed to work with the Company.</li>
          </ul>
          <br />
          <li>
            <strong>TERM</strong>
          </li>
          <ol className="list-decimal pl-10">
            <li>
              This Agreement shall become effective from the date of execution of this Agreement (“<strong>Effective Date</strong>”) and shall remain valid and binding on the Parties for a period of 1 (one) year from the Effective Date (“<strong>Term</strong>”), unless terminated earlier in accordance with the terms hereof.
            </li>
            <li>Upon expiry of this Agreement, the Parties may extend the duration of this Agreement subject to mutually agreed terms and conditions.</li>
          </ol>
          <li>
            <strong>STANDARD OF PERFORMANCE</strong>
          </li>
        </ol>
        <p>Partner shall:</p>
        <ol className="list-decimal pl-5">
          <ol className="list-decimal pl-10">
            <li>perform the Services in a professional manner and in accordance with the terms and conditions of this Agreement, applicable laws, applicable permits, good industry practices, directions, guidelines and instructions issued by the Company from time to time;</li>
            <li>make efforts to prevent any delay in the performance of its obligations hereunder and shall work together with the Company for the effective and timely provisions of the Services;</li>
            <li>maintain, preserve and keep in safe custody any and all records in relation to this Agreement for a period of 24 (twenty-four) months;</li>
          </ol>
          <li>
            <strong>REPRESENTATIONS AND WARRANTIES</strong>
          </li>
          <ol className="list-decimal pl-10">
            <li>Each of the Parties hereby represents and warrants the following to all other Parties of this Agreement:</li>
            <ol className="list-decimal pl-10">
              <li>it has the power and all necessary governmental and other consents, approvals, licenses and authorizations to enter into this Agreement, and that it has complied with such consents, approval, licenses, and authorizations in all material respects and none of the aforesaid have been revoked or otherwise terminated;</li>
              <li>it has the power to enter into and perform, and has taken all necessary action to authorize the entry into, performance, delivery and execution of this Agreement and all agreements executed or to be executed in pursuance thereof;</li>
              <li>this Agreement and all other agreements executed or to be executed in pursuance thereof constitute or will constitute its legal, valid, binding and enforceable obligations in accordance with their terms and conditions.</li>
            </ol>
            <li>the entry into, and performance by it of this Agreement and all agreements executed or to be executed in pursuance hereof and the transactions contemplated hereunder do not and will not:</li>
            <ol className="list-decimal pl-10">
              <li>conflict in any material respect with any law or regulation or judicial or official order in each case currently applicable to them; or</li>
              <li>conflict in any material respect with any document which is binding to them or on any their assets;</li>
            </ol>
            <li>to the best of their knowledge, information and belief, there are no legal proceedings, suits, appeals, and other actions in law, whether judicial, fiscal, administrative, pending or threatened against them, or claim against them which:</li>
            <ol className="list-decimal pl-10">
              <li>may prevent the performance of this Agreement or any of the transaction contemplated hereby, or cause the rescission thereof; or</li>
              <li>may result in any encumbrance and/or any other third-party rights or otherwise challenge or question any right, title or interest of the Parties in this Agreement.</li>
            </ol>
            <li>The Partner represents, warrants and covenants to Company that:</li>
            <ol className="list-decimal pl-10">
              <li>he has requisite skills, knowledge, experience to provide the Services under this Agreement;</li>
              <li>he shall perform the Services in a professional, ethical and workmanlike manner;</li>
              <li>the Services to be performed by the partner/ service provider under this Agreement shall be free from material errors or other defects and if material errors or other defects are found, then the partner/ service provider will fix the deficiency without any additional charges being incurred by the Company.</li>
            </ol>
          </ol>
          <li>
            <strong>TERMS OF CONSIDERATION</strong>
          </li>
          <ol className="list-decimal pl-10">
            <li>The Company will share pre decided commercials only in case of genuine leads which are coming through Partner as source. Any other method is not viable under current scenario and can be discussed and amended as and when it arises.</li>
            <li>The Company will not pay for promotional campaigns of any done by Partner for generating leads.</li>
            <li>Partner shall not charge any registration fee for the customers registering through them.</li>
            <li>The Company is not responsible for resolution of any case even after acceptance as Insurance resolution is a result of many factors which cannot be deemed as final at any stage of the case.</li>
            <li>In cases wherein the success fee is negotiated, the payment shall be made on a pro-rata basis.</li>
            <li>The compensation shall be paid in terms of the mutual agreement between the Parties post the successful completion of work. The mode of payment shall be through money transfer once the company receives the credit from the customer.</li>
          </ol>
          <li>
            <strong>TERMINATION</strong>
          </li>
          <ol className="list-decimal pl-10">
            <li>This Agreement may be terminated by either Party without assigning any reason at any time by giving 1 (one) month prior written notice to the other Party.</li>
            <li>Without prejudice to any other rights or remedies available in law or under this Agreement, either Party may terminate this Agreement forthwith on or at any time after the occurrence of any of the events specified herein below:</li>
            <ol className="list-decimal pl-10">
              <li>Insolvency of the other Party;</li>
              <li>Material breach of any of the terms or conditions of this Agreement by the other Party which breach is not remedied by such other Party to the satisfaction of the non-breaching Party within three (3) days of notice of the breach;</li>
              <li>If a petition for winding up of the other Party is decreed in any Court or a resolution is passed for winding-up the business of such other Party or if any receiver, manager, liquidator, administrator or other similar official is appointed to the other Party or a substantial part of the other Party’s property.</li>
            </ol>
          </ol>
          <li>
            <strong>CONSEQUENCES OF TERMINATION</strong>
          </li>
        </ol>
        <p>On termination of this Agreement for any reason:</p>
        <ol className="list-decimal pl-10">
          <li>the Partner shall forthwith provide such assistance as is reasonably necessary for the orderly assumption of the Services by a third party or the Company;</li>
          <li>the Partner shall deliver all copies of the deliverables that the Partner has developed up to the termination date;</li>
          <li>the accrued rights, remedies, obligations and liabilities of the Company as at termination shall not be affected, including the right to claim damages for any breach of this Agreement, which existed at or before the date of termination.</li>
          <li>if the Partner terminates this Agreement in accordance with termination clause, then the Company shall be entitled to a refund of any Fees paid to the partner/ service provider under this Agreement.</li>
          <li>if the Company exercises the right to terminate under termination clause, the Company may do so without any penalty, obligation, or liability to the partner/ service provider under this Agreement.</li>
        </ol>
        <p>
          <strong>INDEPENDENT CONTRACTOR</strong>
        </p>
        <p>It is hereby acknowledged that Partner shall perform the services in the capacity of an independent contractor and not as an employee, servant or agent of the Company. Nothing contained herein shall be construed to create a relationship of employer-employee and master-servant between Company and Partner.</p>
        <p>&nbsp;</p>
        <p>
          <strong>INTELLECTUAL PROPERTY</strong>
        </p>
        <ol className="list-decimal pl-10">
          <li>The Company does not grant to Partner any right, title or interest in any of its intellectual property except as expressly authorised in writing by the Company and Partner shall not have any right, title or interest in Company’s intellectual property other than the right to use it for purposes of this Agreement for the Term hereof. Partner shall comply with any and all instructions issued by Company in relation to the display of any logo, trademark, copyright or any other intellectual property of the Company. Upon expiry or earlier termination of this Agreement, Partner shall immediately cease and desist for all times from any use of or reference to Company’s intellectual property and shall return to Company copies or materials containing such intellectual property.</li>
          <li>Parties hereby acknowledge and confirm that:</li>
          <ol className="list-decimal pl-10">
            <li>All Intellectual Property Rights in or relating to the Services are and shall at all times remain the property of Company and / or its licensors;</li>
            <li>Partner shall notify Company immediately if Partner becomes aware of any illegal or unauthorized use of any the intellectual property therein or relating thereto and will assist Company in taking all steps necessary to protect and defend Company’s rights therein.</li>
          </ol>
          <li>The provisions of this Clause 9 shall survive the termination of this Agreement.</li>
        </ol>
        <li>
          <strong>CONFIDENTIALITY</strong>
        </li>
        <ol className="list-decimal pl-10">
          <li>
            The Partner acknowledges that during the term of this Agreement, the Partner will have access to Confidential Information of the Company and/or received by the Company from third parties, which is confidential to the Company and/or such third parties. The term “<strong>Confidential Information</strong>” used herein shall mean and include information which is confidential and proprietary to the Company and/or to certain third parties with which the Company has relationships, and disclosed to or obtained by the Partner from the Company and/or such third parties, whether (without limitation) in graphic, written, electronic or machine readable form on any media or orally and whether or not the information is expressly stated to be confidential or marked as such and includes, but is not limited to information of value or significance to the Company and/or its competitors (present or potential) such as Company’s content; data; techniques; plans; designs; programs; customer information; identity and job descriptions of Company personnel; the Company’s organizational structure; financing relationships or terms; service provider or vendor relationships or terms; processes;
            methodologies; compensation or bonus data; the terms of this Agreement; or other information not in the public domain pertaining to the Business or affairs of the Company.
          </li>
          <li>During the term of this Agreement and 1 (One) year after termination of the same, the Partner shall hold the Confidential Information in confidence and shall not publish, disclose or disseminate at any time, to any person or competitor of the Company; or use for any purpose any Confidential Information other than such purposes as shall be required to fulfil the Partner’s duties with the Company, or remove any Confidential Information, in whole or in part, from the Company’s premises, without the Company's prior written permission.</li>
          <li>Notwithstanding the aforesaid provisions, the Partner may disclose Confidential Information (i) that is/ was in the public domain; (ii) that was previously known by Partner, as established by written records of the Partner prior to receipt of such information from the Company; (iii) that was lawfully obtained by the Partner from a third party without any obligations of confidentiality to Company or (iv) where ordered to do so, by any government, judicial or quasi-judicial authority; provided however, that the Partner shall in such a case give the Company a reasonable notice of any prospective disclosure and shall assist the Company in obtaining an exemption or protective order preventing such disclosure.</li>
          <li>Upon termination of this Agreement, the Partner shall return to the Company the Confidential Information, including copies thereof irrespective of storage or presentation medium, including all electronic and hard copies thereof, and any other material containing or disclosing any Confidential Information which is in the Partner’s possession, power and control as and when called upon by the Company or with prior approval from the Company destroy the same and will not make or retain any copies of such Confidential Information.</li>
          <li>The Partner agrees that he shall protect the Confidential Information with at least the same degree of care and confidentiality as it affords his own confidential information and shall at all times exercise at least a reasonable degree of care in such protection.</li>
          <li>The Partner’s obligations with respect to confidentiality shall survive the termination or expiry of this Agreement.</li>
        </ol>
        <li>
          <strong>INDEMNITY</strong>
        </li>
      </div>
      <p>
        The Partner shall indemnify and keep indemnified, save, defend and hold harmless the Company from and against any and all direct Losses incurred or suffered by the Company in relation to any acts or omissions attributable to the Partner. For the purpose of this Clause, “<strong>Losses</strong>” shall mean any and all claims, actions, demands, assessments, losses, damages, liability, judgements, settlements, penalties, costs and expenses (including reasonable attorney’s fees and expenses), of any nature whatsoever, asserted against, resulting to, imposed upon, or incurred by the Company.
      </p>
      <br />
      <ol className="list-decimal pl-5">
        <li>
          <strong>NON-SOLICITATION</strong>
        </li>
        <ol className="list-decimal pl-10">
          <li>The Partner agrees that in consideration of the customer/ partner/ service provider’s engagement with Company, and other good and valuable consideration, the receipt of which is hereby acknowledged, during the term of this Agreement, the Partner shall not, directly or indirectly, in his individual capacity or through a partnership or any other entity:</li>
          <ol className="list-decimal pl-10">
            <li>solicit employment of or advise any other employee of Company to terminate his contract or relationship with Company; or</li>
            <li>contact any of the existing or prospective clients/users of Company, to entice such users away from Company or to damage in any way the Company’s relationship with such users.</li>
          </ol>
        </ol>
        <li>
          <strong>GOVERNING LAW AND DISPUTE RESOLUTION</strong>
        </li>
        <p>This Agreement shall be governed and construed in accordance with the substantive laws of India. The Parties shall endeavor to first resolve any dispute or claim arising amicably between them under this Agreement by mutual negotiations.</p>
      </ol>
      <ol className="list-decimal pl-5">
        <ol className="list-decimal pl-10">
          <li>In case of failure to amicably settle the dispute or the claim within thirty (30) days of one party notifying in writing to the other Party of the dispute or the claim, the dispute or the claim shall be referred to arbitration and finally settled by a sole arbitrator who shall be mutually appointed by the Parties in accordance with the Arbitration and Conciliation Act, 1996, as amended from time to time. The seat and place of arbitration shall be Noida, India and the language of arbitration shall be English.</li>
          <li>The proceedings of such arbitration may be conducted with the Parties being present either physically or virtually. Each Party will bear and pay professional charges for their respective advocates and consultants, if any.</li>
          <li>The Courts at Noida, India shall have exclusive jurisdiction over all and any matters that are ancillary to this Agreement, and the Parties hereby agree to submit to the jurisdiction of the said courts for such matters.</li>
        </ol>
        <li>
          <strong>MISCELLANEOUS</strong>
        </li>
        <ol className="list-decimal pl-10">
          <li>
            <strong>Severability:</strong> The provisions of this Agreement are severable and the validity of remaining Clauses, provisions, terms and parts of this Agreement shall not be affected by a court, administrative board or other proceeding of competent jurisdiction deciding that a Clause, provision, term or part of this Agreement is illegal, unenforceable, void, in conflict with any law or contrary to public policy. In such event the Parties shall, by amendment of this Agreement, properly replace such provision by a reasonable new provision or provisions which, as far as legally possible, shall approximate to the closest possible extent what the Parties intended by such original provision and the purpose thereof.
          </li>
          <li>
            <strong>Assignment of Agreement:</strong> Except as permitted or required by this Agreement or as otherwise agreed in writing between the Parties:
          </li>
          <ol className="list-decimal pl-10">
            <li>This Agreement may not be assigned, novated or transferred; and</li>
            <li>Neither Party shall create or permit to subsist any encumbrance over all or any of its rights and benefits under this Agreement.</li>
          </ol>
          <li>
            <strong>Amendment:</strong> No oral or written modification, amendment, rescission, waiver or other change of this Agreement or any of its terms or provisions shall be valid or legally binding on the Parties unless made in writing and duly executed by or on behalf of all the Parties, including without limitation, any purported modification, amendment, rescission, waiver or other change of this itself. No amendments, supplements or modifications to this Agreement shall be made except by written agreement between the Parties.
          </li>
          <li>
            <strong>No Waiver:</strong> A waiver by any Party of any default or defaults by the other Party in the performance of any of the provisions of this Agreement shall be effective only when executed in writing by duly authorized representatives of all Parties. A waiver shall never operate as or be construed as a waiver of any other or further default or defaults whether of a like or different character. Neither the failure by a Party to insist on any occasion upon the performance of the terms, conditions, and provisions of this Agreement nor time or other indulgence granted by a Party to the other or others shall act as a waiver of such breach of acceptance of any variation or the relinquishment of any such right or any other right under this Agreement, which shall remain in full force and effect.
          </li>
          <li>
            <strong>Survival:</strong> In the event of termination of this Agreement, Clause 4 (Representation and Warranties), Clause 10 (Confidentiality), Clause 9 (Intellectual Property), Clause 11 (Non-Solicitation), Clauses 13 (Governing Law and Dispute Resolution), Clause 14 (g) (Notices) and this Clause 14 (e) shall survive such termination.
          </li>
          <li>
            <strong>Counterparts:</strong> This Agreement may be executed in one or more counterparts, each of which shall be deemed an original and all of which collectively shall be deemed one and the same instrument.
          </li>
          <li>
            <strong>Notices:</strong> Unless otherwise provided herein, all notices or other communications to be given shall be made in writing and by letter (save as otherwise stated) and shall be deemed to be duly given or made, in the case of personal delivery, when delivered; in case of an email, 1 (One) Business Days after being dispatched on the correct email address of the recipient, or, in the case of a letter, 3 (Three) Business Days after being deposited in the post (by registered post, with acknowledgment due), postage prepaid, to such party at its address or email address specified herein or at such other address or email address as such party may hereafter specify for such purposes to the other by notice in writing. The addresses referred to above are:
          </li>
        </ol>
      </ol>
      <p>
        <strong>If to the Company:</strong> <br />
        Attn: Mr. Sanjay Aggarwal
        <br />
        Address: A-31, 2nd floor, Infraline Tower, Sector 3, Noida - 201301, India
        <br />
        Email: <a href="mailto:corporate@insurancesamadhan.com">corporate@insurancesamadhan.com</a>
        <br />
        <strong>If to the customer/ partner/ service provider:</strong> <br />
        Address: [•]
        <br />
        Email: [•]
      </p>
      <p>A notice or other communication received on a day other than a day, or after business hours in the place of receipt, shall be deemed to be given on the next following day in such place. In the event a Party refuses delivery or acceptance of a notice, request or other communication, under this Agreement, it shall be deemed that the notice was given upon proof of the refused delivery, provided the same was sent in the manner specified in this Agreement.</p>
      <ol className="list-decimal pl-5">
        <ol className="list-decimal pl-10">
          <li>
            <strong>Language:</strong> The language of this Agreement shall be English and all documents, notices, waivers and all other written communication or otherwise between the Parties in connection with this Agreement shall be in English.
          </li>
          <li>
            <strong>Remedies:</strong> Parties acknowledge that damages will not be a sufficient remedy for breach of this Agreement and the Parties shall be entitled to seek specific performance of each other’s obligations pursuant to this Agreement.
          </li>
          <li>
            <strong>Further Assurances:</strong> Each Party agrees to perform (or procure the performance of) all further acts and things and execute and deliver (or procure the execution and delivery of) such further documents, as may be required by Law or as the other Party may reasonably require, whether on or after Effective Date, to implement and/or give effect to this Agreement.
          </li>
        </ol>
        <li>
          <strong>ROLE OF ASSOCIATE</strong>
        </li>
        <ol className="list-decimal pl-10">
          <li>Identification, selection, enrolment and supervision of agents and institutions for Company.</li>
          <li>Facilitating financial services distributor/agent and institutional client on boarding and initial orientation</li>
          <li>Generate customer registrations through enrolled financial services distributor/agents and institutions</li>
          <li>Ensuring genuineness, completeness, correctness and timeliness of registered cases for faster resolution.</li>
        </ol>
        <li>
          <strong>REVENUE SHARE BREAK-UP BASED ON SOURCE</strong>
        </li>
      </ol>
      <div className="overflow-auto">
        <table className="table-auto w-full border border-gray-300 mb-6">
          <thead>
            <tr className="bg-gray-200">
              <th className="border border-gray-300 p-2">Registration Source</th>
              <th className="border border-gray-300 p-2">Revenue Share</th>
              <th className="border border-gray-300 p-2">Payable To</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border border-gray-300 p-2">Associate-Self</td>
              <td className="border border-gray-300 p-2">0%</td>
              <td className="border border-gray-300 p-2">Associate</td>
            </tr>
            <tr>
              <td className="border border-gray-300 p-2">Agent</td>
              <td className="border border-gray-300 p-2">0%</td>
              <td className="border border-gray-300 p-2">Agent</td>
            </tr>
            <tr>
              <td className="border border-gray-300 p-2">Agent</td>
              <td className="border border-gray-300 p-2">0%</td>
              <td className="border border-gray-300 p-2">Agent</td>
            </tr>
            <tr>
              <td className="border border-gray-300 p-2">Institution-B2B</td>
              <td className="border border-gray-300 p-2">To be mutually agreed</td>
              <td className="border border-gray-300 p-2">To be mutually agreed</td>
            </tr>
          </tbody>
        </table>
      </div>
      <ol className="list-decimal pl-5">
        <li>
          <strong>BUSINESS TARGET</strong>
        </li>
      </ol>
      <div className="overflow-auto">
        <table className="table-auto w-full border border-gray-300 mb-6">
          <thead>
            <tr className="bg-gray-200">
              <th className="border border-gray-300 p-2">By When</th>
              <th className="border border-gray-300 p-2">No. Of Agents/FSD's per Month</th>
              <th className="border border-gray-300 p-2">Cumulative No. of agents/FSD's</th>
              <th className="border border-gray-300 p-2">Claim Amount per Month (INR Lakhs)</th>
              <th className="border border-gray-300 p-2">Cumulative Claim Amount (INR Lakhs)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border border-gray-300 p-2">Month 1-3</td>
              <td className="border border-gray-300 p-2">0</td>
              <td className="border border-gray-300 p-2">0</td>
              <td className="border border-gray-300 p-2">0</td>
              <td className="border border-gray-300 p-2">0</td>
            </tr>
            <tr>
              <td className="border border-gray-300 p-2">Month 4-6</td>
              <td className="border border-gray-300 p-2">0</td>
              <td className="border border-gray-300 p-2">0</td>
              <td className="border border-gray-300 p-2">0</td>
              <td className="border border-gray-300 p-2">0</td>
            </tr>
            <tr>
              <td className="border border-gray-300 p-2">Month 7-12</td>
              <td className="border border-gray-300 p-2">0</td>
              <td className="border border-gray-300 p-2">0</td>
              <td className="border border-gray-300 p-2">0</td>
              <td className="border border-gray-300 p-2">0</td>
            </tr>
          </tbody>
        </table>
      </div>
      <ol className="list-decimal pl-5">
        <li>
          <strong>COMMERCIALS</strong>
        </li>
      </ol>
      <p>The commercial pay out for Associate’s services shall be paid as per account reconciliation statement generated by Company on 10th and 25th of each month, and shall be paid as mentioned below:</p>
      <div className="overflow-auto">
        <table className="table-auto w-full border border-gray-300 mb-6">
          <thead>
            <tr className="bg-gray-200">
              <th className="border border-gray-300 p-2">Head</th>
              <th className="border border-gray-300 p-2">Unit</th>
              <th className="border border-gray-300 p-2">Amount</th>
              <th className="border border-gray-300 p-2">Payable By</th>
              <th className="border border-gray-300 p-2">Payable To</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border border-gray-300 p-2">Revenue Share</td>
              <td className="border border-gray-300 p-2">Claim amount settled</td>
              <td className="border border-gray-300 p-2">0%</td>
              <td className="border border-gray-300 p-2">5th & 20th of every month</td>
              <td className="border border-gray-300 p-2">Associate</td>
            </tr>
            <tr>
              <td className="border border-gray-300 p-2">Revenue Share</td>
              <td className="border border-gray-300 p-2">Claim amount settled</td>
              <td className="border border-gray-300 p-2">0%</td>
              <td className="border border-gray-300 p-2">5th & 20th of every month</td>
              <td className="border border-gray-300 p-2">Agent</td>
            </tr>
          </tbody>
        </table>
      </div>
      <ol className="list-decimal pl-5">
        <li>
          <strong>TERMS & CONDITIONS</strong>
        </li>
        <ol className="list-decimal pl-10">
          <li>All payments to be made in designated account(s) only, through NEFT/RTGS/cheque/DD</li>
          <li>GST and TDS as applicable</li>
        </ol>
      </ol>

      <div className="border p-4 my-4">
        <div>
          Signed and delivered for and on behalf of <strong>Fastsurance Consultants Pvt. Ltd</strong>
        </div>
        <br />
        <div>Signatures:</div>
        <div className="sign">
          <img src={InvoiceSign} alt="signature" className="w-10" />
        </div>
        <br />
        <div>Date: {new Date().toLocaleDateString()}</div>
        <hr />
        <div>Signed and delivered for and on behalf of partner/agent</div>
        <div>Name: {selectedRow?.name || ""}</div>
        <div>Signatures:</div>
        <br />
        <div>Authorized Signatory</div>
        <div>Date: {new Date().toLocaleDateString()}</div>
      </div>
      <p>
        <strong>Note</strong>: The above terms may change by time without prior notice.
        <br />*<strong>Disclaimer</strong>: Your details may be used by Company for promotion on it's various platforms.
      </p>
      <div className="space"></div>
    </div>
  );
};

const ZeroPercentPartnerForLevelZero: FC<any> = ({ selectedRow }) => {
  return (
    <div className="px-6 py-8 text-gray-800 text-sm leading-6">
      <div className="policies p-4">
        <h2 className="text-center text-2xl font-bold">Agreement for Business Support Services</h2>
        <p className="mt-4">
          This Agreement for Business Support Services (“<strong>Agreement</strong>”) is made between- <br />
          <strong>“The Partner/User/You”</strong> (which expression shall unless repugnant to the context or meaning thereof include its successors, legal representatives and permitted assigns) of the
          <strong>First Part</strong>;<br />
          <strong>AND</strong>
          <br />
          <strong>FASTSURANCE CONSULTANTS PVT. LTD.</strong>, a company incorporated in India and having its office at A-31, 2nd floor, Infraline Tower, Sector 3, Noida - 201301, India (hereinafter referred to as the “Company”, which expression shall, unless repugnant to or inconsistent with the context, mean and include any successors or permitted assigns) of the <strong>SECOND PART.</strong>
          <br />
          <strong>“The Company”</strong> and <strong>“the Partner”</strong> are jointly referred to as <strong>“Parties”</strong> and individually as
          <strong>“Party”</strong>.<br />
          <strong>BY CLICKING THE ACCEPTANCE BUTTON, THE PARTNER EXPRESSLY AGREES TO AND CONSENTS TO BE BOUND BY ALL OF THE TERMS OF THIS AGREEMENT.</strong> <br />
          <strong>WHEREAS</strong>
        </p>
        <ol className="list-decimal pl-6 mt-4">
          <li>
            The Company is <em>inter-alia</em> engaged in the business of providing services of various nature in the field of Life, Health and General Insurance, such as assistance in resolving consumer grievances by representing clients at various forums like Insurance Companies, IRDA, Ombudsman, Consumer Forums, Legal Courts (“Services”). The overall mission of Company is centred towards social cause and the betterment/upliftment of the general impression of the insurance companies.
          </li>
          <li className="mt-2">The Partner has approached the Company for providing its services and to work with the Company for mutual benefits. The Parties have agreed to enter into this Agreement on the terms as set out herein under.</li>
        </ol>
        <br />

        <p className="font-bold mt-4">NOW, IN CONSIDERATION OF THE ABOVE PREMISES AND MUTUAL COVENANTS CONTAINED HEREINAFTER THIS AGREEMENT WITNESSETH AS UNDER:</p>
        <br />

        <ol className="list-decimal pl-6">
          <li>
            <strong>SERVICES</strong>
          </li>
          <ul className="list-disc pl-10 mt-2">
            <li>The Company has communicated its requirement to the Partner and after understanding all the requirements of the Company, Partner has agreed to work with the Company.</li>
          </ul>
          <br />

          <li>
            <strong>TERM</strong>
          </li>
          <ol className="list-decimal pl-6 mt-2">
            <li>
              This Agreement shall become effective from the date of execution of this Agreement (“<strong>Effective Date</strong>”) and shall remain valid and binding on the Parties for a period of 1 (one) year from the Effective Date (“<strong>Term</strong>”), unless terminated earlier in accordance with the terms hereof.
            </li>
            <li className="mt-2">Upon expiry of this Agreement, the Parties may extend the duration of this Agreement subject to mutually agreed terms and conditions.</li>
          </ol>
          <li className="mt-2">
            <strong>STANDARD OF PERFORMANCE</strong>
          </li>
        </ol>
        <p className="mt-2">Partner shall:</p>
        <ol className="list-decimal pl-6">
          <ol className="list-decimal pl-6">
            <li>perform the Services in a professional manner and in accordance with the terms and conditions of this Agreement, applicable laws, applicable permits, good industry practices, directions, guidelines and instructions issued by the Company from time to time;</li>
            <li className="mt-2">make efforts to prevent any delay in the performance of its obligations hereunder and shall work together with the Company for the effective and timely provisions of the Services;</li>
            <li className="mt-2">maintain, preserve and keep in safe custody any and all records in relation to this Agreement for a period of 24 (twenty-four) months;</li>
          </ol>
          <li className="mt-2">
            <strong>REPRESENTATIONS AND WARRANTIES</strong>
          </li>
          <ol className="list-decimal pl-6">
            <li>
              Each of the Parties hereby represents and warrants the following to all other Parties of this Agreement:
              <strong>
                <u></u>
              </strong>
            </li>
            <ol className="list-decimal pl-6">
              <li>it has the power and all necessary governmental and other consents, approvals, licenses and authorizations to enter into this Agreement, and that it has complied with such consents, approval, licenses, and authorizations in all material respects and none of the aforesaid have been revoked or otherwise terminated;</li>
              <li className="mt-2">it has the power to enter into and perform, and has taken all necessary action to authorize the entry into, performance, delivery and execution of this Agreement and all agreements executed or to be executed in pursuance thereof;</li>
              <li className="mt-2">this Agreement and all other agreements executed or to be executed in pursuance thereof constitute or will constitute its legal, valid, binding and enforceable obligations in accordance with their terms and conditions.</li>
            </ol>
            <li className="mt-2">the entry into, and performance by it of this Agreement and all agreements executed or to be executed in pursuance hereof and the transactions contemplated hereunder do not and will not:</li>
            <ol className="list-decimal pl-6">
              <li>conflict in any material respect with any law or regulation or judicial or official order in each case currently applicable to them; or</li>
              <li className="mt-2">conflict in any material respect with any document which is binding to them or on any their assets;</li>
            </ol>
            <li className="mt-2">to the best of their knowledge, information and belief, there are no legal proceedings, suits, appeals, and other actions in law, whether judicial, fiscal, administrative, pending or threatened against them, or claim against them which:</li>
            <ol className="list-decimal pl-6">
              <li>may prevent the performance of this Agreement or any of the transaction contemplated hereby, or cause the rescission thereof; or</li>
              <li className="mt-2">may result in any encumbrance and/or any other third-party rights or otherwise challenge or question any right, title or interest of the Parties in this Agreement.</li>
            </ol>
            <li className="mt-2">The Partner represents, warrants and covenants to Company that: </li>
            <ol className="list-decimal pl-6">
              <li>he has requisite skills, knowledge, experience to provide the Services under this Agreement;</li>
              <li className="mt-2">he shall perform the Services in a professional, ethical and workmanlike manner;</li>
              <li className="mt-2">the Services to be performed by the partner/ service provider under this Agreement shall be free from material errors or other defects and if material errors or other defects are found, then the partner/ service provider will fix the deficiency without any additional charges being incurred by the Company. </li>
            </ol>
          </ol>
          <li className="mt-2">
            <strong>TERMS OF CONSIDERATION</strong>
          </li>
          <ol className="list-decimal pl-6">
            <li>The Company will share pre decided commercials only in case of genuine leads which are coming through Partner as source. Any other method is not viable under current scenario and can be discussed and amended as and when it arises.</li>
            <li className="mt-2">The Company will not pay for promotional campaigns of any done by Partner for generating leads.</li>
            <li className="mt-2">Partner shall not charge any registration fee for the customers registering through them.</li>
            <li className="mt-2">The Company is not responsible for resolution of any case even after acceptance as Insurance resolution is a result of many factors which cannot be deemed as final at any stage of the case.</li>
            <li className="mt-2">In cases wherein the success fee is negotiated, the payment shall be made on a pro-rata basis.</li>
            <li className="mt-2">The compensation shall be paid in terms of the mutual agreement between the Parties post the successful completion of work. The mode of payment shall be through money transfer once the company receives the credit from the customer.</li>
          </ol>
          <li className="mt-2">
            <strong>TERMINATION</strong>
          </li>
          <ol className="list-decimal pl-6">
            <li>This Agreement may be terminated by either Party without assigning any reason at any time by giving 1 (one) month prior written notice to the other Party.</li>
            <li className="mt-2">Without prejudice to any other rights or remedies available in law or under this Agreement, either Party may terminate this Agreement forthwith on or at any time after the occurrence of any of the events specified herein below:</li>
            <ol className="list-decimal pl-6">
              <li>Insolvency of the other Party;</li>
              <li className="mt-2">Material breach of any of the terms or conditions of this Agreement by the other Party which breach is not remedied by such other Party to the satisfaction of the non-breaching Party within three (3) days of notice of the breach;</li>
              <li className="mt-2">If a petition for winding up of the other Party is decreed in any Court or a resolution is passed for winding-up the business of such other Party or if any receiver, manager, liquidator, administrator or other similar official is appointed to the other Party or a substantial part of the other Party’s property.</li>
            </ol>
          </ol>
          <li className="mt-2">
            <strong>CONSEQUENCES OF TERMINATION</strong>
          </li>
        </ol>
        <p className="mt-2">On termination of this Agreement for any reason: </p>
        <ol className="list-decimal pl-6">
          <ol className="list-decimal pl-6">
            <li>the Partner shall forthwith provide such assistance as is reasonably necessary for the orderly assumption of the Services by a third party or the Company; </li>
            <li className="mt-2">the Partner shall deliver all copies of the deliverables that the Partner has developed up to the termination date; </li>
            <li className="mt-2">the accrued rights, remedies, obligations and liabilities of the Company as at termination shall not be affected, including the right to claim damages for any breach of this Agreement, which existed at or before the date of termination. </li>
            <li className="mt-2">if the Partner terminates this Agreement in accordance with termination clause, then the Company shall be entitled to a refund of any Fees paid to the partner/ service provider under this Agreement.</li>
            <li className="mt-2">if the Company exercises the right to terminate under termination clause, the Company may do so without any penalty, obligation, or liability to the partner/ service provider under this Agreement.</li>
          </ol>
          <p className="mt-2 font-bold">INDEPENDENT CONTRACTOR</p>
          <p className="mt-2">It is hereby acknowledged that Partner shall perform the services in the capacity of an independent contractor and not as an employee, servant or agent of the Company. Nothing contained herein shall be construed to create a relationship of employer-employee and master-servant between Company and Partner.</p>
          <p className="mt-2 font-bold">INTELLECTUAL PROPERTY</p>
          <ol className="list-decimal pl-6">
            <li>The Company does not grant to Partner any right, title or interest in any of its intellectual property except as expressly authorised in writing by the Company and Partner shall not have any right, title or interest in Company’s intellectual property other than the right to use it for purposes of this Agreement for the Term hereof. Partner shall comply with any and all instructions issued by Company in relation to the display of any logo, trademark, copyright or any other intellectual property of the Company. Upon expiry or earlier termination of this Agreement, Partner shall immediately cease and desist for all times from any use of or reference to Company’s intellectual property and shall return to Company copies or materials containing such intellectual property.</li>
            <li className="mt-2">Parties hereby acknowledge and confirm that:</li>
            <ol className="list-decimal pl-6">
              <li>All Intellectual Property Rights in or relating to the Services are and shall at all times remain the property of Company and / or its licensors;</li>
              <li className="mt-2">Partner shall notify Company immediately if Partner becomes aware of any illegal or unauthorized use of any the intellectual property therein or relating thereto and will assist Company in taking all steps necessary to protect and defend Company’s rights therein.</li>
            </ol>
            <li className="mt-2">The provisions of this Clause 9 shall survive the termination of this Agreement.</li>
          </ol>
          <li className="mt-2">
            <strong>CONFIDENTIALITY</strong>
          </li>
          <ol className="list-decimal pl-6">
            <li>
              The Partner acknowledges that during the term of this Agreement, the Partner will have access to Confidential Information of the Company and/or received by the Company from third parties, which is confidential to the Company and/or such third parties. The term “<strong>Confidential Information</strong>” used herein shall mean and include information which is confidential and proprietary to the Company and/or to certain third parties with which the Company has relationships, and disclosed to or obtained by the Partner from the Company and/or such third parties, whether (without limitation) in graphic, written, electronic or machine readable form on any media or orally and whether or not the information is expressly stated to be confidential or marked as such and includes, but is not limited to information of value or significance to the Company and/or its competitors (present or potential) such as Company’s content; data; techniques; plans; designs; programs; customer information; identity and job descriptions of Company personnel; the Company’s organizational structure; financing relationships or terms; service provider or vendor relationships or terms; processes;
              methodologies; compensation or bonus data; the terms of this Agreement; or other information not in the public domain pertaining to the Business or affairs of the Company.
            </li>
            <li className="mt-2">During the term of this Agreement and 1 (One) year after termination of the same, the Partner shall hold the Confidential Information in confidence and shall not publish, disclose or disseminate at any time, to any person or competitor of the Company; or use for any purpose any Confidential Information other than such purposes as shall be required to fulfil the Partner’s duties with the Company, or remove any Confidential Information, in whole or in part, from the Company's premises, without the Company's prior written permission.</li>
            <li className="mt-2">Notwithstanding the aforesaid provisions, the Partner may disclose Confidential Information (i) that is/ was in the public domain; (ii) that was previously known by Partner, as established by written records of the Partner prior to receipt of such information from the Company; (iii) that was lawfully obtained by the Partner from a third party without any obligations of confidentiality to Company or (iv) where ordered to do so, by any government, judicial or quasi-judicial authority; provided however, that the Partner shall in such a case give the Company a reasonable notice of any prospective disclosure and shall assist the Company in obtaining an exemption or protective order preventing such disclosure.</li>
            <li className="mt-2">Upon termination of this Agreement, the Partner shall return to the Company the Confidential Information, including copies thereof irrespective of storage or presentation medium, including all electronic and hard copies thereof, and any other material containing or disclosing any Confidential Information which is in the Partner’s possession, power and control as and when called upon by the Company or with prior approval from the Company destroy the same and will not make or retain any copies of such Confidential Information. </li>
            <li className="mt-2">The Partner agrees that he shall protect the Confidential Information with at least the same degree of care and confidentiality as it affords his own confidential information and shall at all times exercise at least a reasonable degree of care in such protection.</li>
            <li className="mt-2">The Partner’s obligations with respect to confidentiality shall survive the termination or expiry of this Agreement. </li>
          </ol>
          <li className="mt-2">
            <strong>INDEMNITY</strong>
          </li>
        </ol>
        <p className="mt-2">
          The Partner shall indemnify and keep indemnified, save, defend and hold harmless the Company from and against any and all direct Losses incurred or suffered by the Company in relation to any acts or omissions attributable to the Partner. For the purpose of this Clause, “<strong>Losses</strong>” shall mean any and all claims, actions, demands, assessments, losses, damages, liability, judgements, settlements, penalties, costs and expenses (including reasonable attorney’s fees and expenses), of any nature whatsoever, asserted against, resulting to, imposed upon, or incurred by the Company.
        </p>
        <br />

        <ol className="list-decimal pl-6">
          <li className="mt-2">
            <strong>NON-SOLICITATION</strong>
          </li>
          <ol className="list-decimal pl-6">
            <li>The Partner agrees that in consideration of the customer/ partner/ service provider’s engagement with Company, and other good and valuable consideration, the receipt of which is hereby acknowledged, during the term of this Agreement, the Partner shall not, directly or indirectly, in his individual capacity or through a partnership or any other entity:</li>
            <ol className="list-decimal pl-6">
              <li>solicit employment of or advise any other employee of Company to terminate his contract or relationship with Company; or </li>
              <li className="mt-2">contact any of the existing or prospective clients/users of Company, to entice such users away from Company or to damage in any way the Company’s relationship with such users. </li>
            </ol>
          </ol>
          <li className="mt-2">
            <strong>GOVERNING LAW AND DISPUTE RESOLUTION</strong>
          </li>
          <p className="mt-2">This Agreement shall be governed and construed in accordance with the substantive laws of India. The Parties shall endeavor to first resolve any dispute or claim arising amicably between them under this Agreement by mutual negotiations.</p>
        </ol>
        <ol className="list-decimal pl-6">
          <ol className="list-decimal pl-6">
            <li>In case of failure to amicably settle the dispute or the claim within thirty (30) days of one party notifying in writing to the other Party of the dispute or the claim, the dispute or the claim shall be referred to arbitration and finally settled by a sole arbitrator who shall be mutually appointed by the Parties in accordance with the Arbitration and Conciliation Act, 1996, as amended from time to time. The seat and place of arbitration shall be Noida, India and the language of arbitration shall be English.</li>
            <li className="mt-2">The proceedings of such arbitration may be conducted with the Parties being present either physically or virtually. Each Party will bear and pay professional charges for their respective advocates and consultants, if any.</li>
            <li className="mt-2">The Courts at Noida, India shall have exclusive jurisdiction over all and any matters that are ancillary to this Agreement, and the Parties hereby agree to submit to the jurisdiction of the said courts for such matters.</li>
          </ol>
          <li className="mt-2">
            <strong>MISCELLANEOUS</strong>
          </li>
          <ol className="list-decimal pl-6">
            <li>
              <strong>Severability:</strong> The provisions of this Agreement are severable and the validity of remaining Clauses, provisions, terms and parts of this Agreement shall not be affected by a court, administrative board or other proceeding of competent jurisdiction deciding that a Clause, provision, term or part of this Agreement is illegal, unenforceable, void, in conflict with any law or contrary to public policy. In such event the Parties shall, by amendment of this Agreement, properly replace such provision by a reasonable new provision or provisions which, as far as legally possible, shall approximate to the closest possible extent what the Parties intended by such original provision and the purpose thereof.
            </li>
            <li className="mt-2">
              <strong>Assignment of Agreement:</strong> Except as permitted or required by this Agreement or as otherwise agreed in writing between the Parties:
            </li>
            <ol className="list-decimal pl-6">
              <li>This Agreement may not be assigned, novated or transferred; and</li>
              <li className="mt-2">Neither Party shall create or permit to subsist any encumbrance over all or any of its rights and benefits under this Agreement.</li>
            </ol>
            <li className="mt-2">
              <strong>Amendment:</strong> No oral or written modification, amendment, rescission, waiver or other change of this Agreement or any of its terms or provisions shall be valid or legally binding on the Parties unless made in writing and duly executed by or on behalf of all the Parties, including without limitation, any purported modification, amendment, rescission, waiver or other change of this itself. No amendments, supplements or modifications to this Agreement shall be made except by written agreement between the Parties.
            </li>
            <li className="mt-2">
              <strong>No Waiver:</strong> A waiver by any Party of any default or defaults by the other Party in the performance of any of the provisions of this Agreement shall be effective only when executed in writing by duly authorized representatives of all Parties. A waiver shall never operate as or be construed as a waiver of any other or further default or defaults whether of a like or different character. Neither the failure by a Party to insist on any occasion upon the performance of the terms, conditions, and provisions of this Agreement nor time or other indulgence granted by a Party to the other or others shall act as a waiver of such breach of acceptance of any variation or the relinquishment of any such right or any other right under this Agreement, which shall remain in full force and effect.
            </li>
            <li className="mt-2">
              <strong>Survival:</strong> In the event of termination of this Agreement, Clause 4 (Representation and Warranties), Clause 10 (Confidentiality), Clause 9 (Intellectual Property), Clause 11 (Non-Solicitation), Clauses 13 (Governing Law and Dispute Resolution), Clause 14 (g) (Notices) and this Clause 14 (e) shall survive such termination.{" "}
            </li>
            <li className="mt-2">
              <strong>Counterparts:</strong> This Agreement may be executed in one or more counterparts, each of which shall be deemed an original and all of which collectively shall be deemed one and the same instrument.
            </li>
            <li className="mt-2">
              <strong>Notices:</strong> Unless otherwise provided herein, all notices or other communications to be given shall be made in writing and by letter (save as otherwise stated) and shall be deemed to be duly given or made, in the case of personal delivery, when delivered; in case of an email, 1 (One) Business Days after being dispatched on the correct email address of the recipient, or, in the case of a letter, 3 (Three) Business Days after being deposited in the post (by registered post, with acknowledgment due), postage prepaid, to such party at its address or email address specified herein or at such other address or email address as such party may hereafter specify for such purposes to the other by notice in writing. The addresses referred to above are:
            </li>
          </ol>
        </ol>
        <p className="mt-2">
          <strong>If to the Company:</strong> <br />
          Attn: Mr. Sanjay Aggarwal
          <br />
          Address: A-31, 2nd floor, Infraline Tower, Sector 3, Noida - 201301, India
          <br />
          Email:{" "}
          <a href="mailto:corporate@insurancesamadhan.com" className="text-blue-500">
            corporate@insurancesamadhan.com
          </a>
          <br />
          <strong>If to the </strong>
          <strong>customer/ partner/ service provider</strong>
          <strong>:</strong> <br />
          Address: [•]
          <br />
          Email: [•]
        </p>
        <p className="mt-2">A notice or other communication received on a day other than a day, or after business hours in the place of receipt, shall be deemed to be given on the next following day in such place. In the event a Party refuses delivery or acceptance of a notice, request or other communication, under this Agreement, it shall be deemed that the notice was given upon proof of the refused delivery, provided the same was sent in the manner specified in this Agreement. </p>
        <ol className="list-decimal pl-6">
          <ol className="list-decimal pl-6">
            <li>
              <strong>Language:</strong> The language of this Agreement shall be English and all documents, notices, waivers and all other written communication or otherwise between the Parties in connection with this Agreement shall be in English.
            </li>
            <li className="mt-2">
              <strong>Remedies:</strong> Parties acknowledge that damages will not be a sufficient remedy for breach of this Agreement and the Parties shall be entitled to seek specific performance of each other’s obligations pursuant to this Agreement.
            </li>
            <li className="mt-2">
              <strong>Further Assurances:</strong> Each Party agrees to perform (or procure the performance of) all further acts and things and execute and deliver (or procure the execution and delivery of) such further documents, as may be required by Law or as the other Party may reasonably require, whether on or after Effective Date, to implement and/or give effect to this Agreement.
            </li>
          </ol>
          <li className="mt-2">
            <strong>ROLE OF ASSOCIATE</strong>
          </li>
          <ol className="list-decimal pl-6">
            <li>Identification, selection, enrolment and supervision of agents and institutions for Company.</li>
            <li className="mt-2">Facilitating financial services distributor/agent and institutional client on boarding and initial orientation</li>
            <li className="mt-2">Generate customer registrations through enrolled financial services distributor/agents and institutions</li>
            <li className="mt-2">Ensuring genuineness, completeness, correctness and timeliness of registered cases for faster resolution.</li>
          </ol>
          <li className="mt-2">
            <strong>REVENUE SHARE BREAK-UP BASED ON SOURCE</strong>
          </li>
        </ol>
        <ol className="list-decimal pl-6">
          <li className="mt-2">
            <strong>BUSINESS TARGET</strong>
          </li>
        </ol>
        <ol className="list-decimal pl-6">
          <li className="mt-2">
            <strong>COMMERCIALS</strong>
          </li>
        </ol>
        <p className="mt-2">The commercial pay out for Associate’s services shall be paid as per account reconciliation statement generated by Company on 10th and 25th of each month, and shall be paid as mentioned below:</p>
        <ol className="list-decimal pl-6">
          <li className="mt-2">
            <strong>TERMS & CONDITIONS</strong>
          </li>
          <ol className="list-decimal pl-6">
            <li>All payments to be made in designated account(s) only, through NEFT/RTGS/cheque/DD</li>
            <li className="mt-2">GST and TDS as applicable</li>
          </ol>
        </ol>

        <div className="border p-4 my-4">
          <div>
            Signed and delivered for and on behalf of <strong>Fastsurance Consultants Pvt. Ltd</strong>
          </div>
          <br />
          <div>Signatures:</div>
          <div className="sign">
            <img src={InvoiceSign} alt="signature" className="w-10" />
          </div>
          <br />
          <div>Date: {selectedRow?.agreedOn ? new Date(selectedRow?.agreedOn).toLocaleDateString() : new Date().toLocaleDateString()}</div>
          <hr />
          <div>Signed and delivered for and on behalf of partner/agent</div>
          <div>Name: {selectedRow?.name || ""}</div>
          <div>Signatures:</div>
          <br />
          <div>Authorized Signatory</div>
          <div>Date: {selectedRow?.agreedOn ? new Date(selectedRow?.agreedOn).toLocaleDateString() : new Date().toLocaleDateString()}</div>
        </div>
        <p className="mt-2">
          {" "}
          <strong>Note</strong>: The above terms may change by time without prior notice.
          <br />*<strong>Disclaimer</strong>: Your details may be used by Company for promotion on its various platforms.
        </p>
        <div className="space mt-4"></div>
      </div>
    </div>
  );
};

const RevenueSharingWithoutLeadCost: FC<any> = ({ selectedRow }) => {
  return (
    <div className="p-6">
      <div className="px-6 py-8 text-gray-800 text-sm leading-6">
        <ol className="list-decimal pl-5">
          <p>
            This <strong>AGREEMENT FOR BUSINESS SUPPORT SERVICES</strong> (hereinafter this <strong>“Agreement”</strong>) is made on this {selectedRow?.agreedOn ? new Date(selectedRow?.agreedOn).toLocaleDateString() : new Date().toLocaleDateString()} (the <strong>“Execution Date”</strong>) by and between:
          </p>
          <ol className="list-decimal pl-5">
            <li>
              <strong>I, {selectedRow?.name || ""}, authorised insurance representative (hereinafter referred to as PARTNER</strong> which expression shall, unless repugnant to or inconsistent with the context, mean and include any successors or permitted assigns) of the <strong>FIRST PART</strong>
            </li>
            <br />
            <li>
              <strong>I, Sanjay Aggarwal, Co-Founder of INSURANCE SAMADHAN (hereunder mentioned as FASTSURANCE CONSULTANTS PVT. LTD.)</strong>, a company incorporated in India and having its registered office at A-31, 2nd floor, Infraline Tower, Sector 3, Noida, 201301 India (hereinafter referred to as the <strong>“Company”</strong>, which expression shall, unless repugnant to or inconsistent with the context, mean and include any successors or permitted assigns) of the <strong>SECOND PART</strong>; and
              <br />
              <strong>“The Company”</strong> and <strong>“PARTNER”</strong> are jointly referred to as “Parties” and individually as “Party”
            </li>
          </ol>
        </ol>
        <ol className="list-decimal pl-5">
          <p>
            <strong>WHEREAS</strong>
          </p>
          <ol type="A" className="list-decimal pl-5">
            <li>The Company is interalia engaged in the business of providing services of various nature in the field of Life, Health and General Insurance, such as assistance in resolving consumer grievances by representing clients at various forums like Insurance Companies, IRDA, Ombudsman, Consumer Forums, Legal Courts. The overall mission of Insurance Samadhan is centered towards social cause and the betterment/upliftment of the general impression of the insurance companies.</li>
            <li>Partner is engaged in {selectedRow?.natureOfBusiness || ""} services.</li>
          </ol>
        </ol>
        <p>
          <strong>NOW, IN CONSIDERATION OF THE ABOVE PREMISES AND MUTUAL COVENANTS CONTAINED HEREINAFTER THIS AGREEMENT WITNESSETH AS UNDER:-</strong>
        </p>
        <ol className="list-decimal pl-5">
          <li>
            <strong>SERVICES</strong>
            <ol className="list-decimal pl-5">
              <li>Company agrees to provide resolution of insurance related grievances to clients/customers approached through Partner/ Agent on such consideration as defined in the present agreement and Partner / Agent has agreed to generate customer registrations/ leads for Company.</li>
            </ol>
          </li>
          <li>
            <strong>TERM</strong>
            <ol className="list-decimal pl-5">
              <li>
                This Agreement shall become effective from the date of execution of this Agreement (<strong>“Effective Date”</strong>) and shall remain valid and binding on the Parties for a period of 1 (one) year from the Effective Date (“Term”), unless terminated earlier in accordance with the terms hereof.
              </li>
              <li>Upon expiry of this Agreement, the Parties may extend the duration of this Agreement subject to mutually agreed terms and conditions.</li>
            </ol>
          </li>
          <li>
            <strong>STANDARD OF PERFORMANCE</strong>
            <ol className="list-decimal pl-5">
              <li>
                PARTNER/AGENT shall:
                <ol type="i" className="list-decimal pl-5">
                  <li>PARTNER/AGENT is a knowledge partner and shouldn’t be accountable for any lapses or liability by claimant / clients of the Company.</li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <strong>REPRESENTATIONS AND WARRANTIES</strong>
            <ol className="list-decimal pl-5">
              <li>
                Each Party represents and warrants to the other Party that:
                <ol type="a" className="list-decimal pl-5">
                  <li>It is a company duly and legally organized and validly existing under the laws of India;</li>
                  <li>This Agreement constitutes its valid and legally binding obligations, enforceable in accordance with its terms;</li>
                  <li>It has full corporate power and authority to execute and deliver this Agreement in accordance with its terms and to perform all its duties and obligations arising or created under or pursuant to this Agreement and all requisite corporate approvals, as applicable, have been obtained by it prior to the execution of this Agreement; and</li>
                  <li>The execution of this Agreement and delivery and performance by it of its obligations hereunder do not and shall not violate or conflict in any manner with or result in a breach or default under Applicable Law or any of its duties or obligations under any agreement, understanding or arrangement, written or oral, to which it is a party.</li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <strong>TERMS OF CONSIDERATION</strong>
            <ol type="a" className="list-decimal pl-5">
              <li>The Company will share pre decided commercials only in case of genuine leads which are coming through Partner as source. Any other method is not viable under current scenario and can be discussed and amended as and when it arises.</li>
              <li>The Company is not responsible for resolution of any case even after acceptance as Insurance resolution is a result of many factors which cannot be deemed as final at any stage of the case.</li>
              <li>The Company hereby agrees and undertakes to pay {selectedRow?.partnerPercent || "0"}% of the aggregate amount transferred to the customers/clients, to Partner/ Agent after the 5% deduction (TDS) on successful completion of the services.</li>
              <li>In the event of client’s case move to legal/court, 2.5% on the resolution amount received by the client on the successful outcome of the case shall be paid to the legal partner and will be proportionately adjusted from the fee percentage i.e. {selectedRow?.partnerPercent || "0"}% being paid to PARTNER on the resolution amount received by the client on successful outcome of the case.</li>
              <li>The compensation shall be paid in terms of the mutual agreement between the Parties post the resolution amount is received by the client on successful outcome of the case.</li>
            </ol>
          </li>
          <li>
            <strong>TERMINATION</strong>
            <ol className="list-decimal pl-5">
              <li>This Agreement may be terminated by either Party without assigning any reason at any time by giving 1 (one) month prior written notice to the other Party.</li>
              <li>
                Without prejudice to any other rights or remedies available in law or under this Agreement, either Party may terminate this Agreement forthwith on or at any time after the occurrence of any of the events specified herein below:
                <ol type="a" className="list-decimal pl-5">
                  <li>Insolvency of the other Party;</li>
                  <li>Material breach of any of the terms or conditions of this Agreement by the other Party which breach is not remedied by such other Party to the satisfaction of the non-breaching Party within three (3) days of notice of the breach; and</li>
                  <li>If a petition for winding up of the other Party is decreed in any Court or a resolution is passed for winding-up the business of such other Party or if any receiver, manager, liquidator, administrator or other similar official is appointed to the other Party or a substantial part of the other Party’s property.</li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <strong>INDEPENDENT CONTRACTOR</strong>
            <ol className="list-decimal pl-5">
              <li>It is hereby acknowledged that Partner/ Agent shall perform the services in the capacity of an independent contractor and not as an employee, servant or agent of the Company. Nothing contained herein shall be construed to create a relationship of employer-employee and master-servant between Company and Partner/ Agent.</li>
            </ol>
          </li>
          <li>
            <strong>INTELLECTUAL PROPERTY</strong>
            <ol type="i" className="list-decimal pl-5">
              <li>The Company does not grant to Partner/ Agent any right, title or interest in any of its intellectual property except as expressly authorised in writing by the Company and Partner shall not have any right, title or interest in Company’s intellectual property other than the right to use it for purposes of this Agreement for the Term hereof. Partner shall comply with any and all instructions issued by Company in relation to the display of any logo, trademark, copyright or any other intellectual property of the Company. Upon expiry or earlier termination of this Agreement, Partner shall immediately cease and desist for all times from any use of or reference to Company’s intellectual property and shall return to Company copies or materials containing such intellectual property.</li>
              <li>
                Parties hereby acknowledge and confirm that:
                <ol type="a" className="list-decimal pl-5">
                  <li>All Intellectual Property Rights in or relating to the Services are and shall at all times remain the property of Company and / or its licensors;</li>
                  <li>Partner/Agent shall notify Company immediately if Partner becomes aware of any illegal or unauthorized use of any the intellectual property therein or relating thereto and will assist Company in taking all steps necessary to protect and defend Company’s rights therein.</li>
                </ol>
              </li>
              <li>The provisions of this Clause 8 shall survive the termination of this Agreement.</li>
            </ol>
          </li>
          <li>
            <strong>CONFIDENTIALITY</strong>
            <ol type="i" className="list-decimal pl-5">
              <li>
                The Company acknowledge that in the course of performing their obligations under this Agreement, they may get access to confidential information (<strong>“Confidential Information”</strong>) of the clients/customers registered through Partner/Agent. Herein, Company agrees not to disclose Confidential Information received (whether in writing, verbally or by any other means and whether directly or indirectly) by the clients/customers including in relation to matters contemplated by this Agreement or its performance or in any agreement in furtherance of the performance of this Agreement, to any Third Party without the prior written consent of Partner/Agent or use the Confidential Information other than for carrying out the purposes of this Agreement.
              </li>
              <li>Company shall keep confidential any Confidential Information it receives from the clients/customers and shall employ all such reasonable steps that it would have taken to protect its own Confidential Information. The Company shall disclose Confidential Information received from the clients/customers to its officers, employees, agents or representatives only for the purposes of carrying out its obligations under this Agreement and strictly on a “need to know” basis only. The Company shall also ensure that their respective directors, employees, officers and any other Persons to whom the above-mentioned information is disclosed to be bound by confidentiality obligations similar to those provided in this Clause.</li>
              <li>The Company shall not disclose any material information about clients/customers including but not limited to name, contact details, methodology of work, and approach of handling a claim etc. to third party.</li>
              <li>
                During the term of this agreement or One year from the termination of the same, the Company shall hold the Confidential Information received from the clients and shall not disclose or disseminate at any time, to any other person without the prior consent/permission of the client. Notwithstanding anything contained in Clause 9.1 and Clause 9.2, a Receiving Party’s obligation of confidentiality shall not extend to any Confidential Information:
                <ol type="a" className="list-decimal pl-5">
                  <li>To the extent that the Confidential Information received is in the public domain other than by breach of this Agreement;</li>
                  <li>To the extent that the Confidential Information is lawfully acquired by the Receiving Party from a Third Party who owes the Disclosing Party no obligation of confidence in respect of such Confidential Information;</li>
                  <li>To the extent that the Confidential Information was previously known or already in the lawful possession of the Receiving Party prior to receipt from the Disclosing Party;</li>
                  <li>To the extent that the Confidential Information received is required to be disclosed by any applicable Law or by any Authority to whose jurisdiction the Receiving Party is subject or with whose instructions it is customary to comply under notice to the Disclosing Party; or</li>
                  <li>In so far as it is disclosed to the employees, directors or professional advisers of the Receiving Party, provided that the Receiving Party shall procure that such persons treat the Confidential Information received as confidential.</li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <strong>Indemnification</strong>
            <ol className="list-decimal pl-5">
              <li>In the event that Company is found to be in breach of terms of this Agreement, Company at all times hereafter indemnify and keep the client (“Indemnified Party”) fully indemnified against all claims, demands, actions, proceedings, losses, damages, costs, charges, expenses, interests and disbursements of any nature whatsoever which the Indemnified Party may pay or incur or suffer or sustain or be liable to pay or incur or suffer or sustain as a result or consequence, direct or indirect, of such breach.</li>
            </ol>
          </li>
          <li>
            <strong>Disputes</strong>
            <ol className="list-decimal pl-5">
              <li>In the event of disputes, differences, claims and questions between the Parties hereto arising out of this Agreement or in any way relating hereto or any term, condition or provision herein mentioned or the construction or interpretation thereof or otherwise in relation hereto, the Parties shall first endeavor to settle such differences, disputes, claims or questions by arbitration of three (3) arbitrators, one each to be appointed by each Party, and each such arbitrator shall appoint a third Arbitrator who shall act as the chairman of the arbitral tribunal. The Arbitration and Conciliation Act, 1996 and the rules made there under shall apply to and shall govern the arbitration proceedings. The venue of the arbitration shall be exclusively at New Delhi and the arbitration proceeding shall be conducted in English. The decision of the Arbitrators shall be final and binding on the Parties.</li>
            </ol>
          </li>
          <li>
            <strong>GOVERNING LAW</strong>
            <ol className="list-decimal pl-5">
              <li>This Agreement shall be governed by and construed in accordance with the laws of India, without giving effect to any choice of law or conflict of law provisions. The Parties consent to the exclusive jurisdiction of courts of Delhi with respect to any matter or claim, suit, action or proceeding arising under or related to this Agreement.</li>
            </ol>
          </li>
          <li>
            <strong>MISCELLANEOUS</strong>
            <ol type="i" className="list-decimal pl-5">
              <li>
                <strong>Severability:</strong> The provisions of this Agreement are severable and the validity of remaining Articles, provisions, terms and parts of this Agreement shall not be affected by a court, administrative board or other proceeding of competent jurisdiction deciding that a Clause, provision, term or part of this Agreement is illegal, unenforceable, void, in conflict with any law or contrary to public policy. In such event the Parties shall, by amendment of this Agreement, properly replace such provision by a reasonable new provision or provisions which, as far as legally possible, shall approximate to the closest possible extent what the Parties intended by such original provision and the purpose thereof.
              </li>
              <li>
                <strong>Assignment of Agreement:</strong> Except as permitted or required by this Agreement or as otherwise agreed in writing between the Parties:
                <ol type="a" className="list-decimal pl-5">
                  <li>This Agreement may not be assigned, novated or transferred; and</li>
                  <li>Neither Party shall create or permit to subsist any encumbrance over all or any of its rights and benefits under this Agreement.</li>
                </ol>
              </li>
              <li>
                <strong>Amendment:</strong> No oral or written modification, amendment, rescission, waiver or other change of this Agreement or any of its terms or provisions shall be valid or legally binding on the Parties unless made in writing and duly executed by or on behalf of all the Parties, including without limitation, any purported modification, amendment, rescission, waiver or other change of this itself. No amendments, supplements or modifications to this Agreement shall be made except by written agreement between the Parties.
              </li>
              <li>
                <strong>No Waiver:</strong> A waiver by any Party of any default or defaults by the other Party in the performance of any of the provisions of this Agreement shall be effective only when executed in writing by duly authorized representatives of all Parties. A waiver shall never operate as or be construed as a waiver of any other or further default or defaults whether of a like or different character. Neither the failure by a Party to insist on any occasion upon the performance of the terms, conditions, and provisions of this Agreement nor time or other indulgence granted by a Party to the other or others shall act as a waiver of such breach of acceptance of any variation or the relinquishment of any such right or any other right under this Agreement, which shall remain in full force and effect.
              </li>
              <li>
                <strong>Counterparts:</strong> This Agreement may be executed in one or more counterparts, each of which shall be deemed an original and all of which collectively shall be deemed one and the same instrument.
              </li>
              <li>
                <strong>Notices:</strong> : All communication, notices or information to be given under this Agreement shall be so given in writing. All notices must be delivered personally, by registered or certified mail or facsimile to the addresses below:
                <br />
                <br />
                <table className="w-full border border-gray-300">
                  <thead>
                    <tr>
                      <th className="border border-gray-300 p-2">
                        <strong>Fastsurance Consultants Pvt. Ltd</strong>
                      </th>
                      <th className="border border-gray-300 p-2">
                        <strong>PARTNER/ AGENT</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border border-gray-300 p-2">ATTN:</td>
                      <td className="border border-gray-300 p-2">ATTN:</td>
                    </tr>
                    <tr>
                      <td className="border border-gray-300 p-2">Sanjay Aggarwal</td>
                      <td className="border border-gray-300 p-2"></td>
                    </tr>
                    <tr>
                      <td className="border border-gray-300 p-2">Director</td>
                      <td className="border border-gray-300 p-2">
                        <hr />
                      </td>
                    </tr>
                    <tr>
                      <td className="border border-gray-300 p-2">A-31, 2nd floor, Infraline Tower,</td>
                      <td className="border border-gray-300 p-2">Director/Authorized Representative</td>
                    </tr>
                    <tr>
                      <td className="border border-gray-300 p-2">Sector 3, Noida, 201301,</td>
                      <td className="border border-gray-300 p-2"></td>
                    </tr>
                    <tr>
                      <td className="border border-gray-300 p-2">
                        <a href="mailto:sanjay@insurancesamadhan.com">sanjay@insurancesamadhan.com</a>
                      </td>
                      <td className="border border-gray-300 p-2"></td>
                    </tr>
                  </tbody>
                </table>
                <br />
                All notices shall be effective:
                <ol className="list-decimal pl-5">
                  <li>If sent by facsimile, when sent (on receipt of confirmation of the correct number);</li>
                  <li>If sent by registered or certified mail, within five (5) days of dispatch; and</li>
                  <li>If delivered personally, on receipt by the intended recipient: Provided that, all notices sent by facsimile, shall be confirmed by registered or certified mail. Each Party shall forthwith notify the other Party of any change in its address to which notices under this Agreement are to be delivered, mailed, facsimiled or electronically mailed.</li>
                </ol>
              </li>
              <li>
                <strong>Language:</strong> The language of this Agreement shall be English and all documents, notices, waivers and all other written communication or otherwise between the Parties in connection with this Agreement shall be in English.
              </li>
              <li>
                <strong>Remedies:</strong> Parties acknowledge that damages will not be a sufficient remedy for breach of this Agreement and the Parties shall be entitled to seek specific performance of each other’s obligations pursuant to this Agreement.
              </li>
              <li>
                <strong>Further Assurances:</strong> Each Party agrees to perform (or procure the performance of) all further acts and things, and execute and deliver (or procure the execution and delivery of) such further documents, as may be required by Law or as the other Party may reasonably require, whether on or after Effective Date, to implement and/or give effect to this Agreement.
              </li>
              <br />
              <strong>IN WITNESS WHEREOF</strong> the Parties have executed these presents through their authorized representatives on the Execution Date.
              <br />
              <strong>IN WITNESS WHEREOF</strong>, the Parties hereto have set and subscribed their hands through their respective duly authorized representatives as of the date first above written.
            </ol>
          </li>
        </ol>
        <br />

        <div className="border p-4 my-4">
          <div>
            Signed and delivered for and on behalf of <strong>Fastsurance Consultants Pvt. Ltd</strong>
          </div>
          <br />
          <div>Signatures:</div>
          <div className="sign">
            <img src={InvoiceSign} alt="signature" className="w-10" />
          </div>
          <br />
          <div>Date: {selectedRow?.agreedOn ? new Date(selectedRow?.agreedOn).toLocaleDateString() : new Date().toLocaleDateString()}</div>
          <hr />
          <div>Signed and delivered for and on behalf of partner/agent</div>
          <div>Name: {selectedRow?.name || ""}</div>
          <div>Signatures:</div>
          <br />
          <div>Authorized Signatory</div>
          <div>Date: {selectedRow?.agreedOn ? new Date(selectedRow?.agreedOn).toLocaleDateString() : new Date().toLocaleDateString()}</div>
        </div>
        <p className="mt-4">
          <strong>Note:</strong> The above terms may change by time without prior notice.
          <br />*<strong>Disclaimer:</strong> Your details may be used by Company for promotion on its various platforms.
        </p>
        <div className="space mt-4"></div>
      </div>
    </div>
  );
};

const RevenueSharingWithLeadCost: FC<any> = ({ selectedRow }) => {
  return (
    <div className="p-6">
      <div className="px-6 py-8 text-gray-800 text-sm leading-6">
        <ol className="list-decimal pl-5">
          <p>
            This <strong>AGREEMENT FOR BUSINESS SUPPORT SERVICES</strong> (hereinafter this <strong>“Agreement”</strong>) is made on this {selectedRow?.agreedOn ? new Date(selectedRow?.agreedOn).toLocaleDateString() : new Date().toLocaleDateString()} (the <strong>“Execution Date”</strong>) by and between:
          </p>
          <ol className="list-decimal pl-5">
            <li>
              <strong>I, {selectedRow?.name || ""}, authorised insurance representative (hereinafter referred to as PARTNER</strong> which expression shall, unless repugnant to or inconsistent with the context, mean and include any successors or permitted assigns) of the <strong>FIRST PART</strong>
            </li>
            <br />
            <li>
              <strong>I, Sanjay Aggarwal, Co-Founder of INSURANCE SAMADHAN (hereunder mentioned as FASTSURANCE CONSULTANTS PVT. LTD.)</strong>, a company incorporated in India and having its registered office at A-31, 2nd floor, Infraline Tower, Sector 3, Noida, 201301 India (hereinafter referred to as the <strong>“Company”</strong>, which expression shall, unless repugnant to or inconsistent with the context, mean and include any successors or permitted assigns) of the <strong>SECOND PART</strong>; and
              <br />
              <strong>“The Company”</strong> and <strong>“PARTNER”</strong> are jointly referred to as “Parties” and individually as “Party”
            </li>
          </ol>
        </ol>
        <ol className="list-decimal pl-5">
          <p>
            <strong>WHEREAS</strong>
          </p>
          <ol className="list-decimal pl-5">
            <li>The Company is interalia engaged in the business of providing services of various nature in the field of Life, Health and General Insurance, such as assistance in resolving consumer grievances by representing clients at various forums like Insurance Companies, IRDA, Ombudsman, Consumer Forums, Legal Courts. The overall mission of Insurance Samadhan is centered towards social cause and the betterment/upliftment of the general impression of the insurance companies.</li>
            <li>Partner is engaged in {selectedRow?.natureOfBusiness || ""} services.</li>
          </ol>
        </ol>
        <p>
          <strong>NOW, IN CONSIDERATION OF THE ABOVE PREMISES AND MUTUAL COVENANTS CONTAINED HEREINAFTER THIS AGREEMENT WITNESSETH AS UNDER:-</strong>
        </p>
        <ol className="list-decimal pl-5">
          <li>
            <strong>SERVICES</strong>
            <ol className="list-decimal pl-5">
              <li>Company agrees to provide resolution of insurance related grievances to clients/customers approached through Partner/ Agent on such consideration as defined in the present agreement and Partner / Agent has agreed to generate customer registrations/ leads for Company.</li>
            </ol>
          </li>
          <li>
            <strong>TERM</strong>
            <ol className="list-decimal pl-5">
              <li>
                This Agreement shall become effective from the date of execution of this Agreement (<strong>“Effective Date”</strong>) and shall remain valid and binding on the Parties for a period of 1 (one) year from the Effective Date (“Term”), unless terminated earlier in accordance with the terms hereof.
              </li>
              <li>Upon expiry of this Agreement, the Parties may extend the duration of this Agreement subject to mutually agreed terms and conditions.</li>
            </ol>
          </li>
          <li>
            <strong>STANDARD OF PERFORMANCE</strong>
            <ol className="list-decimal pl-5">
              <li>PARTNER/AGENT shall:</li>
              <ol type="i" className="list-decimal pl-5">
                <li>PARTNER/AGENT is a knowledge partner and shouldn’t be accountable for any lapses or liability by claimant / clients of the Company</li>
              </ol>
            </ol>
          </li>
          <li>
            <strong>REPRESENTATIONS AND WARRANTIES</strong>
            <ol className="list-decimal pl-5">
              <li>Each Party represents and warrants to the other Party that:</li>
              <ol type="a" className="list-decimal pl-5">
                <li>It is a company duly and legally organized and validly existing under the laws of India;</li>
                <li>This Agreement constitutes its valid and legally binding obligations, enforceable in accordance with its terms;</li>
                <li>It has full corporate power and authority to execute and deliver this Agreement in accordance with its terms and to perform all its duties and obligations arising or created under or pursuant to this Agreement and all requisite corporate approvals, as applicable, have been obtained by it prior to the execution of this Agreement; and</li>
                <li>The execution of this Agreement and delivery and performance by it of its obligations hereunder do not and shall not violate or conflict in any manner with or result in a breach or default under Applicable Law or any of its duties or obligations under any agreement, understanding or arrangement, written or oral, to which it is a party.</li>
              </ol>
            </ol>
          </li>
          <li>
            <strong>TERMS OF CONSIDERATION</strong>
            <ol type="a" className="list-decimal pl-5">
              <li>The Company will share pre decided commercials only in case of genuine leads which are coming through Partner as source. Any other method is not viable under current scenario and can be discussed and amended as and when it arises.</li>
              <li>The Company is not responsible for resolution of any case even after acceptance as Insurance resolution is a result of many factors which cannot be deemed as final at any stage of the case.</li>
              <li>The Company hereby agrees to pay Rs.{selectedRow?.leadAmount || ""} per case to the agents on successful registration of a case with the Company.</li>
              <li>The Company hereby agrees and undertakes to pay {selectedRow?.partnerPercent || "0"}% of the aggregate amount transferred to the customers/clients, to Partner/ Agent after the 5% deduction (TDS) on successful completion of the services.</li>
              <li>In the event of client’s case move to legal/court, 2.5% on the resolution amount received by the client on the successful outcome of the case shall be paid to the legal partner and will be proportionately adjusted from the fee percentage i.e. {selectedRow?.partnerPercent || "0"}% being paid to PARTNER on the resolution amount received by the client on successful outcome of the case.</li>
              <li>The compensation shall be paid in terms of the mutual agreement between the Parties post the resolution amount is received by the client on successful outcome of the case.</li>
            </ol>
          </li>
          <li>
            <strong>TERMINATION</strong>
            <ol className="list-decimal pl-5">
              <li>This Agreement may be terminated by either Party without assigning any reason at any time by giving 1 (one) month prior written notice to the other Party.</li>
              <li>
                Without prejudice to any other rights or remedies available in law or under this Agreement, either Party may terminate this Agreement forthwith on or at any time after the occurrence of any of the events specified herein below:
                <ol type="a" className="list-decimal pl-5">
                  <li>Insolvency of the other Party;</li>
                  <li>Material breach of any of the terms or conditions of this Agreement by the other Party which breach is not remedied by such other Party to the satisfaction of the non-breaching Party within three (3) days of notice of the breach; and</li>
                  <li>If a petition for winding up of the other Party is decreed in any Court or a resolution is passed for winding-up the business of such other Party or if any receiver, manager, liquidator, administrator or other similar official is appointed to the other Party or a substantial part of the other Party’s property.</li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <strong>INDEPENDENT CONTRACTOR</strong>
            <ol className="list-decimal pl-5">
              <li>It is hereby acknowledged that Partner/ Agent shall perform the services in the capacity of an independent contractor and not as an employee, servant or agent of the Company. Nothing contained herein shall be construed to create a relationship of employer-employee and master-servant between Company and Partner/ Agent.</li>
            </ol>
          </li>
          <li>
            <strong>INTELLECTUAL PROPERTY</strong>
            <ol className="list-decimal pl-5">
              <li>The Company does not grant to Partner/ Agent any right, title or interest in any of its intellectual property except as expressly authorised in writing by the Company and Partner shall not have any right, title or interest in Company’s intellectual property other than the right to use it for purposes of this Agreement for the Term hereof. Partner shall comply with any and all instructions issued by Company in relation to the display of any logo, trademark, copyright or any other intellectual property of the Company. Upon expiry or earlier termination of this Agreement, Partner shall immediately cease and desist for all times from any use of or reference to Company’s intellectual property and shall return to Company copies or materials containing such intellectual property.</li>
              <li>
                Parties hereby acknowledge and confirm that:
                <ol type="a" className="list-decimal pl-5">
                  <li>All Intellectual Property Rights in or relating to the Services are and shall at all times remain the property of Company and / or its licensors;</li>
                  <li>Partner/Agent shall notify Company immediately if Partner becomes aware of any illegal or unauthorized use of any the intellectual property therein or relating thereto and will assist Company in taking all steps necessary to protect and defend Company’s rights therein.</li>
                </ol>
              </li>
              <li>The provisions of this Clause 8 shall survive the termination of this Agreement.</li>
            </ol>
          </li>
          <li>
            <strong>CONFIDENTIALITY</strong>
            <ol className="list-decimal pl-5">
              <li>
                The Company acknowledge that in the course of performing their obligations under this Agreement, they may get access to confidential information (<strong>“Confidential Information”</strong>) of the clients/customers registered through Partner/Agent. Herein, Company agrees not to disclose Confidential Information received (whether in writing, verbally or by any other means and whether directly or indirectly) by the clients/customers including in relation to matters contemplated by this Agreement or its performance or in any agreement in furtherance of the performance of this Agreement, to any Third Party without the prior written consent of Partner/Agent or use the Confidential Information other than for carrying out the purposes of this Agreement.
              </li>
              <li>Company shall keep confidential any Confidential Information it receives from the clients/customers and shall employ all such reasonable steps that it would have taken to protect its own Confidential Information. The Company shall disclose Confidential Information received from the clients/customers to its officers, employees, agents or representatives only for the purposes of carrying out its obligations under this Agreement and strictly on a “need to know” basis only. The Company shall also ensure that their respective directors, employees, officers and any other Persons to whom the above-mentioned information is disclosed to be bound by confidentiality obligations similar to those provided in this Clause.</li>
              <li>The Company shall not disclose any material information about clients/customers including but not limited to name, contact details, methodology of work, and approach of handling a claim etc. to third party.</li>
              <li>
                During the term of this agreement or One year from the termination of the same, the Company shall hold the Confidential Information received from the clients and shall not disclose or disseminate at any time, to any other person without the prior consent/permission of the client. Notwithstanding anything contained in Clause 9.1 and Clause 9.2, a Receiving Party’s obligation of confidentiality shall not extend to any Confidential Information:
                <ol type="a" className="list-decimal pl-5">
                  <li>To the extent that the Confidential Information received is in the public domain other than by breach of this Agreement;</li>
                  <li>To the extent that the Confidential Information is lawfully acquired by the Receiving Party from a Third Party who owes the Disclosing Party no obligation of confidence in respect of such Confidential Information;</li>
                  <li>To the extent that the Confidential Information was previously known or already in the lawful possession of the Receiving Party prior to receipt from the Disclosing Party;</li>
                  <li>To the extent that the Confidential Information received is required to be disclosed by any applicable Law or by any Authority to whose jurisdiction the Receiving Party is subject or with whose instructions it is customary to comply under notice to the Disclosing Party; or</li>
                  <li>In so far as it is disclosed to the employees, directors or professional advisers of the Receiving Party, provided that the Receiving Party shall procure that such persons treat the Confidential Information received as confidential.</li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <strong>Indemnification</strong>
            <ol className="list-decimal pl-5">
              <li>In the event that Company is found to be in breach of terms of this Agreement, Company at all times hereafter indemnify and keep the client (“Indemnified Party”) fully indemnified against all claims, demands, actions, proceedings, losses, damages, costs, charges, expenses, interests and disbursements of any nature whatsoever which the Indemnified Party may pay or incur or suffer or sustain or be liable to pay or incur or suffer or sustain as a result or consequence, direct or indirect, of such breach.</li>
            </ol>
          </li>
          <li>
            <strong>Disputes</strong>
            <ol className="list-decimal pl-5">
              <li>In the event of disputes, differences, claims and questions between the Parties hereto arising out of this Agreement or in any way relating hereto or any term, condition or provision herein mentioned or the construction or interpretation thereof or otherwise in relation hereto, the Parties shall first endeavor to settle such differences, disputes, claims or questions by arbitration of three (3) arbitrators, one each to be appointed by each Party, and each such arbitrator shall appoint a third Arbitrator who shall act as the chairman of the arbitral tribunal. The Arbitration and Conciliation Act, 1996 and the rules made there under shall apply to and shall govern the arbitration proceedings. The venue of the arbitration shall be exclusively at New Delhi and the arbitration proceeding shall be conducted in English. The decision of the Arbitrators shall be final and binding on the Parties.</li>
            </ol>
          </li>
          <li>
            <strong>GOVERNING LAW</strong>
            <ol className="list-decimal pl-5">
              <li>This Agreement shall be governed by and construed in accordance with the laws of India, without giving effect to any choice of law or conflict of law provisions. The Parties consent to the exclusive jurisdiction of courts of Delhi with respect to any matter or claim, suit, action or proceeding arising under or related to this Agreement.</li>
            </ol>
          </li>
          <li>
            <strong>MISCELLANEOUS</strong>
            <ol type="i" className="list-decimal pl-5">
              <li>
                <strong>Severability:</strong> The provisions of this Agreement are severable and the validity of remaining Articles, provisions, terms and parts of this Agreement shall not be affected by a court, administrative board or other proceeding of competent jurisdiction deciding that a Clause, provision, term or part of this Agreement is illegal, unenforceable, void, in conflict with any law or contrary to public policy. In such event the Parties shall, by amendment of this Agreement, properly replace such provision by a reasonable new provision or provisions which, as far as legally possible, shall approximate to the closest possible extent what the Parties intended by such original provision and the purpose thereof.
              </li>
              <li>
                <strong>Assignment of Agreement:</strong> Except as permitted or required by this Agreement or as otherwise agreed in writing between the Parties:
                <ol type="a" className="list-decimal pl-5">
                  <li>This Agreement may not be assigned, novated or transferred; and</li>
                  <li>Neither Party shall create or permit to subsist any encumbrance over all or any of its rights and benefits under this Agreement.</li>
                </ol>
              </li>
              <li>
                <strong>Amendment:</strong> No oral or written modification, amendment, rescission, waiver or other change of this Agreement or any of its terms or provisions shall be valid or legally binding on the Parties unless made in writing and duly executed by or on behalf of all the Parties, including without limitation, any purported modification, amendment, rescission, waiver or other change of this itself. No amendments, supplements or modifications to this Agreement shall be made except by written agreement between the Parties.
              </li>
              <li>
                <strong>No Waiver:</strong> A waiver by any Party of any default or defaults by the other Party in the performance of any of the provisions of this Agreement shall be effective only when executed in writing by duly authorized representatives of all Parties. A waiver shall never operate as or be construed as a waiver of any other or further default or defaults whether of a like or different character. Neither the failure by a Party to insist on any occasion upon the performance of the terms, conditions, and provisions of this Agreement nor time or other indulgence granted by a Party to the other or others shall act as a waiver of such breach of acceptance of any variation or the relinquishment of any such right or any other right under this Agreement, which shall remain in full force and effect.
              </li>
              <li>
                <strong>Counterparts:</strong> This Agreement may be executed in one or more counterparts, each of which shall be deemed an original and all of which collectively shall be deemed one and the same instrument.
              </li>
              <li>
                <strong>Notices:</strong> All communication, notices or information to be given under this Agreement shall be so given in writing. All notices must be delivered personally, by registered or certified mail or facsimile to the addresses below:
                <br />
                <br />
                <table className="w-full border border-gray-300">
                  <thead>
                    <tr>
                      <th className="border border-gray-300 p-2">
                        <strong>Fastsurance Consultants Pvt. Ltd</strong>
                      </th>
                      <th className="border border-gray-300 p-2">
                        <strong>PARTNER/ AGENT</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border border-gray-300 p-2">ATTN: Sanjay Aggarwal</td>
                      <td className="border border-gray-300 p-2">ATTN:</td>
                    </tr>
                    <tr>
                      <td className="border border-gray-300 p-2">Director</td>
                      <td className="border border-gray-300 p-2"></td>
                    </tr>
                    <tr>
                      <td className="border border-gray-300 p-2">A-31, 2nd floor, Infraline Tower, Sector 3, Noida, 201301</td>
                      <td className="border border-gray-300 p-2">Director/Authorized Representative</td>
                    </tr>
                    <tr>
                      <td className="border border-gray-300 p-2">
                        <a href="mailto:sanjay@insurancesamadhan.com">sanjay@insurancesamadhan.com</a>
                      </td>
                      <td className="border border-gray-300 p-2"></td>
                    </tr>
                  </tbody>
                </table>
                <br />
                All notices shall be effective:
                <ol className="list-decimal pl-5">
                  <li>If sent by facsimile, when sent (on receipt of confirmation of the correct number);</li>
                  <li>If sent by registered or certified mail, within five (5) days of dispatch; and</li>
                  <li>If delivered personally, on receipt by the intended recipient: Provided that, all notices sent by facsimile, shall be confirmed by registered or certified mail. Each Party shall forthwith notify the other Party of any change in its address to which notices under this Agreement are to be delivered, mailed, facsimiled or electronically mailed.</li>
                </ol>
              </li>
              <li>
                <strong>Language:</strong> The language of this Agreement shall be English and all documents, notices, waivers and all other written communication or otherwise between the Parties in connection with this Agreement shall be in English.
              </li>
              <li>
                <strong>Remedies:</strong> Parties acknowledge that damages will not be a sufficient remedy for breach of this Agreement and the Parties shall be entitled to seek specific performance of each other’s obligations pursuant to this Agreement.
              </li>
              <li>
                <strong>Further Assurances:</strong> Each Party agrees to perform (or procure the performance of) all further acts and things, and execute and deliver (or procure the execution and delivery of) such further documents, as may be required by Law or as the other Party may reasonably require, whether on or after Effective Date, to implement and/or give effect to this Agreement.
              </li>
            </ol>
          </li>
        </ol>
        <p>
          <strong>IN WITNESS WHEREOF</strong> the Parties have executed these presents through their authorized representatives on the Execution Date.
          <br />
          <strong>IN WITNESS WHEREOF</strong>, the Parties hereto have set and subscribed their hands through their respective duly authorized representatives as of the date first above written.
        </p>

        <div className="border p-4 my-4">
          <div>
            Signed and delivered for and on behalf of <strong>Fastsurance Consultants Pvt. Ltd</strong>
          </div>
          <br />
          <div>Signatures:</div>
          <div className="sign">
            <img src={InvoiceSign} alt="signature" className="w-10" />
          </div>
          <br />
          <div>Date: {new Date().toLocaleDateString()}</div>
          <hr />
          <div>Signed and delivered for and on behalf of partner/agent</div>
          <div>Name: {selectedRow?.name || ""}</div>
          <div>Signatures:</div>
          <br />
          <div>Authorized Signatory</div>
          <div>Date: {new Date().toLocaleDateString()}</div>
        </div>
        <p>
          <strong>Note</strong>: The above terms may change by time without prior notice.
          <br />*<strong>Disclaimer</strong>: Your details may be used by Company for promotion on its various platforms.
        </p>
        <div className="space mt-4"></div>
      </div>
    </div>
  );
};

const LeadCostSharing: FC<any> = ({ selectedRow }) => {
  return (
    <div className="px-6 py-8 text-gray-800 text-sm leading-6">
      <ol className="list-decimal space-y-4">
        <p>
          This <strong>AGREEMENT FOR BUSINESS SUPPORT SERVICES</strong> (hereinafter this <strong>“Agreement”</strong>) is made on this {selectedRow?.agreedOn ? new Date(selectedRow?.agreedOn).toLocaleDateString() : new Date().toLocaleDateString()} (the <strong>“Execution Date”</strong>) by and between:
        </p>

        <ol className="list-decimal pl-6">
          <li>
            <strong>I, {selectedRow?.name || ""}, authorised insurance representative (hereinafter referred to as PARTNER</strong> which expression shall, unless repugnant to or inconsistent with the context, mean and include any successors or permitted assigns) of the <strong>FIRST PART</strong>
          </li>
          <br />
          <li>
            <strong>I, Sanjay Aggarwal, Co-Founder of INSURANCE SAMADHAN (hereunder mentioned as FASTSURANCE CONSULTANTS PVT. LTD.)</strong>, a company incorporated in India and having its registered office at A-31, 2nd floor, Infraline Tower, Sector 3, Noida, 201301 India (hereinafter referred to as the <strong>“Company”</strong>, which expression shall, unless repugnant to or inconsistent with the context, mean and include any successors or permitted assigns) of the <strong>SECOND PART</strong>; and
            <br />
            <strong>“The Company”</strong> and <strong>“PARTNER”</strong> are jointly referred to as “Parties” and individually as “Party”
          </li>
        </ol>

        <p>
          <strong>WHEREAS</strong>
        </p>

        <ol type="A" className="list-decimal pl-6 space-y-4">
          <li>The Company is interalia engaged in the business of providing services of various nature in the field of Life, Health and General Insurance, such as assistance in resolving consumer grievances by representing clients at various forums like Insurance Companies, IRDA, Ombudsman, Consumer Forums, Legal Courts. The overall mission of Insurance Samadhan is centered towards social cause and the betterment/upliftment of the general impression of the insurance companies.</li>
          <li>Partner is engaged in {selectedRow?.natureOfBusiness || ""} services.</li>
        </ol>

        <p>
          <strong>NOW, IN CONSIDERATION OF THE ABOVE PREMISES AND MUTUAL COVENANTS CONTAINED HEREINAFTER THIS AGREEMENT WITNESSETH AS UNDER:-</strong>
        </p>

        <ol className="list-decimal space-y-4">
          <li>
            <strong>SERVICES</strong>
            <ol className="list-decimal pl-6 space-y-2">
              <li>Company agrees to provide resolution of insurance related grievances to clients/customers approached through Partner/ Agent on such consideration as defined in the present agreement and Partner / Agent has agreed to generate customer registrations/ leads for Company.</li>
            </ol>
          </li>

          <li>
            <strong>TERM</strong>
            <ol className="list-decimal pl-6 space-y-2">
              <li>
                This Agreement shall become effective from the date of execution of this Agreement (<strong>“Effective Date”</strong>) and shall remain valid and binding on the Parties for a period of 1 (one) year from the Effective Date (“Term”), unless terminated earlier in accordance with the terms hereof.
              </li>
              <li>Upon expiry of this Agreement, the Parties may extend the duration of this Agreement subject to mutually agreed terms and conditions.</li>
            </ol>
          </li>

          <li>
            <strong>STANDARD OF PERFORMANCE</strong>
            <ol className="list-decimal pl-6 space-y-2">
              <li>
                PARTNER/AGENT shall:
                <ol className="list-decimal pl-6 space-y-2">
                  <li>PARTNER/AGENT is a knowledge partner and shouldn’t be accountable for any lapses or liability by claimant / clients of the Company</li>
                </ol>
              </li>
            </ol>
          </li>

          <li>
            <strong>REPRESENTATIONS AND WARRANTIES</strong>
            <ol className="list-decimal pl-6 space-y-2">
              <li>
                Each Party represents and warrants to the other Party that:
                <ol className="list-decimal pl-6 space-y-2">
                  <li>It is a company duly and legally organized and validly existing under the laws of India;</li>
                  <li>This Agreement constitutes its valid and legally binding obligations, enforceable in accordance with its terms;</li>
                  <li>It has full corporate power and authority to execute and deliver this Agreement in accordance with its terms and to perform all its duties and obligations arising or created under or pursuant to this Agreement and all requisite corporate approvals, as applicable, have been obtained by it prior to the execution of this Agreement; and</li>
                  <li>The execution of this Agreement and delivery and performance by it of its obligations hereunder do not and shall not violate or conflict in any manner with or result in a breach or default under Applicable Law or any of its duties or obligations under any agreement, understanding or arrangement, written or oral, to which it is a party.</li>
                </ol>
              </li>
            </ol>
          </li>

          <li>
            <strong>TERMS OF CONSIDERATION</strong>
            <ol className="list-decimal pl-6 space-y-2">
              <li>The Company will share pre decided commercials only in case of genuine leads which are coming through Partner as source. Any other method is not viable under current scenario and can be discussed and amended as and when it arises.</li>
              <li>The Company is not responsible for resolution of any case even after acceptance as Insurance resolution is a result of many factors which cannot be deemed as final at any stage of the case.</li>
              <li>The Company hereby agrees to pay Rs.{selectedRow?.leadAmount || ""} per case to the agents on successful registration of a case with the Company.</li>
              <li>The compensation shall be paid in terms of the mutual agreement between the Parties post the resolution amount is received by the client on successful outcome of the case.</li>
            </ol>
          </li>

          <li>
            <strong>TERMINATION</strong>
            <ol className="list-decimal pl-6 space-y-2">
              <li>This Agreement may be terminated by either Party without assigning any reason at any time by giving 1 (one) month prior written notice to the other Party.</li>
              <li>
                Without prejudice to any other rights or remedies available in law or under this Agreement, either Party may terminate this Agreement forthwith on or at any time after the occurrence of any of the events specified herein below:
                <ol className="list-decimal pl-6 space-y-2">
                  <li>Insolvency of the other Party;</li>
                  <li>Material breach of any of the terms or conditions of this Agreement by the other Party which breach is not remedied by such other Party to the satisfaction of the non-breaching Party within three (3) days of notice of the breach; and</li>
                  <li>If a petition for winding up of the other Party is decreed in any Court or a resolution is passed for winding-up the business of such other Party or if any receiver, manager, liquidator, administrator or other similar official is appointed to the other Party or a substantial part of the other Party’s property.</li>
                </ol>
              </li>
            </ol>
          </li>

          <li>
            <strong>INDEPENDENT CONTRACTOR</strong>
            <ol className="list-decimal pl-6 space-y-2">
              <li>It is hereby acknowledged that Partner/ Agent shall perform the services in the capacity of an independent contractor and not as an employee, servant or agent of the Company. Nothing contained herein shall be construed to create a relationship of employer-employee and master-servant between Company and Partner/ Agent.</li>
            </ol>
          </li>

          <li>
            <strong>INTELLECTUAL PROPERTY</strong>
            <ol className="list-decimal pl-6 space-y-2">
              <li>The Company does not grant to Partner/ Agent any right, title or interest in any of its intellectual property except as expressly authorised in writing by the Company and Partner shall not have any right, title or interest in Company’s intellectual property other than the right to use it for purposes of this Agreement for the Term hereof. Partner shall comply with any and all instructions issued by Company in relation to the display of any logo, trademark, copyright or any other intellectual property of the Company. Upon expiry or earlier termination of this Agreement, Partner shall immediately cease and desist for all times from any use of or reference to Company’s intellectual property and shall return to Company copies or materials containing such intellectual property.</li>
              <li>
                Parties hereby acknowledge and confirm that:
                <ol className="list-decimal pl-6 space-y-2">
                  <li>All Intellectual Property Rights in or relating to the Services are and shall at all times remain the property of Company and / or its licensors;</li>
                  <li>Partner/Agent shall notify Company immediately if Partner becomes aware of any illegal or unauthorized use of any the intellectual property therein or relating thereto and will assist Company in taking all steps necessary to protect and defend Company’s rights therein.</li>
                </ol>
              </li>
              <li>The provisions of this Clause 8 shall survive the termination of this Agreement.</li>
            </ol>
          </li>

          <li>
            <strong>CONFIDENTIALITY</strong>
            <ol className="list-decimal pl-6 space-y-2">
              <li>
                The Company acknowledge that in the course of performing their obligations under this Agreement, they may get access to confidential information (<strong>“Confidential Information”</strong>) of the clients/customers registered through Partner/Agent. Herein, Company agrees not to disclose Confidential Information received (whether in writing, verbally or by any other means and whether directly or indirectly) by the clients/customers including in relation to matters contemplated by this Agreement or its performance or in any agreement in furtherance of the performance of this Agreement, to any Third Party without the prior written consent of Partner/Agent or use the Confidential Information other than for carrying out the purposes of this Agreement.
              </li>
              <li>Company shall keep confidential any Confidential Information it receives from the clients/customers and shall employ all such reasonable steps that it would have taken to protect its own Confidential Information. The Company shall disclose Confidential Information received from the clients/customers to its officers, employees, agents or representatives only for the purposes of carrying out its obligations under this Agreement and strictly on a “need to know” basis only. The Company shall also ensure that their respective directors, employees, officers and any other Persons to whom the above-mentioned information is disclosed to be bound by confidentiality obligations similar to those provided in this Clause.</li>
              <li>The Company shall not disclose any material information about clients/customers including but not limited to name, contact details, methodology of work, and approach of handling a claim etc. to third party.</li>
              <li>During the term of this agreement or One year from the termination of the same, the Company shall hold the Confidential Information received from the clients and shall not disclose or disseminate at any time, to any other person without the prior consent/permission of the client.</li>
              <li>
                Notwithstanding anything contained in Clause 9.1 and Clause 9.2, a Receiving Party’s obligation of confidentiality shall not extend to any Confidential Information:
                <ol className="list-decimal pl-6 space-y-2">
                  <li>To the extent that the Confidential Information received is in the public domain other than by breach of this Agreement;</li>
                  <li>To the extent that the Confidential Information is lawfully acquired by the Receiving Party from a Third Party who owes the Disclosing Party no obligation of confidence in respect of such Confidential Information;</li>
                  <li>To the extent that the Confidential Information was previously known or already in the lawful possession of the Receiving Party prior to receipt from the Disclosing Party;</li>
                  <li>To the extent that the Confidential Information received is required to be disclosed by any applicable Law or by any Authority to whose jurisdiction the Receiving Party is subject or with whose instructions it is customary to comply under notice to the Disclosing Party; or</li>
                  <li>In so far as it is disclosed to the employees, directors or professional advisers of the Receiving Party, provided that the Receiving Party shall procure that such persons treat the Confidential Information received as confidential.</li>
                </ol>
              </li>
            </ol>
          </li>

          <li>
            <strong>Indemnification</strong>
            <ol className="list-decimal pl-6 space-y-2">
              <li>In the event that Company is found to be in breach of terms of this Agreement, Company at all times hereafter indemnify and keep the client (“Indemnified Party”) fully indemnified against all claims, demands, actions, proceedings, losses, damages, costs, charges, expenses, interests and disbursements of any nature whatsoever which the Indemnified Party may pay or incur or suffer or sustain or be liable to pay or incur or suffer or sustain as a result or consequence, direct or indirect, of such breach.</li>
            </ol>
          </li>

          <li>
            <strong>Disputes</strong>
            <ol className="list-decimal pl-6 space-y-2">
              <li>In the event of disputes, differences, claims and questions between the Parties hereto arising out of this Agreement or in any way relating hereto or any term, condition or provision herein mentioned or the construction or interpretation thereof or otherwise in relation hereto, the Parties shall first endeavor to settle such differences, disputes, claims or questions by arbitration of three (3) arbitrators, one each to be appointed by each Party, and each such arbitrator shall appoint a third Arbitrator who shall act as the chairman of the arbitral tribunal. The Arbitration and Conciliation Act, 1996 and the rules made there under shall apply to and shall govern the arbitration proceedings. The venue of the arbitration shall be exclusively at New Delhi and the arbitration proceeding shall be conducted in English. The decision of the Arbitrators shall be final and binding on the Parties.</li>
            </ol>
          </li>

          <li>
            <strong>GOVERNING LAW</strong>
            <ol className="list-decimal pl-6 space-y-2">
              <li>This Agreement shall be governed by and construed in accordance with the laws of India, without giving effect to any choice of law or conflict of law provisions. The Parties consent to the exclusive jurisdiction of courts of Delhi with respect to any matter or claim, suit, action or proceeding arising under or related to this Agreement.</li>
            </ol>
          </li>

          <li>
            <strong>MISCELLANEOUS</strong>
            <ol className="list-decimal pl-6 space-y-2">
              <li>
                <strong>Severability:</strong> The provisions of this Agreement are severable and the validity of remaining Articles, provisions, terms and parts of this Agreement shall not be affected by a court, administrative board or other proceeding of competent jurisdiction deciding that a Clause, provision, term or part of this Agreement is illegal, unenforceable, void, in conflict with any law or contrary to public policy. In such event the Parties shall, by amendment of this Agreement, properly replace such provision by a reasonable new provision or provisions which, as far as legally possible, shall approximate to the closest possible extent what the Parties intended by such original provision and the purpose thereof.
              </li>
              <li>
                <strong>Assignment of Agreement:</strong> Except as permitted or required by this Agreement or as otherwise agreed in writing between the Parties:
                <ol className="list-decimal pl-6 space-y-2">
                  <li>This Agreement may not be assigned, novated or transferred; and</li>
                  <li>Neither Party shall create or permit to subsist any encumbrance over all or any of its rights and benefits under this Agreement.</li>
                </ol>
              </li>
              <li>
                <strong>Amendment:</strong> No oral or written modification, amendment, rescission, waiver or other change of this Agreement or any of its terms or provisions shall be valid or legally binding on the Parties unless made in writing and duly executed by or on behalf of all the Parties, including without limitation, any purported modification, amendment, rescission, waiver or other change of this itself. No amendments, supplements or modifications to this Agreement shall be made except by written agreement between the Parties.
              </li>
              <li>
                <strong>No Waiver:</strong> A waiver by any Party of any default or defaults by the other Party in the performance of any of the provisions of this Agreement shall be effective only when executed in writing by duly authorized representatives of all Parties. A waiver shall never operate as or be construed as a waiver of any other or further default or defaults whether of a like or different character. Neither the failure by a Party to insist on any occasion upon the performance of the terms, conditions, and provisions of this Agreement nor time or other indulgence granted by a Party to the other or others shall act as a waiver of such breach of acceptance of any variation or the relinquishment of any such right or any other right under this Agreement, which shall remain in full force and effect.
              </li>
              <li>
                <strong>Counterparts:</strong> This Agreement may be executed in one or more counterparts, each of which shall be deemed an original and all of which collectively shall be deemed one and the same instrument.
              </li>
              <li>
                <strong>Notices:</strong> : All communication, notices or information to be given under this Agreement shall be so given in writing. All notices must be delivered personally, by registered or certified mail or facsimile to the addresses below:
                <table className="table-auto w-full mt-4 mb-4">
                  <thead>
                    <tr>
                      <th className="text-left">Fastsurance Consultants Pvt. Ltd</th>
                      <th className="text-left">PARTNER/ AGENT</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>ATTN:</td>
                      <td>ATTN:</td>
                    </tr>
                    <tr>
                      <td>Sanjay Aggarwal</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Director</td>
                      <td>
                        <hr />
                      </td>
                    </tr>
                    <tr>
                      <td>A-31, 2nd floor, Infraline Tower,</td>
                      <td>Director/Authorized Representative</td>
                    </tr>
                    <tr>
                      <td>Sector 3, Noida, 201301,</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>
                        <a href="mailto:sanjay@insurancesamadhan.com">sanjay@insurancesamadhan.com</a>
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
                <p>All notices shall be effective:</p>
                <ol className="list-decimal pl-6 space-y-2">
                  <li>If sent by facsimile, when sent (on receipt of confirmation of the correct number);</li>
                  <li>If sent by registered or certified mail, within five (5) days of dispatch; and</li>
                  <li>If delivered personally, on receipt by the intended recipient: Provided that, all notices sent by facsimile, shall be confirmed by registered or certified mail. Each Party shall forthwith notify the other Party of any change in its address to which notices under this Agreement are to be delivered, mailed, facsimiled or electronically mailed.</li>
                </ol>
              </li>
              <li>
                <strong>Language:</strong> The language of this Agreement shall be English and all documents, notices, waivers and all other written communication or otherwise between the Parties in connection with this Agreement shall be in English.
              </li>
              <li>
                <strong>Remedies:</strong> Parties acknowledge that damages will not be a sufficient remedy for breach of this Agreement and the Parties shall be entitled to seek specific performance of each other’s obligations pursuant to this Agreement.
              </li>
              <li>
                <strong>Further Assurances:</strong> Each Party agrees to perform (or procure the performance of) all further acts and things, and execute and deliver (or procure the execution and delivery of) such further documents, as may be required by Law or as the other Party may reasonably require, whether on or after Effective Date, to implement and/or give effect to this Agreement.
              </li>
            </ol>
          </li>
        </ol>
      </ol>

      <div className="border p-4 my-4">
        <div>
          Signed and delivered for and on behalf of <strong>Fastsurance Consultants Pvt. Ltd</strong>
        </div>
        <br />
        <div>Signatures:</div>
        <div className="sign">
          <img src={InvoiceSign} alt="signature" className="w-10" />
        </div>
        <br />
        <div>Date: {selectedRow?.agreedOn ? new Date(selectedRow?.agreedOn).toLocaleDateString() : new Date().toLocaleDateString()}</div>
        <hr />
        <div>Signed and delivered for and on behalf of partner/agent</div>
        <div>Name: {selectedRow?.name || ""}</div>
        <div>Signatures:</div>
        <br />
        <div>Authorized Signatory</div>
        <div>Date: {selectedRow?.agreedOn ? new Date(selectedRow?.agreedOn).toLocaleDateString() : new Date().toLocaleDateString()}</div>
      </div>
      <p>
        {" "}
        <strong>Note</strong>: The above terms may change by time without prior notice.
        <br />*<strong>Disclaimer</strong>: Your details may be used by Company for promotion on its various platforms.
      </p>
      <div className="space"></div>
    </div>
  );
};

const NoRevenueSharingFirstType: FC<any> = ({ selectedRow }) => {
  return (
    <div className="px-6 py-8 text-gray-800 text-sm leading-6">
      <ol className="list-decimal space-y-4">
        <p>
          This <strong>AGREEMENT FOR BUSINESS SUPPORT SERVICES</strong> (hereinafter this
          <strong>“Agreement”</strong>) is made on this {selectedRow?.agreedOn ? new Date(selectedRow?.agreedOn).toLocaleDateString() : new Date().toLocaleDateString()} (the
          <strong>“Execution Date”</strong>) by and between:
        </p>
        <ol className="list-decimal pl-6 space-y-4">
          <li>
            <strong>I, {selectedRow?.name || ""}, authorised insurance representative (hereinafter referred to as PARTNER</strong> which expression shall, unless repugnant to or inconsistent with the context, mean and include any successors or permitted assigns) of the <strong>FIRST PART</strong>
          </li>
          <li>
            <strong>I, Sanjay Aggarwal, Co-Founder of INSURANCE SAMADHAN (hereunder mentioned as FASTSURANCE CONSULTANTS PVT. LTD.)</strong>, a company incorporated in India and having its registered office at A-31, 2nd floor, Infraline Tower, Sector 3, Noida, 201301 India (hereinafter referred to as the <strong>“Company”</strong>, which expression shall, unless repugnant to or inconsistent with the context, mean and include any successors or permitted assigns) of the <strong>SECOND PART</strong>; and
            <br />
            <strong>“The Company”</strong> and <strong>“PARTNER”</strong> are jointly referred to as “Parties” and individually as “Party”
          </li>
        </ol>
      </ol>
      <ol className="list-decimal space-y-4 mt-4">
        <p>
          <strong>WHEREAS</strong>
        </p>
        <ol className="list-upper-alpha pl-6 space-y-4">
          <li>The Company is interalia engaged in the business of providing services of various nature in the field of Life, Health and General Insurance, such as assistance in resolving consumer grievances by representing clients at various forums like Insurance Companies, IRDA, Ombudsman, Consumer Forums, Legal Courts. The overall mission of Insurance Samadhan is centered towards social cause and the betterment/upliftment of the general impression of the insurance companies.</li>
          <li>Partner is engaged in {selectedRow?.natureOfBusiness || ""} services.</li>
        </ol>
      </ol>
      <p className="mt-4">
        <strong>NOW, IN CONSIDERATION OF THE ABOVE PREMISES AND MUTUAL COVENANTS CONTAINED HEREINAFTER THIS AGREEMENT WITNESSETH AS UNDER:-</strong>
      </p>
      <ol className="list-decimal space-y-4 mt-4">
        <li>
          <strong>SERVICES</strong>
          <ol className="list-decimal pl-6">
            <li>Company agrees to provide resolution of insurance related grievances to clients/customers approached through Partner/ Agent on such consideration as defined in the present agreement and Partner / Agent has agreed to generate customer registrations/ leads for Company.</li>
          </ol>
        </li>
        <li>
          <strong>TERM</strong>
          <ol className="list-decimal pl-6">
            <li>
              This Agreement shall become effective from the date of execution of this Agreement (<strong>“Effective Date”</strong>) and shall remain valid and binding on the Parties for a period of 1 (one) year from the Effective Date (“Term”), unless terminated earlier in accordance with the terms hereof.
            </li>
            <li>Upon expiry of this Agreement, the Parties may extend the duration of this Agreement subject to mutually agreed terms and conditions.</li>
          </ol>
        </li>
        <li>
          <strong>STANDARD OF PERFORMANCE</strong>
          <ol className="list-decimal pl-6">
            <li>
              PARTNER/AGENT shall:
              <ol className="list-lower-roman pl-6">
                <li>PARTNER/AGENT is a knowledge partner and shouldn’t be accountable for any lapses or liability by claimant / clients of the Company.</li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <strong>REPRESENTATIONS AND WARRANTIES</strong>
          <ol className="list-decimal pl-6">
            <li>
              Each Party represents and warrants to the other Party that:
              <ol className="list-lower-alpha pl-6">
                <li>It is a company duly and legally organized and validly existing under the laws of India;</li>
                <li>This Agreement constitutes its valid and legally binding obligations, enforceable in accordance with its terms;</li>
                <li>It has full corporate power and authority to execute and deliver this Agreement in accordance with its terms and to perform all its duties and obligations arising or created under or pursuant to this Agreement and all requisite corporate approvals, as applicable, have been obtained by it prior to the execution of this Agreement; and</li>
                <li>The execution of this Agreement and delivery and performance by it of its obligations hereunder do not and shall not violate or conflict in any manner with or result in a breach or default under Applicable Law or any of its duties or obligations under any agreement, understanding or arrangement, written or oral, to which it is a party.</li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <strong>TERMS OF CONSIDERATION</strong>
          <ol className="list-lower-alpha pl-6">
            <li>The Company is not responsible for resolution of any case even after acceptance as Insurance resolution is a result of many factors which cannot be deemed as final at any stage of the case.</li>
          </ol>
        </li>
        <li>
          <strong>TERMINATION</strong>
          <ol className="list-decimal pl-6">
            <li>This Agreement may be terminated by either Party without assigning any reason at any time by giving 1 (one) month prior written notice to the other Party.</li>
            <li>
              Without prejudice to any other rights or remedies available in law or under this Agreement, either Party may terminate this Agreement forthwith on or at any time after the occurrence of any of the events specified herein below:
              <ol className="list-lower-alpha pl-6">
                <li>Insolvency of the other Party;</li>
                <li>Material breach of any of the terms or conditions of this Agreement by the other Party which breach is not remedied by such other Party to the satisfaction of the non-breaching Party within three (3) days of notice of the breach; and</li>
                <li>If a petition for winding up of the other Party is decreed in any Court or a resolution is passed for winding-up the business of such other Party or if any receiver, manager, liquidator, administrator or other similar official is appointed to the other Party or a substantial part of the other Party’s property.</li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <strong>INDEPENDENT CONTRACTOR</strong>
          <ol className="list-decimal pl-6">
            <li>It is hereby acknowledged that Partner/ Agent shall perform the services in the capacity of an independent contractor and not as an employee, servant or agent of the Company. Nothing contained herein shall be construed to create a relationship of employer-employee and master-servant between Company and Partner/ Agent.</li>
          </ol>
        </li>
        <li>
          <strong>INTELLECTUAL PROPERTY</strong>
          <ol className="list-decimal pl-6">
            <li>The Company does not grant to Partner/ Agent any right, title or interest in any of its intellectual property except as expressly authorised in writing by the Company and Partner shall not have any right, title or interest in Company’s intellectual property other than the right to use it for purposes of this Agreement for the Term hereof. Partner shall comply with any and all instructions issued by Company in relation to the display of any logo, trademark, copyright or any other intellectual property of the Company. Upon expiry or earlier termination of this Agreement, Partner shall immediately cease and desist for all times from any use of or reference to Company’s intellectual property and shall return to Company copies or materials containing such intellectual property.</li>
            <li>
              Parties hereby acknowledge and confirm that:
              <ol className="list-lower-alpha pl-6">
                <li>All Intellectual Property Rights in or relating to the Services are and shall at all times remain the property of Company and / or its licensors;</li>
                <li>Partner/Agent shall notify Company immediately if Partner becomes aware of any illegal or unauthorized use of any the intellectual property therein or relating thereto and will assist Company in taking all steps necessary to protect and defend Company’s rights therein.</li>
              </ol>
            </li>
            <li>The provisions of this Clause 8 shall survive the termination of this Agreement.</li>
          </ol>
        </li>
        <li>
          <strong>CONFIDENTIALITY</strong>
          <ol className="list-decimal pl-6">
            <li>
              The Company acknowledge that in the course of performing their obligations under this Agreement, they may get access to confidential information (<strong>“Confidential Information”</strong>) of the clients/customers registered through Partner/Agent. Herein, Company agrees not to disclose Confidential Information received (whether in writing, verbally or by any other means and whether directly or indirectly) by the clients/customers including in relation to matters contemplated by this Agreement or its performance or in any agreement in furtherance of the performance of this Agreement, to any Third Party without the prior written consent of Partner/Agent or use the Confidential Information other than for carrying out the purposes of this Agreement.
            </li>
            <li>Company shall keep confidential any Confidential Information it receives from the clients/customers and shall employ all such reasonable steps that it would have taken to protect its own Confidential Information. The Company shall disclose Confidential Information received from the clients/customers to its officers, employees, agents or representatives only for the purposes of carrying out its obligations under this Agreement and strictly on a “need to know” basis only. The Company shall also ensure that their respective directors, employees, officers and any other Persons to whom the above-mentioned information is disclosed to be bound by confidentiality obligations similar to those provided in this Clause.</li>
            <li>The Company shall not disclose any material information about clients/customers including but not limited to name, contact details, methodology of work, and approach of handling a claim etc. to third party.</li>
            <li>
              During the term of this agreement or One year from the termination of the same, the Company shall hold the Confidential Information received from the clients and shall not disclose or disseminate at any time, to any other person without the prior consent/permission of the client. Notwithstanding anything contained in above mentioned clause, Company’s obligation of confidentiality shall not extend to any Confidential Information:
              <ol className="list-lower-roman pl-6">
                <li>received from the clients is in the public domain other than by breach of this Agreement;</li>
                <li>to the extent that the Confidential Information received is required to be disclosed by any applicable Law or by any Authority to whose jurisdiction the Company is subject or with whose instructions it is customary to comply under notice to the Partner; or</li>
                <li>In so far as it is disclosed to the employees, directors or professional advisers of the Receiving Party, provided that the Receiving Party shall procure that such persons treat the Confidential Information received as confidential.</li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <strong>Indemnification</strong>
          <ol className="list-decimal pl-6">
            <li>In the event that Company is found to be in breach of terms of this Agreement, Company at all times hereafter indemnify and keep the client (“Indemnified Party”) fully indemnified against all claims, demands, actions, proceedings, losses, damages, costs, charges, expenses, interests and disbursements of any nature whatsoever which the Indemnified Party may pay or incur or suffer or sustain or be liable to pay or incur or suffer or sustain as a result or consequence, direct or indirect, of such breach.</li>
          </ol>
        </li>
        <li>
          <strong>Disputes</strong>
          <ol className="list-decimal pl-6">
            <li>In the event of disputes, differences, claims and questions between the Parties hereto arising out of this Agreement or in any way relating hereto or any term, condition or provision herein mentioned or the construction or interpretation thereof or otherwise in relation hereto, the Parties shall first endeavor to settle such differences, disputes, claims or questions by arbitration of three (3) arbitrators, one each to be appointed by each Party, and each such arbitrator shall appoint a third Arbitrator who shall act as the chairman of the arbitral tribunal. The Arbitration and Conciliation Act, 1996 and the rules made there under shall apply to and shall govern the arbitration proceedings. The venue of the arbitration shall be exclusively at New Delhi and the arbitration proceeding shall be conducted in English. The decision of the Arbitrators shall be final and binding on the Parties.</li>
          </ol>
        </li>
        <li>
          <strong>GOVERNING LAW</strong>
          <ol className="list-decimal pl-6">
            <li>This Agreement shall be governed by and construed in accordance with the laws of India, without giving effect to any choice of law or conflict of law provisions. The Parties consent to the exclusive jurisdiction of courts of Delhi with respect to any matter or claim, suit, action or proceeding arising under or related to this Agreement.</li>
          </ol>
        </li>
        <li>
          <strong>MISCELLANEOUS</strong>
          <ol className="list-decimal pl-6">
            <li>
              <strong>Severability:</strong> The provisions of this Agreement are severable and the validity of remaining Articles, provisions, terms and parts of this Agreement shall not be affected by a court, administrative board or other proceeding of competent jurisdiction deciding that a Clause, provision, term or part of this Agreement is illegal, unenforceable, void, in conflict with any law or contrary to public policy. In such event the Parties shall, by amendment of this Agreement, properly replace such provision by a reasonable new provision or provisions which, as far as legally possible, shall approximate to the closest possible extent what the Parties intended by such original provision and the purpose thereof.
            </li>
            <li>
              <strong>Assignment of Agreement:</strong> Except as permitted or required by this Agreement or as otherwise agreed in writing between the Parties:
              <ol className="list-lower-alpha pl-6">
                <li>This Agreement may not be assigned, novated or transferred; and</li>
                <li>Neither Party shall create or permit to subsist any encumbrance over all or any of its rights and benefits under this Agreement.</li>
              </ol>
            </li>
            <li>
              <strong>Amendment:</strong> No oral or written modification, amendment, rescission, waiver or other change of this Agreement or any of its terms or provisions shall be valid or legally binding on the Parties unless made in writing and duly executed by or on behalf of all the Parties, including without limitation, any purported modification, amendment, rescission, waiver or other change of this itself. No amendments, supplements or modifications to this Agreement shall be made except by written agreement between the Parties.
            </li>
            <li>
              <strong>No Waiver:</strong> A waiver by any Party of any default or defaults by the other Party in the performance of any of the provisions of this Agreement shall be effective only when executed in writing by duly authorized representatives of all Parties. A waiver shall never operate as or be construed as a waiver of any other or further default or defaults whether of a like or different character. Neither the failure by a Party to insist on any occasion upon the performance of the terms, conditions, and provisions of this Agreement nor time or other indulgence granted by a Party to the other or others shall act as a waiver of such breach of acceptance of any variation or the relinquishment of any such right or any other right under this Agreement, which shall remain in full force and effect.
            </li>
            <li>
              <strong>Counterparts:</strong> This Agreement may be executed in one or more counterparts, each of which shall be deemed an original and all of which collectively shall be deemed one and the same instrument.
            </li>
            <li>
              <strong>Notices:</strong> All communication, notices or information to be given under this Agreement shall be so given in writing. All notices must be delivered personally, by registered or certified mail or facsimile to the addresses below:
              <br />
              <br />
              <table className="w-full border">
                <thead>
                  <tr>
                    <th className="p-2 border">
                      <strong>Fastsurance Consultants Pvt. Ltd</strong>
                    </th>
                    <th className="p-2 border">
                      <strong>PARTNER/ AGENT</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="p-2 border">ATTN:</td>
                    <td className="p-2 border">ATTN:</td>
                  </tr>
                  <tr>
                    <td className="p-2 border">Sanjay Aggarwal</td>
                    <td className="p-2 border"></td>
                  </tr>
                  <tr>
                    <td className="p-2 border">Director</td>
                    <td className="p-2 border"></td>
                  </tr>
                  <tr>
                    <td className="p-2 border">A-31, 2nd floor, Infraline Tower,</td>
                    <td className="p-2 border">Director/Authorized Representative</td>
                  </tr>
                  <tr>
                    <td className="p-2 border">Sector 3, Noida, 201301,</td>
                    <td className="p-2 border"></td>
                  </tr>
                  <tr>
                    <td className="p-2 border">
                      <a href="mailto:sanjay@insurancesamadhan.com">sanjay@insurancesamadhan.com</a>
                    </td>
                    <td className="p-2 border"></td>
                  </tr>
                </tbody>
              </table>
              <br />
              All notices shall be effective:
              <ol className="list-decimal pl-6">
                <li>If sent by facsimile, when sent (on receipt of confirmation of the correct number);</li>
                <li>If sent by registered or certified mail, within five (5) days of dispatch; and</li>
                <li>If delivered personally, on receipt by the intended recipient: Provided that, all notices sent by facsimile, shall be confirmed by registered or certified mail. Each Party shall forthwith notify the other Party of any change in its address to which notices under this Agreement are to be delivered, mailed, facsimiled or electronically mailed.</li>
              </ol>
            </li>
            <li>
              <strong>Language:</strong> The language of this Agreement shall be English and all documents, notices, waivers and all other written communication or otherwise between the Parties in connection with this Agreement shall be in English.
            </li>
            <li>
              <strong>Remedies:</strong> Parties acknowledge that damages will not be a sufficient remedy for breach of this Agreement and the Parties shall be entitled to seek specific performance of each other’s obligations pursuant to this Agreement.
            </li>
            <li>
              <strong>Further Assurances:</strong> Each Party agrees to perform (or procure the performance of) all further acts and things, and execute and deliver (or procure the execution and delivery of) such further documents, as may be required by Law or as the other Party may reasonably require, whether on or after Effective Date, to implement and/or give effect to this Agreement.
            </li>
          </ol>
        </li>
      </ol>
      <div className="border p-4 my-4">
        <div>
          Signed and delivered for and on behalf of <strong>Fastsurance Consultants Pvt. Ltd</strong>
        </div>
        <br />
        <div>Signatures:</div>
        <div className="sign">
          <img src={InvoiceSign} alt="signature" className="w-10" />
        </div>
        <br />
        <div>Date: {selectedRow?.agreedOn ? new Date(selectedRow?.agreedOn).toLocaleDateString() : new Date().toLocaleDateString()}</div>
        <hr />
        <div>Signed and delivered for and on behalf of partner/agent</div>
        <div>Name: {selectedRow?.name || ""}</div>
        <div>Signatures:</div>
        <br />
        <div>Authorized Signatory</div>
        <div>Date: {selectedRow?.agreedOn ? new Date(selectedRow?.agreedOn).toLocaleDateString() : new Date().toLocaleDateString()}</div>
      </div>
      <p>
        <strong>Note</strong>: The above terms may change by time without prior notice.
        <br />*<strong>Disclaimer</strong>: Your details may be used by Company for promotion on its various platforms.
      </p>
      <div className="space"></div>
    </div>
  );
};

const NoRevenueSharingSecondType: FC<any> = ({ selectedRow }) => {
  return (
    <div className="px-6 py-8 text-gray-800 text-sm leading-6">
      <ol className="list-decimal pl-6">
        <p>
          This <strong>AGREEMENT FOR BUSINESS SUPPORT SERVICES</strong> (hereinafter this <strong>“Agreement”</strong>) is made on this {selectedRow?.agreedOn ? new Date(selectedRow?.agreedOn).toLocaleDateString() : new Date().toLocaleDateString()} (the <strong>“Execution Date”</strong>) by and between:
        </p>

        <ol className="list-decimal pl-6">
          <li className="mb-4">
            <strong>I, {selectedRow?.name || ""}, authorised insurance representative (hereinafter referred to as PARTNER</strong> which expression shall, unless repugnant to or inconsistent with the context, mean and include any successors or permitted assigns) of the <strong>FIRST PART</strong>
          </li>
          <li className="mb-4">
            <strong>I, Sanjay Aggarwal, Co-Founder of INSURANCE SAMADHAN (hereunder mentioned as FASTSURANCE CONSULTANTS PVT. LTD.)</strong>, a company incorporated in India and having its registered office at A-31, 2nd floor, Infraline Tower, Sector 3, Noida, 201301 India (hereinafter referred to as the <strong>“Company”</strong>, which expression shall, unless repugnant to or inconsistent with the context, mean and include any successors or permitted assigns) of the <strong>SECOND PART</strong>; and
            <br />
            <strong>“The Company”</strong> and <strong>“PARTNER”</strong> are jointly referred to as “Parties” and individually as “Party”
          </li>
        </ol>

        <ol className="list-decimal pl-6">
          <p>
            <strong>WHEREAS</strong>
          </p>

          <ol className="list-decimal pl-6">
            <li className="mb-4">The Company is interalia engaged in the business of providing services of various nature in the field of Life, Health and General Insurance, such as assistance in resolving consumer grievances by representing clients at various forums like Insurance Companies, IRDA, Ombudsman, Consumer Forums, Legal Courts. The overall mission of Insurance Samadhan is centered towards social cause and the betterment/upliftment of the general impression of the insurance companies.</li>
            <li className="mb-4">Partner is engaged in {selectedRow?.natureOfBusiness || ""} services.</li>
          </ol>
        </ol>

        <p>
          <strong>NOW, IN CONSIDERATION OF THE ABOVE PREMISES AND MUTUAL COVENANTS CONTAINED HEREINAFTER THIS AGREEMENT WITNESSETH AS UNDER:-</strong>
        </p>

        <ol className="list-decimal pl-6">
          <li className="mb-4">
            <strong>SERVICES</strong>
            <ol className="list-decimal pl-6">
              <li>Company agrees to provide resolution of insurance related grievances to clients/customers approached through Partner/ Agent on such consideration as defined in the present agreement and Partner / Agent has agreed to generate customer registrations/ leads for Company.</li>
            </ol>
          </li>

          <li className="mb-4">
            <strong>TERM</strong>
            <ol className="list-decimal pl-6">
              <li>
                This Agreement shall become effective from the date of execution of this Agreement (<strong>“Effective Date”</strong>) and shall remain valid and binding on the Parties for a period of 1 (one) year from the Effective Date (“Term”), unless terminated earlier in accordance with the terms hereof.
              </li>
              <li>Upon expiry of this Agreement, the Parties may extend the duration of this Agreement subject to mutually agreed terms and conditions.</li>
            </ol>
          </li>

          <li className="mb-4">
            <strong>STANDARD OF PERFORMANCE</strong>
            <ol className="list-decimal pl-6">
              <li>
                PARTNER/AGENT shall:
                <ol className="list-decimal pl-6">
                  <li>PARTNER/AGENT is a knowledge partner and shouldn’t be accountable for any lapses or liability by claimant / clients of the Company</li>
                </ol>
              </li>
            </ol>
          </li>

          <li className="mb-4">
            <strong>REPRESENTATIONS AND WARRANTIES</strong>
            <ol className="list-decimal pl-6">
              <li>
                Each Party represents and warrants to the other Party that:
                <ol className="list-decimal pl-6">
                  <li>It is a company duly and legally organized and validly existing under the laws of India;</li>
                  <li>This Agreement constitutes its valid and legally binding obligations, enforceable in accordance with its terms;</li>
                  <li>It has full corporate power and authority to execute and deliver this Agreement in accordance with its terms and to perform all its duties and obligations arising or created under or pursuant to this Agreement and all requisite corporate approvals, as applicable, have been obtained by it prior to the execution of this Agreement; and</li>
                  <li>The execution of this Agreement and delivery and performance by it of its obligations hereunder do not and shall not violate or conflict in any manner with or result in a breach or default under Applicable Law or any of its duties or obligations under any agreement, understanding or arrangement, written or oral, to which it is a party.</li>
                </ol>
              </li>
            </ol>
          </li>

          <li className="mb-4">
            <strong>TERMS OF CONSIDERATION</strong>
            <ol className="list-decimal pl-6">
              <li>The Company is not responsible for resolution of any case even after acceptance as Insurance resolution is a result of many factors which cannot be deemed as final at any stage of the case.</li>
              <li>The Partner undertakes to pay {selectedRow?.successFeeRate || "0"}% + GST (herein referred to as "Fees") of the aggregate amount within 7 (seven) days from the date the Company raise invoice after the successful resolution of the case.</li>
            </ol>
          </li>

          <li className="mb-4">
            <strong>TERMINATION</strong>
            <ol className="list-decimal pl-6">
              <li>This Agreement may be terminated by either Party without assigning any reason at any time by giving 1 (one) month prior written notice to the other Party.</li>
              <li>
                Without prejudice to any other rights or remedies available in law or under this Agreement, either Party may terminate this Agreement forthwith on or at any time after the occurrence of any of the events specified herein below:
                <ol className="list-decimal pl-6">
                  <li>Insolvency of the other Party;</li>
                  <li>Material breach of any of the terms or conditions of this Agreement by the other Party which breach is not remedied by such other Party to the satisfaction of the non-breaching Party within three (3) days of notice of the breach; and</li>
                  <li>If a petition for winding up of the other Party is decreed in any Court or a resolution is passed for winding-up the business of such other Party or if any receiver, manager, liquidator, administrator or other similar official is appointed to the other Party or a substantial part of the other Party’s property.</li>
                </ol>
              </li>
            </ol>
          </li>

          <li className="mb-4">
            <strong>INDEPENDENT CONTRACTOR</strong>
            <ol className="list-decimal pl-6">
              <li>It is hereby acknowledged that Partner/ Agent shall perform the services in the capacity of an independent contractor and not as an employee, servant or agent of the Company. Nothing contained herein shall be construed to create a relationship of employer-employee and master-servant between Company and Partner/ Agent.</li>
            </ol>
          </li>

          <li className="mb-4">
            <strong>INTELLECTUAL PROPERTY</strong>
            <ol className="list-decimal pl-6">
              <li>The Company does not grant to Partner/ Agent any right, title or interest in any of its intellectual property except as expressly authorised in writing by the Company and Partner shall not have any right, title or interest in Company’s intellectual property other than the right to use it for purposes of this Agreement for the Term hereof. Partner shall comply with any and all instructions issued by Company in relation to the display of any logo, trademark, copyright or any other intellectual property of the Company. Upon expiry or earlier termination of this Agreement, Partner shall immediately cease and desist for all times from any use of or reference to Company’s intellectual property and shall return to Company copies or materials containing such intellectual property.</li>
              <li>
                Parties hereby acknowledge and confirm that:
                <ol className="list-decimal pl-6">
                  <li>All Intellectual Property Rights in or relating to the Services are and shall at all times remain the property of Company and / or its licensors;</li>
                  <li>Partner/Agent shall notify Company immediately if Partner becomes aware of any illegal or unauthorized use of any the intellectual property therein or relating thereto and will assist Company in taking all steps necessary to protect and defend Company’s rights therein.</li>
                </ol>
              </li>
              <li>The provisions of this Clause 8 shall survive the termination of this Agreement.</li>
            </ol>
          </li>

          <li className="mb-4">
            <strong>CONFIDENTIALITY</strong>
            <ol className="list-decimal pl-6">
              <li>
                The Company acknowledge that in the course of performing their obligations under this Agreement, they may get access to confidential information (<strong>“Confidential Information”</strong>) of the clients/customers registered through Partner/Agent. Herein, Company agrees not to disclose Confidential Information received (whether in writing, verbally or by any other means and whether directly or indirectly) by the clients/customers including in relation to matters contemplated by this Agreement or its performance or in any agreement in furtherance of the performance of this Agreement, to any Third Party without the prior written consent of Partner/Agent or use the Confidential Information other than for carrying out the purposes of this Agreement.
              </li>
              <li>Company shall keep confidential any Confidential Information it receives from the clients/customers and shall employ all such reasonable steps that it would have taken to protect its own Confidential Information. The Company shall disclose Confidential Information received from the clients/customers to its officers, employees, agents or representatives only for the purposes of carrying out its obligations under this Agreement and strictly on a “need to know” basis only. The Company shall also ensure that their respective directors, employees, officers and any other Persons to whom the above-mentioned information is disclosed to be bound by confidentiality obligations similar to those provided in this Clause.</li>
              <li>The Company shall not disclose any material information about clients/customers including but not limited to name, contact details, methodology of work, and approach of handling a claim etc. to third party.</li>
              <li>
                During the term of this agreement or One year from the termination of the same, the Company shall hold the Confidential Information received from the clients and shall not disclose or disseminate at any time, to any other person without the prior consent/permission of the client. Notwithstanding anything contained in above mentioned clause, Company’s obligation of confidentiality shall not extend to any Confidential Information:
                <ol className="list-decimal pl-6">
                  <li>received from the clients is in the public domain other than by breach of this Agreement;</li>
                  <li>to the extent that the Confidential Information received is required to be disclosed by any applicable Law or by any Authority to whose jurisdiction the Company is subject or with whose instructions it is customary to comply under notice to the Partner; or</li>
                  <li>In so far as it is disclosed to the employees, directors or professional advisers of the Receiving Party, provided that the Receiving Party shall procure that such persons treat the Confidential Information received as confidential.</li>
                </ol>
              </li>
            </ol>
          </li>

          <li className="mb-4">
            <strong>Indemnification</strong>
            <ol className="list-decimal pl-6">
              <li>In the event that Company is found to be in breach of terms of this Agreement, Company at all times hereafter indemnify and keep the client (“Indemnified Party”) fully indemnified against all claims, demands, actions, proceedings, losses, damages, costs, charges, expenses, interests and disbursements of any nature whatsoever which the Indemnified Party may pay or incur or suffer or sustain or be liable to pay or incur or suffer or sustain as a result or consequence, direct or indirect, of such breach.</li>
            </ol>
          </li>

          <li className="mb-4">
            <strong>Disputes</strong>
            <ol className="list-decimal pl-6">
              <li>In the event of disputes, differences, claims and questions between the Parties hereto arising out of this Agreement or in any way relating hereto or any term, condition or provision herein mentioned or the construction or interpretation thereof or otherwise in relation hereto, the Parties shall first endeavor to settle such differences, disputes, claims or questions by arbitration of three (3) arbitrators, one each to be appointed by each Party, and each such arbitrator shall appoint a third Arbitrator who shall act as the chairman of the arbitral tribunal. The Arbitration and Conciliation Act, 1996 and the rules made there under shall apply to and shall govern the arbitration proceedings. The venue of the arbitration shall be exclusively at New Delhi and the arbitration proceeding shall be conducted in English. The decision of the Arbitrators shall be final and binding on the Parties.</li>
            </ol>
          </li>

          <li className="mb-4">
            <strong>GOVERNING LAW</strong>
            <ol className="list-decimal pl-6">
              <li>This Agreement shall be governed by and construed in accordance with the laws of India, without giving effect to any choice of law or conflict of law provisions. The Parties consent to the exclusive jurisdiction of courts of Delhi with respect to any matter or claim, suit, action or proceeding arising under or related to this Agreement.</li>
            </ol>
          </li>

          <li className="mb-4">
            <strong>MISCELLANEOUS</strong>
            <ol className="list-decimal pl-6">
              <li>
                <strong>Severability:</strong> The provisions of this Agreement are severable and the validity of remaining Articles, provisions, terms and parts of this Agreement shall not be affected by a court, administrative board or other proceeding of competent jurisdiction deciding that a Clause, provision, term or part of this Agreement is illegal, unenforceable, void, in conflict with any law or contrary to public policy. In such event the Parties shall, by amendment of this Agreement, properly replace such provision by a reasonable new provision or provisions which, as far as legally possible, shall approximate to the closest possible extent what the Parties intended by such original provision and the purpose thereof.
              </li>
              <li>
                <strong>Assignment of Agreement:</strong> Except as permitted or required by this Agreement or as otherwise agreed in writing between the Parties:
                <ol className="list-decimal pl-6">
                  <li>This Agreement may not be assigned, novated or transferred; and</li>
                  <li>Neither Party shall create or permit to subsist any encumbrance over all or any of its rights and benefits under this Agreement.</li>
                </ol>
              </li>
              <li>
                <strong>Amendment:</strong> No oral or written modification, amendment, rescission, waiver or other change of this Agreement or any of its terms or provisions shall be valid or legally binding on the Parties unless made in writing and duly executed by or on behalf of all the Parties, including without limitation, any purported modification, amendment, rescission, waiver or other change of this itself. No amendments, supplements or modifications to this Agreement shall be made except by written agreement between the Parties.
              </li>
              <li>
                <strong>No Waiver:</strong> A waiver by any Party of any default or defaults by the other Party in the performance of any of the provisions of this Agreement shall be effective only when executed in writing by duly authorized representatives of all Parties. A waiver shall never operate as or be construed as a waiver of any other or further default or defaults whether of a like or different character. Neither the failure by a Party to insist on any occasion upon the performance of the terms, conditions, and provisions of this Agreement nor time or other indulgence granted by a Party to the other or others shall act as a waiver of such breach of acceptance of any variation or the relinquishment of any such right or any other right under this Agreement, which shall remain in full force and effect.
              </li>
              <li>
                <strong>Counterparts:</strong> This Agreement may be executed in one or more counterparts, each of which shall be deemed an original and all of which collectively shall be deemed one and the same instrument.
              </li>
              <li>
                <strong>Notices:</strong> All communication, notices or information to be given under this Agreement shall be so given in writing. All notices must be delivered personally, by registered or certified mail or facsimile to the addresses below:
                <br />
                <br />
                <table className="table-auto border-collapse w-full">
                  <thead>
                    <tr>
                      <th className="border p-2">
                        <strong>Fastsurance Consultants Pvt. Ltd</strong>
                      </th>
                      <th className="border p-2">
                        <strong>PARTNER/ AGENT</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="p-2 border">ATTN:</td>
                      <td className="p-2 border">ATTN:</td>
                    </tr>
                    <tr>
                      <td className="p-2 border">Sanjay Aggarwal</td>
                      <td className="p-2 border"></td>
                    </tr>
                    <tr>
                      <td className="p-2 border">Director</td>
                      <td className="p-2 border">
                        <hr className="border-none h-px" />
                      </td>
                    </tr>
                    <tr>
                      <td className="p-2 border">A-31, 2nd floor, Infraline Tower,</td>
                      <td className="p-2 border">Director/Authorized Representative</td>
                    </tr>
                    <tr>
                      <td className="p-2 border">Sector 3, Noida, 201301,</td>
                      <td className="p-2 border"></td>
                    </tr>
                    <tr>
                      <td className="p-2 border">
                        <a href="mailto:sanjay@insurancesamadhan.com">sanjay@insurancesamadhan.com</a>
                      </td>
                      <td className="p-2 border"></td>
                    </tr>
                  </tbody>
                </table>
                <br />
                All notices shall be effective:
                <ol className="list-decimal pl-6">
                  <li>If sent by facsimile, when sent (on receipt of confirmation of the correct number);</li>
                  <li>If sent by registered or certified mail, within five (5) days of dispatch; and</li>
                  <li>If delivered personally, on receipt by the intended recipient: Provided that, all notices sent by facsimile, shall be confirmed by registered or certified mail. Each Party shall forthwith notify the other Party of any change in its address to which notices under this Agreement are to be delivered, mailed, facsimiled or electronically mailed.</li>
                </ol>
              </li>
              <li>
                <strong>Language:</strong> The language of this Agreement shall be English and all documents, notices, waivers and all other written communication or otherwise between the Parties in connection with this Agreement shall be in English.
              </li>
              <li>
                <strong>Remedies:</strong> Parties acknowledge that damages will not be a sufficient remedy for breach of this Agreement and the Parties shall be entitled to seek specific performance of each other’s obligations pursuant to this Agreement.
              </li>
              <li>
                <strong>Further Assurances:</strong> Each Party agrees to perform (or procure the performance of) all further acts and things, and execute and deliver (or procure the execution and delivery of) such further documents, as may be required by Law or as the other Party may reasonably require, whether on or after Effective Date, to implement and/or give effect to this Agreement.
              </li>
            </ol>
          </li>
        </ol>
        <div className="border p-4 my-4">
          <div>
            Signed and delivered for and on behalf of <strong>Fastsurance Consultants Pvt. Ltd</strong>
          </div>
          <br />
          <div>Signatures:</div>
          <div className="sign">
            <img src={InvoiceSign} alt="signature" className="w-10" />
          </div>
          <br />
          <div>Date: {new Date().toLocaleDateString()}</div>
          <hr />
          <div>Signed and delivered for and on behalf of partner/agent</div>
          <div>Name: {selectedRow?.name || ""}</div>
          <div>Signatures:</div>
          <br />
          <div>Authorized Signatory</div>
          <div>Date: {new Date().toLocaleDateString()}</div>
        </div>
      </ol>
      <p>
        <strong>Note</strong>: The above terms may change by time without prior notice.
        <br />*<strong>Disclaimer</strong>: Your details may be used by Company for promotion on its various platforms.
      </p>
      <div className="space"></div>
    </div>
  );
};

const HierarchyWiseLevelZero: FC<any> = ({ selectedRow }) => {
  return (
    <div className="px-6 py-8 text-gray-800 text-sm leading-6">
      <ol>
        <p>
          This <strong>AGREEMENT FOR BUSINESS SUPPORT SERVICES</strong> (hereinafter this
          <strong>“Agreement”</strong>) is made on this {selectedRow?.agreedOn ? new Date(selectedRow?.agreedOn).toLocaleDateString() : new Date().toLocaleDateString()} (the <strong>“Execution Date”</strong>) by and between:
        </p>

        <ol className="list-decimal pl-6">
          <li className="mb-4">
            <strong>I, {selectedRow?.name || ""}, authorised insurance representative (hereinafter referred to as PARTNER</strong>
            which expression shall, unless repugnant to or inconsistent with the context, mean and include any successors or permitted assigns) of the <strong>FIRST PART</strong>
          </li>
          <li className="mb-4">
            <strong>I, Sanjay Aggarwal, Co-Founder of INSURANCE SAMADHAN (hereunder mentioned as FASTSURANCE CONSULTANTS PVT. LTD.)</strong>, a company incorporated in India and having its registered office at A-31, 2nd floor, Infraline Tower, Sector 3, Noida, 201301 India (hereinafter referred to as the
            <strong>“Company”</strong>, which expression shall, unless repugnant to or inconsistent with the context, mean and include any successors or permitted assigns) of the <strong>SECOND PART</strong>; and
            <br />
            <strong>“The Company”</strong> and <strong>“PARTNER”</strong> are jointly referred to as “Parties” and individually as “Party”
          </li>
        </ol>

        <ol>
          <p>
            <strong>WHEREAS</strong>
          </p>

          <ol className="list-decimal pl-6" type="A">
            <li>The Company is interalia engaged in the business of providing services of various nature in the field of Life, Health and General Insurance, such as assistance in resolving consumer grievances by representing clients at various forums like Insurance Companies, IRDA, Ombudsman, Consumer Forums, Legal Courts. The overall mission of Insurance Samadhan is centered towards social cause and the betterment/upliftment of the general impression of the insurance companies.</li>
            <li>Partner is engaged in {selectedRow?.natureOfBusiness || ""} services.</li>
          </ol>
        </ol>

        <p>
          <strong>NOW, IN CONSIDERATION OF THE ABOVE PREMISES AND MUTUAL COVENANTS CONTAINED HEREINAFTER THIS AGREEMENT WITNESSETH AS UNDER:-</strong>
        </p>

        <ol className="list-decimal pl-6">
          <li>
            <strong>SERVICES</strong>
            <ol className="list-decimal pl-6">
              <li>Company agrees to provide resolution of insurance related grievances to clients/customers approached through Partner on such consideration as defined in the present agreement and Partner has agreed to generate customer registrations/ leads for Company.</li>
            </ol>
          </li>

          <li>
            <strong>TERM</strong>
            <ol className="list-decimal pl-6">
              <li>
                This Agreement shall become effective from the date of execution of this Agreement (<strong>“Effective Date”</strong>) and shall remain valid and binding on the Parties for a period of 1 (one) year from the Effective Date (“Term”), unless terminated earlier in accordance with the terms hereof.
              </li>
              <li>Upon expiry of this Agreement, the Parties may extend the duration of this Agreement subject to mutually agreed terms and conditions.</li>
            </ol>
          </li>

          <li>
            <strong>STANDARD OF PERFORMANCE</strong>
            <ol className="list-decimal pl-6">
              <li>
                PARTNER shall:
                <ol className="list-decimal pl-6" type="i">
                  <li>PARTNER is a knowledge partner and shouldn’t be accountable for any lapses or liability by claimant / clients of the Company</li>
                </ol>
              </li>
            </ol>
          </li>

          <li>
            <strong>REPRESENTATIONS AND WARRANTIES</strong>
            <ol className="list-decimal pl-6">
              <li>
                Each Party represents and warrants to the other Party that:
                <ol className="list-decimal pl-6" type="a">
                  <li>It is a company duly and legally organized and validly existing under the laws of India;</li>
                  <li>This Agreement constitutes its valid and legally binding obligations, enforceable in accordance with its terms;</li>
                  <li>It has full corporate power and authority to execute and deliver this Agreement in accordance with its terms and to perform all its duties and obligations arising or created under or pursuant to this Agreement and all requisite corporate approvals, as applicable, have been obtained by it prior to the execution of this Agreement; and</li>
                  <li>The execution of this Agreement and delivery and performance by it of its obligations hereunder do not and shall not violate or conflict in any manner with or result in a breach or default under Applicable Law or any of its duties or obligations under any agreement, understanding or arrangement, written or oral, to which it is a party.</li>
                </ol>
              </li>
            </ol>
          </li>

          <li>
            <strong>TERMS OF CONSIDERATION</strong>
            <ol className="list-decimal pl-6">
              <li>The Company will share pre decided commercials only in case of genuine leads which are coming. Any other method is not viable under current scenario and can be discussed and amended as and when it arises.</li>
              <li>The Company is not responsible for resolution of any case even after acceptance as Insurance resolution is a result of many factors which cannot be deemed as final at any stage of the case.</li>
              <li>The Company hereby agrees and undertakes to pay {selectedRow?.partnerPercent || "0"}% of the aggregate amount transferred to the customers/clients, to Partner after the 5% deduction (TDS) on successful completion of the services.</li>
              <li>In the event of client’s case move to legal/court, 2.5% on the resolution amount received by the client on the successful outcome of the case shall be paid to the legal partner and will be proportionately adjusted from the fee percentage i.e., {selectedRow?.partnerPercent || "0"}% being paid to PARTNER on the resolution amount received by the client on successful outcome of the case.</li>
              <li>The Partner can further onboard Associates and Advisors under him for generation of customer registrations/ leads for Company. The Company hereby agrees and undertakes to pay the below mentioned revenue share based on the registration source:</li>
              <div className="overflow-x-auto mt-4 mb-4">
                <table className="table-auto border-collapse border border-black w-full">
                  <thead className="bg-gray-200">
                    <tr>
                      <th className="border border-black px-4 py-2">Registration Source</th>
                      <th className="border border-black px-4 py-2">Revenue Share</th>
                      <th className="border border-black px-4 py-2">Payable to</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border border-black px-4 py-2">Partner (Self)</td>
                      <td className="border border-black px-4 py-2">{selectedRow?.partnerPercent || "0"}%</td>
                      <td className="border border-black px-4 py-2">Partner</td>
                    </tr>
                    <tr>
                      <td className="border border-black px-4 py-2">Associate</td>
                      <td className="border border-black px-4 py-2">Associate contract %</td>
                      <td className="border border-black px-4 py-2">Associate</td>
                    </tr>
                    <tr>
                      <td className="border border-black px-4 py-2">Advisor</td>
                      <td className="border border-black px-4 py-2">Advisor contract %</td>
                      <td className="border border-black px-4 py-2">Advisor</td>
                    </tr>
                    <tr>
                      <td className="border border-black px-4 py-2">Associate</td>
                      <td className="border border-black px-4 py-2">{selectedRow?.partnerPercent || "0"}% minus Associate contract %</td>
                      <td className="border border-black px-4 py-2">Partner</td>
                    </tr>
                    <tr>
                      <td className="border border-black px-4 py-2">Advisor</td>
                      <td className="border border-black px-4 py-2">{selectedRow?.partnerPercent || "0"}% minus Associate contract % minus Advisor contract %</td>
                      <td className="border border-black px-4 py-2">Partner</td>
                    </tr>
                    <tr>
                      <td className="border border-black px-4 py-2">Advisor</td>
                      <td className="border border-black px-4 py-2">Associate contract % minus Advisor contract %</td>
                      <td className="border border-black px-4 py-2">Associate</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <li>The compensation shall be paid in terms of the mutual agreement between the Parties post the resolution amount is received by the client on successful outcome of the case.</li>
            </ol>
          </li>

          <li>
            <strong>TERMINATION</strong>
            <ol className="list-decimal pl-6">
              <li>This Agreement may be terminated by either Party without assigning any reason at any time by giving 1 (one) month prior written notice to the other Party.</li>
              <li>
                Without prejudice to any other rights or remedies available in law or under this Agreement, either Party may terminate this Agreement forthwith on or at any time after the occurrence of any of the events specified herein below:
                <ol className="list-decimal pl-6" type="a">
                  <li>Insolvency of the other Party;</li>
                  <li>Material breach of any of the terms or conditions of this Agreement by the other Party which breach is not remedied by such other Party to the satisfaction of the non-breaching Party within three (3) days of notice of the breach; and</li>
                  <li>If a petition for winding up of the other Party is decreed in any Court or a resolution is passed for winding-up the business of such other Party or if any receiver, manager, liquidator, administrator or other similar official is appointed to the other Party or a substantial part of the other Party’s property.</li>
                </ol>
              </li>
            </ol>
          </li>

          <li>
            <strong>INDEPENDENT CONTRACTOR</strong>
            <ol className="list-decimal pl-6">
              <li>It is hereby acknowledged that Partner shall perform the services in the capacity of an independent contractor and not as an employee, servant or agent of the Company. Nothing contained herein shall be construed to create a relationship of employer-employee and master-servant between Company and Partner.</li>
            </ol>
          </li>

          <li>
            <strong>INTELLECTUAL PROPERTY</strong>
            <ol className="list-decimal pl-6">
              <li>The Company does not grant to Partner any right, title or interest in any of its intellectual property except as expressly authorised in writing by the Company and Partner shall not have any right, title or interest in Company’s intellectual property other than the right to use it for purposes of this Agreement for the Term hereof. Partner shall comply with any and all instructions issued by Company in relation to the display of any logo, trademark, copyright or any other intellectual property of the Company. Upon expiry or earlier termination of this Agreement, Partner Insurance shall immediately cease and desist for all times from any use of or reference to Company’s intellectual property and shall return to Company copies or materials containing such intellectual property.</li>
              <li>
                Parties hereby acknowledge and confirm that:
                <ol className="list-decimal pl-6" type="a">
                  <li>All Intellectual Property Rights in or relating to the Services are and shall at all times remain the property of Company and / or its licensors;</li>
                  <li>Partner shall notify Company immediately if they become aware of any illegal or unauthorized use of any the intellectual property therein or relating thereto and will assist Company in taking all steps necessary to protect and defend Company’s rights therein.</li>
                </ol>
              </li>
              <li>The provisions of this Clause 8 shall survive the termination of this Agreement.</li>
            </ol>
          </li>

          <li>
            <strong>CONFIDENTIALITY</strong>
            <ol className="list-decimal pl-6">
              <li>The Company acknowledge that in the course of performing their obligations under this Agreement, they may get access to confidential information (“Confidential Information”) of the clients/customers registered through Partner. Herein, Company agrees not to disclose Confidential Information received (whether in writing, verbally or by any other means and whether directly or indirectly) by the clients/customers including in relation to matters contemplated by this Agreement or its performance or in any agreement in furtherance of the performance of this Agreement, to any Third Party without the prior written consent of Partner or use the Confidential Information other than for carrying out the purposes of this Agreement.</li>
              <li>Company shall keep confidential any Confidential Information it receives from the clients/customers and shall employ all such reasonable steps that it would have taken to protect its own Confidential Information. The Company shall disclose Confidential Information received from the clients/customers to its officers, employees, agents or representatives only for the purposes of carrying out its obligations under this Agreement and strictly on a “need to know” basis only. The Company shall also ensure that their respective directors, employees, officers and any other Persons to whom the above-mentioned information is disclosed to be bound by confidentiality obligations similar to those provided in this Clause.</li>
              <li>The Company shall not disclose any material information about clients/customers including but not limited to name, contact details, methodology of work, and approach of handling a claim etc. to third party.</li>
              <li>
                During the term of this agreement or One year from the termination of the same, the Company shall hold the Confidential Information received from the clients and shall not disclose or disseminate at any time, to any other person without the prior consent/permission of the client. Notwithstanding anything contained in above mentioned clause, Company’s obligation of confidentiality shall not extend to any Confidential Information:
                <ol className="list-decimal pl-6" type="a">
                  <li>received from the clients is in the public domain other than by breach of this Agreement;</li>
                  <li>to the extent that the Confidential Information received is required to be disclosed by any applicable Law or by any Authority to whose jurisdiction the Company is subject or with whose instructions it is customary to comply under notice to the Partner; or</li>
                  <li>In so far as it is disclosed to the employees, directors or professional advisers of the Receiving Party, provided that the Receiving Party shall procure that such persons treat the Confidential Information received as confidential.</li>
                </ol>
              </li>
            </ol>
          </li>

          <li>
            <strong>INDEMNIFICATION</strong>
            <ol className="list-decimal pl-6">
              <li>In the event that Company is found to be in breach of terms of this Agreement, Company at all times hereafter indemnify and keep the client (“Indemnified Party”) fully indemnified against all claims, demands, actions, proceedings, losses, damages, costs, charges, expenses, interests and disbursements of any nature whatsoever which the Indemnified Party may pay or incur or suffer or sustain or be liable to pay or incur or suffer or sustain as a result or consequence, direct or indirect, of such breach.</li>
            </ol>
          </li>

          <li>
            <strong>DISPUTES</strong>
            <ol className="list-decimal pl-6">
              <li>In the event of disputes, differences, claims and questions between the Parties hereto arising out of this Agreement or in any way relating hereto or any term, condition or provision herein mentioned or the construction or interpretation thereof or otherwise in relation hereto, the Parties shall first endeavor to settle such differences, disputes, claims or questions by arbitration of three (3) arbitrators, one each to be appointed by each Party, and each such arbitrator shall appoint a third Arbitrator who shall act as the chairman of the arbitral tribunal. The Arbitration and Conciliation Act, 1996 and the rules made there under shall apply to and shall govern the arbitration proceedings. The venue of the arbitration shall be exclusively at New Delhi and the arbitration proceeding shall be conducted in English. The decision of the Arbitrators shall be final and binding on the Parties.</li>
            </ol>
          </li>

          <li>
            <strong>GOVERNING LAW</strong>
            <ol className="list-decimal pl-6">
              <li>This Agreement shall be governed by and construed in accordance with the laws of India, without giving effect to any choice of law or conflict of law provisions. The Parties consent to the exclusive jurisdiction of courts of Delhi with respect to any matter or claim, suit, action or proceeding arising under or related to this Agreement.</li>
            </ol>
          </li>

          <li>
            <strong>MISCELLANEOUS</strong>
            <ol className="list-decimal pl-6">
              <li>
                <strong>Severability:</strong> The provisions of this Agreement are severable and the validity of remaining Articles, provisions, terms and parts of this Agreement shall not be affected by a court, administrative board or other proceeding of competent jurisdiction deciding that a Clause, provision, term or part of this Agreement is illegal, unenforceable, void, in conflict with any law or contrary to public policy. In such event the Parties shall, by amendment of this Agreement, properly replace such provision by a reasonable new provision or provisions which, as far as legally possible, shall approximate to the closest possible extent what the Parties intended by such original provision and the purpose thereof.
              </li>
              <li>
                <strong>Assignment of Agreement:</strong> Except as permitted or required by this Agreement or as otherwise agreed in writing between the Parties:
                <ol className="list-decimal pl-6" type="a">
                  <li>This Agreement may not be assigned, novated or transferred; and</li>
                  <li>Neither Party shall create or permit to subsist any encumbrance over all or any of its rights and benefits under this Agreement.</li>
                </ol>
              </li>
              <li>
                <strong>Amendment:</strong> No oral or written modification, amendment, rescission, waiver or other change of this Agreement or any of its terms or provisions shall be valid or legally binding on the Parties unless made in writing and duly executed by or on behalf of all the Parties, including without limitation, any purported modification, amendment, rescission, waiver or other change of this itself. No amendments, supplements or modifications to this Agreement shall be made except by written agreement between the Parties.
              </li>
              <li>
                <strong>No Waiver:</strong> A waiver by any Party of any default or defaults by the other Party in the performance of any of the provisions of this Agreement shall be effective only when executed in writing by duly authorized representatives of all Parties. A waiver shall never operate as or be construed as a waiver of any other or further default or defaults whether of a like or different character. Neither the failure by a Party to insist on any occasion upon the performance of the terms, conditions, and provisions of this Agreement nor time or other indulgence granted by a Party to the other or others shall act as a waiver of such breach of acceptance of any variation or the relinquishment of any such right or any other right under this Agreement, which shall remain in full force and effect.
              </li>
              <li>
                <strong>Counterparts:</strong> This Agreement may be executed in one or more counterparts, each of which shall be deemed an original and all of which collectively shall be deemed one and the same instrument.
              </li>
              <li>
                <strong>Notices:</strong> : All communication, notices or information to be given under this Agreement shall be so given in writing. All notices must be delivered personally, by registered or certified mail or facsimile to the addresses below:
                <br />
                <br />
                <table className="border-collapse border border-black w-full">
                  <thead>
                    <tr>
                      <th className="border border-black px-4 py-2">
                        <strong>Fastsurance Consultants Pvt. Ltd</strong>
                      </th>
                      <th className="border border-black px-4 py-2">
                        <strong>PARTNER/ AGENT</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border border-black px-4 py-2">ATTN:</td>
                      <td className="border border-black px-4 py-2">ATTN:</td>
                    </tr>
                    <tr>
                      <td className="border border-black px-4 py-2">Sanjay Aggarwal</td>
                      <td className="border border-black px-4 py-2"></td>
                    </tr>
                    <tr>
                      <td className="border border-black px-4 py-2">Director</td>
                      <td className="border border-black px-4 py-2"></td>
                    </tr>
                    <tr>
                      <td className="border border-black px-4 py-2">A-31, 2nd floor, Infraline Tower,</td>
                      <td className="border border-black px-4 py-2">Director/Authorized Representative</td>
                    </tr>
                    <tr>
                      <td className="border border-black px-4 py-2">Sector 3, Noida, 201301,</td>
                      <td className="border border-black px-4 py-2"></td>
                    </tr>
                    <tr>
                      <td className="border border-black px-4 py-2">
                        <a href="mailto:sanjay@insurancesamadhan.com">sanjay@insurancesamadhan.com</a>
                      </td>
                      <td className="border border-black px-4 py-2"></td>
                    </tr>
                  </tbody>
                </table>
                <br />
                All notices shall be effective:
                <ol className="list-decimal pl-6">
                  <li>If sent by facsimile, when sent (on receipt of confirmation of the correct number);</li>
                  <li>If sent by registered or certified mail, within five (5) days of dispatch; and</li>
                  <li>If delivered personally, on receipt by the intended recipient: Provided that, all notices sent by facsimile, shall be confirmed by registered or certified mail. Each Party shall forthwith notify the other Party of any change in its address to which notices under this Agreement are to be delivered, mailed, facsimiled or electronically mailed.</li>
                </ol>
              </li>
              <li>
                <strong>Language:</strong> The language of this Agreement shall be English and all documents, notices, waivers and all other written communication or otherwise between the Parties in connection with this Agreement shall be in English.
              </li>
              <li>
                <strong>Remedies:</strong> Parties acknowledge that damages will not be a sufficient remedy for breach of this Agreement and the Parties shall be entitled to seek specific performance of each other’s obligations pursuant to this Agreement.
              </li>
              <li>
                <strong>Further Assurances:</strong> Each Party agrees to perform (or procure the performance of) all further acts and things, and execute and deliver (or procure the execution and delivery of) such further documents, as may be required by Law or as the other Party may reasonably require, whether on or after Effective Date, to implement and/or give effect to this Agreement.
              </li>
            </ol>
            <br />
            <strong>IN WITNESS WHEREOF</strong> the Parties have executed these presents through their authorized representatives on the Execution Date.
            <br />
            <strong>IN WITNESS WHEREOF</strong>, the Parties hereto have set and subscribed their hands through their respective duly authorized representatives as of the date first above written.
          </li>
        </ol>
      </ol>
      <div className="border p-4 my-4">
        <div>
          Signed and delivered for and on behalf of <strong>Fastsurance Consultants Pvt. Ltd</strong>
        </div>
        <br />
        <div>Signatures:</div>
        <div className="sign">
          <img src={InvoiceSign} alt="signature" className="w-10" />
        </div>
        <br />
        <div>Date: {selectedRow?.agreedOn ? new Date(selectedRow?.agreedOn).toLocaleDateString() : new Date().toLocaleDateString()}</div>
        <hr />
        <div>Signed and delivered for and on behalf of partner/agent</div>
        <div>Name: {selectedRow?.name || ""}</div>
        <div>Signatures:</div>
        <br />
        <div>Authorized Signatory</div>
        <div>Date: {selectedRow?.agreedOn ? new Date(selectedRow?.agreedOn).toLocaleDateString() : new Date().toLocaleDateString()}</div>
      </div>
      <p>
        <strong>Note</strong>: The above terms may change by time without prior notice.
        <br />*<strong>Disclaimer</strong>: Your details may be used by Company for promotion on its various platforms.
      </p>
      <div className="space"></div>
    </div>
  );
};

const HierarchyWiseLevelOne: FC<any> = ({ selectedRow }) => {
  return (
    <div className="px-6 py-8 text-gray-800 text-sm leading-6">
      <ol>
        <p>
          This <strong>AGREEMENT FOR BUSINESS SUPPORT SERVICES</strong> (hereinafter this
          <strong>“Agreement”</strong>) is made on this {selectedRow?.agreedOn ? new Date(selectedRow?.agreedOn).toLocaleDateString() : new Date().toLocaleDateString()} (the <strong>“Execution Date”</strong>) by and between:
        </p>
        <ol className="list-decimal pl-6">
          <li className="mb-2">
            <strong>I, {selectedRow?.name || ""}, authorised insurance representative (hereinafter referred to as ASSOCIATE</strong>) which expression shall, unless repugnant to or inconsistent with the context, mean and include any successors or permitted assigns of the <strong>FIRST PART</strong>
          </li>
          <li className="mb-2">
            <strong>I, Sanjay Aggarwal, Co-Founder of INSURANCE SAMADHAN (hereunder mentioned as FASTSURANCE CONSULTANTS PVT. LTD.)</strong>, a company incorporated in India and having its registered office at A-31, 2nd floor, Infraline Tower, Sector 3, Noida, 201301 India (hereinafter referred to as the <strong>“Company”</strong>, which expression shall, unless repugnant to or inconsistent with the context, mean and include any successors or permitted assigns) of the <strong>SECOND PART</strong>; and
            <br />
            <strong>“The Company”</strong> and <strong>“ASSOCIATE”</strong> are jointly referred to as “Parties” and individually as “Party”
          </li>
        </ol>
        <p>
          <strong>WHEREAS</strong>
        </p>
        <ol type="A" className="list-decimal pl-6">
          <li>The Company is interalia engaged in the business of providing services of various nature in the field of Life, Health and General Insurance, such as assistance in resolving consumer grievances by representing clients at various forums like Insurance Companies, IRDA, Ombudsman, Consumer Forums, Legal Courts. The overall mission of Insurance Samadhan is centered towards social cause and the betterment/upliftment of the general impression of the insurance companies.</li>
          <li>Associate is engaged in {selectedRow?.natureOfBusiness || ""} services.</li>
        </ol>
        <p>
          <strong>NOW, IN CONSIDERATION OF THE ABOVE PREMISES AND MUTUAL COVENANTS CONTAINED HEREINAFTER THIS AGREEMENT WITNESSETH AS UNDER:-</strong>
        </p>
        <ol className="list-decimal pl-6">
          <li>
            <strong>SERVICES</strong>
            <ol className="list-decimal pl-6">
              <li>Company agrees to provide resolution of insurance related grievances to clients/customers approached through Associate on such consideration as defined in the present agreement and Associate has agreed to generate customer registrations/ leads for Company.</li>
            </ol>
          </li>
          <li>
            <strong>TERM</strong>
            <ol className="list-decimal pl-6">
              <li>
                This Agreement shall become effective from the date of execution of this Agreement (<strong>“Effective Date”</strong>) and shall remain valid and binding on the Parties for a period of 1 (one) year from the Effective Date (“Term”), unless terminated earlier in accordance with the terms hereof.
              </li>
              <li>Upon expiry of this Agreement, the Parties may extend the duration of this Agreement subject to mutually agreed terms and conditions.</li>
            </ol>
          </li>
          <li>
            <strong>STANDARD OF PERFORMANCE</strong>
            <ol className="list-decimal pl-6">
              <li>
                ASSOCIATE shall:
                <ol type="i" className="list-decimal pl-6">
                  <li>ASSOCIATE is a knowledge partner and shouldn’t be accountable for any lapses or liability by claimant / clients of the Company</li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <strong>REPRESENTATIONS AND WARRANTIES</strong>
            <ol className="list-decimal pl-6">
              <li>
                Each Party represents and warrants to the other Party that:
                <ol type="a" className="list-decimal pl-6">
                  <li>It is a company duly and legally organized and validly existing under the laws of India;</li>
                  <li>This Agreement constitutes its valid and legally binding obligations, enforceable in accordance with its terms;</li>
                  <li>It has full corporate power and authority to execute and deliver this Agreement in accordance with its terms and to perform all its duties and obligations arising or created under or pursuant to this Agreement and all requisite corporate approvals, as applicable, have been obtained by it prior to the execution of this Agreement; and</li>
                  <li>The execution of this Agreement and delivery and performance by it of its obligations hereunder do not and shall not violate or conflict in any manner with or result in a breach or default under Applicable Law or any of its duties or obligations under any agreement, understanding or arrangement, written or oral, to which it is a party.</li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <strong>TERMS OF CONSIDERATION</strong>
            <ol type="a" className="list-decimal pl-6">
              <li>The Company will share pre decided commercials only in case of genuine leads which are coming. Any other method is not viable under current scenario and can be discussed and amended as and when it arises.</li>
              <li>The Company is not responsible for resolution of any case even after acceptance as Insurance resolution is a result of many factors which cannot be deemed as final at any stage of the case.</li>
              <li>The Company hereby agrees and undertakes to pay {selectedRow?.associatePercent || "0"}% of the aggregate amount transferred to the customers/clients, to Associate after the 5% deduction (TDS) on successful completion of the services.</li>
              <li>In the event of client’s case move to legal/court, 2.5% on the resolution amount received by the client on the successful outcome of the case shall be paid to the legal Associate and will be proportionately adjusted from the fee percentage i.e., {selectedRow?.associatePercent || "0"}% being paid to ASSOCIATE on the resolution amount received by the client on successful outcome of the case.</li>
              <li>The Associate can further onboard Advisors under him for generation of customer registrations/ leads for Company. The Company hereby agrees and undertakes to pay the below mentioned revenue share based on the registration source:</li>
              <br />
              <div>
                <table className="table-auto border-2 border-black w-full">
                  <thead>
                    <tr className="bg-gray-200">
                      <th className="border border-black px-4 py-2">Registration Source</th>
                      <th className="border border-black px-4 py-2">Revenue Share</th>
                      <th className="border border-black px-4 py-2">Payable to</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border border-black px-4 py-2">Associate</td>
                      <td className="border border-black px-4 py-2">{selectedRow?.associatePercent || "0"}%</td>
                      <td className="border border-black px-4 py-2">Associate</td>
                    </tr>
                    <tr>
                      <td className="border border-black px-4 py-2">Advisor</td>
                      <td className="border border-black px-4 py-2">{selectedRow?.associatePercent || "0"}% minus Advisor contract %</td>
                      <td className="border border-black px-4 py-2">Associate</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <br />
              <li>The compensation shall be paid in terms of the mutual agreement between the Parties post the resolution amount is received by the client on successful outcome of the case.</li>
            </ol>
          </li>
          <li>
            <strong>TERMINATION</strong>
            <ol className="list-decimal pl-6">
              <li>This Agreement may be terminated by either Party without assigning any reason at any time by giving 1 (one) month prior written notice to the other Party.</li>
              <li>
                Without prejudice to any other rights or remedies available in law or under this Agreement, either Party may terminate this Agreement forthwith on or at any time after the occurrence of any of the events specified herein below:
                <ol type="a" className="list-decimal pl-6">
                  <li>Insolvency of the other Party;</li>
                  <li>Material breach of any of the terms or conditions of this Agreement by the other Party which breach is not remedied by such other Party to the satisfaction of the non-breaching Party within three (3) days of notice of the breach; and</li>
                  <li>If a petition for winding up of the other Party is decreed in any Court or a resolution is passed for winding-up the business of such other Party or if any receiver, manager, liquidator, administrator or other similar official is appointed to the other Party or a substantial part of the other Party’s property.</li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <strong>INDEPENDENT CONTRACTOR</strong>
            <ol className="list-decimal pl-6">
              <li>It is hereby acknowledged that Associate shall perform the services in the capacity of an independent contractor and not as an employee, servant or agent of the Company. Nothing contained herein shall be construed to create a relationship of employer-employee and master-servant between Company and Associate.</li>
            </ol>
          </li>
          <li>
            <strong>INTELLECTUAL PROPERTY</strong>
            <ol className="list-decimal pl-6">
              <li>The Company does not grant to Associate any right, title or interest in any of its intellectual property except as expressly authorised in writing by the Company and Associate shall not have any right, title or interest in Company’s intellectual property other than the right to use it for purposes of this Agreement for the Term hereof. Associate shall comply with any and all instructions issued by Company in relation to the display of any logo, trademark, copyright or any other intellectual property of the Company. Upon expiry or earlier termination of this Agreement, Associate Insurance shall immediately cease and desist for all times from any use of or reference to Company’s intellectual property and shall return to Company copies or materials containing such intellectual property.</li>
              <li>
                Parties hereby acknowledge and confirm that:
                <ol type="a" className="list-decimal pl-6">
                  <li>All Intellectual Property Rights in or relating to the Services are and shall at all times remain the property of Company and / or its licensors;</li>
                  <li>Associate shall notify Company immediately if they become aware of any illegal or unauthorized use of any the intellectual property therein or relating thereto and will assist Company in taking all steps necessary to protect and defend Company’s rights therein.</li>
                </ol>
              </li>
              <li>The provisions of this Clause 8 shall survive the termination of this Agreement.</li>
            </ol>
          </li>
          <li>
            <strong>CONFIDENTIALITY</strong>
            <ol className="list-decimal pl-6">
              <li>The Company acknowledge that in the course of performing their obligations under this Agreement, they may get access to confidential information (“Confidential Information”) of the clients/customers registered through Associate. Herein, Company agrees not to disclose Confidential Information received (whether in writing, verbally or by any other means and whether directly or indirectly) by the clients/customers including in relation to matters contemplated by this Agreement or its performance or in any agreement in furtherance of the performance of this Agreement, to any Third Party without the prior written consent of Associate or use the Confidential Information other than for carrying out the purposes of this Agreement.</li>
              <li>Company shall keep confidential any Confidential Information it receives from the clients/customers and shall employ all such reasonable steps that it would have taken to protect its own Confidential Information. The Company shall disclose Confidential Information received from the clients/customers to its officers, employees, agents or representatives only for the purposes of carrying out its obligations under this Agreement and strictly on a “need to know” basis only. The Company shall also ensure that their respective directors, employees, officers and any other Persons to whom the above-mentioned information is disclosed to be bound by confidentiality obligations similar to those provided in this Clause.</li>
              <li>The Company shall not disclose any material information about clients/customers including but not limited to name, contact details, methodology of work, and approach of handling a claim etc. to third party.</li>
              <li>
                During the term of this agreement or One year from the termination of the same, the Company shall hold the Confidential Information received from the clients and shall not disclose or disseminate at any time, to any other person without the prior consent/permission of the client. Notwithstanding anything contained in above mentioned clause, Company’s obligation of confidentiality shall not extend to any Confidential Information:
                <ol type="a" className="list-decimal pl-6">
                  <li>received from the clients is in the public domain other than by breach of this Agreement;</li>
                  <li>to the extent that the Confidential Information received is required to be disclosed by any applicable Law or by any Authority to whose jurisdiction the Company is subject or with whose instructions it is customary to comply under notice to the Associate; or</li>
                  <li>In so far as it is disclosed to the employees, directors or professional advisers of the Receiving Party, provided that the Receiving Party shall procure that such persons treat the Confidential Information received as confidential.</li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <strong>Indemnification</strong>
            <ol className="list-decimal pl-6">
              <li>In the event that Company is found to be in breach of terms of this Agreement, Company at all times hereafter indemnify and keep the client (“Indemnified Party”) fully indemnified against all claims, demands, actions, proceedings, losses, damages, costs, charges, expenses, interests and disbursements of any nature whatsoever which the Indemnified Party may pay or incur or suffer or sustain or be liable to pay or incur or suffer or sustain as a result or consequence, direct or indirect, of such breach.</li>
            </ol>
          </li>
          <li>
            <strong>Disputes</strong>
            <ol className="list-decimal pl-6">
              <li>In the event of disputes, differences, claims and questions between the Parties hereto arising out of this Agreement or in any way relating hereto or any term, condition or provision herein mentioned or the construction or interpretation thereof or otherwise in relation hereto, the Parties shall first endeavor to settle such differences, disputes, claims or questions by arbitration of three (3) arbitrators, one each to be appointed by each Party, and each such arbitrator shall appoint a third Arbitrator who shall act as the chairman of the arbitral tribunal. The Arbitration and Conciliation Act, 1996 and the rules made there under shall apply to and shall govern the arbitration proceedings. The venue of the arbitration shall be exclusively at New Delhi and the arbitration proceeding shall be conducted in English. The decision of the Arbitrators shall be final and binding on the Parties.</li>
            </ol>
          </li>
          <li>
            <strong>GOVERNING LAW</strong>
            <ol className="list-decimal pl-6">
              <li>This Agreement shall be governed by and construed in accordance with the laws of India, without giving effect to any choice of law or conflict of law provisions. The Parties consent to the exclusive jurisdiction of courts of Delhi with respect to any matter or claim, suit, action or proceeding arising under or related to this Agreement.</li>
            </ol>
          </li>
          <li>
            <strong>MISCELLANEOUS</strong>
            <ol type="i" className="list-decimal pl-6">
              <li>
                <strong>Severability:</strong> The provisions of this Agreement are severable and the validity of remaining Articles, provisions, terms and parts of this Agreement shall not be affected by a court, administrative board or other proceeding of competent jurisdiction deciding that a Clause, provision, term or part of this Agreement is illegal, unenforceable, void, in conflict with any law or contrary to public policy. In such event the Parties shall, by amendment of this Agreement, properly replace such provision by a reasonable new provision or provisions which, as far as legally possible, shall approximate to the closest possible extent what the Parties intended by such original provision and the purpose thereof.
              </li>
              <li>
                <strong>Assignment of Agreement:</strong> Except as permitted or required by this Agreement or as otherwise agreed in writing between the Parties:
                <ol type="a" className="list-decimal pl-6">
                  <li>This Agreement may not be assigned, novated or transferred; and</li>
                  <li>Neither Party shall create or permit to subsist any encumbrance over all or any of its rights and benefits under this Agreement.</li>
                </ol>
              </li>
              <li>
                <strong>Amendment:</strong> No oral or written modification, amendment, rescission, waiver or other change of this Agreement or any of its terms or provisions shall be valid or legally binding on the Parties unless made in writing and duly executed by or on behalf of all the Parties, including without limitation, any purported modification, amendment, rescission, waiver or other change of this itself. No amendments, supplements or modifications to this Agreement shall be made except by written agreement between the Parties.
              </li>
              <li>
                <strong>No Waiver:</strong> A waiver by any Party of any default or defaults by the other Party in the performance of any of the provisions of this Agreement shall be effective only when executed in writing by duly authorized representatives of all Parties. A waiver shall never operate as or be construed as a waiver of any other or further default or defaults whether of a like or different character. Neither the failure by a Party to insist on any occasion upon the performance of the terms, conditions, and provisions of this Agreement nor time or other indulgence granted by a Party to the other or others shall act as a waiver of such breach of acceptance of any variation or the relinquishment of any such right or any other right under this Agreement, which shall remain in full force and effect.
              </li>
              <li>
                <strong>Counterparts:</strong> This Agreement may be executed in one or more counterparts, each of which shall be deemed an original and all of which collectively shall be deemed one and the same instrument.
              </li>
              <li>
                <strong>Notices:</strong> All communication, notices or information to be given under this Agreement shall be so given in writing. All notices must be delivered personally, by registered or certified mail or facsimile to the addresses below:
                <br />
                <table className="table-auto border-collapse border border-black mt-4">
                  <thead>
                    <tr className="bg-gray-200">
                      <th className="border border-black px-4 py-2">Fastsurance Consultants Pvt. Ltd</th>
                      <th className="border border-black px-4 py-2">ASSOCIATE</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border border-black px-4 py-2">ATTN:</td>
                      <td className="border border-black px-4 py-2">ATTN:</td>
                    </tr>
                    <tr>
                      <td className="border border-black px-4 py-2">Sanjay Aggarwal</td>
                      <td className="border border-black px-4 py-2"></td>
                    </tr>
                    <tr>
                      <td className="border border-black px-4 py-2">Director</td>
                      <td className="border border-black px-4 py-2">
                        <hr />
                      </td>
                    </tr>
                    <tr>
                      <td className="border border-black px-4 py-2">A-31, 2nd floor, Infraline Tower,</td>
                      <td className="border border-black px-4 py-2">Director/Authorized Representative</td>
                    </tr>
                    <tr>
                      <td className="border border-black px-4 py-2">Sector 3, Noida, 201301,</td>
                      <td className="border border-black px-4 py-2"></td>
                    </tr>
                    <tr>
                      <td className="border border-black px-4 py-2">
                        <a href="mailto:sanjay@insurancesamadhan.com" className="text-blue-500 underline">
                          sanjay@insurancesamadhan.com
                        </a>
                      </td>
                      <td className="border border-black px-4 py-2"></td>
                    </tr>
                  </tbody>
                </table>
                <br />
                All notices shall be effective:
                <ol className="list-decimal pl-6">
                  <li>If sent by facsimile, when sent (on receipt of confirmation of the correct number);</li>
                  <li>If sent by registered or certified mail, within five (5) days of dispatch; and</li>
                  <li>If delivered personally, on receipt by the intended recipient: Provided that, all notices sent by facsimile, shall be confirmed by registered or certified mail. Each Party shall forthwith notify the other Party of any change in its address to which notices under this Agreement are to be delivered, mailed, facsimiled or electronically mailed.</li>
                </ol>
              </li>
              <li>
                <strong>Language:</strong> The language of this Agreement shall be English and all documents, notices, waivers and all other written communication or otherwise between the Parties in connection with this Agreement shall be in English.
              </li>
              <li>
                <strong>Remedies:</strong> Parties acknowledge that damages will not be a sufficient remedy for breach of this Agreement and the Parties shall be entitled to seek specific performance of each other’s obligations pursuant to this Agreement.
              </li>
              <li>
                <strong>Further Assurances:</strong> Each Party agrees to perform (or procure the performance of) all further acts and things, and execute and deliver (or procure the execution and delivery of) such further documents, as may be required by Law or as the other Party may reasonably require, whether on or after Effective Date, to implement and/or give effect to this Agreement.
              </li>
              <li>
                <strong>IN WITNESS WHEREOF</strong> the Parties have executed these presents through their authorized representatives on the Execution Date.
              </li>
              <li>
                <strong>IN WITNESS WHEREOF</strong>, the Parties hereto have set and subscribed their hands through their respective duly authorized representatives as of the date first above written.
              </li>
            </ol>
          </li>
        </ol>
      </ol>
      <div className="border p-4 my-4">
        <div>
          Signed and delivered for and on behalf of <strong>Fastsurance Consultants Pvt. Ltd</strong>
        </div>
        <br />
        <div>Signatures:</div>
        <div className="sign">
          <img src={InvoiceSign} alt="signature" className="w-10" />
        </div>
        <br />
        <div>Date: {selectedRow?.agreedOn ? new Date(selectedRow?.agreedOn).toLocaleDateString() : new Date().toLocaleDateString()}</div>
        <hr />
        <div>Signed and delivered for and on behalf of partner/agent</div>
        <div>Name: {selectedRow?.name || ""}</div>
        <div>Signatures:</div>
        <br />
        <div>Authorized Signatory</div>
        <div>Date: {selectedRow?.agreedOn ? new Date(selectedRow?.agreedOn).toLocaleDateString() : new Date().toLocaleDateString()}</div>
      </div>
      <p>
        <strong>Note</strong>: The above terms may change by time without prior notice.
        <br />*<strong>Disclaimer</strong>: Your details may be used by Company for promotion on its various platforms.
      </p>
      <div className="space"></div>
    </div>
  );
};

const HierarchyWiseLevelTwo: FC<any> = ({ selectedRow }) => {
  return (
    <div className="px-6 py-8 text-gray-800 text-sm leading-6">
      <ol className="list-decimal pl-6">
        <p>
          This <strong>AGREEMENT FOR BUSINESS SUPPORT SERVICES</strong> (hereinafter this
          <strong>“Agreement”</strong>) is made on this {selectedRow?.agreedOn ? new Date(selectedRow?.agreedOn).toLocaleDateString() : new Date().toLocaleDateString()} (the <strong>“Execution Date”</strong>) by and between:
        </p>
        <ol className="list-decimal pl-6">
          <li className="mb-2">
            <strong>I, {selectedRow?.name || ""}, authorised insurance representative (hereinafter referred to as ADVISOR</strong> which expression shall, unless repugnant to or inconsistent with the context, mean and include any successors or permitted assigns) of the <strong>FIRST PART</strong>
          </li>
          <br />
          <li className="mb-2">
            <strong>I, Sanjay Aggarwal, Co-Founder of INSURANCE SAMADHAN (hereunder mentioned as FASTSURANCE CONSULTANTS PVT. LTD.)</strong>, a company incorporated in India and having its registered office at A-31, 2nd floor, Infraline Tower, Sector 3, Noida, 201301 India (hereinafter referred to as the <strong>“Company”</strong>, which expression shall, unless repugnant to or inconsistent with the context, mean and include any successors or permitted assigns) of the <strong>SECOND PART</strong>; and
            <br />
            <strong>“The Company”</strong> and <strong>“ADVISOR”</strong> are jointly referred to as “Parties” and individually as “Party”
          </li>
        </ol>
        <ol className="list-decimal pl-6">
          <p>
            <strong>WHEREAS</strong>
          </p>
          <ol type="A" className="list-decimal pl-6">
            <li className="mb-2">The Company is interalia engaged in the business of providing services of various nature in the field of Life, Health and General Insurance, such as assistance in resolving consumer grievances by representing clients at various forums like Insurance Companies, IRDA, Ombudsman, Consumer Forums, Legal Courts. The overall mission of Insurance Samadhan is centered towards social cause and the betterment/upliftment of the general impression of the insurance companies.</li>
            <li className="mb-2">Advisor is engaged in {selectedRow?.natureOfBusiness || ""} services.</li>
          </ol>
        </ol>
        <p>
          <strong>NOW, IN CONSIDERATION OF THE ABOVE PREMISES AND MUTUAL COVENANTS CONTAINED HEREINAFTER THIS AGREEMENT WITNESSETH AS UNDER:-</strong>
        </p>
        <ol className="list-decimal pl-6">
          <li>
            <strong>SERVICES</strong>
            <ol className="list-decimal pl-6">
              <li>Company agrees to provide resolution of insurance related grievances to clients/customers approached through Advisor on such consideration as defined in the present agreement and Advisor has agreed to generate customer registrations/ leads for Company.</li>
            </ol>
          </li>
          <li>
            <strong>TERM</strong>
            <ol className="list-decimal pl-6">
              <li>
                This Agreement shall become effective from the date of execution of this Agreement (<strong>“Effective Date”</strong>) and shall remain valid and binding on the Parties for a period of 1 (one) year from the Effective Date (“Term”), unless terminated earlier in accordance with the terms hereof.
              </li>
              <li>Upon expiry of this Agreement, the Parties may extend the duration of this Agreement subject to mutually agreed terms and conditions.</li>
            </ol>
          </li>
          <li>
            <strong>STANDARD OF PERFORMANCE</strong>
            <ol className="list-decimal pl-6">
              <li>
                ADVISOR shall:
                <ol type="i" className="list-decimal pl-6">
                  <li>ADVISOR is a knowledge partner and shouldn’t be accountable for any lapses or liability by claimant / clients of the Company</li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <strong>REPRESENTATIONS AND WARRANTIES</strong>
            <ol className="list-decimal pl-6">
              <li>
                Each Party represents and warrants to the other Party that:
                <ol type="a" className="list-decimal pl-6">
                  <li>It is a company duly and legally organized and validly existing under the laws of India;</li>
                  <li>This Agreement constitutes its valid and legally binding obligations, enforceable in accordance with its terms;</li>
                  <li>It has full corporate power and authority to execute and deliver this Agreement in accordance with its terms and to perform all its duties and obligations arising or created under or pursuant to this Agreement and all requisite corporate approvals, as applicable, have been obtained by it prior to the execution of this Agreement; and</li>
                  <li>The execution of this Agreement and delivery and performance by it of its obligations hereunder do not and shall not violate or conflict in any manner with or result in a breach or default under Applicable Law or any of its duties or obligations under any agreement, understanding or arrangement, written or oral, to which it is a party.</li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <strong>TERMS OF CONSIDERATION</strong>
            <ol type="a" className="list-decimal pl-6">
              <li>The Company will share pre decided commercials only in case of genuine leads which are coming. Any other method is not viable under current scenario and can be discussed and amended as and when it arises.</li>
              <li>The Company is not responsible for resolution of any case even after acceptance as Insurance resolution is a result of many factors which cannot be deemed as final at any stage of the case.</li>
              <li>The Company hereby agrees and undertakes to pay {selectedRow?.advisorPercent || "0"}% of the aggregate amount transferred to the customers/clients, to Advisor after the 5% deduction (TDS) on successful completion of the services.</li>
              <li>In the event of client’s case move to legal/court, 2.5% on the resolution amount received by the client on the successful outcome of the case shall be paid to the legal Advisor and will be proportionately adjusted from the fee percentage i.e.,{selectedRow?.advisorPercent || "0"}% being paid to ADVISOR on the resolution amount received by the client on successful outcome of the case.</li>
              <li>The Company hereby agrees and undertakes to pay the below mentioned revenue share based on the registration source:</li>
              <br />
              <div className="overflow-x-auto">
                <table className="min-w-full bg-white border border-black">
                  <thead>
                    <tr className="bg-gray-200">
                      <th className="px-4 py-2 border border-black">Registration Source</th>
                      <th className="px-4 py-2 border border-black">Revenue Share</th>
                      <th className="px-4 py-2 border border-black">Payable to</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border border-black px-4 py-2">Advisor</td>
                      <td className="border border-black px-4 py-2">{selectedRow?.advisorPercent || "0"}%</td>
                      <td className="border border-black px-4 py-2">Advisor</td>
                    </tr>
                    {/* Additional rows if necessary */}
                  </tbody>
                </table>
              </div>
              <br />
              <li>The compensation shall be paid in terms of the mutual agreement between the Parties post the resolution amount is received by the client on successful outcome of the case.</li>
            </ol>
          </li>
          <li>
            <strong>TERMINATION</strong>
            <ol className="list-decimal pl-6">
              <li>This Agreement may be terminated by either Party without assigning any reason at any time by giving 1 (one) month prior written notice to the other Party.</li>
              <li>
                Without prejudice to any other rights or remedies available in law or under this Agreement, either Party may terminate this Agreement forthwith on or at any time after the occurrence of any of the events specified herein below:
                <ol type="a" className="list-decimal pl-6">
                  <li>Insolvency of the other Party;</li>
                  <li>Material breach of any of the terms or conditions of this Agreement by the other Party which breach is not remedied by such other Party to the satisfaction of the non-breaching Party within three (3) days of notice of the breach; and</li>
                  <li>If a petition for winding up of the other Party is decreed in any Court or a resolution is passed for winding-up the business of such other Party or if any receiver, manager, liquidator, administrator or other similar official is appointed to the other Party or a substantial part of the other Party’s property.</li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <strong>INDEPENDENT CONTRACTOR</strong>
            <ol className="list-decimal pl-6">
              <li>It is hereby acknowledged that Advisor shall perform the services in the capacity of an independent contractor and not as an employee, servant or agent of the Company. Nothing contained herein shall be construed to create a relationship of employer-employee and master-servant between Company and Advisor.</li>
            </ol>
          </li>
          <li>
            <strong>INTELLECTUAL PROPERTY</strong>
            <ol className="list-decimal pl-6">
              <li>The Company does not grant to Advisor any right, title or interest in any of its intellectual property except as expressly authorised in writing by the Company and Advisor shall not have any right, title or interest in Company’s intellectual property other than the right to use it for purposes of this Agreement for the Term hereof. Advisor shall comply with any and all instructions issued by Company in relation to the display of any logo, trademark, copyright or any other intellectual property of the Company. Upon expiry or earlier termination of this Agreement, Advisor Insurance shall immediately cease and desist for all times from any use of or reference to Company’s intellectual property and shall return to Company copies or materials containing such intellectual property.</li>
              <li>
                Parties hereby acknowledge and confirm that:
                <ol type="a" className="list-decimal pl-6">
                  <li>All Intellectual Property Rights in or relating to the Services are and shall at all times remain the property of Company and / or its licensors;</li>
                  <li>Advisor shall notify Company immediately if they become aware of any illegal or unauthorized use of any the intellectual property therein or relating thereto and will assist Company in taking all steps necessary to protect and defend Company’s rights therein.</li>
                </ol>
              </li>
              <li>The provisions of this Clause 8 shall survive the termination of this Agreement.</li>
            </ol>
          </li>
          <li>
            <strong>CONFIDENTIALITY</strong>
            <ol className="list-decimal pl-6">
              <li>The Company acknowledge that in the course of performing their obligations under this Agreement, they may get access to confidential information (“Confidential Information”) of the clients/customers registered through Advisor. Herein, Company agrees not to disclose Confidential Information received (whether in writing, verbally or by any other means and whether directly or indirectly) by the clients/customers including in relation to matters contemplated by this Agreement or its performance or in any agreement in furtherance of the performance of this Agreement, to any Third Party without the prior written consent of Advisor or use the Confidential Information other than for carrying out the purposes of this Agreement.</li>
              <li>Company shall keep confidential any Confidential Information it receives from the clients/customers and shall employ all such reasonable steps that it would have taken to protect its own Confidential Information. The Company shall disclose Confidential Information received from the clients/customers to its officers, employees, agents or representatives only for the purposes of carrying out its obligations under this Agreement and strictly on a “need to know” basis only. The Company shall also ensure that their respective directors, employees, officers and any other Persons to whom the above-mentioned information is disclosed to be bound by confidentiality obligations similar to those provided in this Clause.</li>
              <li>The Company shall not disclose any material information about clients/customers including but not limited to name, contact details, methodology of work, and approach of handling a claim etc. to third party.</li>
              <li>
                During the term of this agreement or One year from the termination of the same, the Company shall hold the Confidential Information received from the clients and shall not disclose or disseminate at any time, to any other person without the prior consent/permission of the client. Notwithstanding anything contained in above mentioned clause, Company’s obligation of confidentiality shall not extend to any Confidential Information:
                <ol type="a" className="list-decimal pl-6">
                  <li>received from the clients is in the public domain other than by breach of this Agreement;</li>
                  <li>to the extent that the Confidential Information received is required to be disclosed by any applicable Law or by any Authority to whose jurisdiction the Company is subject or with whose instructions it is customary to comply under notice to the Advisor; or</li>
                  <li>In so far as it is disclosed to the employees, directors or professional advisers of the Receiving Party, provided that the Receiving Party shall procure that such persons treat the Confidential Information received as confidential.</li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <strong>Indemnification</strong>
            <ol className="list-decimal pl-6">
              <li>In the event that Company is found to be in breach of terms of this Agreement, Company at all times hereafter indemnify and keep the client (“Indemnified Party”) fully indemnified against all claims, demands, actions, proceedings, losses, damages, costs, charges, expenses, interests and disbursements of any nature whatsoever which the Indemnified Party may pay or incur or suffer or sustain or be liable to pay or incur or suffer or sustain as a result or consequence, direct or indirect, of such breach.</li>
            </ol>
          </li>
          <li>
            <strong>Disputes</strong>
            <ol className="list-decimal pl-6">
              <li>In the event of disputes, differences, claims and questions between the Parties hereto arising out of this Agreement or in any way relating hereto or any term, condition or provision herein mentioned or the construction or interpretation thereof or otherwise in relation hereto, the Parties shall first endeavor to settle such differences, disputes, claims or questions by arbitration of three (3) arbitrators, one each to be appointed by each Party, and each such arbitrator shall appoint a third Arbitrator who shall act as the chairman of the arbitral tribunal. The Arbitration and Conciliation Act, 1996 and the rules made there under shall apply to and shall govern the arbitration proceedings. The venue of the arbitration shall be exclusively at New Delhi and the arbitration proceeding shall be conducted in English. The decision of the Arbitrators shall be final and binding on the Parties.</li>
            </ol>
          </li>
          <li>
            <strong>GOVERNING LAW</strong>
            <ol className="list-decimal pl-6">
              <li>This Agreement shall be governed by and construed in accordance with the laws of India, without giving effect to any choice of law or conflict of law provisions. The Parties consent to the exclusive jurisdiction of courts of Delhi with respect to any matter or claim, suit, action or proceeding arising under or related to this Agreement.</li>
            </ol>
          </li>
          <li>
            <strong>MISCELLANEOUS</strong>
            <ol type="i" className="list-decimal pl-6">
              <li>
                <strong>Severability:</strong> The provisions of this Agreement are severable and the validity of remaining Articles, provisions, terms and parts of this Agreement shall not be affected by a court, administrative board or other proceeding of competent jurisdiction deciding that a Clause, provision, term or part of this Agreement is illegal, unenforceable, void, in conflict with any law or contrary to public policy. In such event the Parties shall, by amendment of this Agreement, properly replace such provision by a reasonable new provision or provisions which, as far as legally possible, shall approximate to the closest possible extent what the Parties intended by such original provision and the purpose thereof.
              </li>
              <li>
                <strong>Assignment of Agreement:</strong> Except as permitted or required by this Agreement or as otherwise agreed in writing between the Parties:
                <ol type="a" className="list-decimal pl-6">
                  <li>This Agreement may not be assigned, novated or transferred; and</li>
                  <li>Neither Party shall create or permit to subsist any encumbrance over all or any of its rights and benefits under this Agreement.</li>
                </ol>
              </li>
              <li>
                <strong>Amendment:</strong> No oral or written modification, amendment, rescission, waiver or other change of this Agreement or any of its terms or provisions shall be valid or legally binding on the Parties unless made in writing and duly executed by or on behalf of all the Parties, including without limitation, any purported modification, amendment, rescission, waiver or other change of this itself. No amendments, supplements or modifications to this Agreement shall be made except by written agreement between the Parties.
              </li>
              <li>
                <strong>No Waiver:</strong> A waiver by any Party of any default or defaults by the other Party in the performance of any of the provisions of this Agreement shall be effective only when executed in writing by duly authorized representatives of all Parties. A waiver shall never operate as or be construed as a waiver of any other or further default or defaults whether of a like or different character. Neither the failure by a Party to insist on any occasion upon the performance of the terms, conditions, and provisions of this Agreement nor time or other indulgence granted by a Party to the other or others shall act as a waiver of such breach of acceptance of any variation or the relinquishment of any such right or any other right under this Agreement, which shall remain in full force and effect.
              </li>
              <li>
                <strong>Counterparts:</strong> This Agreement may be executed in one or more counterparts, each of which shall be deemed an original and all of which collectively shall be deemed one and the same instrument.
              </li>
              <li>
                <strong>Notices:</strong> : All communication, notices or information to be given under this Agreement shall be so given in writing. All notices must be delivered personally, by registered or certified mail or facsimile to the addresses below:
                <br />
                <br />
                <div className="overflow-x-auto">
                  <table className="min-w-full bg-white border border-black">
                    <thead>
                      <tr className="bg-gray-200">
                        <th className="px-4 py-2 border border-black">Fastsurance Consultants Pvt. Ltd</th>
                        <th className="px-4 py-2 border border-black">ADVISOR</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="border border-black px-4 py-2">ATTN:</td>
                        <td className="border border-black px-4 py-2">ATTN:</td>
                      </tr>
                      <tr>
                        <td className="border border-black px-4 py-2">Sanjay Aggarwal</td>
                        <td className="border border-black px-4 py-2"></td>
                      </tr>
                      <tr>
                        <td className="border border-black px-4 py-2">Director</td>
                        <td className="border border-black px-4 py-2"></td>
                      </tr>
                      <tr>
                        <td className="border border-black px-4 py-2">A-31, 2nd floor, Infraline Tower,</td>
                        <td className="border border-black px-4 py-2">Director/Authorized Representative</td>
                      </tr>
                      <tr>
                        <td className="border border-black px-4 py-2">Sector 3, Noida, 201301</td>
                        <td className="border border-black px-4 py-2"></td>
                      </tr>
                      <tr>
                        <td className="border border-black px-4 py-2">
                          <a href="mailto:sanjay@insurancesamadhan.com">sanjay@insurancesamadhan.com</a>
                        </td>
                        <td className="border border-black px-4 py-2"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <br />
                All notices shall be effective:
                <ol className="list-decimal pl-6">
                  <li>If sent by facsimile, when sent (on receipt of confirmation of the correct number);</li>
                  <li>If sent by registered or certified mail, within five (5) days of dispatch; and</li>
                  <li>If delivered personally, on receipt by the intended recipient: Provided that, all notices sent by facsimile, shall be confirmed by registered or certified mail. Each Party shall forthwith notify the other Party of any change in its address to which notices under this Agreement are to be delivered, mailed, facsimiled or electronically mailed.</li>
                </ol>
              </li>
              <li>
                <strong>Language:</strong> The language of this Agreement shall be English and all documents, notices, waivers and all other written communication or otherwise between the Parties in connection with this Agreement shall be in English.
              </li>
              <li>
                <strong>Remedies:</strong> Parties acknowledge that damages will not be a sufficient remedy for breach of this Agreement and the Parties shall be entitled to seek specific performance of each other’s obligations pursuant to this Agreement.
              </li>
              <li>
                <strong>Further Assurances:</strong> Each Party agrees to perform (or procure the performance of) all further acts and things, and execute and deliver (or procure the execution and delivery of) such further documents, as may be required by Law or as the other Party may reasonably require, whether on or after Effective Date, to implement and/or give effect to this Agreement.
              </li>
              <br />
              <strong>IN WITNESS WHEREOF</strong> the Parties have executed these presents through their authorized representatives on the Execution Date.
              <br />
              <strong>IN WITNESS WHEREOF</strong>, the Parties hereto have set and subscribed their hands through their respective duly authorized representatives as of the date first above written.
            </ol>
          </li>
        </ol>
      </ol>
      <div className="border p-4 my-4">
        <div>
          Signed and delivered for and on behalf of <strong>Fastsurance Consultants Pvt. Ltd</strong>
        </div>
        <br />
        <div>Signatures:</div>
        <div className="sign">
          <img src={InvoiceSign} alt="signature" className="w-10" />
        </div>
        <br />
        <div>Date: {selectedRow?.agreedOn ? new Date(selectedRow?.agreedOn).toLocaleDateString() : new Date().toLocaleDateString()}</div>
        <hr />
        <div>Signed and delivered for and on behalf of partner/agent</div>
        <div>Name: {selectedRow?.name || ""}</div>
        <div>Signatures:</div>
        <br />
        <div>Authorized Signatory</div>
        <div>Date: {selectedRow?.agreedOn ? new Date(selectedRow?.agreedOn).toLocaleDateString() : new Date().toLocaleDateString()}</div>
      </div>
      <p>
        <strong>Note</strong>: The above terms may change by time without prior notice.
        <br />*<strong>Disclaimer</strong>: Your details may be used by Company for promotion on its various platforms.
      </p>
      <div className="space"></div>
    </div>
  );
};
