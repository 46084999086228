import React, { useEffect, useState } from "react";
import FormInput from "../../../component/semantic/FormInput";
import Select from "react-select";
import { polyfixSelect } from "../../../helpers/constants/styles";
import { reportDateTypeLead } from "../../../helpers/constants/constants";
import Typography from "../../../component/semantic/Typography";
import { useAppSelector } from "../../../hooks/redux_toolkit";

interface ReportProps {
  formProps: any;
}

const LeadReport: React.FC<ReportProps> = ({ formProps }) => {
  const [userListArr, setUserListArr] = useState<any>([]);
  const [listStatusArr, setLeadStatusArr] = useState<any>([]);
  const userList = useAppSelector((state) => state.rootReducer.common.userDropdownList);

  const leadStatus = useAppSelector((state) => state.rootReducer.report.leadStatus);

  // const loadOptions = (inputValue: string, callback: (options: { value: string; label: string }[]) => void) => {
  //   // Here you can implement your logic to filter or search for options based on the inputValue
  //   // For simplicity, we'll return all users
  //   callback(leadStatusArr.data.map((user: any) => ({ value: user.id, label: user.name })));
  // };

  useEffect(() => {
    if (userList.data) setUserListArr(userList.data);
    if (leadStatus.data) setLeadStatusArr(leadStatus?.data.map((obj: any) => ({ label: obj, value: obj })));
  }, [userList, leadStatus]);

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      {/* <FormInput name="reportType" label="Report Type" disabled formProps={formProps} /> */}
      <span>
        <Typography className="font-normal mb-1">Select Date Type</Typography>
        <Select
          options={reportDateTypeLead}
          name="type"
          placeholder="Select Date Type"
          onChange={(option) => {
            formProps.setFieldValue("type", option?.value);
          }}
          styles={polyfixSelect}
          isSearchable={false}
        />
      </span>
      <span>
        <Typography className="font-normal mb-1">Complaint Status</Typography>
        <Select
          options={[{ label: "All", value: "All" }, ...listStatusArr]}
          className="w-full "
          name="status"
          onChange={(option: any) => {
            formProps.setFieldValue("status", option?.value);
          }}
          styles={polyfixSelect}
          placeholder="Select Complaint Status"
        />
      </span>
      <FormInput name="startDate" max="true" type="date" label="Start Date" formProps={formProps} />
      <FormInput name="endDate" min={formProps.values.startDate} type="date" label="End Date" formProps={formProps} />

      <span>
        <Typography className="font-normal mb-1">Select User</Typography>
        <Select
          options={[{ _id: "all", name: "All" }, ...userListArr]}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option._id}
          className="w-full "
          styles={polyfixSelect}
          name="userId"
          onChange={(option: any) => {
            formProps.setFieldValue("userId", option._id);
          }}
          placeholder="Select User"
        />
      </span>
      {/* <AsyncSelect
      loadOptions= {loadOptions}
          getOptionLabel={(option:any) => option.name}
          getOptionValue={(option:any) => option._id}
          className="w-full "
          styles={polyfixSelect}
          placeholder="Select User"
      /> */}
    </div>
  );
};

export default LeadReport;
