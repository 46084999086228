import React, { useEffect, useState } from "react";
import Container from "../../component/common/Container";
import Typography from "../../component/semantic/Typography";
import Tabpane from "../../component/common/Tabpane";
import { useFormik } from "formik";
import ComplaintReport from "./component/ComplaintReport";
import Divide from "../../component/semantic/Divide";
import ButtonOutline from "../../component/semantic/Button";
import { downloadCSV, isEmpty } from "../../helpers/utils";
import { useAppDispatch, useAppSelector } from "../../hooks/redux_toolkit";
import LeadReport from "./component/LeadReport";
import UserReport from "./component/UserReport";
import { getUserDropdownThunk } from "../../store/thunk/Common.thunk";
import { getCurrentUser } from "../../helpers/currentUser";
import { downloadReportThunkActiveUser, downloadReportThunkComplaint, downloadReportThunkLead, downloadReportThunkSettled, downloadReportThunkUser, leadStatusThunk, reportDataThunkActiveUser, reportDataThunkComplaint, reportDataThunkLead, reportDataThunkSettled, reportDataThunkUser } from "../../store/thunk/Report.thunk";
import SimpleTable from "../../component/table/SimpleTable";
import { activeUserReportHeading, activeUserReportTableKeys, reportTableHeading, reportTableKeys, settledTableReportHeading, settledTableReportKeys, userReportHeading, userReportTableKeys } from "../../component/table/TableConstants";
import { reportSchema, reportSchemav2 } from "../../helpers/schemas/Simple.Schema";
import dayjs from "dayjs";
import Pagination from "../../component/common/Pagination";
import Spinner from "../../component/common/Spinner";
import ActiveUserReport from "./component/ActiveUserReport";
import SettledCaseReport from "./component/SettledCaseReport";

const initialValues = {
  reportType: "",
  type: "",
  status: "",
  startDate: "",
  endDate: "",
  userId: "",
  submitted: false
};

const tabpaneList = ["Complaint Report", "Lead Report", "User Report", "Settled Report"];
const tabpaneListAdmin = ["Complaint Report", "Lead Report", "User Report", "Active User Report"];

const Report = () => {
  const [selectedTab, setSelectedTab] = useState<number>(1);
  const [tableDataArr, setTableData] = useState<any>([
    { complaintData: [], count: 0 },
    { leadData: [], count: 0 },
    { userData: [], count: 0 },
    { settledCaseData: [], count: 0 },
    { activeUserData: [], count: 0 }
  ]);
  const [currentPage, setCurrentPage] = useState(1);
  const [downloadCSVReport, setDownloadCSV] = useState();

  const dispatch = useAppDispatch();
  const currentUser = getCurrentUser();

  const tableData: any = useAppSelector((state) => state.rootReducer.report);
  const downloadComplaintData = useAppSelector((state) => state.rootReducer.report.downloadComplaintReport);
  const downloadLeadData = useAppSelector((state) => state.rootReducer.report.downloadLeadReport);
  const downloadActiveUserData = useAppSelector((state) => state.rootReducer.report.downloadActiveUserReport);
  const downloadUserData = useAppSelector((state) => state.rootReducer.report.downloadUserReport);
  const downloadSettledCase = useAppSelector((state) => state.rootReducer.report.downloadSettledCaseReport);

  useEffect(() => {
    const tempData = [...tableDataArr];
    if (selectedTab == 1) {
      if (tableData.complaintData.data?.leads) {
        tempData[0] = { complaintData: tableData.complaintData.data?.leads, count: tableData.complaintData.data?.count };
        setTableData(tempData);
      }
    } else if (selectedTab == 2) {
      if (tableData.leadData.data?.leads) {
        tempData[1] = { leadData: tableData.leadData.data?.leads, count: tableData.leadData.data?.count };
        setTableData(tempData);
      }
    } else if (selectedTab == 3) {
      if (tableData.userData.data?.users) {
        tempData[2] = { userData: tableData.userData.data?.users, count: tableData.userData.data?.count };
        setTableData(tempData);
      }
    } else if (selectedTab == 4) {
      if (tableData?.settledCaseData?.data?.cases) {
        tempData[3] = { settledCaseData: tableData.settledCaseData?.data?.cases, count: tableData.settledCaseData.data?.count };
        setTableData(tempData);
      }
    } else if (selectedTab == 5) {
      if (tableData.activeUserData?.data?.users) {
        tempData[4] = { activeUserData: tableData.activeUserData.data?.users, count: tableData.activeUserData.data?.count };
        setTableData(tempData);
      }
    }
  }, [tableData]);

  useEffect(() => {
    if (currentUser?.userType == "Manager") {
      dispatch(getUserDropdownThunk({ type: "Manager" }));
    } else if (currentUser?.userType !== "Vendor") {
      dispatch(getUserDropdownThunk({ type: "Partner", level: currentUser?.hierarchicalLevel }));
    } else {
      dispatch(getUserDropdownThunk({ type: "Associate", level: currentUser?.hierarchicalLevel, parentId: currentUser?._id }));
    }
    dispatch(leadStatusThunk());
  }, []);

  useEffect(() => {
    if (selectedTab == 1) {
      setDownloadCSV(downloadComplaintData);
    } else if (selectedTab == 2) {
      setDownloadCSV(downloadLeadData);
    } else if (selectedTab === 4) {
      setDownloadCSV(downloadSettledCase);
    } else if (selectedTab == 5) {
      setDownloadCSV(downloadActiveUserData);
    } else setDownloadCSV(downloadUserData);
  }, [downloadComplaintData, downloadLeadData, downloadUserData, selectedTab, downloadActiveUserData, downloadSettledCase]);

  const { values, handleSubmit, handleBlur, handleChange, touched, errors, resetForm, setFieldValue } = useFormik({
    initialValues: initialValues,
    validationSchema: selectedTab > 3 ? reportSchemav2 : reportSchema,
    enableReinitialize: true,
    onSubmit: async (values: any) => {
      const formattedStartDate = dayjs(values.startDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
      const formattedEndDate = dayjs(values.endDate).endOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
      values.submitted = true;
      const formValues = { ...values };
      if (selectedTab == 1) {
        if (formValues.status == "All") delete formValues.status;
        await dispatch(reportDataThunkComplaint({ body: { ...formValues, startDate: formattedStartDate, endDate: formattedEndDate }, pageSize, currentPage }));
        dispatch(downloadReportThunkComplaint({ ...formValues, startDate: formattedStartDate, endDate: formattedEndDate }));
      } else if (selectedTab === 2) {
        if (formValues.status == "All") delete formValues.status;
        dispatch(reportDataThunkLead({ body: { ...formValues, startDate: formattedStartDate, endDate: formattedEndDate }, pageSize, currentPage }));
        dispatch(downloadReportThunkLead({ ...formValues, startDate: formattedStartDate, endDate: formattedEndDate }));
      } else if (selectedTab === 3) {
        if (formValues.status == "All") delete formValues.status;
        dispatch(reportDataThunkUser({ body: { ...formValues, startDate: formattedStartDate, endDate: formattedEndDate, status: formValues?.type }, pageSize, currentPage }));
        dispatch(downloadReportThunkUser({ ...formValues, startDate: formattedStartDate, endDate: formattedEndDate, status: formValues?.type }));
      } else if (selectedTab === 4) {
        dispatch(reportDataThunkSettled({ body: { startDate: formattedStartDate, endDate: formattedEndDate }, pageSize, currentPage }));
        dispatch(downloadReportThunkSettled({ startDate: formattedStartDate, endDate: formattedEndDate }));
      } else if (selectedTab === 5) {
        dispatch(reportDataThunkActiveUser({ body: { startDate: formattedStartDate, endDate: formattedEndDate, isActive: true }, pageSize, currentPage }));
        dispatch(downloadReportThunkActiveUser({ startDate: formattedStartDate, endDate: formattedEndDate, isActive: true }));
      }
    }
  });

  useEffect(() => {
    resetForm();
    if (selectedTab === 1) setFieldValue("reportType", "Complaint Report");
    if (selectedTab === 2) setFieldValue("reportType", "Lead Report");
    if (selectedTab === 3) setFieldValue("reportType", "User Report");
    if (selectedTab === 4) setFieldValue("reportType", "Settled Report");
    if (selectedTab === 5) setFieldValue("reportType", "Active User Report");
  }, [selectedTab]);

  useEffect(() => {
    const formattedStartDate: any = dayjs(values.startDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    const formattedEndDate = dayjs(values.endDate).endOf("day").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    const formValues: any = { ...values };
    if (formValues.submitted === true && selectedTab == 1) {
      if (formValues.status == "All") delete formValues.status;
      dispatch(reportDataThunkComplaint({ body: { ...formValues, startDate: formattedStartDate, endDate: formattedEndDate }, pageSize, currentPage }));
    } else if (formValues.submitted === true && selectedTab == 2) {
      if (formValues.status == "All") delete formValues.status;
      dispatch(reportDataThunkLead({ body: { ...formValues, startDate: formattedStartDate, endDate: formattedEndDate }, pageSize, currentPage }));
    } else if (formValues.submitted === true && selectedTab == 3) {
      if (formValues.status == "All") delete formValues.status;
      dispatch(reportDataThunkUser({ body: { ...formValues, startDate: formattedStartDate, endDate: formattedEndDate, status: formValues?.type }, pageSize, currentPage }));
    } else if (formValues.submitted === true && selectedTab == 4) {
      dispatch(reportDataThunkSettled({ body: { startDate: formattedStartDate, endDate: formattedEndDate, isActive: true }, pageSize, currentPage }));
    } else if (formValues.submitted === true && selectedTab == 5) {
      dispatch(reportDataThunkActiveUser({ body: { startDate: formattedStartDate, endDate: formattedEndDate, isActive: true }, pageSize, currentPage }));
    }
  }, [currentPage]);

  const downloadReportHandler = () => {
    downloadCSV(downloadCSVReport);
  };

  const pageSize = 10;
  const formProps = { values, handleBlur, handleChange, touched, errors, setFieldValue };
  return (
    <>
      <Container>
        <Typography variant="h2" className="text-md lg:text-2xl font-bold">
          Generate or Download Report
        </Typography>
        <Typography className="text-gray-400 mb-3">Choose Report Type</Typography>
        <div className="-mx-4 lg:-mx-9 overflow-hidden ">
          <Tabpane selectedTab={selectedTab} setSelectedTab={setSelectedTab} values={currentUser?.userType === "Manager" ? tabpaneListAdmin : tabpaneList} />
        </div>
        <form onSubmit={handleSubmit}>
          <div>
            {selectedTab == 1 && <ComplaintReport formProps={formProps} />}
            {selectedTab == 2 && <LeadReport formProps={formProps} />}
            {selectedTab == 3 && <UserReport formProps={formProps} />}
            {selectedTab == 4 && <SettledCaseReport formProps={formProps} />}
            {selectedTab == 5 && <ActiveUserReport formProps={formProps} />}
          </div>
          <Divide className="my-6 shadow-above-border -mx-4 lg:-mx-9" />
          <div className=" flex justify-evenly md:justify-end  gap-2">
            <ButtonOutline type="submit" className={`hover:bg-black hover:text-white  border-black w-full md:w-42 ${!isEmpty(errors) && "opacity-50 pointer-events-none"}`} title="Generate Report" />
            <ButtonOutline type="button" onClick={downloadReportHandler} className={`bg-polyfixTheme  w-full md:w-42 ${!downloadCSVReport && "opacity-50 pointer-events-none"}`} title="Download Report" />
          </div>
        </form>
      </Container>
      {(tableDataArr[0]?.complaintData.length > 0 || tableDataArr[1]?.leadData.length > 0 || tableDataArr[2]?.userData.length > 0 || tableDataArr[3]?.settledCaseData.length > 0 || tableDataArr[4]?.activeUserData.length > 0) && (
        <Container customClassName="mt-8 ">
          {tableData.loading ? (
            <Spinner />
          ) : (
            <>
              {selectedTab == 1 ? <SimpleTable currentPage={currentPage - 1} pageSize={pageSize} tableHeading={reportTableHeading} tableKeys={reportTableKeys} tableData={tableDataArr[0].complaintData} /> : selectedTab == 2 ? <SimpleTable currentPage={currentPage - 1} pageSize={pageSize} tableHeading={reportTableHeading} tableKeys={reportTableKeys} tableData={tableDataArr[1].leadData} /> : selectedTab == 3 ? <SimpleTable currentPage={currentPage - 1} pageSize={pageSize} tableHeading={userReportHeading} tableKeys={userReportTableKeys} tableData={tableDataArr[2].userData} /> : selectedTab === 4 ? <SimpleTable currentPage={currentPage - 1} pageSize={pageSize} tableHeading={settledTableReportHeading} tableKeys={settledTableReportKeys} tableData={tableDataArr[3].settledCaseData} /> : <SimpleTable currentPage={currentPage - 1} pageSize={pageSize} tableHeading={activeUserReportHeading} tableKeys={activeUserReportTableKeys} tableData={tableDataArr[4]?.activeUserData} />}

              <Divide className="my-4 invisible" />
              <Pagination itemsPerPage={pageSize} totalItems={selectedTab == 1 ? tableDataArr[0].count : selectedTab == 2 ? tableDataArr[1].count : selectedTab == 3 ? tableDataArr[2].count : tableDataArr[3].count} setCurrentPage={setCurrentPage} currentPage={currentPage} />
            </>
          )}
        </Container>
      )}
    </>
  );
};

export default Report;
