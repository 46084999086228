import { MdKeyboardDoubleArrowDown } from "react-icons/md";
import Typography from "../../../component/semantic/Typography";
import React from "react";
import { useAppSelector } from "../../../hooks/redux_toolkit";

interface CardsProps {
  bg?: string;
  img?: string;
  title: string;
  count: number;
  amount: any;
}

const Cards: React.FC<CardsProps> = ({ bg, img, title, count, amount }) => {
  const isLoading = useAppSelector((state) => state.rootReducer.dashboard.cardLoading);
  return (
    <div className="flex gap-3">
      {img && (
        <div style={{ backgroundColor: bg }} className={`w-[4.5rem] h-[4.5rem] lg:w-24 lg:h-24 rounded-full flex justify-center items-center`}>
          <img src={img} className="w-8 h-8 lg:h-12 lg:w-12  rounded-full" alt="Lead Info" />
        </div>
      )}
      <div className="flex flex-col justify-between ml-3">
        <Typography className="flex items-center text-customgray gap-1">
          {title}{" "}
          <span>
            <MdKeyboardDoubleArrowDown />
          </span>
        </Typography>
        <div className="flex flex-row flex-wrap md:flex-nowrap lg:flex-col gap-3 lg:gap-0 items-center lg:items-start">
          {isLoading ? <div className="h-4 bg-gray-400 rounded-full dark:bg-gray-700 w-12 mb-2.5 animate-pulse"></div> : <p className="text-3xl font-extrabold">{count}</p>}

          {isLoading ? (
            <div className="h-4 bg-gray-400 rounded-full dark:bg-gray-700 w-20 mb-2.5 animate-pulse"></div>
          ) : (
            <Typography variant="h2">
              ₹<span className={`${title !== "Rejected Leads" ? "text-[#00AC4F]" : "text-[#F03131]"} ml-1`}>{amount}</span>
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
};

export default Cards;
