import React, { useEffect, useState } from "react";
import Container from "../../component/common/Container";
import CustomInput from "../../component/semantic/Input";
import { CiFilter } from "react-icons/ci";
import Typography from "../../component/semantic/Typography";
import ActionTable from "../../component/table/ActionTable";
import { useDispatch, useSelector } from "react-redux";
import { getPayoutMarkingList, markPaidPayoutMarking } from "../../store/thunk/PayoutMarking";
import ButtonOutline from "../../component/semantic/Button";
import emptyLead from "../../assets/icons/lead/emptyLead.svg";
import { getCurrentUser } from "../../helpers/currentUser";
import dayjs from "dayjs";

function PayoutMarking() {
  const tabs = [
    {
      id: "tab-1",
      label: "9th - 23th",
      value: "9-23"
    },
    {
      id: "tab-2",
      label: "24th - 8th",
      value: "24-8"
    }
  ];

  const dispatch = useDispatch();
  const state = useSelector((state: any) => state?.rootReducer?.PayoutMarking);
  const [selectedMonth, setSelectedMonth] = useState(dayjs().format("YYYY-MM"));
  // eslint-disable-next-line
  const [selectedRow, setSelectedRow] = useState<any>();

  const [activeTab, setActiveTab] = useState({
    id: "tab-1",
    label: "9th - 23th",
    value: "9-23"
  });

  useEffect(() => {
    const [year, month] = selectedMonth.split("-");
    if (!year) return;
    if (!month) return;
    dispatch(getPayoutMarkingList({ dateFilter: activeTab.value, year, month }));
  }, [activeTab]);

  const handleMarkPayoutMarking = (rowData: any) => {
    const payload: any = {
      remunerationUpdateArray: [
        {
          insuranceId: rowData?._id,
          remunerationHistoryId: rowData?.remunerationHistoryId
        }
      ]
    };
    dispatch(markPaidPayoutMarking(payload));
  };

  const currentUser = getCurrentUser();

  const tableData: [] = state?.data || [];
  const headingsAdmin = ["S.No", "Name", "Email", "Level", "Lead Number", "Claim Amount", "Commission", "TDS Amount", "Pay Amount", "Action"];
  const tableKeysAdmin: any = ["s.no", "name", "email", "level", "leadNumbers", "claimAmount", "commisionAmount", "tds", "finalPayableAmount", "action"];
  const headingsUser = ["S.No", "Name", "Email", "Level", "Lead Number", "Claim Amount", "Commission", "TDS Amount", "Pay Amount"];
  const tableKeysUser: any = ["s.no", "name", "email", "level", "leadNumbers", "claimAmount", "commisionAmount", "tds", "finalPayableAmount"];

  return (
    <div className="">
      <div className="mb-4">
        <div className="rounded-lg  bg-inherit p-0 m-0 px-6  sm:m-0 md:bg-white lg:bg-white xl:bg-white 2xl:bg-white :bg-inheri t">
          <div className="flex w-full py-3 items-center">
            <div className="flex gap-5 justify-between items-center mx-auto w-auto md:w-[800px] lg:w-[1000px] xl:w-[1000px] 2xl:w-[1200px]">
              <div className="md:flex lg:flex  xl:flex 2xl:flex gap-4 items-center">
                <div className="flex justify-between items-center mb-2">
                  <div className="flex items-center gap-2">
                    <CiFilter className="text-[#FAAA52]" size={20} />
                    <Typography className="w-20">Filter By</Typography>
                  </div>
                  <Typography variant="h2" className="text-polyfixTheme cursor-pointer block md:hidden lg:hidden xl:hidden 2xl:hidden" onClick={() => setSelectedMonth("")}>
                    Clear Filters
                  </Typography>
                </div>
                <div className="md:w-[280px] lg:w-[280px] xl:w-[280px] 2xl:w-[280px] w-[332px]">
                  <CustomInput placeholder="Enter Your Search" className="w-full" type="month" value={selectedMonth} onChange={(e) => setSelectedMonth(e.target.value)} />
                </div>
                <div></div>
              </div>
            </div>
            <ButtonOutline
              title="Clear Filter"
              color="polyfixTheme"
              className="hover:bg-polyfixTheme hover:text-white w-32 mb-2 d-block hidden md:block lg:block xl:block 2xl:block"
              onClick={() => {
                setSelectedMonth("");
              }}
            />
          </div>
        </div>
      </div>
      <Container>
        <div>
          <div className="font-medium text-3xl">
            <Typography>Payout Marking</Typography>
          </div>
          <div className="mt-3 text-[#666666]">
            <Typography>List of all partners, Associates and Advisors.</Typography>
          </div>
        </div>
        <div className="border-b-2">
          <ul className="flex flex-wrap -mb-px text-sm font-medium text-center" id="default-styled-tab" data-tabs-toggle="#default-styled-tab-content" data-tabs-active-classes="text-purple-600 hover:text-purple-600 dark:text-purple-500 dark:hover:text-purple-500 border-purple-600 dark:border-purple-500" data-tabs-inactive-classes="dark:border-transparent text-gray-500 hover:text-gray-600 dark:text-gray-400 border-gray-100 hover:border-gray-300 dark:border-gray-700 dark:hover:text-gray-300" role="tablist">
            {tabs?.map((res): any => {
              return (
                <li className="me-2 py-4" key={res.id} role="presentation">
                  <button className={`inline-block p-4 text-[1rem] md:text-[1.5rem] xl:text-[1.5rem] 2xl:text-[1.5rem] px-6 rounded-t-lg ${activeTab?.id == res.id ? "border-b-4 border-[#FAAA52] " : ""}`} id="profile-styled-tab" data-tabs-target="#styled-profile" type="button" role="tab" aria-controls="profile" aria-selected="false" onClick={() => setActiveTab(res)}>
                    {res.label}
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
        {!tableData?.length ? (
          <div className="h-72 flex flex-col justify-center gap-3 items-center">
            <img src={emptyLead} />
            <Typography variant="h3" className="text-[#666666]">
              No data available for this status
            </Typography>
          </div>
        ) : (
          <ActionTable actions={currentUser?.userType == "Manager" ? [{ image: <ButtonOutline title="PAID" className="bg-green-500 font-bold" />, tooltip: "", onClickFunction: (rowData: any) => handleMarkPayoutMarking(rowData), isDisplay: (data: any) => (data?.isPaid == true ? 1 : 2), actionPlaceholder: <span className="bg-green-400 px-2 rounded-sm">Paid</span> }] : []} selectedRow={setSelectedRow} tableData={tableData} tableHeading={currentUser?.userType == "Manager" ? headingsAdmin : headingsUser} tableKeys={currentUser?.userType == "Manager" ? tableKeysAdmin : tableKeysUser} />
        )}
      </Container>
    </div>
  );
}

export default PayoutMarking;
