import { createSlice } from "@reduxjs/toolkit";
import { barCodeGenrator, updateReferralCode } from "../thunk/Referral.thunk";

interface ApiState {
  data: any;
  loading: "idle" | "pending" | "fulfilled" | "rejected";
  error: string | null;
  updateReferral: object;
  barCodeData: object;
  showToast: {
    show: boolean;
    message?: string;
    status?: string;
  };
}

const initialState: ApiState = {
  data: null,
  loading: "idle",
  error: null,
  updateReferral: {},
  barCodeData: {},
  showToast: {
    show: false
  }
};

const referralSlice = createSlice({
  name: "referralSlice",
  initialState,
  reducers: {
    hideToastReferral: (state) => {
      state.showToast = { show: false };
    }
  },
  extraReducers: (builder) => {
    builder

      .addCase(updateReferralCode.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(updateReferralCode.fulfilled, (state, action) => {
        state.loading = "fulfilled";
        state.updateReferral = action.payload;
        state.error = null;
        if (action.payload.success == false) {
          state.showToast = {
            show: true,
            message: action.payload.message,
            status: "error"
          };
        }
      })
      .addCase(updateReferralCode.rejected, (state, action) => {
        state.loading = "rejected";
        state.error = action.error.message ?? "An error occurred";
      })

      .addCase(barCodeGenrator.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(barCodeGenrator.fulfilled, (state, action) => {
        state.loading = "fulfilled";
        state.barCodeData = action.payload;
        state.error = null;
      })
      .addCase(barCodeGenrator.rejected, (state, action) => {
        state.loading = "rejected";
        state.error = action.error.message ?? "An error occurred";
      });
  }
});

export const { hideToastReferral } = referralSlice.actions;

export default referralSlice.reducer;
