import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../helpers/axios";
import { baseUrl } from "../../helpers/constants/defaultUrls";

interface initUserArgs {
  page: number;
  size: number;
  search: string;
}

interface deactivateInitUserArgs {
  mobile: string;
}

export const getInitUserThunk = createAsyncThunk("initUser/getInitUserThunk", async (payload: initUserArgs) => {
  try {
    const response = await axios.get<{ data: any }>(baseUrl + `v1/app/user/getInitUser?page=${payload.page - 1}&size=${payload.size}&search=${payload.search}`);
    return response.data;
  } catch (error: any) {
    return error;
  }
});

export const deactivateInitUserThunk = createAsyncThunk("initUser/deactivateInitUserThunk", async (payload: deactivateInitUserArgs) => {
  try {
    const response = await axios.post<{ data: any }>(baseUrl + `v1/app/user/deactivateInitUser`, payload);
    return response.data;
  } catch (error: any) {
    return error;
  }
});

export const approveUserThunk = createAsyncThunk("initUser/approveUserThunk", async (payload: any) => {
  try {
    const response = await axios.post<{ data: any }>(baseUrl + `v1/auth/signup`, payload);
    return response.data;
  } catch (error: any) {
    return error;
  }
});

export const activateUser = createAsyncThunk("initUser/activateUserThunk", async (payload: any) => {
  try {
    const response = await axios.post<{ data: any }>(baseUrl + `v1/app/user/activateUser`, payload);
    return response.data;
  } catch (error: any) {
    return error;
  }
});

export const softDelete = createAsyncThunk("initUser/softDeleteUserThunk", async (payload: any) => {
  try {
    const response = await axios.post<{ data: any }>(baseUrl + `v1/app/user/softDeleteUser`, payload);
    return response.data;
  } catch (error: any) {
    return error;
  }
});
export const updateAppUserThunk = createAsyncThunk("initUser/updateAppUserThunk", async (payload: any) => {
  try {
    const response = await axios.put<{ data: any }>(baseUrl + `v1/app/user/updateAppUser`, payload);
    return response.data;
  } catch (error: any) {
    return error;
  }
});
