import React, { FC } from "react";
import totalOnboard from "../../../assets/images/dashboard/totalOnboard.svg";
import signupOnboard from "../../../assets/images/dashboard/signupMonth.svg";
import Container from "../../../component/common/Container";
import Typography from "../../../component/semantic/Typography";
import { MdKeyboardDoubleArrowDown } from "react-icons/md";
import Divide from "../../../component/semantic/Divide";
import { useAppSelector } from "../../../hooks/redux_toolkit";

interface AgentProps {
  title: string;
  img: string;
  desc: string;
  count: number;
}

const AgentDetails = () => {
  const dashboardData = useAppSelector((state) => state.rootReducer.dashboard.cardData?.data);

  return (
    <Container customClassName="mt-6 ">
      <Typography variant="h3" className="hidden md:block">
        Agent Details
      </Typography>
      <div className="mt-6 flex flex-col md:flex-row gap-5 flex-wrap justify-between">
        <AgentCard title="Total Onboard Agents" count={dashboardData?.totalOnboardPartner || 0} img={totalOnboard} desc="Total Onboarded Agents Till Now." />
        <Divide horizontal={true} className="hidden md:block" />
        <Divide className="block md:hidden" />
        <AgentCard title="Current Month Total Onboard Agents" count={dashboardData?.totalOnboardPartnerMonth || 0} img={totalOnboard} desc="Total Onboarded Agents in This Month." />
        <Divide horizontal={true} className="hidden md:block" />
        <Divide className="block md:hidden" />
        <AgentCard title="Total Signup in Current Month" count={dashboardData?.totalSignupPartnerMonth || 0} img={signupOnboard} desc="Total Agents Signup in This Month." />
      </div>
    </Container>
  );
};

const AgentCard: FC<AgentProps> = ({ title, img, desc, count }) => {
  const isLoading = useAppSelector((state) => state.rootReducer.dashboard.cardLoading);

  return (
    <div>
      <div className="flex items-start gap-5">
        <img src={img} className="w-[4.5rem] h-[4.5rem] lg:w-24 lg:h-24  rounded-full" />
        <div className="flex flex-col justify-between">
          <Typography className="flex items-start  leading-5 text-gray-500 ">
            <span className="w-40"> {title} </span>
            <MdKeyboardDoubleArrowDown className="mt-1" />
          </Typography>
          {isLoading ? <div className="h-8 bg-gray-400 rounded-full dark:bg-gray-700 w-24  animate-pulse mt-3"></div> : <p className="text-3xl font-extrabold mt-2">{count}</p>}
        </div>
      </div>
      <div className="flex gap-3 items-center mt-2">
        <p className="w-2 rounded-full h-2 bg-[#7556B0]"></p>
        <Typography className="text-gray-600">{desc}</Typography>
      </div>
    </div>
  );
};

export default AgentDetails;
