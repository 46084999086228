import { useEffect, useRef, useState } from 'react';
import io from 'socket.io-client';

interface SocketService {
  isConnected: boolean;
  emit: (event: string, data?: any) => void;
  on: (event: string, callback: (...args: any[]) => void) => void;
  off: (event: string, callback: (...args: any[]) => void) => void;
  disconnect: () => void; // New function to disconnect the socket
}

const useSocketService = (url: string): SocketService => {
  const [isConnected, setIsConnected] = useState(false);
  const socketRef = useRef<any>(null);

  // Function to emit events
  const emit = (event: string, data?: any) => {
    if (socketRef.current) {
      socketRef.current.emit(event, data);
    }
  };

  // Function to listen to events
  const on = (event: string, callback: (...args: any[]) => void) => {
    if (socketRef.current) {
      socketRef.current.on(event, callback);
    }
  };

  // Function to stop listening to events
  const off = (event: string, callback: (...args: any[]) => void) => {
    if (socketRef.current) {
      socketRef.current.off(event, callback);
    }
  };

  // Function to disconnect the socket
  const disconnect = () => {
    if (socketRef.current) {
      socketRef.current.disconnect();
      setIsConnected(false);
    }
  };

  useEffect(() => {
    // Create a new socket connection when the component mounts
    socketRef.current = io(url);

    // Event listener for connection
    socketRef.current.on('connect', () => {
      setIsConnected(true);
    });

    // Clean up the socket connection when the component unmounts
    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, [url]);

  return { isConnected, emit, on, off, disconnect };
};

export default useSocketService;
