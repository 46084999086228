import { createSlice } from "@reduxjs/toolkit";
import { uploadBulkBlockUsersFile, uploadBulkSignupFile } from "../thunk/BulkAction.thunk";
interface ApiState {
  loading: "idle" | "pending" | "fulfilled" | "rejected";
  error: string | null;
  navigationUrl: string;
}

const initialState: ApiState = {
  loading: "idle",
  error: null,
  navigationUrl: ""
};

const bulkAction = createSlice({
  name: "bulkAction",
  initialState,
  reducers: {
    emptyNavigationUrl: (state) => {
      state.navigationUrl = "";
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadBulkSignupFile.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(uploadBulkSignupFile.fulfilled, (state) => {
        state.loading = "fulfilled";
        state.error = null;
      })
      .addCase(uploadBulkSignupFile.rejected, (state, action) => {
        state.loading = "rejected";
        state.error = action.error.message ?? "An error occurred";
      })
      .addCase(uploadBulkBlockUsersFile.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(uploadBulkBlockUsersFile.fulfilled, (state) => {
        state.loading = "fulfilled";
        state.error = null;
      })
      .addCase(uploadBulkBlockUsersFile.rejected, (state, action) => {
        state.loading = "rejected";
        state.error = action.error.message ?? "An error occurred";
      });
  }
});

export const { emptyNavigationUrl } = bulkAction.actions;

export default bulkAction.reducer;
