import React, { FC, useEffect, useState } from "react";
import Modal from "../../../component/common/Modal";
import CloseIcon from "../../../assets/icons/gallery/close.svg";
import Typography from "../../../component/semantic/Typography";
import ButtonOutline from "../../../component/semantic/Button";
import { toast } from "react-toastify";
import { useAppDispatch } from "../../../hooks/redux_toolkit";
import { updateAppUserThunk } from "../../../store/thunk/initUser.thunk";

interface ModalProps {
  showModal: boolean;
  setOpenActionModal: any;
  data: any;
  setToggleApiCall: any;
  toggleApiCall: boolean;
}

const EditDetails: FC<ModalProps> = ({ showModal, setOpenActionModal, data, toggleApiCall, setToggleApiCall }) => {
  const dispatch = useAppDispatch();
  const [partnerType, setPartnerType] = useState<string>("");
  const [revenueType, setRevenueType] = useState<string>("");
  const [invoiceTo, setInvoiceTo] = useState<string>("Customer");
  const [isRevenueSharing, setIsRevenueSharing] = useState<boolean>(false);
  const [isCustomerContractSigning, setIsCustomerContractSigning] = useState<boolean>(false);
  const [isLeadCost, setIsLeadCost] = useState<boolean>(false);
  const [leadAmount, setLeadAmount] = useState<string>("0");
  const [customerContractSignedRate, setCustomerContractSignedRate] = useState<string>("0");
  const [partnerContractSignedRate, setPartnerContractSignedRate] = useState<string>("0");
  const [isCustomerRegistrationFees, setIsCustomerRegistrationFees] = useState<boolean>(false);
  const [isCustomerCommunicationEmail, setIsCustomerCommunicationEmail] = useState<boolean>(false);
  const [isCustomerCalling, setIsCustomerCalling] = useState<boolean>(false);
  const [successFeeRate, setSuccessFeeRate] = useState("0");

  const handleSave = () => {
    // Simulating a save action

    const formData = {
      id: data._id,
      partnerType,
      revenueType,
      isInvoiceRaisedToCustomer: invoiceTo == "Customer" ? true : false,
      isRevenueSharing,
      isCustomerContractSigning,
      isCustomerRegistrationFees,
      isLeadCost,
      customerContractSignedRate,
      partnerContractSignedRate,
      isCustomerCommunicationEmail,
      isCustomerCalling,
      leadAmount,
      successFeeRate
    };

    dispatch(updateAppUserThunk({ ...formData }))
      .unwrap()
      .then(() => {
        setToggleApiCall(!toggleApiCall);
      })
      .catch((error: any) => {
        toast.error(error.message ? error.message : "Something went wrong");
      });
    toast.success("Details saved successfully!");

    // Close the modal
    setOpenActionModal(false);
  };

  useEffect(() => {
    setPartnerType(data?.partnerType || "");
    setRevenueType(data?.revenueType || "");
    setInvoiceTo(data?.isInvoiceRaisedToCustomer ? "Customer" : "Partner/Company");
    setIsRevenueSharing(data?.isRevenueSharing);
    setIsCustomerContractSigning(data?.isCustomerContractSigning);
    setIsLeadCost(data?.isLeadCost);
    setLeadAmount(data?.leadAmount || "0");
    setCustomerContractSignedRate(data?.customerContractSignedRate || "0");
    setPartnerContractSignedRate(data?.partnerContractSignedRate || "0");
    setIsCustomerRegistrationFees(data?.isCustomerRegistrationFees);
    setIsCustomerCommunicationEmail(data?.isCustomerCommunicationEmail);
    setIsCustomerCalling(data?.isCustomerCalling);
    setSuccessFeeRate(data?.successFeeRate || "0");
  }, [data]);

  return (
    <Modal showModal={showModal} onClose={() => setOpenActionModal(false)} className="relative w-[45%] z-[99]">
      <img src={CloseIcon} className="absolute z-50 text-3xl text-gray-400 -right-3 -top-3 cursor-pointer" onClick={() => setOpenActionModal(false)} />
      <Typography variant="h1" className="px-8 pt-4">
        Edit User Details
      </Typography>
      <div className="relative h-[50vh] pb-8 overflow-y-scroll custom-scrollbar2 pr-4 p-8">
        <div className="mt-4">
          <label className="block mb-2 font-bold">Partner Type</label>
          <select
            value={partnerType}
            onChange={(e) => {
              setPartnerType(e.target.value);
              if (e.target.value != "noRevenueSharing") {
                setRevenueType("");
              } else {
                setInvoiceTo("Partner/Company");
                setIsRevenueSharing(false);
                setIsCustomerContractSigning(false);
                setIsLeadCost(false);
              }
            }}
            className="w-full p-2 border border-gray-300 rounded"
          >
            <option value="">Select</option>
            <option value="noRevenueSharing">No Revenue Sharing</option>
            <option value="revenueSharing">Revenue sharing (Dynamic %) + Lead cost sharing</option>
            <option value="leadCost">Only Lead Cost</option>
            <option value="hierarchyWise">Hierarchy Wise</option>
          </select>
        </div>
        {partnerType != "" && partnerType == "noRevenueSharing" && (
          <div className="mt-4">
            <label className="block mb-2 font-bold">No Revenue Sharing Type</label>
            <select
              value={revenueType}
              onChange={(e) => {
                setRevenueType(e.target.value);
                if (e.target.value == "firstType") {
                  setInvoiceTo("Customer");
                  setIsCustomerContractSigning(true);
                } else if (e.target.value == "secondType") {
                  setInvoiceTo("Partner/Company");
                  setIsCustomerContractSigning(false);
                }
              }}
              className="w-full p-2 border border-gray-300 rounded"
            >
              <option value="">Select</option>
              <option value="firstType">First Type</option>
              <option value="secondType">Second Type</option>
            </select>
          </div>
        )}
        {partnerType != "" && (
          <div className="mt-4">
            <label className="block mb-2 font-bold">Invoice to be raised to</label>
            <div className="flex space-x-8">
              <label className="flex items-center w-[40%]">
                <input type="radio" name="invoiceTo" value="Customer" checked={invoiceTo === "Customer"} onChange={() => setInvoiceTo("Customer")} className="text-gray-500 accent-[black] p-2" />
                <span className="ml-2">Customer</span>
              </label>
              <label className="flex items-center">
                <input type="radio" name="invoiceTo" value="Partner/Company" checked={invoiceTo === "Partner/Company"} onChange={() => setInvoiceTo("Partner/Company")} className="text-gray-500 accent-[black] p-2" />
                <span className="ml-2">Partner/Company</span>
              </label>
            </div>
          </div>
        )}

        {partnerType != "" && (
          <div className="mt-4">
            <label className="block mb-2 font-bold">Revenue Sharing</label>
            <div className="flex space-x-8">
              <label className="flex items-center w-[40%]">
                <input type="radio" name="revenueSharing" value="Yes" checked={isRevenueSharing === true} onChange={() => setIsRevenueSharing(true)} className="text-gray-500 accent-[black] p-2" />
                <span className="ml-2">Yes</span>
              </label>
              <label className="flex items-center">
                <input type="radio" name="revenueSharing" value="No" checked={isRevenueSharing === false} onChange={() => setIsRevenueSharing(false)} className="text-gray-500 accent-[black] p-2" />
                <span className="ml-2">No</span>
              </label>
            </div>
          </div>
        )}

        {partnerType != "" && (
          <div className="mt-4">
            <label className="block mb-2 font-bold">Customer Contract to be signed</label>
            <div className="flex space-x-8">
              <label className="flex items-center w-[40%]">
                <input type="radio" name="contractSigned" value="Yes" checked={isCustomerContractSigning === true} onChange={() => setIsCustomerContractSigning(true)} className="text-gray-500 accent-[black] p-2" />
                <span className="ml-2">Yes</span>
              </label>
              <label className="flex items-center">
                <input type="radio" name="contractSigned" value="No" checked={isCustomerContractSigning === false} onChange={() => setIsCustomerContractSigning(false)} className="text-gray-500 accent-[black] p-2" />
                <span className="ml-2">No</span>
              </label>
            </div>
          </div>
        )}

        {partnerType != "" && (
          <div className="mt-4">
            <label className="block mb-2 font-bold">Customer Registration Fee</label>
            <div className="flex space-x-8">
              <label className="flex items-center w-[40%]">
                <input type="radio" name="regFee" value="Yes" checked={isCustomerRegistrationFees === true} onChange={() => setIsCustomerRegistrationFees(true)} className="text-gray-500 accent-[black] p-2" />
                <span className="ml-2">Yes</span>
              </label>
              <label className="flex items-center">
                <input type="radio" name="regFee" value="No" checked={isCustomerRegistrationFees === false} onChange={() => setIsCustomerRegistrationFees(false)} className="text-gray-500 accent-[black] p-2" />
                <span className="ml-2">No</span>
              </label>
            </div>
          </div>
        )}

        {partnerType != "" && (
          <div className="mt-4">
            <label className="block mb-2 font-bold">Lead Cost to be given to Partner (only after successful lead generation)</label>
            <div className="flex space-x-8">
              <label className="flex items-center w-[40%]">
                <input type="radio" name="leadCost" value="Yes" checked={isLeadCost} onChange={() => setIsLeadCost(true)} className="text-gray-500 accent-[black] p-2" />
                <span className="ml-2 text-gray-500">Yes</span>
              </label>
              <label className="flex justify-end items-center">
                <input type="radio" name="leadCost" value="No" checked={!isLeadCost} onChange={() => setIsLeadCost(false)} className="text-gray-500 accent-[black] p-2" />
                <span className="ml-2 text-gray-500">No</span>
              </label>
            </div>
          </div>
        )}

        {partnerType != "" && (
          <div className="mt-4">
            <label className="block mb-2 font-bold">Customer Communication Email</label>
            <div className="flex space-x-8">
              <label className="flex items-center w-[40%]">
                <input type="radio" name="communicationEmail" value="Yes" checked={isCustomerCommunicationEmail === true} onChange={() => setIsCustomerCommunicationEmail(true)} className="text-gray-500 accent-[black] p-2" />
                <span className="ml-2">Yes</span>
              </label>
              <label className="flex items-center">
                <input type="radio" name="communicationEmail" value="No" checked={isCustomerCommunicationEmail === false} onChange={() => setIsCustomerCommunicationEmail(false)} className="text-gray-500 accent-[black] p-2" />
                <span className="ml-2">No</span>
              </label>
            </div>
          </div>
        )}

        {partnerType != "" && (
          <div className="mt-4">
            <label className="block mb-2 font-bold">Customer Calling</label>
            <div className="flex space-x-8">
              <label className="flex items-center w-[40%]">
                <input type="radio" name="customerCalling" value="Yes" checked={isCustomerCalling === true} onChange={() => setIsCustomerCalling(true)} className="text-gray-500 accent-[black] p-2" />
                <span className="ml-2">Yes</span>
              </label>
              <label className="flex items-center">
                <input type="radio" name="customerCalling" value="No" checked={isCustomerCalling === false} onChange={() => setIsCustomerCalling(false)} className="text-gray-500 accent-[black] p-2" />
                <span className="ml-2">No</span>
              </label>
            </div>
          </div>
        )}

        {partnerType != "" && isCustomerContractSigning && (
          <div className="mt-4">
            <label className="block mb-2 font-bold">Customer Contract Signed %</label>
            <input
              type="number"
              min="0"
              value={customerContractSignedRate}
              onChange={(e: any) => {
                const value = e.target.value;
                if (value < 0) {
                  toast("Customer Contract rate cannot be less than zero.");
                  return;
                }
                setCustomerContractSignedRate(value);
              }}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
        )}

        {partnerType != "" && isRevenueSharing && (
          <div className="mt-4">
            <label className="block mb-2 font-bold">Partner Contract Signed %</label>
            <input
              type="number"
              min="0"
              value={partnerContractSignedRate}
              onChange={(e: any) => {
                const value = e.target.value;
                if (value < 0) {
                  toast("Parnter Contract rate cannot be less than zero.");
                  return;
                }
                setPartnerContractSignedRate(value);
              }}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
        )}

        {partnerType != "" && isLeadCost && (
          <div className="mt-4">
            <label className="block mb-2 font-bold">Lead Cost Amount</label>
            <input
              type="number"
              min="0"
              value={leadAmount}
              onChange={(e: any) => {
                const value = e.target.value;
                if (value < 0) {
                  toast("Lead Amount cannot be less than zero.");
                  return;
                }
                setLeadAmount(value);
              }}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
        )}

        {partnerType != "" && revenueType == "secondType" && (
          <div className="mt-4">
            <label className="block mb-2 font-bold">Success Fee Percentage Chargeable</label>
            <input
              type="number"
              min="0"
              value={successFeeRate}
              onChange={(e: any) => {
                const value = e.target.value;
                if (value < 0) {
                  toast("Success Fee cannot be less than zero.");
                  return;
                }
                setSuccessFeeRate(value);
              }}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
        )}
      </div>
      <div className="flex justify-end items-center w-full mt-6 shadow-above-border pt-4">
        <ButtonOutline onClick={() => setOpenActionModal(false)} className="text-white  bg-[#FF3C3CED] outline-[none]" type="button" title="Close" />
        <ButtonOutline onClick={handleSave} className="text-white bg-[#1BA785] outline-[none]" type="button" title="Save" />
      </div>
    </Modal>
  );
};

export default EditDetails;
