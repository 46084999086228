import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../helpers/axios";
import { baseUrl } from "../../helpers/constants/defaultUrls";

interface LeadCostTypes {
    dateFilter: string;
    month: number,
    year: number
}

export const getLeadCostData: any = createAsyncThunk("leadCost/getDataList", async (payload: LeadCostTypes) => {
    const response = await axios.post(baseUrl + "v1/lead/getLeadCostData", payload);
    return response.data;
});

export const markPayoutDone: any =  createAsyncThunk("leadCost/markPayoutDone", async (payload: LeadCostTypes) => {
    const response = await axios.post(baseUrl + "v1/lead/markPayoutDoneOfLeadCost", payload);
    return response.data;
});
