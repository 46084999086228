import React, { FC } from "react";
import Modal from "../../../../component/common/Modal";
import CloseIcon from "../../../../assets/icons/gallery/close.svg";

interface ReelModalProps {
  showReel: boolean;
  setShowReel: any;
  videoSrc: string;
  className?: string;
}

const ReelModal: FC<ReelModalProps> = ({ showReel, setShowReel, videoSrc, className }) => {
  return (
    <Modal showModal={showReel} className={` w-[25%] p-12 relative ${className}`} onClose={() => setShowReel(false)}>
      <img src={CloseIcon} className="absolute text-3xl text-gray-400 right-[-15px] top-[-20px] cursor-pointer" onClick={() => setShowReel(false)} />
      <video src={videoSrc} autoPlay className="w-full" controls />
    </Modal>
  );
};

export default ReelModal;
