import { FC } from "react";
import CloseIcon from "../../assets/icons/gallery/close.svg";
import Modal from "../common/Modal";
import ButtonOutline from "../semantic/Button";
import { getCurrentUser } from "../../helpers/currentUser";

interface ModalProps {
  showModal: boolean;
  setShowModal: any;
}

const ShareSamadhanModal: FC<ModalProps> = ({ showModal, setShowModal }) => {
  const currentUser = getCurrentUser();

  const url = `https://sharesamadhan.com/become-our-business-associate?name=${currentUser?.name}&email=${currentUser?.email}&phone=${currentUser?.mobile}&refBy=${currentUser?.referralCode}`;

  return (
    <Modal showModal={showModal} onClose={() => setShowModal(false)} className="flex p-0 flex-col relative w-[70%] z-50 items-center gap-4">
      <img src={CloseIcon} className="absolute text-3xl text-gray-400 -right-3 -top-3 cursor-pointer" onClick={() => setShowModal(false)} />
      <p className="font-extrabold text-3xl">Share Samadhan</p>
      <div className="bg-gray-200 rounded-lg  p-5 flex flex-col w-full h-[30.5rem] overflow-scroll custom-polifyx-scrollbar">
        <p className="font-bold text-xl mb-3  text-center">English</p>
        <p>
          <strong>Share Samadhan (सारे समाधान )</strong>, is India’s Largest Unclaimed Investment Retrieval Advisory company, assisting client to recover their Old / Lost / Forgotten / Blocked or Scattered Investment or Money from any asset class such as Old physical shares, Unclaimed PF, Unclaimed bank account, Unclaimed mutual fund or other similar investments.
        </p>
        <ul className="list-disc px-6 mt-4">
          <li className="list-item">
            We come with <strong>50+years</strong> of Financial / Capital Market Domain expertise.
          </li>
          <li>In the last 10 years, we have advised more than 20,000 clients and helped recovery of more than 100 crore worth of Investments.</li>
        </ul>
        <p className="mt-4">
          Invest and forget or Investing and not sharing with immediate family is typically story of most of the homes in the country. In case of any contingency, it becomes very difficult for family member to identity & recover the investment in various assets done by the earning member of the family. You can offer them the Investment retrieval advisory services. <strong>Share Samadhan (सारे समाधान )</strong> can be your resolution partner.
        </p>
        <strong className="text-lg mt-6">Few Highlights:</strong>
        <ol className="list-decimal px-6 mt-4">
          <li>
            India’s Largest Unclaimed Investment Retrieval advisory company, widely covered for its unique service offering in print and electronic media(&nbsp;
            <a href="https://www.youtube.com/watch?v=9EgtQWgipF0" rel="noreferrer" target="_blank" className="text-blue-600">
              https://www.youtube.com/watch?v=9EgtQWgipF0
            </a>
            &nbsp;)
          </li>
          <li>Professionally qualified team of Chartered Accountants / Company Secretary and lawyers under the same roof along with presence in more than 100 cities</li>
          <li>
            YouTube page for videos / customer testimonies- (&nbsp;
            <a href="https://www.youtube.com/c/ShareSamadhan1/videos" rel="noreferrer" target="_blank" className="text-blue-600">
              https://www.youtube.com/c/ShareSamadhan1/videos
            </a>
            &nbsp;)
          </li>
        </ol>
        <p className="font-bold text-xl mb-3 mt-8  text-center">हिंदी</p>
        <p>
          <strong>शेयर समाधान,</strong>, भारत की सबसे बड़ी निवेश या धन पुनर्प्राप्ति सलाहकार कंपनी है जो अपने पुराने / खोए / भुला दिए गए / अवरुद्ध या बिखरे हुए निवेश या धन को पुनर्प्राप्त करने के लिए ग्राहक की सहायता करती है, जैसे किसी भी संपत्ति वर्ग से पुराने शेयर, लावारिस पीएफ(PF) , लावारिस बैंक खाता, लावारिस म्यूचुअल फंड या अन्य फंसे निवेश।
        </p>
        <ul className="list-disc px-6 mt-4">
          <li className="list-item">
            हम <strong>50+ वर्ष</strong> के वित्तीय / पूंजी बाजार विशेषज्ञता के साथ आते हैं।
          </li>
          <li>पिछले 10 वर्षों में, हमने 20,000 से अधिक ग्राहकों को सलाह दी है और 100 करोड़ रुपये से अधिक के निवेश की वसूली में मदद की है।</li>
        </ul>
        <p className="mt-4">
          निवेश करके भूल जाना या निवेश करके अपने परिवार के साथ साझा नहीं करना आमतौर पर देश के अधिकांश घर की कहानी है। किसी भी आकस्मिक दुर्घटना के मामले में परिवार के अन्य सदस्य के लिए परिवार के कमाऊ सदस्य द्वारा की गई विभिन्न संपत्तियों में निवेश की वसूली करना बहुत मुश्किल हो जाता है। आप उन्हें सम्पति पुनर्प्राप्ति सलाह सेवाओं की पेशकश कर सकते हैं। <strong>शेयर समाधान</strong> इन सम्पति को फिर से हसिल करने में आपका पार्टनर हो सकता है।{" "}
        </p>
        <strong className="text-lg mt-6">हमारी कुछ सफलताएं:</strong>
        <ol className="list-decimal px-6 mt-4">
          <li>
            भारत की सबसे बड़ी लावारिस निवेशित पुनर्प्राप्ति सलाहकार कंपनी, जो प्रिंट और इलेक्ट्रॉनिक मीडिया में अपनी अनूठी सेवा की पेशकश के लिए व्यापक रूप से शामिल है(&nbsp;
            <a href="https://www.youtube.com/watch?v=9EgtQWgipF0" rel="noreferrer" target="_blank" className="text-blue-600">
              https://www.youtube.com/watch?v=9EgtQWgipF0
            </a>
            &nbsp;)
          </li>
          <li>100 से अधिक शहरों में उपस्थिति के साथ-साथ एक ही छत के नीचे चार्टर्ड अकाउंटेंट / कंपनी सेक्रेटरी और वकीलों की व्यावसायिक रूप से योग्य टीम</li>
          <li>
            वीडियो / ग्राहक प्रशंसापत्र के लिए यूट्यूब पेज- (&nbsp;
            <a href="https://www.youtube.com/c/ShareSamadhan1/videos" rel="noreferrer" target="_blank" className="text-blue-600">
              https://www.youtube.com/c/ShareSamadhan1/videos
            </a>
            &nbsp;)
          </li>
        </ol>
      </div>
      <div>
        <ButtonOutline onClick={() => window.open(url)} title="Visit Share Samadhan" className="bg-blue-400" />
      </div>
    </Modal>
  );
};

export default ShareSamadhanModal;
