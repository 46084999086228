import { Navigate } from "react-router-dom";
import HocComponents from "../component/hoc/hocWrapper";
import React from "react";
import Login from "../pages/login/Login.components";
import { getCurrentUser } from "../helpers/currentUser";
import Signup from "../pages/referral/componenet/Signup.referral";
import Recover from "../pages/referral/componenet/Recover.referral";
import AgentOnboarding from "../pages/agentOnboarding/AgentOnboarding.components";
import { PERMISSIONS } from "../helpers/constants/constants";
import Error from "./Error";
import UserContract from "../pages/userContract/UserContract.main";
import ValidToken from "./ValidToken";
import CreateLead from "../pages/agentOnboarding/CreateLead.main";

interface RouteType {
  path?: string;
  element?: React.ReactNode;
  errorElement?: React.ReactNode;
  privateRoute: boolean;
  children?: RouteType[];
  errorBoundary?: React.ReactNode;
  index?: any;
  loader?: () => Promise<any>;
  authorizationRequired?: Array<any>;
  renderToAll?: boolean;
}

const currentUser = getCurrentUser();

const routes: RouteType[] = [
  {
    privateRoute: false,
    renderToAll: true,
    children: [
      {
        index: true,
        path: "/",
        element: currentUser?.token ? <Navigate to="/dashboard" /> : <Navigate to="/login" />,
        privateRoute: true
      }
    ]
  },
  {
    privateRoute: false,
    renderToAll: true,
    children: [
      {
        index: true,
        path: "/login",
        element: <Login />,
        privateRoute: true
      }
    ]
  },
  {
    privateRoute: false,
    renderToAll: true,
    children: [
      {
        index: true,
        path: "/auth/login",
        element: <Navigate to="/login" />,
        privateRoute: true
      }
    ]
  },
  {
    privateRoute: false,
    renderToAll: true,
    children: [
      {
        index: true,
        path: "/auth/signup/:referralCode?",
        element: <Signup />,
        privateRoute: true
      }
    ]
  },
  {
    privateRoute: false,
    renderToAll: true,
    children: [
      {
        index: true,
        path: "/auth/recoverPassword",
        element: <Recover />,
        privateRoute: true
      }
    ]
  },
  {
    privateRoute: true,
    renderToAll: true,
    children: [
      {
        index: true,
        path: "/dashboard",
        element: <HocComponents.Dashboard />,
        privateRoute: true
      }
    ]
  },
  {
    privateRoute: true,
    authorizationRequired: [PERMISSIONS.ADMIN, PERMISSIONS.PARTNER, PERMISSIONS.ASSOCIATE, PERMISSIONS.ADVISOR],
    children: [
      {
        index: true,
        path: "/notification",
        element: <HocComponents.Notification />,
        privateRoute: true
      }
    ]
  },
  {
    privateRoute: true,
    authorizationRequired: [PERMISSIONS.ADMIN, PERMISSIONS.PARTNER, PERMISSIONS.ASSOCIATE, PERMISSIONS.ADVISOR],
    children: [
      {
        path: "/profile",
        element: <HocComponents.Profile />,
        privateRoute: true,
        children: []
      }
    ]
  },
  {
    privateRoute: true,
    authorizationRequired: [PERMISSIONS.PARTNER, PERMISSIONS.ASSOCIATE],
    children: [
      {
        path: "/referral",
        element: <HocComponents.Referral />,
        privateRoute: true,
        children: []
      }
    ]
  },
  {
    privateRoute: true,
    authorizationRequired: [PERMISSIONS.ADMIN, PERMISSIONS.PARTNER, PERMISSIONS.ASSOCIATE, PERMISSIONS.ADVISOR],
    children: [
      {
        path: "/requirement",
        element: <HocComponents.Requirement />,
        privateRoute: true,
        children: []
      }
    ]
  },
  {
    privateRoute: true,
    authorizationRequired: [PERMISSIONS.ADMIN, PERMISSIONS.PARTNER, PERMISSIONS.ASSOCIATE, PERMISSIONS.ADVISOR],
    children: [
      {
        path: "/reports",
        element: <HocComponents.Reports />,
        privateRoute: true,
        children: []
      }
    ]
  },
  {
    privateRoute: true,
    authorizationRequired: [PERMISSIONS.ADMIN, PERMISSIONS.PARTNER, PERMISSIONS.ASSOCIATE, PERMISSIONS.ADVISOR],
    children: [
      {
        path: "/chat",
        element: <HocComponents.Chat />,
        privateRoute: true,
        children: []
      }
    ]
  },
  {
    privateRoute: true,
    authorizationRequired: [PERMISSIONS.ADMIN, PERMISSIONS.PARTNER, PERMISSIONS.ASSOCIATE, PERMISSIONS.ADVISOR],
    children: [
      {
        path: "/gallery",
        element: <HocComponents.Gallery />,
        privateRoute: true,
        children: []
      }
    ]
  },
  {
    privateRoute: true,
    authorizationRequired: [PERMISSIONS.ADMIN, PERMISSIONS.PARTNER, PERMISSIONS.ASSOCIATE, PERMISSIONS.ADVISOR],
    children: [
      {
        path: "/resolvedCases",
        element: <HocComponents.ResolveCase />,
        privateRoute: true,
        children: []
      }
    ]
  },
  {
    privateRoute: true,
    authorizationRequired: [PERMISSIONS.ADMIN, PERMISSIONS.PARTNER, PERMISSIONS.ASSOCIATE, PERMISSIONS.ADVISOR],
    children: [
      {
        path: "/faq",
        element: <HocComponents.FAQ />,
        privateRoute: true,
        children: []
      }
    ]
  },
  {
    privateRoute: true,
    authorizationRequired: [PERMISSIONS.ADMIN, PERMISSIONS.PARTNER, PERMISSIONS.ASSOCIATE, PERMISSIONS.ADVISOR],
    children: [
      {
        path: "/changePassword",
        element: <HocComponents.ChangePassword />,
        privateRoute: true,
        children: []
      }
    ]
  },
  {
    privateRoute: true,
    authorizationRequired: [PERMISSIONS.ADMIN, PERMISSIONS.PARTNER, PERMISSIONS.ASSOCIATE, PERMISSIONS.ADVISOR],
    children: [
      {
        path: "/newCase",
        element: <HocComponents.NewCase />,
        privateRoute: true,
        children: []
      }
    ]
  },
  {
    privateRoute: true,
    authorizationRequired: [PERMISSIONS.ADMIN, PERMISSIONS.PARTNER, PERMISSIONS.ASSOCIATE, PERMISSIONS.ADVISOR],
    children: [
      {
        path: "/allLeads",
        element: <HocComponents.AllLeads />,
        privateRoute: true,
        children: []
      }
    ]
  },
  {
    privateRoute: true,
    authorizationRequired: [PERMISSIONS.PARTNER, PERMISSIONS.ASSOCIATE, PERMISSIONS.ADVISOR],
    children: [
      {
        path: "/userManagement",
        element: <HocComponents.UserManagement />,
        privateRoute: true,
        children: []
      }
    ]
  },
  {
    privateRoute: true,
    authorizationRequired: [PERMISSIONS.ADMIN, PERMISSIONS.PARTNER, PERMISSIONS.ASSOCIATE, PERMISSIONS.ADVISOR],
    children: [
      {
        path: "/allComplaints",
        element: <HocComponents.AllComplaints />,
        privateRoute: true,
        children: []
      }
    ]
  },
  {
    privateRoute: true,
    authorizationRequired: [PERMISSIONS.ADMIN],
    children: [
      {
        path: "/partnerManagement",
        element: <HocComponents.PartnerManagement />,
        privateRoute: true,
        children: []
      }
    ]
  },
  {
    privateRoute: true,
    authorizationRequired: [PERMISSIONS.ADMIN],
    children: [
      {
        path: "/newDashboard",
        element: <HocComponents.NewDashboard />,
        privateRoute: true,
        children: []
      }
    ]
  },
  {
    privateRoute: true,
    authorizationRequired: [PERMISSIONS.ADMIN],
    children: [
      {
        path: "/allUsers",
        element: <HocComponents.AllUsers />,
        privateRoute: true,
        children: []
      }
    ]
  },
  {
    privateRoute: true,
    authorizationRequired: [PERMISSIONS.ADMIN],
    children: [
      {
        path: "/appInitialUser",
        element: <HocComponents.AppInitialUser />,
        privateRoute: true,
        children: []
      }
    ]
  },
  {
    privateRoute: true,
    authorizationRequired: [PERMISSIONS.ADMIN, PERMISSIONS.PARTNER, PERMISSIONS.ASSOCIATE, PERMISSIONS.ADVISOR],
    children: [
      {
        path: "/payoutMarking",
        element: <HocComponents.PayoutMarking />,
        privateRoute: true,
        children: []
      }
    ]
  },
  {
    privateRoute: true,
    authorizationRequired: [PERMISSIONS.ADMIN],
    children: [
      {
        path: "/pendingContract",
        element: <HocComponents.PendingContract />,
        privateRoute: true,
        children: []
      }
    ]
  },
  // {
  //   privateRoute: true,
  //   authorizationRequired: [PERMISSIONS.ADMIN],
  //   children: [
  //     {
  //       path: "/newHospitalCase",
  //       element: <HocComponents.HospitalCase />,
  //       privateRoute: true,
  //       children: []
  //     }
  //   ]
  // },
  {
    privateRoute: true,
    authorizationRequired: [PERMISSIONS.ADMIN],
    children: [
      {
        path: "/leadCost",
        element: <HocComponents.LeadCost />,
        privateRoute: true,
        children: []
      }
    ]
  },
  {
    privateRoute: true,
    authorizationRequired: [PERMISSIONS.ADMIN],
    children: [
      {
        path: "/company",
        element: <HocComponents.Company />,
        privateRoute: true,
        children: []
      }
    ]
  },
  {
    privateRoute: true,
    authorizationRequired: [PERMISSIONS.ADMIN],
    children: [
      {
        path: "/executive-partner-mapping",
        element: <HocComponents.ExecutivePartnerMapping />,
        privateRoute: true,
        children: []
      }
    ]
  },
  {
    privateRoute: false,
    renderToAll: true,
    children: [
      {
        path: "/agent/onboarding",
        element: <AgentOnboarding />,
        privateRoute: true
      }
    ]
  },
  {
    privateRoute: false,
    renderToAll: true,
    children: [
      {
        path: "/auth/verify/:token",
        element: <ValidToken />,
        privateRoute: true
      }
    ]
  },
  {
    privateRoute: false,
    renderToAll: true,
    children: [
      {
        path: "/user/contractv2/:userId",
        element: <UserContract />,
        privateRoute: true
      }
    ]
  },
  {
    privateRoute: false,
    renderToAll: true,
    children: [
      {
        path: "/create/new/lead/:userId",
        element: <CreateLead />,
        privateRoute: true
      }
    ]
  },
  // {
  //   privateRoute: true,
  //   authorizationRequired: [PERMISSIONS.ADMIN],
  //   children: [
  //     {
  //       path: "/bulkAction",
  //       element: <HocComponents.BulkAction />,
  //       privateRoute: true,
  //       children: []
  //     }
  //   ]
  // },
  {
    privateRoute: false,
    renderToAll: true,
    children: [
      {
        path: "*",
        element: <Error />,
        privateRoute: true,
        children: []
      }
    ]
  }
];

const allRoutes: RouteType[] = routes;
export default allRoutes;
