import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./store/store";
import "react-toastify/dist/ReactToastify.css";
import "react-image-crop/dist/ReactCrop.css";
import { Outlet } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  //Useeffect runs twice because of this
  // <React.StrictMode>
  <Provider store={store}>
    <App />
    <Outlet />
  </Provider>
  // </React.StrictMode>
);
