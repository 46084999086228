import React, { useEffect, useState } from "react";
import Container from "../../component/common/Container";
import Typography from "../../component/semantic/Typography";
import Tabpane from "../../component/common/Tabpane";
import Festival from "./component/Festival.section";
import History from "../../assets/icons/gallery/history.svg";
import UploadCreative from "./component/UploadCreative";
import { HiDotsHorizontal } from "react-icons/hi";
import { useAppDispatch, useAppSelector } from "../../hooks/redux_toolkit";
import Referral from "./component/ReferralCreative";
import Info from "./component/InfoCreatives";
import BrandingCreatives from "./component/BrandingCreatives";
import AddLogo from "./component/Dialogs/Logo";
import SimpleTable from "../../component/table/SimpleTable";
import { uploadHistoryHeading, uploadHistoryKeys } from "../../component/table/TableConstants";
import Pagination from "../../component/common/Pagination";
import { UploadHistoryThunk, clearHistoryThunk } from "../../store/thunk/Gallery.thunk";
import Menu from "../../component/common/Menu";
import clearHistory from "../../assets/icons/gallery/clearHistory.svg";
import { toast } from "react-toastify";
import LeadCreative from "./component/leadCreative";
import { getCurrentUser } from "../../helpers/currentUser";
import { userListAllThunk } from "../../store/thunk/Common.thunk";
import { PERMISSIONS } from "../../helpers/constants/constants";

const Gallery = () => {
  const [addLogoModal, setShowAddLogoModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [showHistoryMenu, setHistoryMenu] = useState(false);
  const [logoImg, setLogoImg] = useState<any>();
  const [tabPaneValues, setTabPaneValue] = useState(["Lead Generation Creatives", "Referral Creatives", "Informative Creatives", "Festivities Creatives", "Branding Creatives"]);

  const dispatch = useAppDispatch();

  const currentUser = getCurrentUser();
  const hasExternalPermission = currentUser?.roles.some((role: string) => [PERMISSIONS.ADVISOR, PERMISSIONS.ASSOCIATE, PERMISSIONS.PARTNER].includes(role));
  const hasAdminPermission = currentUser?.roles.includes(PERMISSIONS.ADMIN);

  useEffect(() => {
    if (currentUser.userType !== "Vendor") {
      dispatch(userListAllThunk({ type: "Partner" }));
    } else {
      dispatch(userListAllThunk({ type: "Associate", parentId: currentUser?._id }));
    }
  }, []);

  const pageSize = 10;

  const [selectedTab, setSelectedTab] = useState(1);

  const contentHistory = useAppSelector((state) => state.rootReducer.gallery);

  useEffect(() => {
    if (hasAdminPermission) {
      setTabPaneValue(["Upload creatives", "Lead Generation Creatives", "Referral Creatives", "Informative Creatives", "Festivities Creatives", "Branding Creatives"]);
    }
  }, []);

  useEffect(() => {
    dispatch(UploadHistoryThunk({ pageSize, pageIndex: currentPage }));
  }, [currentPage]);

  const clearHistoryHandler = async () => {
    await dispatch(clearHistoryThunk())
      .unwrap()
      .then((data) => {
        if (data.message == "History cleared Successfully") toast.success(data.message);
      });
    dispatch(UploadHistoryThunk({ pageSize, pageIndex: currentPage }));
    setHistoryMenu(false);
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    setLogoImg(file);
    setShowAddLogoModal(true);
  };
  return (
    <>
      <header className="flex lg:hidden justify-between items-center mb-4 pl-6 pr-4">
        <p className="text-lg font-extrabold">Insurance Samadhan Gallery</p>
        {hasExternalPermission && (
          <label htmlFor="logoImg">
            <p className="flex justify-center flex-row items-center gap-1 border-2  border-black font-extrabold rounded-md text-sm px-1 py-2 text-center me-2 w-20 bg-white">Add Logo</p>
          </label>
        )}
      </header>
      <Container>
        <header className="hidden lg:flex justify-between items-center mb-4">
          <span>
            <Typography variant="h3">Gallery</Typography>
            <Typography className="text-customgray mt-2">Some images from Insurance Samadhan to you</Typography>
          </span>
          {hasExternalPermission && (
            <div>
              <input type="file" className="hidden" id="logoImg" accept=".jpg, .jpeg, .png" onChange={handleFileChange} multiple />
              <label htmlFor="logoImg">
                <p className={`hover:text-white cursor-pointer text-black flex justify-center flex-row items-center gap-1 border border-black hover:bg-black   focus:ring-4 focus:outline-none focus:ring-blue-300 font-semibold rounded-md text-sm px-2  lg:px-5 py-2.5 text-center me-2 w-32 `}>Add Logo</p>
              </label>
            </div>
          )}
        </header>
        <Tabpane selectedTab={selectedTab} setSelectedTab={setSelectedTab} values={tabPaneValues} className="w-full whitespace-nowrap" />
        {selectedTab == 1 && currentUser?.roles.includes(PERMISSIONS.ADMIN) && <UploadCreative />}
        {selectedTab == (currentUser?.roles.includes(PERMISSIONS.ADMIN) ? 2 : 1) && <LeadCreative />}
        {selectedTab == (currentUser?.roles.includes(PERMISSIONS.ADMIN) ? 3 : 2) && <Referral />}
        {selectedTab == (currentUser?.roles.includes(PERMISSIONS.ADMIN) ? 4 : 3) && <Info />}
        {selectedTab == (currentUser?.roles.includes(PERMISSIONS.ADMIN) ? 5 : 4) && <Festival />}
        {selectedTab == (currentUser?.roles.includes(PERMISSIONS.ADMIN) ? 6 : 5) && <BrandingCreatives />}
      </Container>
      {selectedTab == 1 && currentUser?.roles.includes(PERMISSIONS.ADMIN) && (
        <Container customClassName="mt-6">
          <div className="flex justify-between mb-4 relative">
            <Typography className="flex items-center gap-2">
              <img src={History} />
              Upload History
            </Typography>
            {contentHistory?.historyData?.data?.totalCount ? <HiDotsHorizontal className="text-2xl cursor-pointer" onClick={() => setHistoryMenu(!showHistoryMenu)} /> : ""}
            <Menu showMenu={showHistoryMenu} onClose={() => setHistoryMenu(false)} className="w-48 top-4 right-0 z-50">
              <ul>
                <li onClick={clearHistoryHandler} className="flex items-center gap-3 py-1 pl-2 rounded-md cursor-pointer hover:bg-[#fff9f3] hover:text-polyfixTheme ">
                  <img src={clearHistory} />
                  <Typography>Clear History</Typography>
                </li>
              </ul>
            </Menu>
          </div>
          <SimpleTable tableHeading={uploadHistoryHeading} tableKeys={uploadHistoryKeys} tableData={contentHistory?.historyData?.data?.historyData} />
          <Pagination itemsPerPage={pageSize} totalItems={contentHistory?.historyData?.data?.totalCount} setCurrentPage={setCurrentPage} currentPage={currentPage} />
        </Container>
      )}
      {logoImg && <AddLogo addLogoModal={addLogoModal} setShowAddLogoModal={setShowAddLogoModal} imgSource={logoImg} setImgSource={setLogoImg} />}{" "}
    </>
  );
};

export default Gallery;
