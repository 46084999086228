import React, { useState } from "react";
import { components } from "react-select";
import dayjs from "dayjs";
import Typography from "../../../component/semantic/Typography";
import CustomInput from "../../../component/semantic/Input";
import { todayDateTimestamp } from "../../../helpers/constants/constants";
const CustomMenu2 = (props: any) => {
    const listClassName = "py-2 px-2 mx-2 rounded-lg hover:bg-[#FDD2A3] hover:text-black";
    const [selectedDateStart, setSelectedDateStart] = useState<string | undefined>();
    const [selectedDateEnd, setSelectedDateEnd] = useState<string | undefined>();

    const handleClick = (data: string, customTo?: string) => {
        props.setShowRange(false);
        const to = todayDateTimestamp;
        let from = 0;

        if (data === "7days") {
            from = todayDateTimestamp - 604800000;
            props.setSelectedDate({ startDate: dayjs(from).format("YYYY-MM-DD"), endDate: dayjs(to).format("YYYY-MM-DD"), label: "7 Days" });
        } else if (data === "30days") {
            from = todayDateTimestamp - 2592000000;
            props.setSelectedDate({ startDate: dayjs(from).format("YYYY-MM-DD"), endDate: dayjs(to).format("YYYY-MM-DD"), label: "30 Days" });
        } else if (data === "custom") {
            props.setSelectedDate({
                startDate: selectedDateStart || "",
                endDate: customTo || "",
                label: selectedDateStart && customTo ? `${dayjs(selectedDateStart).format('D/MM/YY')} ➤ ${dayjs(customTo).format('D/MM/YY')}` : "Custom Selection"
            });
        } else {
            props.setSelectedDate({ startDate: "", endDate: "", label: "All Time" });
        }

        props.closeMenu();
    };

    return (
        <components.Menu {...props}>
            <ul className="my-1">
                <li className={listClassName} onClick={() => handleClick("")}>All Time</li>
                <li className={listClassName} onClick={() => handleClick("7days")}>Last 7 Days</li>
                <li className={listClassName} onClick={() => handleClick("30days")}>Last 30 Days</li>
                <li className={listClassName} onClick={() => props.setShowRange(!props.showRange)}>Custom Selection</li>
            </ul>
            <div className={`m-2 flex flex-col gap-2 ${!props.showRange && "hidden"}`}>
                <div>
                    <Typography>Start Date</Typography>
                    <CustomInput type="date" value={selectedDateStart} onChange={(e: any) => setSelectedDateStart(e.target.value)} className="mt-2" />
                </div>
                <div className={`${!selectedDateStart && "opacity-50 pointer-events-none"}`}>
                    <Typography>End Date</Typography>
                    <CustomInput
                        type="date"
                        min={selectedDateStart}
                        value={selectedDateEnd}
                        onChange={(e: any) => {
                            setSelectedDateEnd(e.target.value);
                            handleClick("custom", e.target.value);
                        }}
                        className="mt-2"
                    />
                </div>
            </div>
        </components.Menu>
    );
};

export default CustomMenu2
