import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../helpers/axios";
import { baseUrl } from "../../helpers/constants/defaultUrls";

export const getAllUser: any = createAsyncThunk("user/allUsers", async (payload: any) => {
  try {
    const response = await axios.get(baseUrl + `v1/user/all?page=${payload.page}&size=${payload.size}&hierarchicalLevel=${payload.hierarchicalLevel}&parentId=${payload.parentId}`);
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
});

export const singleV2: any = createAsyncThunk("user/singleV2", async (payload: any) => {
  try {
    const response = await axios.get(baseUrl + `v1/user/singleV2/${payload._id}`);
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
});

export const sendContractThunk: any = createAsyncThunk("user/sendContract", async (payload: any) => {
  try {
    const response = await axios.post(baseUrl + `v1/user/sendPartnerContract`, payload);
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
});

export const signContractThunk: any = createAsyncThunk("user/sendContract", async (id: any) => {
  try {
    const response = await axios.put(baseUrl + `v1/user/signpartnercontract/${id}`, {});
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
});

export const sendEmailVerification: any = createAsyncThunk("user/sendContract", async (payload: any) => {
  try {
    const response = await axios.get(baseUrl + `v1/user/send/email/verification?userId=${payload.userId}`);
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
});

export const getAllUserCount: any = createAsyncThunk("user/allUsersCount", async (payload: any) => {
  try {
    const response = await axios.get(baseUrl + `v1/user/count?hierarchicalLevel=${payload.hierarchicalLevel}&userType=${payload.userType}`);
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
});

export const getUsersChildren: any = createAsyncThunk("user/usersChildren", async (payload: any) => {
  try {
    const response = await axios.post(baseUrl + `v1/user/childrens/stats`, payload);
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
});

export const searchUserThunk: any = createAsyncThunk("user/searchUserThunk", async (payload: { query: string; userId: string }) => {
  try {
    const response = await axios.post(baseUrl + `v1/user/children/filter`, payload);
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
});

export const activeDeactiveUserThunk: any = createAsyncThunk("user/activeDeactiveUserThunk", async (payload: any) => {
  try {
    const response = await axios.put(baseUrl + `v1/user/toggle`, payload);
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
});

export const markImpThunk: any = createAsyncThunk("user/markImp", async (payload: any) => {
  try {
    const response = await axios.put(baseUrl + `v1/user/markImportant`, payload);
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
});

export const addOrUpdatePolicyTypeThunk: any = createAsyncThunk("user/addOrUpdatePolicyTypes", async (payload: any) => {
  try {
    const response = await axios.post(baseUrl + `v1/user/addOrUpdatePolicyTypeToPartner`, payload);
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
});

export const verifyExecutiveAgentThunk: any = createAsyncThunk("user/verify", async (payload: any) => {
  try {
    const response = await axios.post(baseUrl + `v1/user/verify`, payload);
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
});

export const verifyExecutiveOTPThunk: any = createAsyncThunk("user/verifyOtp", async (payload: any) => {
  try {
    const response = await axios.post(baseUrl + `v1/user/verify/otp`, payload);
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
});

export const updateAgentThunk = createAsyncThunk("allUsers/updateAgentThunk", async (payload: any) => {
  try {
    const response = await axios.put<{ data: any }>(baseUrl + `v1/user/update/agentDetails`, payload);
    return response.data;
  } catch (error: any) {
    return error;
  }
});

export const softDeleteAgentThunk = createAsyncThunk("allUsers/softDeleteAgentThunk", async (payload: any) => {
  try {
    const response = await axios.post<{ data: any }>(baseUrl + `v1/user/softDeleteAgent`, payload);
    return response.data;
  } catch (error: any) {
    return error;
  }
});

export const activateAgentThunk = createAsyncThunk("allUsers/activateAgentThunk", async (payload: any) => {
  try {
    const response = await axios.post<{ data: any }>(baseUrl + `v1/user/activateAgent`, payload);
    return response.data;
  } catch (error: any) {
    return error;
  }
});
