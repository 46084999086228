import React, { useEffect, useState } from "react";
import Cards from "./Cards";
import { MdKeyboardDoubleArrowDown } from "react-icons/md";
import TotalLead from "../../../assets/images/dashboard/Total.svg";
import PendingLead from "../../../assets/images/dashboard/Pending.svg";
import RejectedLead from "../../../assets/images/dashboard/Rejected.svg";
import RegisteredLead from "../../../assets/images/dashboard/Registered.svg";
import Container from "../../../component/common/Container";
import { useAppSelector } from "../../../hooks/redux_toolkit";

const CardSection = () => {
  const [cardData, setCardData] = useState<any>();

  const dashboardData = useAppSelector((state) => state.rootReducer.dashboard.cardData);

  const amountDisplayHandler = (amount: string) => {
    if (!amount) return 0;
    const formattedAmount = Number(amount).toLocaleString("en-IN", {
      style: "decimal"
    });
    return formattedAmount;
  };

  useEffect(() => {
    if (dashboardData?.data) {
      setCardData(dashboardData?.data);
    }
  }, [dashboardData?.data]);

  return (
    <Container customClassName="mt-6 overflow-hidden">
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-6 lg:gap-0  lg:flex-row justify-between items-start lg:items-center">
          <div className="flex justify-between flex-col lg:flex-row gap-4 lg:gap-20">
            <Cards bg="#C8ECE2" img={TotalLead} title="Total Leads" count={cardData?.totalLeads || 0} amount={amountDisplayHandler(cardData?.claimAmount)} />
            <div className="border-t-2 block lg:hidden border-gray-200 w-[18.5rem] "></div>
          </div>
          <span className="border-l-2 hidden lg:block border-gray-200 h-20"></span>
          <div className="flex justify-between flex-col lg:flex-row gap-4 lg:gap-20">
            <Cards bg="#EFD1C2" img={PendingLead} title="Pending Leads" count={cardData?.pendingLeads || 0} amount={amountDisplayHandler(cardData?.claimAmountPending)} />
            <div className="border-t-2 block lg:hidden border-gray-200 w-[18.5rem] "></div>
          </div>
          <span className="border-l-2 hidden lg:block border-gray-200 h-20"></span>
          <Cards bg="#E499AA" img={RejectedLead} title="Rejected Leads" count={cardData?.rejectedLeads || 0} amount={amountDisplayHandler(cardData?.claimAmountRejected)} />
        </div>
        <div className="border-t-2 border-gray-200 w-full"></div>
        <div className="flex items-start lg:items-center flex-wrap lg:flex-row justify-between gap-3 w-full">
          <Cards bg="#96B1EC" img={RegisteredLead} title="Registered Leads" count={cardData?.registerLeads || 0} amount={amountDisplayHandler(cardData?.claimAmountRegister)} />
          <div className="flex justify-center items w-full lg:w-auto">
            <MdKeyboardDoubleArrowDown size={30} className=" lg:-rotate-90 transform -rotate-0 text-customgray" />
          </div>
          <Cards title="Settled" count={cardData?.settledLeads || 0} amount={amountDisplayHandler(cardData?.claimAmountSettled)} />
          <span className="border-l-2 hidden lg:block border-gray-200 h-20"></span>
          <Cards title="Resolved" count={cardData?.resolvedLeads || 0} amount={amountDisplayHandler(cardData?.claimAmountResolved)} />
          <span className="border-l-2 hidden lg:block border-gray-200 h-20"></span>
          <Cards title="Work in Progress" count={cardData?.inprogressLeads || 0} amount={amountDisplayHandler(cardData?.claimAmountInProgress)} />
        </div>
      </div>
    </Container>
  );
};

export default CardSection;
