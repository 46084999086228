import { createSlice } from "@reduxjs/toolkit";
import { clearNotificationThunk, userNotificationThunk } from "../thunk/Notification.thunk";
import { toast } from "react-toastify";

interface responseData {
  data: any;
}

interface ApiState {
  data: any;
  loading: boolean;
  error: string | null;
  userNotification: responseData;
  clearNotification: responseData;
}

const initialState: ApiState = {
  data: null,
  loading: false,
  error: null,
  userNotification: { data: "" },
  clearNotification: { data: "" }
};

const notificationSlice = createSlice({
  name: "notificationSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(userNotificationThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(userNotificationThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.userNotification = action.payload;
        state.error = null;
      })
      .addCase(userNotificationThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "An error occurred";
      })

      .addCase(clearNotificationThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(clearNotificationThunk.fulfilled, (state, action: any) => {
        if (action.payload && action.payload?.status == 200) toast.success("Notification Cleared Successfully");
        state.loading = false;
        state.clearNotification = action.payload;
        state.error = null;
      })
      .addCase(clearNotificationThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "An error occurred";
      });
  }
});

export default notificationSlice.reducer;
