import React, { useEffect, useState } from "react";
import Container from "../../component/common/Container";
import AddCompany from "./component/AddCompany";
import CompanyTable from "./component/CompanyTable";
import { useAppDispatch } from "../../hooks/redux_toolkit";
import { getAllCompanyThunk } from "../../store/thunk/Company.thunk";

const Company = () => {
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 20;

  useEffect(() => {
    dispatch(getAllCompanyThunk({ pageSize, currentPage }));
  }, [currentPage]);

  return (
    <Container>
      <AddCompany currentPage={currentPage} setCurrentPage={setCurrentPage} />
      <CompanyTable currentPage={currentPage} setCurrentPage={setCurrentPage} pageSize={pageSize} />
    </Container>
  );
};

export default Company;
