import Bell from "../../assets/icons/navbar/Bell.svg";
import Chat from "../../assets/icons/navbar/chat.svg";
import Bars from "../../assets/icons/navbar/Bars.svg";
import Comment from "../../assets/icons/navbar/Comment.svg";
import React, { useEffect, useState } from "react";
import profile from "../../assets/icons/navbar/profile.svg";
import updatePic from "../../assets/icons/navbar/updatePhoto.svg";
import Typography from "../semantic/Typography";
import logout from "../../assets/icons/navbar/logout.svg";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Menu from "../common/Menu";
import { useAppDispatch, useAppSelector } from "../../hooks/redux_toolkit";
import { notificationBell, userProfile } from "../../store/thunk/Common.thunk";
import { isAuthenticatedUser } from "../../helpers/authenticatedUser";
import { getCurrentUser } from "../../helpers/currentUser";
import { s3Url, socketIOUrl } from "../../helpers/constants/defaultUrls";
import defaultPic from "../../assets/icons/navbar/profilePicDefault.jpg";
import { daysTimestamp8 } from "../../helpers/constants/constants";
import { profilePicThunk } from "../../store/thunk/Profile.thunk";
import Toast from "../common/Toast";
import useSocketService from "../../services/Socket.service";
import { getUnreadCount } from "../../store/thunk/Chat.thunk";
import CountCircle from "../common/CountCircle";
import { Tooltip } from "react-tooltip";
import dayjs from "dayjs";

interface NavbarProp {
  title?: string;
  toggleSidebar: () => void;
}

const Navbar: React.FC<NavbarProp> = ({ title, toggleSidebar }) => {
  const { disconnect } = useSocketService(socketIOUrl);

  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState<boolean>(false);

  const currentUser = getCurrentUser();
  const dispatch = useAppDispatch();

  const location = useLocation();

  useEffect(() => {
    dispatch(userProfile(currentUser?._id));
    dispatch(notificationBell({ id: currentUser?._id, type: "unread", date: dayjs().startOf("day").valueOf() - daysTimestamp8 }));
    dispatch(getUnreadCount()).unwrap();
  }, []);

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const formData = new FormData();
      formData.append("image", file);
      await dispatch(profilePicThunk(formData));
      dispatch(userProfile(currentUser?._id));
      setShowMenu(false);
    }
  };

  const userData: any = useAppSelector((state) => state.rootReducer.common);
  const chatCount: any = useAppSelector((state) => state.rootReducer.chat.unreadCount);
  const profilePicUrl = s3Url + currentUser?._id + "/" + userData?.userProfile?.data?.profilePic;

  const logoutHandler = () => {
    localStorage.removeItem("userInfo");
    navigate("/login");
    disconnect();
  };

  const isAuthenticated = isAuthenticatedUser();
  useEffect(() => {
    if (!isAuthenticated) navigate("/login");
  }, [isAuthenticated]);

  return (
    <div className="flex justify-end fixed w-full z-10 ">
      {location.pathname !== "/profile" && <Toast className="right-4 md:right-6 top-16" />}
      <nav className="bg-white  w-full lg:ml-64 ml-0 lg:pl-8 shadow-sm" style={{ zIndex: 2000 }}>
        <div className="flex justify-between items-center px-1 lg:pr-10 py-3 pr-4 ">
          {/* <ProgressBar progress={referralLoading == 'pending' ? 50 : 100} /> */}
          <div className="flex justify-between gap-4">
            <img src={Bars} className="cursor-pointer block lg:hidden" alt="sidebar" onClick={() => toggleSidebar()} />
            <Typography variant="h1" className="hidden  lg:block">
              {title}
            </Typography>
          </div>
          <div className="flex justify-between items-center gap-6">
            <Tooltip delayShow={1} key="leads" id="leads" place="bottom" />
            <NavLink to="/allLeads" data-tooltip-id="leads" data-tooltip-content="Add Comment">
              <img src={Comment} className="cursor-pointer" alt="comment" />
            </NavLink>
            <Tooltip delayShow={1} key="chat" id="chat" place="bottom" />
            <NavLink to="/chat" className="relative inline-block cursor-pointer" data-tooltip-id="chat" data-tooltip-content="Chat">
              <img src={Chat} className="cursor-pointer" alt="chat" />
              {chatCount?.data ? <CountCircle count={chatCount?.data} /> : ""}
            </NavLink>
            <Tooltip delayShow={1} key="notification" id="notification" place="bottom" />
            <NavLink to="/notification" className="relative inline-block cursor-pointer" data-tooltip-id="notification" data-tooltip-content="Notification">
              <img src={Bell} />
              {userData?.notificationBell?.data ? <CountCircle count={userData?.notificationBell?.data} /> : ""}
            </NavLink>
            <Tooltip delayShow={1} key="logout" id="logout" place="bottom" />
            <img src={logout} onClick={logoutHandler} className="cursor-pointer" alt="logout" data-tooltip-id="logout" data-tooltip-content="Logout" />
            <div className="flex gap-2 items-center">
              <div className="font-bold text-right hidden  lg:block">
                <Typography variant="h2">Welcome</Typography>
                <Typography variant="h2" className="text-customgray">
                  {userData?.userProfile?.data?.name}
                </Typography>
              </div>
              <img onClick={() => setShowMenu(!showMenu)} alt="profile picture" src={userData?.userProfile?.data?.profilePic ? profilePicUrl : defaultPic} className="w-10 h-10 cursor-pointer rounded-md" />
            </div>
            <Menu showMenu={showMenu} onClose={() => setShowMenu(false)} className="w-48 top-16 right-4">
              <ul>
                <input type="file" id="updatePicInput" className="hidden" accept=".jpg, .jpeg, .png, .pdf" onChange={handleFileChange} multiple={false} />
                <li onClick={() => navigate("/profile")} className="flex items-center gap-3 py-1 pl-2 rounded-md cursor-pointer hover:bg-[#fff9f3] hover:text-polyfixTheme ">
                  <img src={profile} />
                  <Typography>Profile</Typography>
                </li>
                <label htmlFor="updatePicInput">
                  <li className="flex items-center gap-3 py-1 pl-2 rounded-md cursor-pointer hover:bg-[#fff9f3] hover:text-polyfixTheme ">
                    <img src={updatePic} />
                    <Typography>Update Photo</Typography>
                  </li>
                </label>
              </ul>
            </Menu>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
