import Container from "../../component/common/Container";
import Typography from "../../component/semantic/Typography";
import React, { useState } from "react";
import { requirementContants } from "../../helpers/constants/constants";
import Collapse from "./component/Collapse";

interface CollapseState {
  id: string;
}

function Requirement() {
  const [collapse, setCollapse] = useState<CollapseState>({ id: "" });

  const toggleCollapse = (section: string) => {
    if (collapse.id === section) {
      setCollapse({ id: "" });
    } else {
      setCollapse({ id: section });
    }
  };

  return (
    <Container customClassName="ml-5 mr-6 lg:mx-2 mb-8">
      <Typography variant="h2" className="text-md lg:text-2xl">
        List of required documents as per complaint
      </Typography>
      {requirementContants.map((insurance, i) => {
        return (
          <>
            <div className="flex gap-2 items-center mt-8" key={i}>
              <img src={insurance.img} alt="health" />
              <Typography variant="h3">{insurance.name}</Typography>
            </div>
            {insurance.requirementDocuments.map((policy, i) => {
              return <Collapse key={i} collapse={collapse} policy={policy} toggleCollapse={toggleCollapse} />;
            })}
          </>
        );
      })}
    </Container>
  );
}

export default Requirement;
