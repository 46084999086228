import React, { FC, useRef, useState } from "react";
import Container from "../../../component/common/Container";
import { CiFilter } from "react-icons/ci";
import Typography from "../../../component/semantic/Typography";
import ButtonOutline from "../../../component/semantic/Button";
import ReactSelect from "react-select";
import { CustomDateOption } from "./CustomFilter";

const dateOptions = [{ name: "Daily" }, { name: "Monthly" }, { name: "Yearly" }];

interface FilterProps {
  dateFilter: any;
  setDateFilter: any;
  clearFilterHandler: any;
  customDateHandler: any;
  customDate: { startDate: string; endDate: string };
  setCustomDate: any;
}

const Filter: FC<FilterProps> = ({ dateFilter, setDateFilter, clearFilterHandler, customDateHandler, customDate, setCustomDate }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const filterRef: any = useRef(null);
  const filterRefMob: any = useRef(null);


  return (
    <>
      <Container id="web-view" customClassName="hidden lg:block">
        <div className="justify-between   lg:flex">
          <div className="flex justify-between gap-3 items-center">
            <CiFilter className="text-[#FAAA52]" size={20} />
            <Typography className="w-20">Filter By</Typography>
            <ReactSelect
              className="w-72"
              isSearchable={false}
              value={dateFilter}
              placeholder="Daily/Monthly/Yearly"
              onChange={(option) => {
                setDateFilter(option);
                setCustomDate({ startDate: "", endDate: "" });
              }}
              options={dateOptions}
              getOptionLabel={(option: any) => option.name}
              getOptionValue={(option: any) => option.name}
            />

            <ReactSelect
              className="w-72 cursor-pointer"
              closeMenuOnSelect={false}
              ref={filterRef}
              isSearchable={false}
              placeholder="Select Custom Date"
              menuIsOpen={isMenuOpen}
              onFocus={() => setIsMenuOpen(true)}
              getOptionLabel={(option: any) => option?.name}
              onBlur={() => setIsMenuOpen(false)}
              value={customDate.startDate && customDate.endDate ? { name: customDate.startDate + " ➤ " + customDate.endDate } : { name: "Select Custom Date" }}
              components={{
                Menu: (props) => (
                  <CustomDateOption
                    {...props}
                    customDateHandler={(startDate: string, endDate: string) => {
                      customDateHandler(startDate, endDate);
                    }}
                    closeMenu={() => {
                      setIsMenuOpen(false);
                      filterRef.current?.blur();
                    }}
                  />
                )
              }}
            />
          </div>
          <ButtonOutline title="Clear Filter" onClick={clearFilterHandler} color="polyfixTheme" className="hover:bg-polyfixTheme text-polyfixTheme hover:text-white" />
        </div>
      </Container>
      <div id="mob-filter" className="block lg:hidden px-4">
        <div className="font-semibold px-2">
          <p className="font-extrabold text-xl">New Dashboard</p>
          <div className="flex justify-between mt-2">
            <span className="flex gap-3">
              <CiFilter className="text-polyfixTheme bg-[#faa95221] rounded p-[2px]" size={20} />
              <Typography className="text-customgray">Filter By</Typography>
            </span>
            <Typography variant="h2" className="text-polyfixTheme cursor-pointer" onClick={clearFilterHandler}>
              Clear Filters
            </Typography>
          </div>
        </div>
        <div className="px-2 mt-2 gap-2 flex flex-col">
          <ReactSelect
            className="w-full cursor-pointer"
            closeMenuOnSelect={false}
            ref={filterRefMob}
            isSearchable={false}
            placeholder="Select Custom Date"
            menuIsOpen={isMenuOpen}
            onFocus={() => setIsMenuOpen(true)}
            getOptionLabel={(option: any) => option?.name}
            onBlur={() => setIsMenuOpen(false)}
            value={customDate.startDate && customDate.endDate ? { name: customDate.startDate + " ➤ " + customDate.endDate } : { name: "Select Custom Date" }}
            components={{
              Menu: (props) => (
                <CustomDateOption
                  {...props}
                  customDateHandler={(startDate: string, endDate: string) => {
                    customDateHandler(startDate, endDate);
                  }}
                  closeMenu={() => {
                    setIsMenuOpen(false);
                    filterRefMob.current?.blur();
                  }}
                />
              )
            }}
          />
          <ReactSelect
            isSearchable={false}
            className="w-full"
            value={dateFilter}
            placeholder="Daily/Monthly/Yearly"
            onChange={(option) => {
              setDateFilter(option);
              setCustomDate({ startDate: "", endDate: "" });
            }}
            options={dateOptions}
            getOptionLabel={(option: any) => option.name}
            getOptionValue={(option: any) => option.name}
          />
        </div>
      </div>
    </>
  );
};

export default Filter;
