import React, { useEffect } from "react";
import FestivalCard from "./Festival.card";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux_toolkit";
import ImageSkeleton from "./Skeletons/FestivalSkeleton";
import { getGalleryContentThunk } from "../../../store/thunk/Gallery.thunk";

const Festival = () => {
  const festivalContent = useAppSelector((state) => state.rootReducer.gallery);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!festivalContent.galleryFestival.data) dispatch(getGalleryContentThunk({ category: "Festivities Creatives" }));
  }, []);

  return (
    <>
      <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">{festivalContent.loading ? <ImageSkeleton /> : festivalContent.galleryFestival.data && festivalContent.galleryFestival.data.map((obj: any, i: number) => <FestivalCard key={i} obj={obj} imgSrc={obj.mediaUrl} title={obj.title} />)}</div>
    </>
  );
};

export default Festival;
