import { createSlice } from "@reduxjs/toolkit";
import { getAllResolveCase } from "../thunk/resolveCase.thunk";
interface ApiState {
  data: any;
  loading: boolean;
  error: string | null;
  resolvedCaseData: responseData;
}

const initialState: ApiState = {
  data: null,
  loading: false,
  error: null,
  resolvedCaseData: { data: "" }
};

interface responseData {
  data: any;
}

const resolveCaseSlice = createSlice({
  name: "resolveCaseSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllResolveCase.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllResolveCase.fulfilled, (state, action) => {
        state.loading = false;
        state.resolvedCaseData = action.payload;
        state.error = null
      })
      .addCase(getAllResolveCase.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "An error occurred";
      });
  }
});

export default resolveCaseSlice.reducer;
