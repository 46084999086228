import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux_toolkit";
import Modal from "../../../component/common/Modal";
import FormInput from "../../../component/semantic/FormInput";
import { addCompanySchema } from "../../../helpers/schemas/AddCompany.Schema";
import { updateCompany } from "../../../store/thunk/Common.thunk";
import ButtonOutline from "../../../component/semantic/Button";
import ReactSelect from "react-select";
import Typography from "../../../component/semantic/Typography";
import { errorStyles, polyfixSelect } from "../../../helpers/constants/styles";
import { useFormik } from "formik";
import { getAllCompanyThunk } from "../../../store/thunk/Company.thunk";

function UpdateCompany({ selectedCompany, showEditCompany, setShowEditCompany, pageSize, currentPage }: any) {
  interface valuesProps {
    address: string;
    companyEmail: string;
    companyLevel1Email: string;
    name: string;
    policyType: any;
  }

  const initialValues: valuesProps = {
    address: "",
    companyEmail: "",
    companyLevel1Email: "",
    name: "",
    policyType: ""
  };

  const dispatch = useAppDispatch();

  const { values, handleSubmit, handleBlur, handleChange, touched, errors, resetForm, setFieldValue, setValues }: any = useFormik({
    initialValues,
    validationSchema: addCompanySchema,
    enableReinitialize: true,
    onSubmit: async (values: any) => {
      const payload: any = {
        ...values,
        companyLevel1Email: values?.companyLevel1Email?.split(","),
        companyEmail: values?.companyEmail?.split(","),
        policyType: values.policyType?.name,
        id: selectedCompany?._id
      };
      dispatch(updateCompany(payload));
      resetForm();
      dispatch(getAllCompanyThunk({ pageSize, currentPage }));
      setShowEditCompany(false);
    }
  });

  useEffect(() => {
    if (showEditCompany) {
      setValues({
        address: selectedCompany?.address || "",
        companyEmail: selectedCompany?.companyEmail?.join(",") || "",
        companyLevel1Email: selectedCompany?.companyLevel1Email?.join(",") || "",
        name: selectedCompany?.name || "",
        policyType: selectedCompany?.policyTypeId
      });
    }
  }, [showEditCompany]);

  const policyList = useAppSelector((state) => state.rootReducer.common.policyList.data);

  const formProps = { values, handleBlur, handleChange, touched, errors, setFieldValue };

  return (
    <Modal
      showModal={showEditCompany}
      onClose={() => {
        setShowEditCompany(false);
      }}
    >
      <h1>Change Company Details</h1>
      <div className="w-[600px] p-4">
        <Typography variant="h3">Company</Typography>
        <Typography className="text-gray-600 mb-4">List of companies</Typography>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
          <div className="col-span-4">
            <FormInput name="name" placeholder="Enter Company Name" label="Name *" formProps={formProps} />
          </div>
          <div className="col-span-4 md:col-span-4">
            <FormInput name="address" placeholder="Enter Company Address" label="Address *" formProps={formProps} />
          </div>
          <div className="col-span-4 md:col-span-4">
            <Typography className="font-normal mb-1">Policy Type *</Typography>
            <ReactSelect
              options={policyList}
              name="policyType"
              placeholder="Select Policy Type"
              getOptionLabel={(option) => option?.name}
              getOptionValue={(option) => option}
              value={values.policyType}
              onChange={(option) => {
                setFieldValue("policyType", option);
              }}
              styles={errors?.policyType && touched.policyType ? errorStyles : polyfixSelect}
              isSearchable={false}
            />
            {touched.policyType && errors?.policyType && <p className="text-red-600 font-semibold text-sm">{errors?.policyType}</p>}
          </div>
          <div className="col-span-4">
            <FormInput name="companyEmail" placeholder="Enter Company Email" label="Company Email *" formProps={formProps} />
          </div>
          <div className="col-span-4">
            <FormInput name="companyLevel1Email" placeholder="Enter Company Level1 Email" label="Company Level1 Email *" formProps={formProps} />
          </div>
        </div>
        {/* <Divide className="my-6 mt-10 -mx-4 shadow-above-border" /> */}
        <div className="block md:flex justify-end mt-4 ">
          <ButtonOutline className="bg-[#FF3C3C] text-white w-full md:w-auto" onClick={() => setShowEditCompany(false)} title="CLOSE" type="submit" />
          <ButtonOutline className="bg-[#1BA785] text-white w-full md:w-auto" onClick={handleSubmit} title="SAVE" type="submit" />
        </div>
        {/* <Divide className="my-6 mb-10 -mx-4 shadow-above-border" /> */}
      </div>
    </Modal>
  );
}

export default UpdateCompany;
