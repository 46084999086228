import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../helpers/axios";
import { baseUrl } from "../../helpers/constants/defaultUrls";

interface resolveDataArgs {
  endDate: string;
  startDate: string;
  generateReport: boolean;
  hierarchy: string;
  userId: string;
  userType: string;
}

export const getAllResolveCase: any = createAsyncThunk("user/getAllResolveCase", async (payload: resolveDataArgs) => {
  try {
    const response = await axios.post(baseUrl + `v1/lead/getResolvedDashboardv2`, payload);
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
});

export const getAllResolveCaseDownload: any = createAsyncThunk("user/getAllResolveCaseDownload", async (payload: resolveDataArgs) => {
  try {
    const response = await axios.post(baseUrl + `v1/lead/getResolvedDashboardv2`, payload);
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
});
