import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../helpers/axios";
import { baseUrl } from "../../helpers/constants/defaultUrls";

interface NewDashboardArgs {
  KeyRefresh: boolean;
  dateWise?: string;
  startDate?: string;
  endDate?: string;
  user_id: string;
}

interface InvoiceArgs extends NewDashboardArgs {
  type: string;
}

interface PartnerCountArgs {
  KeyRefresh: boolean;
  user_id: string;
}

interface resendCasesCountArgs extends NewDashboardArgs {
  selectedStatus: string;
}

export const getPartnerRegistrationCount = createAsyncThunk("newDashboard/getPartnerRegistrationCount", async (payload: NewDashboardArgs) => {
  const response = await axios.post<{ data: any }>(baseUrl + `v1/user/getPartnerRegistrationCount`, payload);
  return response?.data;
});

export const getPartnerResolvedCount = createAsyncThunk("newDashboard/getPartnerResolvedCount", async (payload: NewDashboardArgs) => {
  const response = await axios.post<{ data: any }>(baseUrl + `v1/user/getPartnerResolvedCount`, payload);
  return response?.data;
});

export const getInvoiceSettledCount = createAsyncThunk("newDashboard/getInvoiceSettledCount", async (payload: InvoiceArgs) => {
  const response = await axios.post<{ data: any }>(baseUrl + `v1/user/getInvoiceSettledCount`, payload);
  return response?.data;
});

export const getPartnerCount = createAsyncThunk("newDashboard/getPartnerCount", async (payload: PartnerCountArgs) => {
  const response = await axios.post<{ data: any }>(baseUrl + `v1/user/getPartnerCount`, payload);
  return response?.data;
});

export const resendCasesCount = createAsyncThunk("newDashboard/resendCasesCount", async (payload: resendCasesCountArgs) => {
  const response = await axios.post<{ data: any }>(baseUrl + `v1/user/resendCasesCount`, payload);
  return response?.data;
});

export const getOmdCountThunk = createAsyncThunk("newDashboard/getOmdCountThunk", async (payload: NewDashboardArgs) => {
  const response = await axios.post<{ data: any }>(baseUrl + `v1/user/getOmdCount`, payload);
  return response?.data;
});

export const getMailingCountThunk = createAsyncThunk("newDashboard/getMailingCountThunk", async (payload: NewDashboardArgs) => {
  const response = await axios.post<{ data: any }>(baseUrl + `v1/user/getMailingCount`, payload);
  return response?.data;
});
