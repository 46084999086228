import React, { useEffect, useRef, HTMLAttributes, ReactNode } from "react";

interface MenuProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  showMenu: boolean;
  children: ReactNode;
  onClose?: () => void;
}

const Menu: React.FC<MenuProps> = ({ showMenu, className, onClose, children, ...props }) => {
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        if (onClose) {
          onClose();
        }
      }
    };

    if (showMenu) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showMenu, onClose]);

  return (
    <div ref={menuRef} style={{ display: showMenu ? "block" : "none" }} className={`rounded-lg transition-all bg-white p-5 overflow-hidden shadow-2xl absolute lg:flex lg:order-1 ${className}`} {...props}>
      {children}
    </div>
  );
};

export default Menu;
