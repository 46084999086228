import React, { FC } from "react";
import { ImSpinner2 } from "react-icons/im";

interface SpinnerProps {
  className?: string;
}

const Spinner: FC<SpinnerProps> = ({ className }) => {
  return (
    <div className="flex justify-center mt-4">
      <ImSpinner2 className={`animate-spin text-polyfixTheme text-4xl ${className}`} />
    </div>
  );
};

export default Spinner;
