import React, { useState } from "react";
import Container from "../../component/common/Container";
import Typography from "../../component/semantic/Typography";
import { useFormik } from "formik";
import ButtonOutline from "../../component/semantic/Button";
import { passwordSchema } from "../../helpers/schemas/Simple.Schema";
import InputWithIcon from "./components/InputWithIcon";
import { useAppDispatch } from "../../hooks/redux_toolkit";
import { passwordThunk } from "../../store/thunk/Password.thunk";
import { toast } from "react-toastify";
import { isEmpty } from "../../helpers/utils";
import Divide from "../../component/semantic/Divide";

const initialValues: { oldPassword: string; newPassword: string } = {
  oldPassword: "",
  newPassword: ""
};
const Password = () => {
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const dispatch = useAppDispatch();

  const { values, handleSubmit, handleBlur, handleChange, touched, errors, resetForm, setFieldValue } = useFormik({
    initialValues,
    validationSchema: passwordSchema,
    onSubmit: async (values: any) => {
      dispatch(passwordThunk(values))
        .unwrap()
        .then((data) => {
          if (data?.success) toast.success("Password Changed  Successfully");
        });
      resetForm();
    }
  });

  const formProps = { values, handleBlur, handleChange, touched, errors, setFieldValue };

  return (
    <>
      <div id="mob-filter" className="font-semibold px-2 mx-4 mb-4 block lg:hidden">
        <p className="font-extrabold text-xl">Change your Password</p>
        <Typography className="text-customgray text-xs md:text-base">Enter you old and new password to change your password</Typography>
      </div>
      <Container>
        <header className="hidden lg:block">
          <Typography variant="h3">Change your Password</Typography>
          <Typography className="text-customgray mt-2">Enter you old and new password to change your password</Typography>
        </header>
        <div className="grid grid-cols-1 md:grid-cols-2 mt-0 md:mt-8 gap-2 md:gap-10">
          <InputWithIcon formProps={formProps} name="oldPassword" label="Old Password*" placeholder="Old Password" showPassword={showOldPassword} toggle={() => setShowOldPassword(!showOldPassword)} />
          <InputWithIcon formProps={formProps} name="newPassword" label="New Password*" placeholder="New Password" showPassword={showNewPassword} toggle={() => setShowNewPassword(!showNewPassword)} />
        </div>
        <Divide className="my-6 -mx-4 lg:-mx-9  shadow-above-border" />
        <div className="block md:flex flex-row-reverse mt-2">
          <ButtonOutline className={`bg-polyfixTheme  w-full ${!isEmpty(errors) && "opacity-50 pointer-events-none"} `} onClick={handleSubmit} title="Change Password" type="submit" />
        </div>
      </Container>
    </>
  );
};

export default Password;
