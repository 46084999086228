import React from "react";
import Image1 from "../../../assets/icons/home/partnerInfo1.svg";
import Image2 from "../../../assets/icons/home/partnerInfo2.svg";
import Image3 from "../../../assets/icons/home/partnerInfo3.svg";
import Card from "./Card.component";

function AboutPartners() {
  return (
    <div className="flex flex-col md:flex-row justify-center mx-auto w-full">
      {/* <div className='flex justify-center'>
                <Typography className='text-5xl' variant='h2'>Why partner with us?</Typography>
            </div> */}
      <Card src={Image1} heading={"Earn Commision"} content={"As our partner, you will have the opportunity to earn commissions on every successful case resolution that we handle. We believe in rewarding our partners for their hard work and dedication."} />
      <Card src={Image2} className="bg-[#F5EFF5]" heading={"Add Value to Your Business"} content={"Are you looking for a way to add value to your business and expand your services? Look no further than partnering with us. With our help, you can offer your clients a more comprehensive range of services and expand your customer base."} />
      <Card src={Image3} className="bg-[#FBF2F2]" heading={"Workshop and Events"} content={"At Insurance Samadhan, strong relationships with our partners are essential to mutual success. That's why we offer a variety of workshops and events designed to help you develop the skills and knowledge you need to excel in your role as a partner."} />
    </div>
    // <div className='bg-[#F0F4FD] px-8 py-14 w-[30%]'>
    //     <div className='flex justify-center'>
    //         <Typography className='text-5xl' variant='h2'>Why partner with us?</Typography>
    //     </div>
    //     <div className='flex'>
    //         <div className='px-4 pt-4 pb-8 bg-white rounded-2xl'>
    //             <div className='py-2 px-6 bg-[#DFEAE7] flex justify-center rounded-lg'>
    //                 <img alt='image' src={Image1} />
    //             </div>

    //             <div className='py-3'>
    //                 <Typography>Earn Commision</Typography>
    //             </div>
    //             <div>
    //                 <Typography>As our partner, you will have the opportunity to earn commissions on every successful case resolution that we handle. We believe in rewarding our partners for their hard work and dedication.</Typography>
    //             </div>
    //         </div>
    //     </div>
    // </div>
  );
}

export default AboutPartners;
