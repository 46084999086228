import React, { FC } from "react";
import Modal from "../../../component/common/Modal";
import Typography from "../../../component/semantic/Typography";
import ButtonOutline from "../../../component/semantic/Button";
import { useAppDispatch } from "../../../hooks/redux_toolkit";
import { remove_partner_mapping } from "../../../store/thunk/ExecutiveMapping.thunk";
import { toast } from "react-toastify";
interface ModalProps {
  confirmationModal: boolean;
  setConfirmationModal: any;
  successButtonHandler: any;
  selectedRow: any;
}
const RejectModal: FC<ModalProps> = ({ setConfirmationModal, confirmationModal, selectedRow }) => {
  const dispatch = useAppDispatch();

  const rejectHandler = () => {
    if (selectedRow)
      dispatch(remove_partner_mapping({ partnerId: selectedRow._id }))
        .unwrap()
        .then((data: any) => {
          if (data.success) {
            toast.success(data.message);
            setConfirmationModal(false);
          }
        });
  };

  return (
    <Modal showModal={confirmationModal} onClose={() => setConfirmationModal(false)} className="relative w-[35%] p-8">
      <Typography>{"Are you sure you want to reject this user?"}</Typography>
      <div className="flex justify-between items-center w-full mt-6">
        <ButtonOutline onClick={rejectHandler} className=" bg-[#1BA785] outline-[none]" type="button" title="YES" />
        <ButtonOutline
          onClick={() => {
            setConfirmationModal(false);
          }}
          className=" bg-[#FF3C3CED] outline-[none]"
          type="button"
          title="NO"
        />
      </div>
    </Modal>
  );
};
export default RejectModal;
