import React from "react";
import Typography from "../semantic/Typography";
import Divide from "../semantic/Divide";

interface TabpaneProps {
  selectedTab: number;
  setSelectedTab: (a: number) => void;
  values: string[];
  className?: string;
}

const Tabpane: React.FC<TabpaneProps> = ({ selectedTab, setSelectedTab, values, className }) => {
  return (
    <div
      className="mb-8 overflow-x-scroll custom-scrollbar"
      style={{
        scrollbarWidth: "thin",
        scrollbarColor: "#D7DBEC transparent",
        msOverflowStyle: "none",
        overflowX: "scroll",
        overflowY: "hidden",
        borderRadius: "16px"
      }}>
      <div className="flex gap-4 ">
        {values.map((value, i) => {
          return (
            <Typography key={i} variant="h2" className={`cursor-pointer ml-6 pb-3 transition duration-300 text-center w-48  py-2  ${selectedTab == i + 1 ? "border-b-4 border-polyfixTheme text-black" : "text-gray-400"} ${className}`} onClick={() => setSelectedTab(i + 1)}>
              {value}
            </Typography>
          );
        })}
      </div>
      <Divide />
    </div>
  );
};

export default Tabpane;
