import React, { useEffect, useRef, useState } from "react";
import DisplayPicCard from "./DisplayPicCard";
import Typography from "../../../component/semantic/Typography";
import { HiDotsHorizontal } from "react-icons/hi";
import Divide from "../../../component/semantic/Divide";
import chatEmoji from "../../../assets/icons/chat/chatEmoji.svg";
import sendChat from "../../../assets/icons/chat/sendChat.svg";
import { IoMdAdd, IoIosArrowBack } from "react-icons/io";
import ChatBubble from "./ChatBubble";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux_toolkit";
import { clearChatThunk, deleteChatThunk, getLastMsgThunk, getUserChatThunk, sendFileThunk, getUnreadCount } from "../../../store/thunk/Chat.thunk";
import { s3Url, socketIOUrl } from "../../../helpers/constants/defaultUrls";
import { getCurrentUser } from "../../../helpers/currentUser";
import useSocketService from "../../../services/Socket.service";
import profileDefault from "../../../assets/icons/navbar/profilePicDefault.jpg";
import Spinner from "../../../component/common/Spinner";
import dayjs from "dayjs";
import { convertToMidnightIST } from "../../../helpers/utils";
import DeleteChat from "./Dialogs/DeleteChat";
import EmojiMenu from "./Dialogs/EmojiMenu";
import HeaderMenu from "./Dialogs/HeaderMenu";

interface ChatScreenProps {
  selectedChat: any;
  showMobChat: boolean;
  setMobChat: any;
}

const ChatScreen: React.FC<ChatScreenProps> = ({ selectedChat, showMobChat, setMobChat }) => {
  const { emit, on } = useSocketService(socketIOUrl);

  const [showActionMenu, setShowActionMenu] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [showEmojiMenu, setShowEmojiMenu] = useState(false);
  const [chatMsg, setChatMsg] = useState("");
  const [isUserOnline, setUserOnline] = useState(false);
  const [chatDate, setChatDate] = useState<number>(0);

  const profilePic = selectedChat?.profilePic ? s3Url + selectedChat?._id + "/" + selectedChat?.profilePic : profileDefault;
  const userChats = useAppSelector((state) => state.rootReducer.chat);
  const currentUser = getCurrentUser();
  const dispatch = useAppDispatch();

  useEffect(() => {
    on(currentUser._id, () => {
      console.log(currentUser._id);
      dispatch(getUserChatThunk({ receiverId: selectedChat?._id, senderId: currentUser._id }));
      dispatch(getUnreadCount());
      dispatch(getLastMsgThunk());
    });
    if (selectedChat) {
      emit("mark-as-seen", { senderId: selectedChat?._id, receiverId: currentUser._id });
      dispatch(getUnreadCount());
      dispatch(getLastMsgThunk());
    }
  }, [selectedChat]);

  useEffect(() => {
    const element = document.getElementById("chat");
    if (element) {
      element.scrollTo({ top: document.body.scrollHeight });
    }
  }, [userChats.userChat.data]);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleEmojiClick = (emoji: string) => {
    if (inputRef.current) {
      const start = inputRef.current.selectionStart;
      if (start !== null) {
        const end: any = inputRef.current.selectionEnd;
        setChatMsg((value) => {
          return value.substring(0, start) + " " + emoji + value.substring(end);
        });
        inputRef.current.focus();
      }
    }
  };

  const onlineUserList = useAppSelector((state) => state.rootReducer.common.allOnlinceUserList);

  useEffect(() => {
    if (onlineUserList) {
      const userOnline = onlineUserList.find((user: any) => user?.userId === selectedChat?._id);

      if (userOnline) setUserOnline(true);
      else setUserOnline(false);
    }
  }, [onlineUserList, selectedChat]);

  const sendChatHandler = () => {
    if (chatMsg.trim() == "") return;
    const formData = {
      message: chatMsg,
      receiverId: selectedChat._id,
      senderId: currentUser._id,
      createdAt: Date.now(),
      createdOn: new Date()
    };

    emit("chat-msg", formData);
    // emit('unread-msgs', selectedChat._id)
    dispatch(getUserChatThunk({ receiverId: selectedChat._id, senderId: currentUser._id }));
    setChatMsg("");
    // dispatch(getLastMsgThunk());
    setShowEmojiMenu(false);
    return;
  };

  const inputChangeHandler = (e: any) => {
    if (e.key == "Enter") sendChatHandler();
  };

  const clearChatHandler = async () => {
    await dispatch(clearChatThunk({ receiverId: selectedChat._id, senderId: currentUser._id }));
    dispatch(getUserChatThunk({ receiverId: selectedChat._id, senderId: currentUser._id }));
    setShowActionMenu(false);
  };

  const deleteChatHandler = async () => {
    await dispatch(deleteChatThunk({ senderId: currentUser._id, receiverId: selectedChat._id }));
    dispatch(getLastMsgThunk());
    setDeleteModal(false);
    setShowActionMenu(false);
  };

  const fileUploadHandler = async (e: any) => {
    const file = e.target.files?.[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      await dispatch(sendFileThunk({ receiverId: selectedChat._id, formData }));
      dispatch(getUserChatThunk({ receiverId: selectedChat._id, senderId: currentUser._id }));
    }
    return;
  };

  const actionCloser = () => {
    setShowActionMenu(false);
    setShowEmojiMenu(false);
  };

  // useEffect(()=>{
  //   if(userChats.userChat.data[0]){
  //     const chatDay=  convertToMidnightIST(userChats.userChat.data[0].createdAt)
  //     setChatDate(chatDay)
  //   }
  // },[userChats])

  return (
    <>
      {!selectedChat ? (
        <div className="hidden  md:flex justify-center flex-col font-bold items-center h-full w-full">
          <Typography variant="h3">No message selected</Typography>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          <Typography variant="h2" className="text-gray-400">
            Please select one message from the chat's sidebar
          </Typography>
        </div>
      ) : (
        <div className={`w-full md:flex flex-col ${showMobChat ? "flex" : "hidden"}`}>
          <div className="flex justify-between items-center mb-3 pl-0 md:pl-4">
            <div className="flex gap-3 items-center">
              <IoIosArrowBack className="text-3xl md:hidden cursor-pointer" onClick={() => setMobChat(false)} />
              <DisplayPicCard img={profilePic} online={isUserOnline} />
              <div>
                <Typography variant="h3" className="text-base">
                  {selectedChat?.name}
                </Typography>
                <Typography className="text-customgray">{isUserOnline ? "Online" : "Offline"}</Typography>
              </div>
            </div>
            <HiDotsHorizontal className="text-3xl cursor-pointer" onClick={() => setShowActionMenu(!showActionMenu)} />
            <HeaderMenu clearChatHandler={clearChatHandler} setDeleteModal={setDeleteModal} showActionMenu={showActionMenu} />
          </div>
          <Divide className="-mr-9" />
          <div
            className="h-full overflow-x-0 pr-2 mb-1 pt-2 pl-6 relative"
            id="chat"
            style={{
              scrollbarWidth: "thin",
              scrollbarColor: "#D7DBEC transparent",
              msOverflowStyle: "none",
              overflowY: "scroll",
              overflowX: "hidden",
              borderRadius: "16px"
            }}
            onClick={actionCloser}
          >
            {userChats.loadingChat ? (
              <div className="flex justify-center h-full items-center">
                <Spinner className="text-6xl" />
              </div>
            ) : (
              userChats.userChat.data &&
              [...userChats.userChat.data].reverse().map((obj, key) => {
                let showDate = false;
                if (obj.createdAt < chatDate) {
                  showDate = true;
                  setChatDate(convertToMidnightIST(obj.createdAt));
                }
                return (
                  <>
                    {showDate ? <Typography className={`flex justify-center text-customgray w-full`}>{dayjs(obj.createdAt).startOf("day").format("DD MMM YYYY")}</Typography> : ""}
                    <ChatBubble key={key} content={obj} profilePic={profilePic} selectedChat={selectedChat} />
                  </>
                );
              })
            )}
          </div>
          <div className="flex items-center w-[90%]  md:w-full gap-3 shadow-top py-4 pl-4  border-t-2 -mb-6">
            <p className="bg-gray-100 relative">
              <input type="file" className="hidden" id="uploadFile" accept=".jpg, .jpeg, .png, .pdf" onChange={fileUploadHandler} multiple />
              <label htmlFor="uploadFile">
                <IoMdAdd className={`text-2xl m-1 font-extrabold text-customgray transition-all  cursor-pointer`} />
              </label>
            </p>
            <input placeholder="Type message here" ref={inputRef} value={chatMsg} onChange={(e) => setChatMsg(e.target.value)} onKeyUpCapture={inputChangeHandler} className="w-full  appearance-none text-lg focus:outline-none font-semibold" />
            <div className="flex gap-3 relative">
              <img
                src={chatEmoji}
                className="cursor-pointer"
                onClick={() => {
                  setShowEmojiMenu(!showEmojiMenu);
                }}
              />
              <img src={sendChat} className="cursor-pointer" onClick={sendChatHandler} />
              <EmojiMenu handleEmojiClick={handleEmojiClick} showEmojiMenu={showEmojiMenu} />
            </div>
          </div>

          <DeleteChat deleteChatHandler={deleteChatHandler} setDeleteModal={setDeleteModal} showDeleteModal={showDeleteModal} />
        </div>
      )}
    </>
  );
};

export default ChatScreen;
