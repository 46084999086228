import { FC } from "react";
import Modal from "../../../../component/common/Modal";
import Typography from "../../../../component/semantic/Typography";
import ButtonOutline from "../../../../component/semantic/Button";
import CustomInput from "../../../../component/semantic/Input";
import CloseIcon from "../../../../assets/icons/gallery/close.svg";

interface ModalProps {
  showBlockModal: boolean;
  setBlockModal: any;
  blockUserHandler: any;
  setBlockUserData: any;
}

interface UserBlockState {
  status: string;
  userId: string;
  deActReason: string;
}

const BlockUser: FC<ModalProps> = ({ showBlockModal, setBlockModal, blockUserHandler, setBlockUserData }) => {
  return (
    <Modal showModal={showBlockModal} onClose={() => setBlockModal(false)} className="flex flex-col relative justify-center z-50 items-center gap-4">
      <img src={CloseIcon} className="absolute text-3xl text-gray-400 -right-3 -top-3 cursor-pointer" onClick={() => setBlockModal(false)} />
      <Typography variant="h2">Block User ?</Typography>
      <div className="flex flex-col md:flex-row items-center gap-0 justify-center md:gap-3">
        <CustomInput
          parentClassName="w-full flex gap-1 flex-col item-center"
          onChange={(e) =>
            setBlockUserData((prev: UserBlockState) => ({
              ...prev,
              deActReason: e.target.value
            }))
          }
          label="Reason"
        />
        <ButtonOutline
          title="Block"
          color="[#ff0000]"
          className="hover:bg-[#ff0000] hover:text-[#fee0e9] bg-[#fee0e9] text-[#ff0000] border-[#ff0000] h-10 mt-5"
          onClick={() => {
            blockUserHandler();
            setBlockUserData({
              status: "",
              userId: "",
              deActReason: ""
            });
            setBlockModal(false);
          }}
        />
      </div>
    </Modal>
  );
};

export default BlockUser;
