import { createSlice } from "@reduxjs/toolkit";
import { getAllUser, getUsersChildren } from "../thunk/UserManagement.thunk";
interface ApiState {
  data: any;
  loading: boolean;
  error: string | null;
  userData: responseData;
  userDataStats: responseData;
}

const initialState: ApiState = {
  data: null,
  loading: false,
  error: null,
  userData: { data: "" },
  userDataStats: { data: "" }
};

interface responseData {
  data: any;
}

const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllUser.fulfilled, (state, action) => {
        state.loading = false;
        state.userData = action.payload;
        state.error = null
      })
      .addCase(getAllUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "An error occurred";
      });

    builder
      .addCase(getUsersChildren.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUsersChildren.fulfilled, (state, action) => {
        state.loading = false;
        state.userDataStats = action.payload;
        state.error = null
      })
      .addCase(getUsersChildren.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "An error occurred";
      });
  }
});

export default userSlice.reducer;
