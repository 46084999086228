import ButtonOutlineIcon from "../../component/semantic/Button";
import Container from "../../component/common/Container";
import { FaShareAlt } from "react-icons/fa";
import { IoIosCopy } from "react-icons/io";
import DownloadImg from "../../assets/images/referral/DownloadApp.svg";
import Playstore from "../../assets/icons/referral/Playstore.svg";
import Apple from "../../assets/icons/referral/apple.svg";
import Typography from "../../component/semantic/Typography";
import { appCommonLink, appLink, referralUrl } from "../../helpers/constants/defaultUrls";
import React, { useEffect, useState } from "react";
import CustomInput from "../../component/semantic/Input";
import { handleCopy } from "../../helpers/utils";
import { useAppDispatch, useAppSelector } from "../../hooks/redux_toolkit";
import { barCodeGenrator, updateReferralCode } from "../../store/thunk/Referral.thunk";
import QRCodeGenerator from "../../component/common/QRCode";
import { userProfile } from "../../store/thunk/Common.thunk";
import Toast from "../../component/common/Toast";
import { getCurrentUser } from "../../helpers/currentUser";
import { showInputMessage, showToast } from "../../store/slices/Common.slice";

function Referral() {
  const [showReferralSection, setShowReferralSection] = useState<boolean>(false);
  const [referalQRCode, setReferralCode] = useState<string>("");
  const [appQRCode, setAppCode] = useState<string>("");
  const [newreferralCode, setNewReferallCode] = useState<string>("");

  const currentUser = getCurrentUser();

  const playStoreLink = appLink.get("playstore");
  const iosLink = appLink.get("ios");

  const dispatch = useAppDispatch();

  const updateCodeHandler = async () => {
    if (!newreferralCode) {
      dispatch(showInputMessage({ inputFieldMessage: "", name: "referral" }));
      if (!newreferralCode) return;
    }

    const isSuccess = await dispatch(updateReferralCode(newreferralCode)).unwrap();
    if (isSuccess.status == 200) dispatch(showToast({ message: "Referral Code Updated Successfully", method: "success" }));
    dispatch(userProfile(currentUser._id));
    setShowReferralSection(false);
    setNewReferallCode("");
  };

  const userData: any = useAppSelector((state) => state.rootReducer.common.userProfile?.data);

  useEffect(() => {
    dispatch(barCodeGenrator(referralUrl + userData?.referralCode))
      .unwrap()
      .then((data: any) => {
        setReferralCode(data.data);
      });
    dispatch(barCodeGenrator(appCommonLink))
      .unwrap()
      .then((data: any) => {
        setAppCode(data.data);
      });
  }, [userData]);

  return (
    <>
      <div id="mob-view" className="block lg:hidden mx-6 mb-3">
        <Typography variant="h1">Refer Associates</Typography>
        <Typography>Refer people using the link below and add them as your connections.</Typography>
      </div>

      <Toast className="hidden lg:flex left-[19rem] top-[6.5rem]" />
      <Container>
        <header className="hidden lg:block">
          <Typography variant="h3">Spread the word</Typography>
          <Typography className="text-customgray">Refer people using the link below and add them as your connections.</Typography>
        </header>
        <div className="flex gap-10 flex-col lg:flex-row justify-between ">
          <div className="mt-2 flex flex-col w-full md:w-[30rem] lg:w-[60rem] justify-between gap-4">
            <div className="w-full hidden lg:block">
              <Typography>Referral Code</Typography>
              <span className="flex gap-4">
                <CustomInput name="curReferral" value={userData?.referralCode} disabled />
                <ButtonOutlineIcon title="Update Referral Code" onClick={() => setShowReferralSection(!showReferralSection)} color="black" className="hover:bg-black hover:text-white w-48" />
              </span>
            </div>
            {showReferralSection && (
              <div className=" hidden lg:block">
                <Typography>Enter a new referral code</Typography>
                <div className="flex gap-4 items-start">
                  <CustomInput name="referral" required={true} value={newreferralCode} onChange={(e: any) => setNewReferallCode(e.target.value)} validationType="referral" type="text" placeholder="Enter new code" />
                  <ButtonOutlineIcon type="submit" onClick={updateCodeHandler} title="Update" color="black" className="hover:bg-black hover:text-white  w-48" />
                </div>
              </div>
            )}
            <div className="flex flex-col gap-4">
              <Typography variant="h2">Referral Link</Typography>
              <div className="flex flex-col items-center lg:flex-row gap-4 w-full">
                <Typography className="p-1 pl-4 pr-16 bg-[#7ddfb045] truncate rounded-md">{referralUrl + userData?.referralCode}</Typography>
                <span className="flex justify-center">
                  <ButtonOutlineIcon title="Share Link" color="[#000000]" className="hover:bg-black block lg:hidden hover:text-white" icon={<FaShareAlt className=" text-customgray " />} />
                  <ButtonOutlineIcon title="Copy" isCopy={true} color="[#000000]" className="hover:bg-black hover:text-white" onClick={() => handleCopy([referralUrl + userData?.referralCode || ""])} icon={<IoIosCopy className=" text-customgray" />} />
                </span>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center lg:justify-start gap-1">
            <span className="border-l-2 hidden lg:block border-gray-200 h-56 pr-12 ml-8"></span>
            <QRCodeGenerator base64String={referalQRCode && referalQRCode} className="w-36 h-40 shadow-lg" />
          </div>
        </div>

        <div className="border-t-2 border-gray-200 w-full my-8 "></div>

        <div className="flex flex-col lg:flex-row gap-4 justify-between">
          <div className="flex w-full items-center">
            <div className="w-auto lg:w-[30rem]   xl:w-[40rem]">
              <Typography variant="h2" className="mb-4">
                Download Our App
              </Typography>

              <div className="flex flex-col lg:flex-row gap-6">
                <div className="flex gap-6 ">
                  <img src={Playstore} className="w-16" />
                  <Typography className="p-1 px-2 font-semibold  bg-[#407cff28] break-all bg rounded-md">{playStoreLink}</Typography>
                </div>
                <div className="flex flex-row-reverse">
                  <ButtonOutlineIcon title="Copy" isCopy={true} color="black" className="hover:bg-black hover:text-white" onClick={() => handleCopy([playStoreLink || ""])} icon={<IoIosCopy className=" text-customgray" />} />
                </div>
              </div>

              <div className="flex flex-col lg:flex-row gap-6 mt-4 w-full">
                <div className="flex gap-6 w-full">
                  <img src={Apple} className="w-12" />
                  <Typography className="p-1 px-2 font-semibold  bg-[#407cff28] break-all bg rounded-md">{iosLink}</Typography>
                </div>
                <div className="flex flex-row-reverse">
                  <ButtonOutlineIcon title="Copy" isCopy={true} color="black" className="hover:bg-black hover:text-white" onClick={() => handleCopy([iosLink || ""])} icon={<IoIosCopy className=" text-customgray" />} />
                </div>
              </div>
            </div>
            <img src={DownloadImg} className="w-52 hidden xl:block" />
          </div>

          <div className="flex items-center justify-center lg:justify-start gap-1">
            <span className="border-l-2 hidden lg:block border-gray-200 h-56 pr-12 ml-8"></span>
            <QRCodeGenerator base64String={appQRCode} className="w-36 h-40 shadow-lg" />
          </div>
        </div>

        {/* Mob view */}
        <div id="mob-view" className="lg:hidden flex flex-col gap-3 mt-6">
          <div className="flex flex-col gap-3">
            <Toast className="block lg:hidden  left-7 bottom-[10rem] " />
            <Typography>Referral Code</Typography>
            <CustomInput name="currReferral" value={userData?.referralCode} disabled />
            <span className="flex flex-row-reverse">
              <ButtonOutlineIcon title="Update Referral Code" onClick={() => setShowReferralSection(!showReferralSection)} color="black" className="hover:bg-black w-48" />
            </span>
          </div>

          {showReferralSection && (
            <div className="flex flex-col gap-3">
              <Typography>Enter a new referral code</Typography>
              <div className="flex gap-4 flex-col">
                <CustomInput name="referral" required={true} value={newreferralCode} onChange={(e: any) => setNewReferallCode(e.target.value)} validationType="referral" type="text" placeholder="Enter new code" />
                <span className="flex flex-row-reverse">
                  <ButtonOutlineIcon type="submit" onClick={updateCodeHandler} title="Update" color="black" className="hover:bg-black w-48" />
                </span>
              </div>
            </div>
          )}
        </div>
      </Container>
    </>
  );
}

export default Referral;
