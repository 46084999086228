import * as Yup from "yup";

export const editUserSchema = Yup.object({
  name: Yup.string()
    .min(2, "Name must be greater than 2 character")
    .max(25, "Name must be less than 25 character")
    .trim()
    .test("is-not-empty-or-space", "Name cannot be empty or spaces only", (value: any) => value && value.trim().length > 0)
    .required("Name is required!"),
  mobile: Yup.string()
    .matches(/^[6789]\d{9}$/, "Please Enter a valid mobile number")
    .required("Mobile number is required!"),
  email: Yup.string()
    .trim()
    .matches(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/, "Please enter a valid Email")
    .required("Email is required!"),
  address: Yup.string()
    .trim()
    .test("not-only-spaces", "Address cannot be only spaces", (value: any) => value?.trim().length > 0)
    .required("Address is required!"),
  dob: Yup.date().max(new Date(), "Date of Birth cannot be in the future").required("Date of Birth is required!"),
  gender: Yup.string(),
  stateName: Yup.string().required("State is required"),
  districtName: Yup.string().required("District is required").notOneOf(["Select District"]),
  pincode: Yup.number().min(100000, "Please enter a valid 6-digit pin code!").max(999999, "Please enter a valid 6-digit pin code!").required("Pin code is required!"),
  aadhaarNumber: Yup.string()
    .length(12, "Aadhar Number must be of exactly 12 digits")
    .test("is-not-empty-or-space", "Aadhaar number cannot be empty or spaces only", (value: any) => value && value.trim().length > 0)
    .required("Aadhar number is required!"),
  natureOfBusiness: Yup.string(),
  areaOfOperation: Yup.array(),
  panNumber: Yup.string()
    .matches(/^[a-zA-Z]{5}\d{4}[a-zA-Z]$/, "Please Enter a valid pan number!")
    .required("Pan number is required!"),
  gstNumber: Yup.string().matches(/^\d{2}[a-zA-Z]{5}\d{4}[a-zA-Z]\d[a-zA-Z]\d$/, "Please Enter a valid gst Number!"),
  companyName: Yup.string().matches(/[^\s\W]/g, "Please Enter a valid comapny name !"),
  about: Yup.string().matches(/[^\s\W]/g, "Please Enter a valid data !"),
  website: Yup.string().matches(/^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:[0-9]{1,5})?(\/.*)?$/, "Please Enter a valid Web URL !")
});
