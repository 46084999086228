import React, { FC } from "react";

interface CountCircle {
  count: number;
}

const CountCircle: FC<CountCircle> = ({ count }) => {
  return <span className={`absolute -top-4 -right-4 bg-orange-400 text-white h-7  px-1.5 py-0.5 border-4 border-white  rounded-full text-xs font-semibold animate-noti-bounce`}>{count || 0}</span>;
};

export default CountCircle;
