import React from "react";

interface DisplayPicCardProps {
  img: string;
  online?: boolean;
  imgClassName?: string;
}

const DisplayPicCard: React.FC<DisplayPicCardProps> = ({ img, online, imgClassName }) => {
  return (
    <div className="relative rounded-lg overflow-hidden">
      <img src={img} className={`w-12 h-12 ${imgClassName}`} alt="Avatar" />
      {online !== undefined && <div className={`absolute bottom-0 right-0  rounded-lg border-2 h-3 w-3 ${online ? "bg-green-600" : "bg-red-600"}  border-white`} />}
    </div>
  );
};

export default DisplayPicCard;
