import { createSlice } from "@reduxjs/toolkit";
import { getAllUsersThunk } from "../thunk/AllUser.thunk";

interface ApiState {
  data: any;
  loading: boolean;
  error: string | null;
  usersList: responseData;
}

interface responseData {
  data: any;
}

const initialState: ApiState = {
  data: null,
  loading: false,
  error: null,
  usersList: { data: "" }
};

const AllUsers = createSlice({
  name: "AllUsersSlice",
  initialState,
  reducers: {
    setSortedDataUsers: (state, action) => {
      state.usersList.data.userList = [...action.payload];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllUsersThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllUsersThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.usersList = action.payload;
        state.error = null;
      })
      .addCase(getAllUsersThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "An error occurred";
      });
  }
});

export const { setSortedDataUsers } = AllUsers.actions;

export default AllUsers.reducer;
