import React, { useState } from "react";
import Container from "../../../component/common/Container";
import coverPhoto from "../../../assets/images/profile/cover.svg";
import Typography from "../../../component/semantic/Typography";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux_toolkit";
import { s3Url } from "../../../helpers/constants/defaultUrls";
import { profilePicThunk } from "../../../store/thunk/Profile.thunk";
import { getCurrentUser } from "../../../helpers/currentUser";
import { userProfile } from "../../../store/thunk/Common.thunk";
import defaultPic from "../../../assets/icons/navbar/profilePicDefault.jpg";
import { FaCamera } from "react-icons/fa6";
import Toast from "../../../component/common/Toast";

const Header = () => {
  const userData: any = useAppSelector((state) => state.rootReducer.common.userProfile.data);
  const currentUser = getCurrentUser();
  const dispatch = useAppDispatch();

  const [profileHover, setProfileHover] = useState<boolean>();

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const formData = new FormData();
      formData.append("image", file);
      await dispatch(profilePicThunk(formData));
      dispatch(userProfile(currentUser?._id));
    }
  };

  const profilePicUrl = s3Url + userData?._id + "/" + userData?.profilePic;

  return (
    <>
      <Toast className="right-8" />
      <Container customClassName="px-0 pl-0 pr-0 pt-0 pb-0 lg:px-0 py-0 lg:py-0 lg:pl-0 lg:pr-0 ">
        <img src={coverPhoto} className="w-full rounded-t-lg  object-cover h-[8rem] lg:h-[10rem] bg-gray-400" />
        <div className="relative -top-12 left-6 lg:-top-16 lg:left-20 flex gap-6 w-[20rem]">
          <div className="w-24 z-0 h-24 lg:h-36 lg:w-36 rounded-full overflow-hidden">
            <input type="file" id="profilePic" className="hidden" accept=".jpg, .jpeg, .png, .pdf" onChange={handleFileChange} multiple={false} />
            <label htmlFor="profilePic">
              <div className="relative inline-block" onMouseEnter={() => setProfileHover(true)} onMouseLeave={() => setProfileHover(false)}>
                <img src={userData?.profilePic ? profilePicUrl : defaultPic} className={`cursor-pointer  ${profileHover && "filter brightness-50"}`} alt="Profile pic" />
                <div className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2  ${!profileHover && "hidden"}`}>
                  <FaCamera className="text-xl cursor-pointer text-white" />
                </div>
              </div>
            </label>
          </div>
          <span className="mt-12 lg:mt-20">
            <Typography variant="h1">{userData?.name}</Typography>
            <Typography className="text-gray-400">{userData?.userType}</Typography>
          </span>
        </div>
      </Container>
    </>
  );
};

export default Header;
