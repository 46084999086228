import { createSlice } from "@reduxjs/toolkit";
import { downloadTemplateThunk, getGalleryContentThunk, UploadHistoryThunk, uploadLogoThunk, uploadMediaThunk } from "../thunk/Gallery.thunk";
import { toast } from "react-toastify";

interface ApiState {
  data: any;
  loading: boolean;
  uploadLoading: boolean;
  error: string | null;
  galleryReferral: responseData;
  galleryFestival: responseData;
  galleryReels: responseData;
  galleryStandee: responseData;
  galleryPosters: responseData;
  galleryBanner: responseData;
  galleryBlogs: responseData;
  galleryLeadGeneration: responseData;
  galleryBusinessCards: responseData;
  historyData: responseData;
  downloadLoading: boolean;
  logoLoading: boolean;
}

interface responseData {
  data: any;
}

const initialState: ApiState = {
  data: null,
  loading: false,
  error: null,
  uploadLoading: false,
  galleryReferral: { data: "" },
  galleryFestival: { data: "" },
  galleryReels: { data: "" },
  galleryStandee: { data: "" },
  galleryLeadGeneration: { data: "" },
  galleryPosters: { data: "" },
  galleryBlogs: { data: "" },
  galleryBusinessCards: { data: "" },
  galleryBanner: { data: "" },
  historyData: { data: "" },
  downloadLoading: false,
  logoLoading: false
};

const gallerySlice = createSlice({
  name: "gallerySlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(uploadMediaThunk.pending, (state) => {
        state.uploadLoading = true;
      })
      .addCase(uploadMediaThunk.fulfilled, (state, action) => {
        if (action.payload) toast.success("File Uploaded Successfully");
        state.uploadLoading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(uploadMediaThunk.rejected, (state, action) => {
        state.uploadLoading = false;
        state.error = action.error.message ?? "An error occurred";
      })

      .addCase(UploadHistoryThunk.pending, (state) => {
        state.loading = false;
      })
      .addCase(UploadHistoryThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.historyData = action.payload;
        state.error = null;
      })
      .addCase(UploadHistoryThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "An error occurred";
      })

      .addCase(getGalleryContentThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getGalleryContentThunk.fulfilled, (state, action) => {
        if (action.meta.arg.category == "Referral Creatives") state.galleryReferral = action.payload;
        else if (action.meta.arg.category == "Informative Creatives" && action.meta.arg.subcategory == "Reels") state.galleryReels = action.payload;
        else if (action.meta.arg.category == "Informative Creatives" && action.meta.arg.subcategory == "Blogs") state.galleryBlogs = action.payload;
        else if (action.meta.arg.category == "Festivities Creatives") state.galleryFestival = action.payload;
        else if (action.meta.arg.category == "Branding Creatives" && action.meta.arg.subcategory == "Standee") state.galleryStandee = action.payload;
        else if (action.meta.arg.category == "Branding Creatives" && action.meta.arg.subcategory == "Posters") state.galleryPosters = action.payload;
        else if (action.meta.arg.category == "Branding Creatives" && action.meta.arg.subcategory == "Banner") state.galleryBanner = action.payload;
        else if (action.meta.arg.category == "Branding Creatives" && action.meta.arg.subcategory == "Business Cards") state.galleryBusinessCards = action.payload;
        else if (action.meta.arg.category == "Lead Generation Creatives") state.galleryLeadGeneration = action.payload;

        state.loading = false;
        state.error = null;
      })
      .addCase(getGalleryContentThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "An error occurred";
      })

      .addCase(downloadTemplateThunk.pending, (state) => {
        state.downloadLoading = true;
      })
      .addCase(downloadTemplateThunk.fulfilled, (state) => {
        state.downloadLoading = false;
        state.error = null;
      })
      .addCase(downloadTemplateThunk.rejected, (state, action) => {
        state.downloadLoading = false;
        state.error = action.error.message ?? "An error occurred";
      })

      .addCase(uploadLogoThunk.pending, (state) => {
        state.logoLoading = true;
      })
      .addCase(uploadLogoThunk.fulfilled, (state) => {
        state.logoLoading = false;
        state.error = null;
      })
      .addCase(uploadLogoThunk.rejected, (state, action) => {
        state.logoLoading = false;
        state.error = action.error.message ?? "An error occurred";
      });
  }
});

export default gallerySlice.reducer;
