import React, { useEffect, useState } from "react";
import Filter from "./component/Filter";
import Section from "./component/Section";
import Stats from "./component/Stats";
import { useAppDispatch } from "../../hooks/redux_toolkit";
import { getInvoiceSettledCount, getMailingCountThunk, getOmdCountThunk, getPartnerCount, getPartnerRegistrationCount, getPartnerResolvedCount, resendCasesCount } from "../../store/thunk/NewDashboard.thunk";
import { getCurrentUser } from "../../helpers/currentUser";
import { bucketsConstants } from "../../helpers/constants/constants";

const NewDashboard = () => {
  const [dateFilter, setDateFilter] = useState<any>({ name: "Monthly" });
  const [selectedBucket, setSelectedBucket] = useState(bucketsConstants[0]);
  const [customDate, setCustomDate] = useState({ startDate: "", endDate: "" });
  const [ombudsmanFilter, setOmbudsmanFilter] = useState({ location: "", status: "OMBUDSMAN PENDING" });

  const currentUser = getCurrentUser();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (dateFilter) apiHandler({ KeyRefresh: true });
    else apiHandler({ KeyRefresh: true, startDate: customDate.startDate, endDate: customDate.endDate });
  }, [dateFilter, selectedBucket]);

  const refreshHandler = () => {
    if (dateFilter) apiHandler({ KeyRefresh: true });
    else apiHandler({ KeyRefresh: true, startDate: customDate.startDate, endDate: customDate.endDate });
  };

  const clearFilterHandler = () => {
    setDateFilter({ name: "Monthly" });
    setCustomDate({ startDate: "", endDate: "" });
    setOmbudsmanFilter({ location: "", status: "OMBUDSMAN PENDING" });
  };

  const apiHandler = ({ KeyRefresh, startDate, endDate }: { KeyRefresh: boolean; startDate?: string; endDate?: string }) => {
    if (selectedBucket.index === 0) {
      dispatch(
        getPartnerRegistrationCount({
          KeyRefresh,
          user_id: currentUser._id,
          ...(startDate && endDate ? { startDate, endDate } : { dateWise: dateFilter.name })
        })
      );
    } else if (selectedBucket.selectedSubBucket === "Partner Resend Cases") {
      dispatch(resendCasesCount({ KeyRefresh, selectedStatus: ombudsmanFilter.status ? ombudsmanFilter?.status : "", dateWise: dateFilter.name, user_id: currentUser._id }));
    } else if (selectedBucket.index == 1) {
      dispatch(getMailingCountThunk({ KeyRefresh, ...(startDate && endDate ? { startDate, endDate } : { dateWise: dateFilter.name }), user_id: currentUser._id }));
    } else if (selectedBucket.index == 3) {
      dispatch(getPartnerCount({ KeyRefresh, user_id: currentUser._id }));
    } else if (selectedBucket.index === 4) {
      if (selectedBucket.selectedSubBucket == "Partner Resolution Dashboard") {
        dispatch(getPartnerResolvedCount({ KeyRefresh, ...(startDate && endDate ? { startDate, endDate } : { dateWise: dateFilter.name }), user_id: currentUser._id }));
      } else if (selectedBucket.selectedSubBucket == "Partner Settled Cases") {
        dispatch(getInvoiceSettledCount({ KeyRefresh, type: "Settled Data", ...(startDate && endDate ? { startDate, endDate } : { dateWise: dateFilter.name }), user_id: currentUser._id }));
      } else if (selectedBucket.selectedSubBucket == "Partner Invoice Cases") {
        dispatch(getInvoiceSettledCount({ KeyRefresh, type: "Invoice Data", ...(startDate && endDate ? { startDate, endDate } : { dateWise: dateFilter.name }), user_id: currentUser._id }));
      }
    } else if (selectedBucket.selectedSubBucket === "Partner Ombudsman Dashboard") {
      dispatch(getOmdCountThunk({ KeyRefresh, ...(startDate && endDate ? { startDate, endDate } : { dateWise: dateFilter.name }), user_id: currentUser._id }));
    }
    return;
  };

  const customDateHandler = (startDate: string, endDate: string) => {
    setCustomDate({ startDate, endDate });
    apiHandler({ KeyRefresh: false, startDate, endDate });
    setDateFilter("");
  };

  return (
    <>
      <Filter setDateFilter={setDateFilter} dateFilter={dateFilter} clearFilterHandler={clearFilterHandler} customDateHandler={customDateHandler} customDate={customDate} setCustomDate={setCustomDate} />
      <Section selectedBucket={selectedBucket} setSelectedBucket={setSelectedBucket} setOmbudsmanFilter={setOmbudsmanFilter} ombudsmanFilter={ombudsmanFilter} />
      <Stats selectedStatus={selectedBucket.selectedSubBucket} refreshHandler={refreshHandler} selectedFilter={dateFilter} customDate={customDate} />
    </>
  );
};

export default NewDashboard;
