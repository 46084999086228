import React, { FC } from "react";

interface ToggleProps {
  onClick: any;
  enabled: boolean;
}

const Toggle: FC<ToggleProps> = ({ onClick, enabled }) => {
  return (
    <button onClick={onClick} className={`w-16 h-8 flex items-center rounded-full p-1 cursor-pointer transition-colors duration-300 ${enabled ? "bg-green-500" : "bg-gray-300"}`}>
      <div className={`bg-white w-6 h-6 rounded-full hover:scale-110 shadow-md transform transition-transform duration-300 ${enabled ? "translate-x-8" : ""}`} />
    </button>
  );
};

export default Toggle;
