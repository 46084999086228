import React, { useEffect, useState } from "react";
import Typography from "../../../component/semantic/Typography";
import Select from "react-select";
import { polyfixSelect } from "../../../helpers/constants/styles";
import FileUpload from "../../../component/common/FileUpload";
import { FaFile } from "react-icons/fa";
import Divide from "../../../component/semantic/Divide";
import ButtonOutline from "../../../component/semantic/Button";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux_toolkit";
import { UploadHistoryThunk, getGalleryContentThunk, uploadMediaThunk } from "../../../store/thunk/Gallery.thunk";
import { ImSpinner10 } from "react-icons/im";

const dropdowns = [{ name: "Lead Generation Creatives" }, { name: "Referral Creatives" }, { name: "Informative Creatives" }, { name: "Festivities Creatives" }, { name: "Branding Creatives" }];
const subCategoryDropdown = [
  [{ name: "Reels" }, { name: "Blogs" }],
  [{ name: "Standee" }, { name: "Posters" }, { name: "Banner" }, { name: "Business Cards" }]
];

const UploadCreative = () => {
  const [selectedContent, setSelectedContent] = useState<any>();
  const [selectedCover, setSelectedCover] = useState<any>();
  const [selectedCategory, setSelectedCategory] = useState(dropdowns[0]);
  const [subcategory, setSubcategory] = useState<any>();
  const [selectedSubcategory, setSelectedSubcategory] = useState<any>();
  // eslint-disable-next-line
  const [acceptedInput, setAcceptedInput] = useState(".jpg, .jpeg, .png");

  const dispatch = useAppDispatch();

  const loading = useAppSelector((state) => state.rootReducer.gallery.uploadLoading);

  const submitHandler = (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", selectedContent);
    formData.append("category", selectedCategory.name);
    if (selectedSubcategory) formData.append("subcategory", selectedSubcategory.name);
    if (selectedCover) formData.append("cover", selectedCover);
    formData.append("title", selectedContent.name.split(".")[0]);
    formData.append("type", selectedContent.type);
    dispatch(uploadMediaThunk(formData))
      .unwrap()
      .then(() => {
        const category = selectedCategory.name;
        dispatch(UploadHistoryThunk({ pageSize: 10, pageIndex: 1 }));
        if (category === "Lead Generation Creatives") dispatch(getGalleryContentThunk({ category: "Lead Generation Creatives" }));
        else if (category === "Referral Creatives") dispatch(getGalleryContentThunk({ category: "Referral Creatives" }));
        else if (category === "Informative Creatives") {
          if (selectedSubcategory?.name === "Reels") dispatch(getGalleryContentThunk({ category: "Informative Creatives", subcategory: "Reels" }));
          else dispatch(getGalleryContentThunk({ category: "Informative Creatives", subcategory: "Blogs" }));
        } else if (category === "Festivities Creatives") dispatch(getGalleryContentThunk({ category: "Festivities Creatives" }));
        else if (category === "Branding Creatives") {
          if (selectedSubcategory?.name === "Standee") dispatch(getGalleryContentThunk({ category: "Branding Creatives", subcategory: "Standee" }));
          else if (selectedSubcategory?.name === "Posters") dispatch(getGalleryContentThunk({ category: "Branding Creatives", subcategory: "Posters" }));
          else if (selectedSubcategory?.name === "Banner") dispatch(getGalleryContentThunk({ category: "Branding Creatives", subcategory: "Banner" }));
          else dispatch(getGalleryContentThunk({ category: "Branding Creatives", subcategory: "Business Cards" }));
        }
      });
    setSelectedContent("");
    setSelectedCover("");
    // setSelectedCategory(dropdowns[0])
  };

  useEffect(() => {
    if (selectedCategory.name == "Informative Creatives") setSubcategory(subCategoryDropdown[0]);
    else if (selectedCategory.name == "Branding Creatives") setSubcategory(subCategoryDropdown[1]);
  }, [selectedCategory]);

  useEffect(() => {
    if (selectedCategory.name === "Informative Creatives") {
      if (selectedSubcategory?.name === "Reels") setAcceptedInput(".mp4");
      else setAcceptedInput(".pdf");
    } else setAcceptedInput(".jpg, .jpeg, .png");
  }, [selectedCategory]);

  const inputTypeHandler = () => {
    if (selectedCategory.name === "Informative Creatives") {
      if (selectedSubcategory?.name === "Reels") return ".mp4";
      else return ".pdf";
    } else return ".jpg, .jpeg, .png";
  };

  return (
    <div className="flex flex-col justify-center items-center ">
      <p className="text-black  text-lg md:text-base md:text-gray-400 font-bold mb-4">Upload new creatives for partners</p>
      <form onSubmit={submitHandler} className="w-[20rem] md:w-[35rem] bottom-0 md:border-2  border-gray-200 rounded-xl  shadow-none md:shadow-sm px-2 md:p-6 ">
        <Typography className="my-2 text-sm text-[#333333] font-bold">Select Creative Category</Typography>
        <Select options={dropdowns} getOptionLabel={(option) => option.name} getOptionValue={(option) => option.name} styles={polyfixSelect} value={selectedCategory} onChange={(option: any) => setSelectedCategory(option)} isSearchable={false} />
        {(selectedCategory.name == "Informative Creatives" || selectedCategory.name == "Branding Creatives") && (
          <>
            <Typography className="my-2 mt-3 text-sm text-[#333333] font-bold">Select Sub-category</Typography>
            <Select options={subcategory} getOptionLabel={(option) => option.name} getOptionValue={(option) => option.name} styles={polyfixSelect} value={selectedSubcategory} onChange={(option: any) => setSelectedSubcategory(option)} isSearchable={false} />
          </>
        )}
        {selectedCategory.name == "Informative Creatives" && (
          <>
            <Typography className="mt-6 mb-2 text-sm text-[#333333] font-bold">Choose Cover</Typography>
            <FileUpload id="coverFromGallery" accept=".jpg, .jpeg, .png" setSelectedFile={setSelectedCover} />
            <span className="items-center flex mt-4">
              {selectedCover && (
                <div className="text-gray-700 bg-uploadTheme flex px-4 rounded-lg py-1 gap-1 items-center">
                  <FaFile className="text-gray-400 opacity-50" />
                  {selectedCover.name}
                </div>
              )}
            </span>
          </>
        )}
        <Typography className="mt-6 mb-2 text-sm text-[#333333] font-bold">Choose Creative</Typography>
        <FileUpload id="creativeFromGallery" accept={inputTypeHandler()} setSelectedFile={setSelectedContent} />
        <span className="items-center flex mt-4">
          {selectedContent && (
            <div className="text-gray-700 bg-uploadTheme flex px-4 rounded-lg py-1 gap-1 items-center">
              <FaFile className="text-gray-400 opacity-50" />
              {selectedContent.name}
            </div>
          )}
        </span>
        <Divide className="my-4 shadow-2xl md:-mx-6" />
        <span className="flex justify-center">
          <ButtonOutline title={loading ? "Uploading" : "Upload"} icon={loading && <ImSpinner10 className="animate-spin" />} type="submit" className={`bg-polyfixTheme hover:text-black  ${!selectedContent && "pointer-events-none   opacity-50"}`} />
        </span>
      </form>
    </div>
  );
};

export default UploadCreative;
