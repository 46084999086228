import React, { HTMLProps } from "react";

interface HeadingProps extends HTMLProps<HTMLDivElement> {
  className?: string;
}

const Heading1: React.FC<HeadingProps> = ({ className = "", children, ...props }) => {
  return (
    <h1 className={className} {...props}>
      {children}
    </h1>
  );
};

export default Heading1;
