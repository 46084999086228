import React, { useEffect, useRef, useState } from "react";
import Container from "../../component/common/Container";
import { CiFilter } from "react-icons/ci";
import Typography from "../../component/semantic/Typography";
import ButtonOutline from "../../component/semantic/Button";
import ExcelIcon from "../../assets/icons/resolveCase/excel.svg";
import totalCaseImg from "../../assets/icons/resolveCase/totalCase.svg";
import resolutionImg from "../../assets/icons/resolveCase/resolutionAmount.svg";
import commissionImg from "../../assets/icons/resolveCase/commissionAmount.svg";
import Divide from "../../component/semantic/Divide";
import emptyCase from "../../assets/icons/resolveCase/emptyCase.svg";
import ReactSelect from "react-select";
import { filterStyles } from "../../helpers/constants/styles";
import { CustomMenu } from "../dashboard/components/ReactSelectCustom";
import { useAppDispatch, useAppSelector } from "../../hooks/redux_toolkit";
import { getAllResolveCase, getAllResolveCaseDownload } from "../../store/thunk/resolveCase.thunk";
import SimpleTable from "../../component/table/SimpleTable";
import { resolveCaseHeading, resolveCaseKeys } from "../../component/table/TableConstants";
import { getCurrentUser } from "../../helpers/currentUser";
import Spinner from "../../component/common/Spinner";

const ResolveCase = () => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [showRange, setShowRange] = useState<boolean>(false);
  const [resolutionAmount, setResolutionAmount] = useState<number>(0);
  const [commissionAmount, setCommissionAmount] = useState<number>(0);
  const [downloadExcel, setDownloadExcel] = useState<any>();

  const [selectedDate, setSelectedDate] = useState<any>({ label: "Date" });
  const dispatch = useAppDispatch();

  const selectRef = useRef<any>("");
  const selectRefMob = useRef<any>("");
  const currentUser = getCurrentUser();

  const resolveCaseData = useAppSelector((state) => state.rootReducer.resolveCase);

  function isDateBetween10And24() {
    const date = new Date();
    const dayOfMonth = date.getDate();
    return dayOfMonth >= 10 && dayOfMonth <= 24;
  }
  function convertDate(date: number) {
    const today = new Date();
    today.setDate(date);
    return today.toISOString().slice(0, 10);
  }

  function convertDateOfNextMonth(date: number) {
    const today = new Date();
    today.setMonth(today.getMonth() + 1);
    today.setDate(date);
    return today.toISOString().slice(0, 10);
  }

  useEffect(() => {
    initialDataHandler();
  }, []);

  const initialDataHandler = () => {
    if (isDateBetween10And24()) {
      resolveCaseDataHandler({ startDate: convertDate(10), endDate: convertDate(24), generateReport: false });
    } else resolveCaseDataHandler({ startDate: convertDate(25), endDate: convertDateOfNextMonth(9), generateReport: false });
    setSelectedDate({ label: "Date" });
  };

  useEffect(() => {
    if (resolveCaseData.resolvedCaseData.data) {
      let totalResolutionAmount = 0;
      let totalCommissionAmount = 0;
      resolveCaseData.resolvedCaseData.data?.map((data: any) => {
        totalResolutionAmount = totalResolutionAmount + data?.claimAmount;
        totalCommissionAmount = totalCommissionAmount + data?.commission;
      });
      setCommissionAmount(totalCommissionAmount);
      setResolutionAmount(totalResolutionAmount);
    }
  }, [resolveCaseData]);

  const resolveCaseDataHandler = async ({ startDate, endDate, generateReport }: { startDate: string; endDate: string; generateReport: boolean }) => {
    await dispatch(
      getAllResolveCase({
        startDate,
        endDate,
        generateReport,
        hierarchy: currentUser?.hierarchicalLevel,
        userId: currentUser?._id,
        userType: currentUser?.userType
      })
    );
    dispatch(
      getAllResolveCaseDownload({
        startDate,
        endDate,
        generateReport: true,
        hierarchy: currentUser?.hierarchicalLevel,
        userId: currentUser?._id,
        userType: currentUser?.userType
      })
    )
      .unwrap()
      .then((data: any) => setDownloadExcel(data));
  };

  const downloadExcelHandler = () => {
    try {
      const blob = new Blob([downloadExcel], { type: "text/csv" });
      const urlObject = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("href", urlObject);
      a.setAttribute("download", "Resolved_Cases");
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(urlObject);
    } catch (error) {
      // eslint-disable-next-line
      console.log("Error fetching or processing data:", error);
    }
  };

  return (
    <div className="mb-6">
      <div id="mob-filter" className="block md:hidden mx-4">
        <div className="font-semibold px-2">
          <p className="font-extrabold text-xl">Resolved Cases</p>
          <div className="flex justify-between mt-2">
            <span className="flex gap-3">
              <CiFilter className="text-polyfixTheme bg-[#faa95221] rounded p-[2px]" size={20} />
              <Typography className="text-customgray">Filter By</Typography>
            </span>
            <Typography variant="h2" className="text-polyfixTheme cursor-pointer" onClick={initialDataHandler}>
              Clear Filters
            </Typography>
          </div>
        </div>
        <div className="px-2 mt-2 gap-2 flex flex-col">
          <ReactSelect
            ref={selectRefMob}
            className="w-full cursor-pointer"
            closeMenuOnSelect={false}
            isSearchable={false}
            onFocus={() => setIsMenuOpen(true)}
            onBlur={() => setIsMenuOpen(false)}
            menuIsOpen={isMenuOpen}
            placeholder="Date"
            styles={filterStyles}
            value={{ label: selectedDate?.label, value: "" }}
            components={{
              Menu: (props) => (
                <CustomMenu
                  {...props}
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                  closeMenu={() => {
                    setIsMenuOpen(false);
                    selectRefMob.current?.blur();
                  }}
                  showRange={showRange}
                  setShowRange={setShowRange}
                  apiFunction={(payload: any) => resolveCaseDataHandler(payload)}
                />
              )
            }}
          />
          <ButtonOutline onClick={downloadExcelHandler} title="Download Excel" icon={<img src={ExcelIcon} />} className={`hover:bg-[#00733B] hover:text-white bg-white text-[#00733B] border-2 border-[#00733B] w-full mb-2 ${typeof downloadExcel != "string" && "opacity-50 pointer-events-none"}`} />
        </div>
      </div>
      <Container customClassName="hidden md:flex items-center justify-between">
        <div className="flex gap-2 items-center">
          <CiFilter className="text-[#FAAA52]" size={20} />
          <Typography className="w-20">Filter By</Typography>
          <ReactSelect
            ref={selectRef}
            className="w-60 cursor-pointer"
            closeMenuOnSelect={false}
            isSearchable={false}
            onFocus={() => setIsMenuOpen(true)} // Open menu on focus
            onBlur={() => setIsMenuOpen(false)} // Close menu on blur
            menuIsOpen={isMenuOpen}
            placeholder="Date"
            styles={filterStyles}
            value={{ label: selectedDate?.label, value: "" }}
            components={{
              Menu: (props) => (
                <CustomMenu
                  {...props}
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                  closeMenu={() => {
                    setIsMenuOpen(false);
                    selectRef.current?.blur();
                  }}
                  showRange={showRange}
                  setShowRange={setShowRange}
                  apiFunction={(payload: any) => resolveCaseDataHandler(payload)}
                />
              )
            }}
          />
        </div>
        <div className="flex gap-2">
          <ButtonOutline onClick={initialDataHandler} title="Clear Filter" color="polyfixTheme" className="hover:bg-polyfixTheme hover:text-white border-2 w-32 mb-2" />
          <ButtonOutline title="Download Excel" onClick={downloadExcelHandler} icon={<img src={ExcelIcon} />} className={`hover:bg-[#00733B] text-[#00733B] hover:text-white border-2 border-[#00733B] w-44 mb-2 ${typeof downloadExcel != "string" && "opacity-50 pointer-events-none"}`} />
        </div>
      </Container>

      <Container customClassName="mt-6">
        <p className="font-bold text-lg md:text-xl">List of Resolved Cases</p>
        <Typography className="text-gray-400 text-sm md:text-base"> Check the resolved cases dates, amounts, and commissions.</Typography>

        {resolveCaseData.loading ? (
          <Spinner />
        ) : resolveCaseData.resolvedCaseData.data && resolveCaseData.resolvedCaseData.data.length == 0 ? (
          <div className="h-72 flex flex-col justify-center items-center">
            <img src={emptyCase} />
            <Typography variant="h2" className="text-center">
              Please select the time period to view the list of resolved cases
            </Typography>
          </div>
        ) : (
          <SimpleTable tableData={resolveCaseData.resolvedCaseData?.data} tableHeading={resolveCaseHeading} tableKeys={resolveCaseKeys} />
        )}
      </Container>

      {resolveCaseData?.resolvedCaseData?.data?.length > 0 || resolutionAmount || commissionAmount ? (
        <Container customClassName="mt-6   flex justify-between flex-wrap gap-3">
          <div className="flex gap-4 items-center">
            <img src={totalCaseImg} />
            <div className="flex flex-col items-start justify-center">
              <Typography className="text-gray-500 text-lg"> Total Resolved Cases</Typography>
              <Typography variant="h1">{resolveCaseData?.resolvedCaseData?.data?.length || 0}</Typography>
            </div>
          </div>
          <Divide horizontal={true} className="hidden md:block" />
          <Divide className="w-full block md:hidden my-2" />
          <div className="flex gap-4 items-center">
            <img src={resolutionImg} />
            <div className="flex flex-col items-start justify-center">
              <Typography className="text-gray-500 text-lg"> Total Resolution Amount</Typography>
              <Typography variant="h1">
                ₹ <span className="text-[#00AC4F]">{resolutionAmount}</span>
              </Typography>
            </div>
          </div>
          <Divide horizontal={true} className="hidden md:block" />
          <Divide className="w-full block md:hidden my-2" />
          <div className="flex gap-4 items-center">
            <img src={commissionImg} />
            <div className="flex flex-col items-start justify-center">
              <Typography className="text-gray-500 text-lg"> Total Commission Amount</Typography>
              <Typography variant="h1">
                ₹ <span className="text-[#00AC4F]">{commissionAmount}</span>
              </Typography>
            </div>
          </div>
        </Container>
      ) : (
        ""
      )}
    </div>
  );
};

export default ResolveCase;
