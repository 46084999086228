import React, { FC } from "react";
import Modal from "../../../component/common/Modal";
import Typography from "../../../component/semantic/Typography";
import ButtonOutline from "../../../component/semantic/Button";
interface ModalProps {
  confirmationModal: boolean;
  setConfirmationModal: any;
  text: string;
  successButtonHandler: any;
}
const ConfirmationModal: FC<ModalProps> = ({ setConfirmationModal, confirmationModal, text, successButtonHandler }) => {
  return (
    <Modal showModal={confirmationModal} onClose={() => setConfirmationModal(false)} className="relative w-[25%] p-8">
      <Typography>{text}</Typography>
      <div className="flex items-center w-full mt-6">
        <div className="w-1/2">
          <ButtonOutline onClick={successButtonHandler} className="text-white w-full bg-[#1BA785] outline-[none]" type="button" title="YES" />
        </div>
        <div className="w-1/2">
          <ButtonOutline
            onClick={() => {
              setConfirmationModal(false);
            }}
            className="text-white w-full bg-[#FF3C3CED] outline-[none]"
            type="button"
            title="NO"
          />
        </div>
      </div>
    </Modal>
  );
};
export default ConfirmationModal;
