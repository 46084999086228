import React from "react";
import Bell from "../../../assets/icons/navbar/Bell.svg";
import Typography from "../../../component/semantic/Typography";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { readSingleNotificationThunk } from "../../../store/thunk/Notification.thunk";
import { useAppDispatch } from "../../../hooks/redux_toolkit";

interface CardProps {
  className?: string;
  content: any;
}

const Card: React.FC<CardProps> = ({ className, content }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const formattedDate: string = dayjs(content?.createdAt).locale("en").format("ddd, D MMM, YYYY");

  const formattedTime: string = dayjs(content?.createdAt).format("h:mm A");

  const leadIdGenerator = (lead: any) => {
    const leadId = lead.split(":");
    return leadId[1];
  };
  const leadContent = (
    <p>
      You&lsquo;ve successfully created new <span className="bg-[#cbf2df] px-2 mx-1 rounded-md">lead</span> with lead number: {leadIdGenerator(content?.message)}
    </p>
  );
  const commentContent = (
    <p>
      A new <span className="bg-[#cbf2df] px-2 mx-1 rounded-md">comment</span>was added to your lead with lead number: {leadIdGenerator(content?.message)}
    </p>
  );

  const notificationHandler = () => {
    dispatch(readSingleNotificationThunk(content._id))
      .unwrap()
      .then(() => {
        navigate("/allLeads");
      });
  };

  return (
    <div onClick={notificationHandler} className={`flex hover:scale-105 transition-all flex-col lg:flex-row justify-between border-2 shadow-md border-gray-200 ${!content?.seen && "border-l-[#7bdcac]"}   border-l-8 my-4 py-4 px-4 lg:px-6  cursor-pointer rounded-lg ${className}`}>
      <div className="flex lg:hidden text-xs text-customgray gap-2  mb-1">
        <Typography>{formattedDate}</Typography>|<Typography>{formattedTime}</Typography>
      </div>
      <div className="flex gap-4 items-center">
        <img src={Bell} alt="Bell icon" className="w-6" />
        <Typography variant="h2" className="text-xs md:text-base">
          {content?.subCategory == "lead" ? leadContent : commentContent}
        </Typography>
      </div>
      <div className="hidden lg:block">
        <Typography className="text-customgray">{formattedDate}</Typography>
        <Typography className="text-customgray">{formattedTime}</Typography>
      </div>
    </div>
  );
};

export default Card;
