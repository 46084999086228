import { createSlice } from "@reduxjs/toolkit";
import { getInvoiceSettledCount, getMailingCountThunk, getOmdCountThunk, getPartnerCount, getPartnerRegistrationCount, getPartnerResolvedCount, resendCasesCount } from "../thunk/NewDashboard.thunk";

interface ApiState {
  data: any;
  loading: boolean;
  error: string | null;
  getPartnerRegistrationCount: responseData;
  loadingInsurance: boolean;
  loadingTable: boolean;
  getPartnerResolvedCount: responseData;
  getPartnerCount: responseData;
  invoiceData: responseData;
  settledData: responseData;
  resendCasesCount: responseData;
  getOmdCountTable: responseData;
  getMailingCountTable: responseData;
}

interface responseData {
  data: any;
}

const initialState: ApiState = {
  data: null,
  loading: false,
  error: null,
  getPartnerRegistrationCount: { data: "" },
  loadingInsurance: false,
  loadingTable: false,
  getPartnerResolvedCount: { data: "" },
  getPartnerCount: { data: "" },
  invoiceData: { data: "" },
  settledData: { data: "" },
  resendCasesCount: { data: [] },
  getOmdCountTable: { data: [] },
  getMailingCountTable: { data: [] }
};

const NewDashboardSlice = createSlice({
  name: "NewDashboardSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPartnerRegistrationCount.pending, (state) => {
        state.loadingInsurance = true;
      })
      .addCase(getPartnerRegistrationCount.fulfilled, (state, action) => {
        state.loadingInsurance = false;
        state.getPartnerRegistrationCount = action.payload;
        state.error = null;
      })
      .addCase(getPartnerRegistrationCount.rejected, (state, action) => {
        state.loadingInsurance = false;
        state.error = action.error.message ?? "An error occurred";
      })

      .addCase(getPartnerResolvedCount.pending, (state) => {
        state.loadingInsurance = true;
      })
      .addCase(getPartnerResolvedCount.fulfilled, (state, action) => {
        state.loadingInsurance = false;
        state.getPartnerResolvedCount = action.payload;
        state.error = null;
      })
      .addCase(getPartnerResolvedCount.rejected, (state, action) => {
        state.loadingInsurance = false;
        state.error = action.error.message ?? "An error occurred";
      })

      .addCase(getPartnerCount.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPartnerCount.fulfilled, (state, action) => {
        state.loading = false;
        state.getPartnerCount = action.payload;
        state.error = null;
      })
      .addCase(getPartnerCount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "An error occurred";
      })

      .addCase(getInvoiceSettledCount.pending, (state) => {
        state.loadingInsurance = true;
      })
      .addCase(getInvoiceSettledCount.fulfilled, (state, action) => {
        if (action.meta.arg.type === "Invoice Data") state.invoiceData = action.payload;
        else if (action.meta.arg.type === "Settled Data") state.settledData = action.payload;
        state.loadingInsurance = false;
        state.error = null;
      })
      .addCase(getInvoiceSettledCount.rejected, (state, action) => {
        state.loadingInsurance = false;
        state.error = action.error.message ?? "An error occurred";
      })

      .addCase(resendCasesCount.pending, (state) => {
        state.loadingInsurance = true;
      })
      .addCase(resendCasesCount.fulfilled, (state, action) => {
        state.loading = false;
        state.resendCasesCount = action.payload;
        state.error = null;
      })
      .addCase(resendCasesCount.rejected, (state, action) => {
        state.loadingInsurance = false;
        state.error = action.error.message ?? "An error occurred";
      })

      .addCase(getOmdCountThunk.pending, (state) => {
        state.loadingTable = true;
      })
      .addCase(getOmdCountThunk.fulfilled, (state, action) => {
        state.loadingTable = false;
        state.getOmdCountTable = action.payload;
        state.error = null;
      })
      .addCase(getOmdCountThunk.rejected, (state, action) => {
        state.loadingTable = false;
        state.error = action.error.message ?? "An error occurred";
      })

      .addCase(getMailingCountThunk.pending, (state) => {
        state.loadingTable = true;
      })
      .addCase(getMailingCountThunk.fulfilled, (state, action) => {
        state.loadingTable = false;
        state.getMailingCountTable = action.payload;
        state.error = null;
      })
      .addCase(getMailingCountThunk.rejected, (state, action) => {
        state.loadingTable = false;
        state.error = action.error.message ?? "An error occurred";
      });
  }
});

export default NewDashboardSlice.reducer;
