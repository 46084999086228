import React from "react";
import { FaRegImage } from "react-icons/fa6";

const arr = [1, 2, 3, 4, 5, 6];

const ImageSkeleton = () => {
  return (
    <>
      {arr.map((obj: any, i: number) => (
        <div key={i} className=" border-2 shadow-lg">
          <div key={obj} role="status" className="flex items-center w-full justify-center h-80  bg-gray-300 animate-pulse ">
            <FaRegImage className="w-16 h-16 text-bg-gray-300" />
          </div>
          <div className="flex justify-center p-5">
            <div className="h-2 bg-gray-200 rounded-full w-32"></div>
          </div>
        </div>
      ))}
    </>
  );
};

export default ImageSkeleton;
