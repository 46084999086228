import React, { ChangeEvent } from "react";

export type InputProps = {
  label?: string;
  name?: string;
  value?: string;
  placeholder?: string;
  type?: string;
  className?: string;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  keyDownValidation?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  onclick?: (event: React.MouseEvent) => void;
  message?: string;
  validationType?: string;
  disabled?: boolean;
  rows?: number
};

const CustomTextArea: React.FC<InputProps> = ({ className, ...rest }) => {
  return <textarea {...rest} style={{ resize: "none" }} className={`appearance-none border focus:ring-2 focus:outline-none focus:ring-blue-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:shadow-outline ${className}`} />;
};

export default CustomTextArea;
