import React, { useState } from "react";
import { components, OptionProps } from "react-select";
import { useAppDispatch } from "../../../hooks/redux_toolkit";
import { dashboardCardStats } from "../../../store/thunk/Dashboard.thunk";
import dayjs from "dayjs";
import Typography from "../../../component/semantic/Typography";
import CustomInput from "../../../component/semantic/Input";
import { todayDateTimestamp } from "../../../helpers/constants/constants";

interface CustomOptionProps extends OptionProps<any> {
  test?: string;
  checkColor?: string;
}

export const Option: React.FC<CustomOptionProps> = (props) => {
  return (
    <components.Option {...props} key={Math.random()}>
      <div className="flex items-center gap-2">
        <input type="checkbox" checked={props.isSelected || false} onChange={() => null} className={`form-checkbox h-4 w-4  ${props.checkColor ? "accent-white" : "accent-black"} `} />
        <label className="pt-1">{props.label}</label>
      </div>
    </components.Option>
  );
};

export const DateOption: React.FC<CustomOptionProps> = (props) => {
  const selectedValue = props.getValue()[0];

  return (
    <components.Option {...props} key={Math.random()}>
      <label>{props.label}</label>
      {props.isSelected && selectedValue.label == "Custom Selection" && (
        <div className="flex bg-white flex-col">
          <div className="text-black">
            <p>Start Date</p>
            <input type="date" className="border-2 border-black w-48 text-black" />
          </div>
          <div>
            <p>End Date</p>
            <input type="date" className="border-2 border-black w-48 text-black" />
          </div>
        </div>
      )}
    </components.Option>
  );
};

export const MultiValueLabel = (props: any) => {
  const { children, ...rest } = props;
  return (
    <components.MultiValueLabel {...rest} key={""}>
      {children} {","}
    </components.MultiValueLabel>
  );
};

export const CustomMenu = (props: any) => {
  const listClassName = "py-2 px-2 mx-2 rounded-lg hover:bg-[#FDD2A3] hover:text-black";
  const [selectedDateStart, setSelectedDateStart] = useState();
  const [selectedDateEnd, setSelectedDateEnd] = useState();

  const dispatch = useAppDispatch();
  const handleClick = (data: string, customTo?: string) => {
    props.setShowRange(false);
    let to = todayDateTimestamp;
    let from = 0;
    props.setSelectedDate((prev: object) => ({ ...prev, label: "All Time" }));
    if (data == "7days") {
      from = todayDateTimestamp - 604800000;
      props.setSelectedDate({ from: "", to: "", label: "7 Days" });
    } else if (data == "30days") {
      from = todayDateTimestamp - 2592000000;
      props.setSelectedDate({ from: "", to: "", label: "30 Days" });
    } else if (data == "custom") {
      props.setSelectedDate((prev: object) => ({ ...prev, label: dayjs(selectedDateStart).format(`D/MM/YY`) + " ➤ " + dayjs(customTo).format(`D/MM/YY`) }));
      if (selectedDateStart && customTo) {
        from = new Date(selectedDateStart).getTime();
        to = new Date(customTo).getTime();
      }
    }
    if (props?.apiFunction) {
      props.apiFunction({ startDate: dayjs(from).format("YYYY-MM-DD"), endDate: dayjs(to).format("YYYY-MM-DD") });
    } else dispatch(dashboardCardStats({ from: dayjs(from).format("YYYY-MM-DD"), to: dayjs(to).format("YYYY-MM-DD") }));
    props.closeMenu();
  };

  return (
    <components.Menu {...props}>
      <ul className="my-1">
        <li className={listClassName} onClick={() => handleClick("")}>
          All Time
        </li>
        <li className={listClassName} onClick={() => handleClick("7days")}>
          Last 7 Days
        </li>
        <li className={listClassName} onClick={() => handleClick("30days")}>
          Last 30 Days
        </li>
        <li className={listClassName} onClick={() => props.setShowRange(!props.showRange)}>
          Custom Selection
        </li>
      </ul>
      <div className={`m-2 flex flex-col gap-2 ${!props.showRange && "hidden"}`}>
        <div>
          <Typography>Start Date</Typography>
          <CustomInput type="date" value={selectedDateStart} onChange={(e: any) => setSelectedDateStart(e.target.value)} className="mt-2" />
        </div>
        <div className={`${!selectedDateStart && "opacity-50 pointer-events-none"}`}>
          <Typography>End Date</Typography>
          <CustomInput
            type="date"
            min={selectedDateStart}
            value={selectedDateEnd}
            onChange={(e: any) => {
              setSelectedDateEnd(e.target.value);
              handleClick("custom", e.target.value);
            }}
            className="mt-2"
          />
        </div>
      </div>
    </components.Menu>
  );
};
