import React, { FC } from "react";
import { MdOutlineClear } from "react-icons/md";
import { InputProps } from "../../../component/semantic/Input";
import Typography from "../../../component/semantic/Typography";

interface InputWithIconProps extends InputProps {
  className?: string;
  onClickFunction: any;
}

const InputIconClear: FC<InputWithIconProps> = ({ onClickFunction, value, onChange, label, placeholder, onKeyDown }) => {
  return (
    <div className=" w-full flex gap-1 flex-col item-center">
      <Typography>{label}</Typography>
      <div className="relative ">
        <input onKeyDown={onKeyDown} type="text" value={value} onChange={onChange} className=" appearance-none border focus:ring-2 focus:outline-none focus:ring-blue-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:shadow-outline" placeholder={placeholder} />
        {value !== "" && (
          <button type="button" onClick={onClickFunction} className="absolute inset-y-0 end-0 top-0 flex items-center pe-3">
            <MdOutlineClear className="w-6 h-6" />
          </button>
        )}
      </div>
    </div>
  );
};

export default InputIconClear;
