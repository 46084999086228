import React, { FC, useState } from "react";
import Modal from "../../../../component/common/Modal";
import Typography from "../../../../component/semantic/Typography";
import CloseIcon from "../../../../assets/icons/gallery/close.svg";
import ButtonOutline from "../../../../component/semantic/Button";
import ActionTable from "../../../../component/table/ActionTable";
import { leadInvoicingHeading, leadInvoicingKeys } from "../../../../component/table/TableConstants";

interface ModalProps {
  leadInvoicing: boolean;
  setLeadInvoicing: any;
  selectedComplaint: any;
}

const LeadInvoicing: FC<ModalProps> = ({ setLeadInvoicing, leadInvoicing }) => {
  // eslint-disable-next-line
  const [selectedData, setSelectedData] = useState();
  return (
    <Modal showModal={leadInvoicing} onClose={() => setLeadInvoicing(false)} className="relative w-[35%] p-8">
      <img src={CloseIcon} className="absolute text-3xl text-gray-400 right-[-15px] top-[-20px] cursor-pointer" onClick={() => setLeadInvoicing(false)} />
      <Typography className="text-2xl font-semibold">Leads for Invoicing</Typography>
      <div className="mt-8 mb-8">
        <Typography className="font-extrabold">Select the Leads for Invoicing below:</Typography>
      </div>
      <div className="mt-2">
        <ActionTable actions={[]} selectedRow={setSelectedData} tableHeading={leadInvoicingHeading} tableKeys={leadInvoicingKeys} tableData={[]} />
      </div>
      <div className="flex justify-end items-center pt-8">
        <ButtonOutline className="whitespace-nowrap" title="Raise Single Lead Voice" />
        <ButtonOutline className="whitespace-nowrap bg-[#FAAA52]" title="Raise Multiple Lead Voice" />
      </div>
    </Modal>
  );
};

export default LeadInvoicing;
