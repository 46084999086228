// import dayjs from "dayjs";
import React, { useState } from "react";
import { ActionModalHandler } from "../../../component/table/TableFunctions";
import { actionModalTypes } from "../../../helpers/constants/interfaceTypes";
import { useAppSelector } from "../../../hooks/redux_toolkit";
import ReactSelect from "react-select";
import { Option, MultiValueLabel } from "../../dashboard/components/ReactSelectCustom";

interface TableProps {
  tableHeading: string[];
  tableKeys: string[];
  tableData: any[];
  actions: actionModalTypes;
  selectedRow: any;
  setTableData?: any;
  setSelectedTeam: any;
  selectedTeam: { table: string; index: number; value: any };
  tableName: string;
}

export const leadHeading = ["Actions", "Date", "ID", "Name", "Mobile", "Status", "Email", "Policy Type", "Complaint Type", "Insurance Company", "Claim Amount", "Assign To", "Expert To", "SRC | MED | CPN"];

const ActionTable2: React.FC<TableProps> = ({ tableHeading, tableKeys, tableData, actions, selectedRow, setSelectedTeam, selectedTeam, tableName }) => {
  const [showTooltip, setShowToolTip] = useState<any>([-1, -1]);
  const [showMenu, setShowMenu] = useState(-1);
  const internalteam = useAppSelector((state) => state.rootReducer.ExecutiveMapping.internalTeam.data);

  return (
    <div
      className="overflow-x-scroll w-full mr-6"
      style={{
        scrollbarWidth: "thin",
        scrollbarColor: "#D7DBEC transparent",
        msOverflowStyle: "none",
        overflowX: "scroll",
        overflowY: "hidden",
        borderRadius: "16px"
      }}
    >
      <table className="w-full">
        <thead>
          <tr className="border-b-2 whitespace-nowrap">
            {tableHeading.map((data: any, i: number) => {
              if (i === 0) return null;
              return (
                <th key={i} className="pr-1 md:pr-3 py-2 md:py-4 text-sm md:text-base">
                  <div className="flex gap-2 px-4 min-w-32">
                    <p className="font-extrabold">{data}</p>
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="text-gray-600 w-full text-center">
          {tableData && tableData.length > 0 ? (
            tableData.map((data, index) => (
              <tr key={index} className="border-b-2">
                {tableKeys?.map((key, i) => {
                  if (i === 0) return null;

                  if (key === "action") {
                    return <ActionModalHandler key={`${index}-${i}`} index={index} actions={actions} data={data} selectedRow={selectedRow} setShowToolTip={setShowToolTip} showTooltip={showTooltip} showMenu={showMenu} setShowMenu={setShowMenu} />;
                  }

                  if (key === "executives") {
                    return (
                      <td key={i} className="text-left pr-6">
                        <ReactSelect menuPlacement={tableData.length - 1 == index ? "top" : "bottom"} closeMenuOnSelect={false} components={{ Option, MultiValueLabel }} isSearchable={false} placeholder="Select Executive" className="border rounded px-2 py-1" options={internalteam} getOptionLabel={(option: any) => option.name} getOptionValue={(option: any) => option.value} isMulti value={selectedTeam.index === index && selectedTeam.table === tableName && selectedTeam.value} onChange={(option: any) => setSelectedTeam({ table: tableName, index, value: option })} />
                      </td>
                    );
                  }
                  if (key === "mappedInSaUsersDetails") {
                    const datavalue = data[key]?.map((ele: any) => ele?.internalTeamMemberName)?.toString() ?? "";
                    return (
                      <td key={i} className="text-left pr-6 py-4">
                        {datavalue}
                      </td>
                    );
                  }
                  let cellValue = data[key];

                  if (key.includes(".")) {
                    const nestedKeys = key.split(".");
                    let nestedData = data;
                    for (const nestedKey of nestedKeys) {
                      nestedData = nestedData[nestedKey];
                      if ([undefined, null].includes(nestedData)) {
                        cellValue = "-";
                        break;
                      } else {
                        cellValue = nestedData;
                      }
                    }
                  }

                  return (
                    <td key={i} className="text-left pr-6 py-4">
                      {cellValue || "-"}
                    </td>
                  );
                })}
              </tr>
            ))
          ) : (
            <td colSpan={tableHeading.length} className="text-center text-xl pt-2">
              No data found
            </td>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ActionTable2;
