import * as Yup from "yup";

export const hospitalCaseSchema = Yup.object({
  hospitalName: Yup.string().required("Please enter hospital name"),
  policyTypeId: Yup.object().required("Please select insurance type"),
  companyId: Yup.object().required("Please select company name"),
  complaintTypeId: Yup.object().required("Please select complaint type"),
  isThisGroupCase: Yup.object().required("Please select policy type"),
  assignToExpert: Yup.object().required("Please assign to expert")
});
