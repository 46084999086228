import React, { FC } from "react";
import FormInput from "../../../component/semantic/FormInput";

interface ReportProps {
  formProps: any;
}

const SettledCaseReport: FC<ReportProps> = ({ formProps }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8 ">
      <FormInput name="startDate" max="true" type="date" label="Start Date" formProps={formProps} />
      <FormInput name="endDate" max="true" min={formProps.values.startDate} type="date" label="End Date" formProps={formProps} />
    </div>
  );
};

export default SettledCaseReport;
