import React, { useState } from "react";
interface ButtonOutlineIconProps {
  title: string;
  icon?: React.ReactNode;
  color?: string;
  name?: string;
  className?: string;
  onClick?: any;
  type?: "button" | "submit" | "reset";
  isCopy?: boolean;
}

const ButtonOutline: React.FC<ButtonOutlineIconProps> = ({ title, icon, color, isCopy, name, className, onClick, type }) => {
  const [buttonTitle, setButtonTitle] = useState(title);

  const handleClick = () => {
    if (isCopy) {
      setButtonTitle("Copied");
      setTimeout(() => {
        setButtonTitle(title);
      }, 2000);
    }
  };

  return (
    <div className="flex items-center w-auto" onClick={handleClick}>
      <button onClick={onClick} name={name} type={type || "button"} className={`text-${color} flex justify-center flex-row items-center gap-1 border border-${color} border-2  focus:ring-4 focus:outline-none focus:ring-blue-300 font-semibold rounded-md text-sm px-2  lg:px-5 py-2.5 text-center me-2 min-w-32 ${className}`}>
        {icon}
        {isCopy ? buttonTitle : title}
      </button>
    </div>
  );
};

export default ButtonOutline;
