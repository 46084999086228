import React, { useEffect, useState } from "react";
import "./Carousel.css";
import { GoDotFill, GoDot } from "react-icons/go";

const Carousel = ({ items, autoSlide = true, slideInterval = 5000, itemClassName = "" }: any) => {
  const [currentIndex, setCurrentIndex] = useState(3);

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
  };

  useEffect(() => {
    if (autoSlide) {
      const interval = setInterval(goToNext, slideInterval);
      return () => clearInterval(interval);
    }
  }, [currentIndex, autoSlide, slideInterval]);

  // const goToSlide = (index) => {
  //     setCurrentIndex(index);
  // };

  return (
    <div className="carousel mx-[5%] h-auto">
      {/* <button onClick={goToPrevious} className="carousel__button carousel__button--left">‹</button> */}
      <div className="carousel__content" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
        {items.map((item: any, index: number) => (
          <div key={index} className={`carousel__item items-center ${index === currentIndex ? "carousel__item--active" : ""} ${itemClassName}`}>
            {item}
          </div>
        ))}
      </div>
      <div className="absolute bottom-0 flex">
        {items?.map((res: any, index: number) => {
          return currentIndex === index ? <GoDotFill className="text-lg" /> : <GoDot className="text-lg" />;
        })}
      </div>
      {/* <button onClick={goToNext} className="carousel__button carousel__button--right">›</button> */}
    </div>
  );
};

export default Carousel;
