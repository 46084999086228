import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../helpers/axios";
import { baseUrl } from "../../helpers/constants/defaultUrls";

interface GetAllComplaintArgs {
  id: string;
  pageSize: number;
  currentPage: number;
  status?: string;
}

interface searchComplaintCustomer {
  search: string;
  page: number;
  size: number;
  userAccount: string;
  userType: string;
}
interface searchComplaintPartner {
  search: string;
  page: number;
  size: number;
  hierarchy: number;
}

interface addCommentArgs {
  id: string;
  body: {
    comment: string;
    notificationsTo: any;
    private: boolean;
    type: string;
  };
}

export const getAllComplaintThunk = createAsyncThunk("complaint/getAllComplaintThunk", async (payload: GetAllComplaintArgs) => {
  try {
    const response = await axios.get(baseUrl + `v2/lead/getComplaintsv2?page=${payload.currentPage - 1}&size=${payload.pageSize}&userId=${payload.id}${payload.status ? "&status=" + payload.status : ""}`);
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
});

export const searchComplaintCustomerThunk = createAsyncThunk("complaint/searchComplaintCustomerThunk", async (payload: searchComplaintCustomer) => {
  const response = await axios.get(baseUrl + `v2/lead/searchComplaints?search=${payload.search}&page=${payload.page - 1}&size=${payload.size}&userAccount=${payload.userAccount}&userType=${payload.userType}`);
  return response.data;
});

export const searchComplaintPartnerThunk = createAsyncThunk("complaint/searchComplaintPartnerThunk", async (payload: searchComplaintPartner) => {
  const response = await axios.get(baseUrl + `v1/lead/searchH?search=${payload.search}&page=${payload.page - 1}&size=${payload.size}&hierarchy=${payload.hierarchy}`);
  return response.data;
});

export const commentHistoryComplaint = createAsyncThunk("complaint/commentHistoryComplaint", async (complaintId: string) => {
  const response = await axios.get(baseUrl + `v1/complaint/comment/get?complaintId=` + complaintId);
  return response.data;
});

export const addCommentComplaint = createAsyncThunk("complaint/addCommentComplaint", async (payload: addCommentArgs) => {
  const response = await axios.post(baseUrl + `v1/complaint/comment/addV2/${payload.id}`, payload.body);
  return response.data;
});

export const getLeadDataThunk = createAsyncThunk("complaint/getLeadDataThunk", async (id: string) => {
  const response = await axios.get(baseUrl + `v1/lead/single/` + id);
  return response.data;
});

export const sendContractThunk = createAsyncThunk("complaint/sendContractThunk", async (id: string) => {
  const response = await axios.get(baseUrl + `v1/lead/email/contract?leadId=` + id);
  return response.data;
});

export const searchComplaintPartnerAdmin = createAsyncThunk("complaint/searchComplaintPartner", async (payload: any) => {
  const response = await axios.get(baseUrl + `v2/lead/searchComplaintsAAP?search=${payload.search}&size=${payload.pageSize}&page=${payload.currentPage - 1}`);
  return response.data;
});
