import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../helpers/axios";
import { baseUrl } from "../../helpers/constants/defaultUrls";


export const uploadBulkSignupFile: any = createAsyncThunk("bulkAction/uploadSignUpData", async (payload: any) => {
    const response = await axios.post(baseUrl + "v1/auth/bulk/signup", payload);
    return response.data;
});

export const uploadBulkBlockUsersFile: any = createAsyncThunk("bulkAction/uploadBlockUserFile", async (payload: any) => {
    const response = await axios.post(baseUrl + "v1/user/bulk/block", payload);
    return response.data;
});
