import { createSlice } from "@reduxjs/toolkit";
import { agentDocThunk, cancelledChequeThunk, natureOfBusiness, profilePicThunk, userUpdate } from "../thunk/Profile.thunk";

interface responseData {
  data: any;
}

interface ApiState {
  data: any;
  loading: "idle" | "pending" | "fulfilled" | "rejected";
  error: string | null;
  profilePic: object;
  updatedUser: object;
  cancelledCheque: object;
  agentDoc: object;
  invalidInput: boolean;
  userLoading: boolean;
  navigationUrl: string;
  natureOfBuisness: responseData;
  showToast: {
    show: boolean;
    message?: string;
    status?: string;
  };
}

const initialState: ApiState = {
  data: null,
  loading: "idle",
  error: null,
  profilePic: {},
  updatedUser: {},
  agentDoc: {},
  cancelledCheque: {},
  invalidInput: false,
  navigationUrl: "/",
  userLoading: false,
  natureOfBuisness: { data: [] },
  showToast: {
    show: false
  }
};

const loginSlice = createSlice({
  name: "loginSlice",
  initialState,
  reducers: {
    emptyNavigationUrl: (state) => {
      state.navigationUrl = "";
    },
    invalidInput: (state) => {
      state.invalidInput = true;
    },
    validInput: (state) => {
      state.invalidInput = false;
    },
    hideToastProfile: (state) => {
      state.showToast = { show: false };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(profilePicThunk.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(profilePicThunk.fulfilled, (state, action) => {
        if (action.payload.success == true) {
          state.showToast = {
            show: true,
            message: action.payload.message,
            status: "success"
          };
        }
        state.loading = "fulfilled";
        state.profilePic = action.payload;
        state.error = null;
      })
      .addCase(profilePicThunk.rejected, (state, action) => {
        state.loading = "rejected";
        state.error = action.error.message ?? "An error occurred";
      });

    builder
      .addCase(userUpdate.pending, (state) => {
        state.userLoading = true;
      })
      .addCase(userUpdate.fulfilled, (state, action) => {
        state.userLoading = false;
        state.updatedUser = action.payload;
        state.error = null;
        if (action.payload.success == false) {
          state.showToast = {
            show: true,
            message: action.payload.message,
            status: "error"
          };
        }
      })
      .addCase(userUpdate.rejected, (state, action: any) => {
        state.userLoading = false;
        state.error = action.error.message ?? "An error occurred";
      });

    builder
      .addCase(cancelledChequeThunk.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(cancelledChequeThunk.fulfilled, (state, action) => {
        if (action.payload.success == true) {
          state.showToast = {
            show: true,
            message: action.payload.message,
            status: "success"
          };
        }
        state.loading = "fulfilled";
        state.updatedUser = action.payload;
        state.error = null;
      })
      .addCase(cancelledChequeThunk.rejected, (state, action) => {
        state.loading = "rejected";
        state.error = action.error.message ?? "An error occurred";
      });

    builder
      .addCase(agentDocThunk.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(agentDocThunk.fulfilled, (state, action) => {
        if (action.payload.success == true) {
          state.showToast = {
            show: true,
            message: action.payload.message,
            status: "success"
          };
        }
        state.loading = "fulfilled";
        state.agentDoc = action.payload;
        state.error = null;
      })
      .addCase(agentDocThunk.rejected, (state, action) => {
        state.loading = "rejected";
        state.error = action.error.message ?? "An error occurred";
      });

    builder
      .addCase(natureOfBusiness.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(natureOfBusiness.fulfilled, (state, action) => {
        state.loading = "fulfilled";
        state.natureOfBuisness = action.payload;
        state.error = null;
      })
      .addCase(natureOfBusiness.rejected, (state, action) => {
        state.loading = "rejected";
        state.error = action.error.message ?? "An error occurred";
      });
  }
});

export const { emptyNavigationUrl, hideToastProfile, invalidInput, validInput } = loginSlice.actions;

export default loginSlice.reducer;
