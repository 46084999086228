import { FC, useState } from "react";
import Modal from "../../../../component/common/Modal";
import Typography from "../../../../component/semantic/Typography";
import CloseIcon from "../../../../assets/icons/gallery/close.svg";
import LifeIcon from "../../../../assets/icons/newDashboard/Life.svg";
import HealthIcon from "../../../../assets/icons/newDashboard/Health.svg";
import GeneralIcon from "../../../../assets/icons/newDashboard/General.svg";

interface ModalProps {
  showSelectPolicyModal: boolean;
  setSelectPolicyModal: any;
  selectedUserData: any;
  updatePolicyTypes: any;
}

const SelectPolicy: FC<ModalProps> = ({ showSelectPolicyModal, setSelectPolicyModal, selectedUserData, updatePolicyTypes }) => {
  const [policyTypes, setPolicyTypes] = useState<any>([
    { name: "General Insurance", icon: GeneralIcon, id: "2de58f71-ab0a-446f-b5f6-b6e0fa6f46f2", checked: selectedUserData?.policyTypes.includes("2de58f71-ab0a-446f-b5f6-b6e0fa6f46f2") },
    { name: "Life Insurance", icon: LifeIcon, id: "864a375b-5fa7-49c7-9f39-2b1fda4674b5", checked: selectedUserData?.policyTypes.includes("864a375b-5fa7-49c7-9f39-2b1fda4674b5") },
    { name: "Health Insurance", icon: HealthIcon, id: "3030b68f-82e9-4dfb-b2b8-82b743d0692a", checked: selectedUserData?.policyTypes.includes("3030b68f-82e9-4dfb-b2b8-82b743d0692a") }
  ]);

  return (
    <Modal showModal={showSelectPolicyModal} onClose={() => setSelectPolicyModal(false)} className="flex relative flex-col justify-center z-50 w-[45%] gap-1">
      <img src={CloseIcon} className="absolute text-3xl text-gray-400 -right-3 -top-3 cursor-pointer" onClick={() => setSelectPolicyModal(false)} />
      <div className="m-4">
        <Typography variant="h1">Select Policy Type</Typography>
        <Typography variant="h2" className="font-medium text-gray-500 mb-3">
          Update policy type of user from here.
        </Typography>
        <Typography variant="h2" className="font-medium">
          You're updating policy type of {selectedUserData?.name} ({selectedUserData?.userName})
        </Typography>
        <input
          type="checkbox"
          className="mt-5 mr-2"
          checked={policyTypes.every((data: any) => data.checked)}
          onChange={(e) => {
            const selectAll = [...policyTypes];
            const temp = selectAll.map((data: any) => ({ ...data, checked: e.target.checked }));
            setPolicyTypes([...temp]);
          }}
        />{" "}
        Select All
        <div className="flex flex-col md:flex-row mb-5">
          {policyTypes.map((data: any, i: any) => {
            return (
              <div key={i} className="flex mr-3">
                <input
                  type="checkbox"
                  checked={data.checked}
                  className="p-5 mr-3"
                  onChange={(e: any) => {
                    const temp = [...policyTypes];
                    temp[i]["checked"] = e.target.checked;
                    setPolicyTypes([...temp]);
                  }}
                />
                <img src={data.icon} alt="" width={15} /> <span className="ml-1">{data.name}</span>
              </div>
            );
          })}
        </div>
        <div className="flex justify-end w-[100%]">
          {/* <ButtonOutline
            title="SUBMIT"
            className="bg-polyfixTheme text-black w-[100%] mb-2"
            onClick={() => {
              const selectedPolicyTypes = policyTypes.filter((data: any) => data.checked);
              updatePolicyTypes(selectedPolicyTypes, selectedUserData);
              setSelectPolicyModal(false);
            }}
          /> */}
          <button
            className="w-[100%] md:w-32 bg-polyfixTheme text-black border border-2 focus:ring-4 focus:ring-blue-300 font-semibold rounded-md text-sm px-2 lg:px-5 py-2.5 text-center me-2"
            onClick={() => {
              const selectedPolicyTypes = policyTypes.filter((data: any) => data.checked);
              updatePolicyTypes(selectedPolicyTypes, selectedUserData);
              setSelectPolicyModal(false);
            }}
          >
            SUBMIT
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default SelectPolicy;
