import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../helpers/axios";
import { baseUrl } from "../../helpers/constants/defaultUrls";

export const getAllInsuranceType = createAsyncThunk("hospitalCase/getAllInsuranceType", async () => {
  try {
    const response = await axios.get<{ data: any }>(baseUrl + `v1/policy-type/getall`);
    return response.data;
  } catch (error: any) {
    return error;
  }
});

export const getAllComplaintsType = createAsyncThunk("hospitalCase/getAllComplaintsType", async (id: string) => {
  try {
    const response = await axios.get<{ data: any }>(baseUrl + `v1/com-type/getall/${id}`);
    return response.data;
  } catch (error: any) {
    return error;
  }
});

export const getAllCompanyName = createAsyncThunk("hospitalCase/getAllCompanyName", async (id: string) => {
  try {
    const response = await axios.get<{ data: any }>(baseUrl + `v1/ins-comp/getall/${id}`);
    return response.data;
  } catch (error: any) {
    return error;
  }
});

export const getAllAgent = createAsyncThunk("hospitalCase/getAllAgent", async (policy: string) => {
  try {
    const response = await axios.get<{ data: any }>(baseUrl + `v1/user/agents/hi?policyName=${policy}`);
    return response.data;
  } catch (error: any) {
    return error;
  }
});

export const submitHospitalCaseThunk = createAsyncThunk("hospitalCase/submitHospitalCaseThunk", async (payload: FormData) => {
  try {
    const response = await axios.post<{ data: any }>(baseUrl + `v1/hospital/createBulkHospitalLeadsV2`, payload);
    return response.data;
  } catch (error: any) {
    return error;
  }
});
