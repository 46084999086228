import React, { ReactNode } from "react";

interface TooltipProps {
  text: string;
  children: ReactNode;
  showTooltip: boolean;
}

const Tooltip: React.FC<TooltipProps> = ({ text, children, showTooltip }) => {
  return (
    <div className="relative inline-block">
      {children}
      <div className={`absolute -left-28 md:left-6 -top-14 md:-top-5 w-[15rem] ${showTooltip ? "opacity-100" : "opacity-0"} z-10  bg-gray-800 text-white text-xs rounded py-2 px-2 leading-tight shadow-2xl`}>
        <span className="">{text}</span>
      </div>
      <div className={`absolute left-0 md:left-5 -top-6 md:-top-1 ${showTooltip ? "opacity-100" : "opacity-0"}   w-4 h-4 bg-gray-800  rotate-45`}>&nbsp;</div>
    </div>
  );
};

export default Tooltip;
