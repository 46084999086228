import React, { FC } from "react";
import Modal from "../../../component/common/Modal";
import Typography from "../../../component/semantic/Typography";
import ButtonOutline from "../../../component/semantic/Button";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux_toolkit";
import { partnerAssociateMapping } from "../../../store/thunk/ExecutiveMapping.thunk";
import { toast } from "react-toastify";
interface ModalProps {
  confirmationModal: boolean;
  setConfirmationModal: any;
  successButtonHandler: any;
  PayloadData: any;
}
const ConfirmationModal: FC<ModalProps> = ({ setConfirmationModal, confirmationModal, PayloadData }) => {
  const loading = useAppSelector((state) => state.rootReducer.ExecutiveMapping.confirmationLoading);
  const dispatch = useAppDispatch();

  const approveHandler = () => {
    if (PayloadData?.partnerData && PayloadData?.teams?.value) {
      dispatch(partnerAssociateMapping({ internalAssociateId: PayloadData?.teams?.value?.map((ele: any) => ele._id), partnerId: PayloadData?.partnerData?._id }))
        .unwrap()
        .then((data: any) => {
          if (data.success) {
            toast.success(data.message);
            setConfirmationModal(false);
          }
        });
    }
  };

  return (
    <Modal showModal={confirmationModal} onClose={() => setConfirmationModal(false)} className="relative w-[35%] p-8">
      <Typography>{"Are you sure you want to approve this user?"}</Typography>
      <div className="flex justify-between items-center w-full mt-6">
        <ButtonOutline onClick={approveHandler} className=" bg-[#1BA785] outline-[none]" type="button" title={loading ? "Approving" : "YES"} />
        <ButtonOutline
          onClick={() => {
            setConfirmationModal(false);
          }}
          className=" bg-[#FF3C3CED] outline-[none]"
          type="button"
          title="NO"
        />
      </div>
    </Modal>
  );
};
export default ConfirmationModal;
