import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../helpers/axios";
import { baseUrl } from "../../helpers/constants/defaultUrls";

interface payloadArgs {
  currentPage: number;
  pageSize: number;
}

export const getAllCompanyThunk = createAsyncThunk("company/getAllCompanyThunk", async (payload: payloadArgs) => {
  const response = await axios.get<{ data: any }>(baseUrl + `v1/ins-comp/getAllCompanyv2?size=${payload.pageSize}&page=${payload.currentPage - 1}`);
  return response.data;
});

export const updateCompanyStatus = createAsyncThunk("company/updateCompanyStatus", async (id: string) => {
  const response = await axios.get<{ data: any }>(baseUrl + `v1/ins-comp/updateStatus/` + id);
  return response.data;
});

export const deleteCompanyThunk = createAsyncThunk("company/deleteCompanyThunk", async (id: string) => {
  const response = await axios.delete<{ data: any }>(baseUrl + `v1/ins-comp/delete/` + id);
  return response.data;
});

export const companyStatusThunk = createAsyncThunk("company/companyStatusThunk", async (payload: { id: string; body: { id: string; isActive: boolean } }) => {
  const response = await axios.put<{ data: any }>(baseUrl + `v1/ins-comp/updateStatus/` + payload.id, payload.body);
  return response.data;
});
