import React, { FC } from "react";
import Modal from "../../../../component/common/Modal";
import CloseIcon from "../../../../assets/icons/gallery/close.svg";

interface UserAccount {
  viewUserAccount: boolean;
  setViewUserAccount: any;
  selectedComplaint: any;
}

const UserAccount: FC<UserAccount> = ({ viewUserAccount, setViewUserAccount }) => {
  return (
    <Modal showModal={viewUserAccount} onClose={() => setViewUserAccount(false)} className="relative p-8 w-[40%] z-[99] ">
      <img src={CloseIcon} className="absolute text-3xl text-gray-400 right-[-15px] top-[-20px] cursor-pointer z-[999]" onClick={() => setViewUserAccount(false)} />
      <div>Hello</div>
    </Modal>
  );
};

export default UserAccount;
