import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../helpers/axios";
import { baseUrl } from "../../helpers/constants/defaultUrls";

interface allUserArgs {
  page: number;
  size: number;
  search: string;
  startDate: string;
  endDate: string;
}

export const getAllUsersThunk = createAsyncThunk("allUsers/getAllUsersThunk", async (payload: allUserArgs) => {
  try {
    const response = await axios.get<{ data: any }>(baseUrl + `v1/user/userList?page=${payload.page - 1}&size=${payload.size}&search=${payload.search}&startDate=${payload.startDate}&endDate=${payload.endDate}`);
    return response.data;
  } catch (error: any) {
    return error;
  }
});

export const updateUserThunk = createAsyncThunk("allUsers/updateAllUsersThunk", async (payload: any) => {
  try {
    const response = await axios.put<{ data: any }>(baseUrl + `v1/user/updateUser`, payload);
    return response.data;
  } catch (error: any) {
    return error;
  }
});
