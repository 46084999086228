import { combineReducers } from "redux";
import dashboard from "./slices/Dashboard.slice";
import referral from "./slices/Referral.slice";
import common from "./slices/Common.slice";
import login from "./slices/Login.slice";
import profile from "./slices/Profile.slice";
import notification from "./slices/Notification.slice";
import report from "./slices/Report.slice";
import chat from "./slices/Chat.slice";
import gallery from "./slices/Gallery.slice";
import newCase from "./slices/NewCase.slice";
import allLead from "./slices/AllLeadSlice";
import userManagement from "./slices/UserManagement.slice";
import resolveCase from "./slices/resolveCase.slice";
import allComplaint from "./slices/AllComplaintSlice";
import newDashboard from "./slices/NewDashboard.slice";
import AllUsers from "./slices/AllUsers.slice";
import InitUsers from "./slices/InitUserSlice";
import HospitalCase from "./slices/Hospital.slice";
import PendingContract from "./slices/PendingContractSlice";
import PayoutMarking from "./slices/PayoutMarking.slice";
import LeadCost from "./slices/leadCost.slice";
import Company from "./slices/Company.Slice";
import ExecutiveMapping from "./slices/ExecutiveMappingSlice";
import BulkAction from "./slices/BulkAction.slice";

const rootReducer = combineReducers({
  login,
  common,
  dashboard,
  referral,
  profile,
  notification,
  report,
  chat,
  gallery,
  newCase,
  allLead,
  userManagement,
  resolveCase,
  allComplaint,
  newDashboard,
  AllUsers,
  InitUsers,
  HospitalCase,
  PendingContract,
  PayoutMarking,
  LeadCost,
  Company,
  ExecutiveMapping,
  BulkAction
});

export default rootReducer;
