import React, { useEffect } from "react";
import { MdClose } from "react-icons/md";
import { useAppDispatch, useAppSelector } from "../../hooks/redux_toolkit";
import { hideToast } from "../../store/slices/Common.slice";
import Typography from "../semantic/Typography";
import { hideToastReferral } from "../../store/slices/Referral.slice";
import { hideToastProfile } from "../../store/slices/Profile.slice";

interface ToastProps {
  className?: string;
}

const Toast: React.FC<ToastProps> = ({ className }) => {
  const showToastSlice: any = useAppSelector((state) => state.rootReducer.common.showToast);
  const showToastReferral: any = useAppSelector((state) => state.rootReducer.referral.showToast);
  const showToastProfile: any = useAppSelector((state) => state.rootReducer.profile.showToast);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (showToastSlice?.show || showToastReferral.show || showToastProfile.show) {
      const timer = setTimeout(() => {
        dispatch(hideToast());
        dispatch(hideToastReferral());
        dispatch(hideToastProfile());
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [showToastSlice, showToastReferral, showToastProfile, dispatch]);

  const handleDismiss = () => {
    dispatch(hideToast());
    dispatch(hideToastReferral());
    dispatch(hideToastProfile());
  };

  return (
    <>
      {(showToastSlice.show || showToastReferral.show || showToastProfile.show) && (
        <div className={`fixed z-50 items-center p-2 w-[20rem] lg:w-[30rem] border-2 border-[#95E3BE] justify-between mb-4 text-[#143F2A] rounded-lg bg-[#D5F4E5] ${(showToastReferral.status === "error" || showToastProfile.status === "error") && "bg-red-400 text-white border-red-500"} transition-all duration-500 flex transform translate-y-0 opacity-100 ${className}`}>
          <Typography variant="reg" className="ms-3 text-sm lg:text-lg font-bold">
            {showToastSlice.message || showToastReferral.message || showToastProfile.message}
          </Typography>
          <MdClose onClick={handleDismiss} className="text-2xl cursor-pointer" />
        </div>
      )}
    </>
  );
};

export default Toast;
