import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../helpers/axios";
import { baseUrl } from "../../helpers/constants/defaultUrls";

interface MappingArgs {
  internalAssociateId: string[];
  partnerId: string;
}

export const getInternalTeamThunk = createAsyncThunk("ExecutiveMapping/ExecutiveMappingThunk", async () => {
  const response = await axios.get<{ data: any }>(baseUrl + `v1/internal_team/internal_team_list`);
  return response.data;
});

export const getAdvisorsThunk = createAsyncThunk("ExecutiveMapping/getAdvisorsThunk", async (payload: any) => {
  const response = await axios.get<{ data: any }>(baseUrl + `v1/internal_team/getAdvisors?page=${payload.page - 1}&size=5&search=${payload.search}`, payload);
  return response.data;
});

export const getAssociateThunk = createAsyncThunk("ExecutiveMapping/getAssociateThunk", async (payload: any) => {
  const response = await axios.get<{ data: any }>(baseUrl + `v1/internal_team/getAsscociate?page=${payload.page - 1}&size=5&search=${payload.search}`, payload);
  return response.data;
});

export const getPartnerThunk = createAsyncThunk("ExecutiveMapping/getPartnerThunk", async (payload: any) => {
  const response = await axios.get<{ data: any }>(baseUrl + `v1/internal_team/getPartners?page=${payload.page - 1}&size=5&search=${payload.search}`, payload);
  return response.data;
});

export const partnerAssociateMapping = createAsyncThunk("ExecutiveMapping/partnerAssociateMapping", async (payload: MappingArgs) => {
  const response = await axios.post<{ data: any }>(baseUrl + `v1/internal_team/partner_associate_mapping`, payload);
  return response.data;
});

export const remove_partner_mapping = createAsyncThunk("ExecutiveMapping/remove_partner_mapping", async (payload: { partnerId: string }) => {
  const response = await axios.post<{ data: any }>(baseUrl + `v1/internal_team/remove_partner_mapping`, payload);
  return response.data;
});
