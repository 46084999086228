import React, { useEffect, useState } from "react";
import Typography from "../../component/semantic/Typography";
import ButtonOutline from "../../component/semantic/Button";
import FormInput from "../../component/semantic/FormInput";
import ReactSelect from "react-select";
import { newCaseSchema } from "../../helpers/schemas/Simple.Schema";
import { useFormik } from "formik";
import { useAppDispatch, useAppSelector } from "../../hooks/redux_toolkit";
import { complaintListThunk, policyListThunk } from "../../store/thunk/Common.thunk";
import { polyfixSelect } from "../../helpers/constants/styles";
import { generalCaseType } from "../../helpers/constants/constants";
import FormTextArea from "../../component/semantic/FormTextArea";
import Logo from "../../assets/images/partnerLogo.svg";
import cover from "../../assets/images/coverSignup.svg";
import { useNavigate, useParams } from "react-router-dom";
import { createLeadWithoutTokenThunk } from "../../store/thunk/AllLead.thunk";
import { toast } from "react-toastify";
import UploadDocs from "../newCase/components/UploadDocs";

interface caseTypes {
  name: string;
  email: string;
  phone: any;
  problemStatement: string;
  policyTypeId: any;
  generalPolicyType: string;
  complaintTypeId: any;
  policyTypeName: string;
  complaintTypeName: string;
}

const initialValues: caseTypes = {
  name: "",
  email: "",
  phone: "",
  problemStatement: "",
  policyTypeId: "",
  generalPolicyType: "",
  complaintTypeId: "",
  policyTypeName: "",
  complaintTypeName: ""
};

const CreateLead = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const policyList = useAppSelector((state) => state.rootReducer.common.policyList.data);
  const complaintList = useAppSelector((state) => state.rootReducer.common.complaintList.data);
  const isLoading = useAppSelector((state) => state.rootReducer.allLead.submitLoaderWithoutToken);
  const { userId } = useParams();

  const [createdLeadInfo, setCreatedLeadInfo] = useState<any>();
  const [showDocModal, setShowDocModal] = useState<any>();

  const { values, handleBlur, handleChange, handleSubmit, resetForm, touched, errors, setFieldValue } = useFormik({
    initialValues,
    validationSchema: newCaseSchema,
    onSubmit: async (values: any) => {
      const payload = {
        userId: userId,
        body: { name: values.name, email: values.email.trim(), phone: values.phone.toString(), problemStatement: values.problemStatement, policyTypeId: values.policyTypeId?._id, complaintTypeId: values.complaintTypeId?._id, policyTypeName: values.policyTypeId?.name, generalPolicyType: values.policyTypeId?.name === "General Insurance" ? values.generalPolicyType?.value : "", complaintTypeName: values.complaintTypeId?.name, creationSrc: "partner-web" }
      };
      dispatch(createLeadWithoutTokenThunk(payload))
        .unwrap()
        .then((data: any) => {
          if (data?.status == 201) {
            if (data?.data?.message) toast.success(data?.data?.message);
            setCreatedLeadInfo({ policyInfo: values.policyTypeId, complaintInfo: values.complaintTypeId, leadId: data?.data?.data._id });
            if (data?.data?.data?.policyTypeId !== "7e5ecc07-70e3-40a3-95a0-f0bee04814d4") {
              //Not Motor
              setShowDocModal(true);
            }
            resetForm();
          }
        });
    }
  });
  const formProps = { values, handleBlur, handleChange, touched, errors, setFieldValue };

  const complaintTypeValidation = () => {
    if (values.policyTypeId.name == "Motor Insurance") return false;
    if (formProps.values.complaintTypeId.length == 0 && formProps.touched["complaintTypeId"]) return true;
    return false;
  };

  useEffect(() => {
    if (values.policyTypeId._id) {
      dispatch(complaintListThunk(values.policyTypeId?._id));
      setFieldValue("complaintTypeId", "");
    }
  }, [values.policyTypeId]);

  useEffect(() => {
    dispatch(policyListThunk());
  }, []);
  return (
    <div>
      <nav className="bg-white flex justify-center py-4 shadow-2xl w-full fixed z-10">
        <img src={Logo} onClick={() => navigate("/login")} className="cursor-pointer" />
      </nav>
      <img src={cover} className="w-full" />
      <div className="flex justify-center ">
        <div className="bg-white w-[90%] md:w-[60%] absolute top-32 rounded-2xl  shadow-2xl border-t-[1rem] border-polyfixTheme">
          <div className="shadow-xl pb-4 p-5 px-8">
            <Typography variant="h3">Submit New Lead</Typography>
            <Typography>Provide us the details of your new Insurance case you'll like to submit us.</Typography>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 mt-4 gap-x-16 gap-y-4 px-10 pt-5 pb-10">
            <FormInput name="name" placeholder="Enter Name" label="Policyholder's/Nominee's Name *" formProps={formProps} />
            <span>
              <Typography className="font-normal mb-1">Insurance Type *</Typography>
              <ReactSelect
                options={policyList}
                name="policyTypeId"
                placeholder="Select Insurance Type"
                getOptionLabel={(option: any) => option?.name}
                getOptionValue={(option: any) => option}
                value={values.policyTypeId}
                onChange={(option) => {
                  setFieldValue("policyTypeId", option);
                }}
                styles={polyfixSelect}
                isSearchable={false}
              />
              {formProps.values.policyTypeId.length == 0 && formProps.touched["policyTypeId"] && <span className="mt-1 text-red-600 font-semibold text-sm">Please Select Policy</span>}
            </span>
            {values.policyTypeId?.name === "General Insurance" && (
              <span>
                <Typography className="font-normal mb-1">Policy Type *</Typography>
                <ReactSelect
                  options={generalCaseType}
                  name="generalPolicyType"
                  placeholder="Select Policy Type"
                  value={values.generalPolicyType}
                  onChange={(option: any) => {
                    setFieldValue("generalPolicyType", option);
                  }}
                  styles={polyfixSelect}
                />
                {values.policyTypeId?.name === "General Insurance" && !values.generalPolicyType && formProps.touched["generalPolicyType"] && <span className="mt-1 text-red-600 font-semibold text-sm">Please Select Policy</span>}
              </span>
            )}
            <FormInput name="email" onKeyDown={(e: any) => (e.code !== "Space" ? e.target.value : e.preventDefault())} type="email" label="Policyholder's Email *" placeholder="Enter Email id" formProps={formProps} />
            <span>
              <Typography className="font-normal mb-1">Complaint Type *</Typography>
              <ReactSelect
                options={complaintList || []}
                name="complaintTypeId"
                placeholder="Select Complaint Type"
                getOptionLabel={(option: any) => option?.name}
                getOptionValue={(option: any) => option}
                value={values.complaintTypeId}
                onChange={(option) => {
                  setFieldValue("complaintTypeId", option);
                }}
                styles={polyfixSelect}
                isSearchable={false}
              />
              {complaintTypeValidation() && <span className="mt-1 text-red-600 font-semibold text-sm">Please Select Complaint</span>}
            </span>
            <FormInput name="phone" type="number" limit={10} label="Policyholder's Mobile *" formProps={formProps} placeholder="Enter Mobile Number" />
            <span className="col-span-1 md:col-span-2 h-full row-span-4  md:row-span-2">
              <FormTextArea label="Problem Statement *" className="h-full" placeholder="Tell us more about your problem, this will help us to analyze your case" name="problemStatement" formProps={formProps} />
            </span>
          </div>
          <div className="w-full flex justify-center mb-10">
            <ButtonOutline onClick={handleSubmit} title={isLoading ? "SUBMITTING" : "SUBMIT LEAD"} className={`bg-polyfixTheme ${isLoading && "opacity-50 pointer-events-none"} `} />
          </div>
        </div>
      </div>
      <UploadDocs setShowModal={setShowDocModal} showModal={showDocModal} createdLeadInfo={createdLeadInfo} setCreatedLeadInfo={setCreatedLeadInfo} />
    </div>
  );
};

export default CreateLead;
