import { createSlice } from "@reduxjs/toolkit";
import { getAdvisorsThunk, getAssociateThunk, getInternalTeamThunk, getPartnerThunk, partnerAssociateMapping } from "../thunk/ExecutiveMapping.thunk";

interface ApiState {
  data: any;
  loading: boolean;
  confirmationLoading: boolean;
  error: string | null;
  internalTeam: responseData;
  partners: responseData;
  advisors: responseData;
  associates: responseData;
}

interface responseData {
  data: any;
}

const initialState: ApiState = {
  data: null,
  loading: false,
  error: null,
  confirmationLoading: false,
  internalTeam: { data: [] },
  partners: { data: "" },
  advisors: { data: "" },
  associates: { data: "" }
};

const ExecutiveMapping = createSlice({
  name: "ExecutiveMappingSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getInternalTeamThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getInternalTeamThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.internalTeam = action.payload;
        state.error = null;
      })
      .addCase(getInternalTeamThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "An error occurred";
      });
    builder
      .addCase(getAssociateThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAssociateThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.associates = action.payload;
        state.error = null;
      })
      .addCase(getAssociateThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "An error occurred";
      });

    builder
      .addCase(getAdvisorsThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAdvisorsThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.advisors = action.payload;
        state.error = null;
      })
      .addCase(getAdvisorsThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "An error occurred";
      });

    builder
      .addCase(getPartnerThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPartnerThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.partners = action.payload;
        state.error = null;
      })
      .addCase(getPartnerThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "An error occurred";
      });

    builder
      .addCase(partnerAssociateMapping.pending, (state) => {
        state.confirmationLoading = true;
      })
      .addCase(partnerAssociateMapping.fulfilled, (state, action) => {
        state.confirmationLoading = false;
        state.partners = action.payload;
        state.error = null;
      })
      .addCase(partnerAssociateMapping.rejected, (state, action) => {
        state.confirmationLoading = false;
        state.error = action.error.message ?? "An error occurred";
      });
  }
});

export default ExecutiveMapping.reducer;
