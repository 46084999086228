import React from "react";
import { IoIosArrowBack } from "react-icons/io";

interface PaginationProps {
  totalItems: number;
  itemsPerPage: number;
  currentPage: number;
  setCurrentPage: any;
}

const Pagination: React.FC<PaginationProps> = ({ totalItems, itemsPerPage, setCurrentPage, currentPage }) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const goToPage = (page: number) => {
    setCurrentPage(page);
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const renderPaginationButtons = () => {
    const buttons = [];
    const maxPageButtonsToShow = 3;
    const ellipsisThreshold = 2; // Number of pages after which to show ellipses

    if (totalPages <= maxPageButtonsToShow) {
      // If total pages are less than or equal to max buttons, display all
      for (let i = 1; i <= totalPages; i++) {
        buttons.push(
          <button key={i} onClick={() => goToPage(i)} className={`px-2 md:px-4 py-1 rounded-lg text-gray-600 ${currentPage === i ? "bg-polyfixTheme text-white" : "hover:bg-polyfixTheme hover:text-white"}`}>
            {i}
          </button>
        );
      }
    } else {
      const halfButtonsToShow = Math.floor(maxPageButtonsToShow / 2);
      const start = Math.max(1, currentPage - halfButtonsToShow);
      const end = Math.min(totalPages, start + maxPageButtonsToShow - 1);

      if (start > ellipsisThreshold) {
        buttons.push(
          <span key={1} onClick={() => setCurrentPage(1)} className="px-2 md:px-4  py-1 pt-2 rounded-lg text-gray-600 hover:bg-polyfixTheme hover:text-white cursor-pointer">
            1
          </span>
        );
        if (start > ellipsisThreshold + 1) {
          buttons.push(
            <span key={"start-ellipsis"} className="px-4 py-1 rounded-lg text-gray-600">
              ...
            </span>
          );
        }
      }

      for (let i = start; i <= end; i++) {
        buttons.push(
          <button key={i} onClick={() => goToPage(i)} className={`px-2 md:px-4  py-1 rounded-lg text-gray-600 ${currentPage === i ? "bg-polyfixTheme text-white" : "hover:bg-polyfixTheme hover:text-white"}`}>
            {i}
          </button>
        );
      }

      if (end < totalPages - ellipsisThreshold) {
        if (end < totalPages - ellipsisThreshold - 1) {
          buttons.push(
            <span key={"end-ellipsis"} className="px-2 md:px-4  py-1 rounded-lg text-gray-600">
              ...
            </span>
          );
        }
        buttons.push(
          <span onClick={() => setCurrentPage(totalPages)} key={totalPages} className="px-2 md:px-4  py-1 rounded-lg text-gray-600 pt-2 hover:bg-polyfixTheme hover:text-white cursor-pointer">
            {totalPages}
          </span>
        );
      }
    }

    return buttons;
  };

  return (
    <div className="flex flex-col gap-3 lg:flex-row justify-between items-center">
      <div>
        Showing <strong>{totalItems > itemsPerPage ? itemsPerPage : totalItems}</strong> of <strong>{totalItems}</strong> Results
      </div>
      {totalItems > itemsPerPage && (
        <div className="flex gap-2 text-xs md:text-base font-semibold">
          <div
            className={`flex gap-1 items-center justify-center cursor-pointer hover:bg-polyfixTheme hover:text-white p-2 rounded-lg 
                ${currentPage === 1 && "opacity-50 pointer-events-none"}  `}
            onClick={prevPage}>
            <IoIosArrowBack />
            <p>Previous</p>
          </div>
          {renderPaginationButtons()}
          <div className={`flex gap-1 items-center justify-center cursor-pointer hover:bg-polyfixTheme p-2 hover:text-white  rounded-lg ${currentPage === totalPages && "opacity-50 pointer-events-none"} `} onClick={nextPage}>
            <p>Next</p>
            <IoIosArrowBack className="rotate-180" />
          </div>
        </div>
      )}
    </div>
  );
};

export default Pagination;
