import React from "react";

function LoginBtn({
  onClick = () => {
    console.log("No change function passed");
  },
  children = "",
  className = ""
}: any) {
  return (
    <button className={`bg-white text-black border-2 border-black px-4 rounded-3xl ${className}`} onClick={onClick}>
      {children}
    </button>
  );
}

export default LoginBtn;
