import React, { FC } from "react";
import Menu from "../../../../component/common/Menu";
import clearChat from "../../../../assets/icons/chat/clearChat.svg";
import deleteChat from "../../../../assets/icons/chat/deleteChat.svg";
import Typography from "../../../../component/semantic/Typography";

interface HeaderMenu {
  showActionMenu: boolean;
  clearChatHandler: any;
  setDeleteModal: any;
}

const HeaderMenu: FC<HeaderMenu> = ({ showActionMenu, clearChatHandler, setDeleteModal }) => {
  return (
    <Menu showMenu={showActionMenu} className="w-48 top-40 z-50 border-2 right-6 md:right-4">
      <ul>
        <li onClick={clearChatHandler} className="flex items-center gap-3 py-1 pl-2 rounded-md cursor-pointer hover:bg-[#fff9f3] hover:text-polyfixTheme ">
          <img src={clearChat} />
          <Typography>Clear Chat</Typography>
        </li>
        <li onClick={() => setDeleteModal(true)} className="flex items-center gap-3 py-1 pl-2 rounded-md cursor-pointer hover:bg-[#fff9f3] hover:text-polyfixTheme ">
          <img src={deleteChat} />
          <Typography className="pl-1">Delete Chat</Typography>
        </li>
      </ul>
    </Menu>
  );
};

export default HeaderMenu;
