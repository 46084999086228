import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux_toolkit";
import { partnerMappingHeadings, partnerMappingKeys } from "../../component/table/TableConstants";
import Container from "../../component/common/Container";
import Spinner from "../../component/common/Spinner";
import Pagination from "../../component/common/Pagination";
import { actionModalTypes } from "../../helpers/constants/interfaceTypes";
import approveIcon from "../../assets/icons/basic/approve.svg";
import rejectIcon from "../../assets/icons/basic/reject.svg";
import Typography from "../../component/semantic/Typography";
import ActionTable2 from "./components/ActionTable2";
import CustomInput from "../../component/semantic/Input";
import ButtonOutline from "../../component/semantic/Button";
import InputIcon from "../../component/semantic/InputIconMob";
import { getAdvisorsThunk, getAssociateThunk, getInternalTeamThunk, getPartnerThunk } from "../../store/thunk/ExecutiveMapping.thunk";
import ConfirmationModal from "./components/ApproveModal";
import RejectModal from "./components/RejectModal";
import { toast } from "react-toastify";

const ExecutivePartnerMapping = () => {
  const userData = useAppSelector((state) => state.rootReducer.AllUsers);
  const [selectedRow, setSelectedUser] = useState<any>();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPage1, setCurrentPage1] = useState(1);
  const [currentPage2, setCurrentPage2] = useState(1);
  const [openPartnerModal, setOpenPartnerModal] = useState(false);
  const [openAssociateModal, setOpenAssociateModal] = useState(false);
  const [openAdvisorModal, setOpenAdvisorModal] = useState(false);
  const [openPartnerModal1, setOpenPartnerModal1] = useState(false);
  const [openAssociateModal1, setOpenAssociateModal1] = useState(false);
  const [openAdvisorModal1, setOpenAdvisorModal1] = useState(false);
  const [searchPartner, setSearchPartner] = useState("");
  const [searchAssociate, setSearchAssociate] = useState("");
  const [searchAdvisor, setSearchAdvisor] = useState("");
  const [selectedTeam, setSelectedTeam] = useState({ table: "", index: -1, value: "" });
  const pageSize = 5;

  const partnerData = useAppSelector((state) => state.rootReducer.ExecutiveMapping.partners.data);
  const advisorData = useAppSelector((state) => state.rootReducer.ExecutiveMapping.advisors.data);
  const associateData = useAppSelector((state) => state.rootReducer.ExecutiveMapping.associates.data);

  const dispatch = useAppDispatch();
  async function getPartners(search = "") {
    dispatch(getPartnerThunk({ page: currentPage, size: pageSize, search: search, data: { selectedRow } }));
  }
  async function getAssociates(search = "") {
    dispatch(getAssociateThunk({ page: currentPage, size: pageSize, search: search }));
  }
  async function getAdvisors(search = "") {
    dispatch(getAdvisorsThunk({ page: currentPage, size: pageSize, search: search }));
  }
  useEffect(() => {
    dispatch(getInternalTeamThunk());
  }, []);

  useEffect(() => {
    getPartners(searchPartner);
  }, [currentPage]);
  useEffect(() => {
    getAdvisors(searchAdvisor);
  }, [currentPage1]);
  useEffect(() => {
    getAssociates(searchAssociate);
  }, [currentPage2]);

  const actions: actionModalTypes = [
    {
      image: <img src={approveIcon} className="cursor-pointer" />,
      tooltip: "Approve this user",
      onClickFunction: () => {
        if (selectedTeam.value) setOpenPartnerModal(true);
        else toast.warning("Please select a executive");
      }
    },
    {
      image: <img src={rejectIcon} className="cursor-pointer" />,
      tooltip: "Reject this user",
      onClickFunction: () => {
        if (selectedRow?.mappedInSaUsersIds?.length) {
          setOpenPartnerModal1(true);
        } else {
          toast.warning("No executive selected");
        }
      }
    }
  ];

  const actions1: actionModalTypes = [
    {
      image: <img src={approveIcon} className="cursor-pointer" />,
      tooltip: "Approve this user",
      onClickFunction: () => {
        if (selectedTeam.value) setOpenAssociateModal(true);
        else toast.warning("Please select a executive");
      }
    },
    {
      image: <img src={rejectIcon} className="cursor-pointer" />,
      tooltip: "Reject this user",
      onClickFunction: () => {
        if (selectedRow?.mappedInSaUsersIds?.length) {
          setOpenAssociateModal1(true);
        } else {
          toast.warning("No executive selected");
        }
      }
    }
  ];
  const actions2: actionModalTypes = [
    {
      image: <img src={approveIcon} className="cursor-pointer" />,
      tooltip: "Approve this user",
      onClickFunction: () => {
        if (selectedTeam.value) setOpenAdvisorModal(true);
        else toast.warning("Please select a executive");
      }
    },
    {
      image: <img src={rejectIcon} className="cursor-pointer" />,
      tooltip: "Reject this user",
      onClickFunction: () => {
        if (selectedRow?.mappedInSaUsersIds?.length) {
          setOpenAdvisorModal1(true);
        } else {
          toast.warning("No executive selected");
        }
      }
    }
  ];
  const setVal = () => {
    return;
  };

  // console.log(selectedTeam.value);
  return (
    <>
      {/* <Filter onFilterChange={handleFilterChange} setFilter={setFilter} /> */}
      <Container customClassName="mt-6">
        <div className="hidden md:flex justify-center items-end gap-2 w-[60%] mb-4">
          <CustomInput
            parentClassName="w-full flex gap-1 flex-col item-center"
            onKeyDown={setVal}
            onChange={(e) => {
              setSearchPartner(e.target.value);
            }}
            value={searchPartner}
            label=""
            placeholder="Search by Partner/Associate/Advisor/ Name and Number"
          />
          <ButtonOutline
            type="button"
            className={`bg-polyfixTheme  w-32 mb-2`}
            title="Search"
            onClick={() => {
              if (searchPartner) {
                getPartners(searchPartner);
              }
            }}
          />
          <ButtonOutline
            title="Clear All"
            color="polyfixTheme"
            className="hover:bg-polyfixTheme hover:text-white w-32 mb-2"
            onClick={() => {
              setSearchPartner("");
              getPartners("");
            }}
          />
        </div>
        <div className="flex md:hidden w-full flex-col gap-1">
          <Typography
            className="text-polyfixTheme text-end"
            onClick={() => {
              setSearchPartner("");
              getPartners("");
            }}
          >
            Clear All
          </Typography>
          <InputIcon
            placeholder="Search by Partner/Associate/Advisor/ Name and Number"
            onClickFunction={() => {
              if (searchPartner) {
                getPartners(searchPartner);
              }
            }}
            value={searchPartner}
            onChange={(e) => {
              setSearchPartner(e.target.value);
            }}
          />
        </div>
        <Typography variant="h1">Executive Partner Mapping</Typography>
        <Typography className="text-gray-600 mb-6">Map partner against InSa executive</Typography>
        {userData.loading ? <Spinner /> : <ActionTable2 tableName="first" setSelectedTeam={setSelectedTeam} selectedTeam={selectedTeam} actions={actions} selectedRow={setSelectedUser} tableData={partnerData?.partnersList || []} tableHeading={partnerMappingHeadings} tableKeys={partnerMappingKeys} />}
        <span className="my-2">&nbsp;</span>
        <Pagination currentPage={currentPage} itemsPerPage={pageSize} setCurrentPage={setCurrentPage} totalItems={partnerData?.partnersCount || 0} />
        {/* <EditDetails showModal={openActionModal} setOpenActionModal={setOpenActionModal} data={selectedRow} /> */}
      </Container>
      <Container customClassName="mt-6">
        <div className="hidden md:flex justify-center items-end gap-2 w-[60%] mb-4">
          <CustomInput
            parentClassName="w-full flex gap-1 flex-col item-center"
            onKeyDown={setVal}
            onChange={(e) => {
              setSearchAssociate(e.target.value);
            }}
            value={searchAssociate}
            label=""
            placeholder="Search by Partner/Associate/Advisor/ Name and Number"
          />
          <ButtonOutline
            type="button"
            className={`bg-polyfixTheme w-32 mb-2`}
            title="Search"
            onClick={() => {
              if (searchAssociate) {
                getAssociates(searchAssociate);
              }
            }}
          />
          <ButtonOutline
            title="Clear All"
            color="polyfixTheme"
            className="hover:bg-polyfixTheme hover:text-white w-32 mb-2"
            onClick={() => {
              setSearchAssociate("");
              getAssociates("");
            }}
          />
        </div>
        <div className="flex md:hidden w-full hover:text-white flex-col gap-1">
          <Typography
            className="text-polyfixTheme text-end"
            onClick={() => {
              setSearchAssociate("");
              getAssociates("");
            }}
          >
            Clear All
          </Typography>
          <InputIcon
            placeholder="Search by Partner/Associate/Advisor/ Name and Number"
            onClickFunction={() => {
              if (searchAssociate) {
                getAssociates(searchAssociate);
              }
            }}
            value={searchAssociate}
            onChange={(e) => {
              setSearchAssociate(e.target.value);
            }}
          />
        </div>
        <Typography variant="h1">Executive Associate Mapping</Typography>
        <Typography className="text-gray-600 mb-6">Map associate against InSa executive</Typography>
        {userData.loading ? <Spinner /> : <ActionTable2 tableName="second" setSelectedTeam={setSelectedTeam} selectedTeam={selectedTeam} actions={actions2} selectedRow={setSelectedUser} tableData={associateData?.associateList} tableHeading={partnerMappingHeadings} tableKeys={partnerMappingKeys} />}
        <span className="my-2">&nbsp;</span>
        <Pagination currentPage={currentPage2} itemsPerPage={pageSize} setCurrentPage={setCurrentPage2} totalItems={associateData?.associateCount || 0} />
        {/* <EditDetails showModal={openActionModal} setOpenActionModal={setOpenActionModal} data={selectedRow} /> */}
      </Container>
      <Container customClassName="mt-6">
        <div className="hidden md:flex justify-center items-end gap-2 w-[60%] mb-4">
          <CustomInput
            parentClassName="w-full flex gap-1 flex-col item-center"
            onKeyDown={setVal}
            onChange={(e) => {
              setSearchAdvisor(e.target.value);
            }}
            value={searchAdvisor}
            placeholder="Search by Partner/Associate/Advisor/ Name and Number"
          />
          <ButtonOutline
            type="button"
            className={`bg-polyfixTheme w-32 mb-2`}
            title="Search"
            onClick={() => {
              if (searchAdvisor) {
                getAdvisors(searchAdvisor);
              }
            }}
          />
          <ButtonOutline
            title="Clear All"
            color="polyfixTheme"
            className="hover:bg-polyfixTheme hover:text-white w-32 mb-2"
            onClick={() => {
              setSearchAdvisor("");
              getAdvisors("");
            }}
          />
        </div>
        <div className="flex md:hidden w-full flex-col gap-1">
          <Typography
            className="text-polyfixTheme text-end"
            onClick={() => {
              setSearchAdvisor("");
              getAdvisors("");
            }}
          >
            Clear All
          </Typography>
          <InputIcon
            placeholder="Search by Partner/Associate/Advisor/ Name and Number"
            onClickFunction={() => {
              if (searchAdvisor) {
                getAdvisors(searchAdvisor);
              }
            }}
            value={searchAdvisor}
            onChange={(e) => {
              setSearchAdvisor(e.target.value);
            }}
          />
        </div>
        <Typography variant="h1">Executive Advisor Mapping</Typography>
        <Typography className="text-gray-600 mb-6">Map advisor against InSa executive</Typography>
        {userData.loading ? <Spinner /> : <ActionTable2 tableName="third" setSelectedTeam={setSelectedTeam} selectedTeam={selectedTeam} actions={actions1} selectedRow={setSelectedUser} tableData={advisorData?.advisorList} tableHeading={partnerMappingHeadings} tableKeys={partnerMappingKeys} />}
        <span className="my-2">&nbsp;</span>
        <Pagination currentPage={currentPage1} itemsPerPage={pageSize} setCurrentPage={setCurrentPage1} totalItems={advisorData?.advisorCount || 0} />
        {/* <EditDetails showModal={openActionModal} setOpenActionModal={setOpenActionModal} data={selectedRow} /> */}
      </Container>

      <ConfirmationModal PayloadData={{ partnerData: selectedRow, teams: selectedTeam }} confirmationModal={openAdvisorModal} setConfirmationModal={setOpenAdvisorModal} successButtonHandler={setVal} />
      <ConfirmationModal PayloadData={{ partnerData: selectedRow, teams: selectedTeam }} confirmationModal={openAssociateModal} setConfirmationModal={setOpenAssociateModal} successButtonHandler={setVal} />
      <ConfirmationModal PayloadData={{ partnerData: selectedRow, teams: selectedTeam }} confirmationModal={openPartnerModal} setConfirmationModal={setOpenPartnerModal} successButtonHandler={setVal} />

      <RejectModal confirmationModal={openAdvisorModal1} setConfirmationModal={setOpenAdvisorModal1} successButtonHandler={setVal} selectedRow={selectedRow} />
      <RejectModal confirmationModal={openAssociateModal1} setConfirmationModal={setOpenAssociateModal1} successButtonHandler={setVal} selectedRow={selectedRow} />
      <RejectModal confirmationModal={openPartnerModal1} setConfirmationModal={setOpenPartnerModal1} successButtonHandler={setVal} selectedRow={selectedRow} />
    </>
  );
};

export default ExecutivePartnerMapping;
