import React, { useEffect } from "react";
import { useFormik } from "formik";
import ReactSelect from "react-select";

import { useAppDispatch, useAppSelector } from "../../../hooks/redux_toolkit";
import { addNewCompany, policyListThunk } from "../../../store/thunk/Common.thunk";
import { errorStyles, polyfixSelect } from "../../../helpers/constants/styles";
import Typography from "../../../component/semantic/Typography";
import ButtonOutline from "../../../component/semantic/Button";
import FormInput from "../../../component/semantic/FormInput";
import Divide from "../../../component/semantic/Divide";
import { addCompanySchema } from "../../../helpers/schemas/AddCompany.Schema";
import { getAllCompanyThunk } from "../../../store/thunk/Company.thunk";

interface valuesProps {
  address: string;
  companyEmail: string;
  companyLevel1Email: string;
  name: string;
  policyType: any;
}

const initialValues: valuesProps = {
  address: "",
  companyEmail: "",
  companyLevel1Email: "",
  name: "",
  policyType: ""
};

const AddCompany = ({ pageSize, currentPage }: any) => {
  const dispatch = useAppDispatch();

  const { values, handleSubmit, handleBlur, handleChange, touched, errors, resetForm, setFieldValue }: any = useFormik({
    initialValues,
    validationSchema: addCompanySchema,
    onSubmit: async (values: any) => {
      const payload = {
        ...values,
        companyEmail: values.companyEmail.trim(),
        companyLevel1Email: values.companyLevel1Email.trim()
      };
      dispatch(addNewCompany({ ...payload, policyType: values.policyType?._id }))
        .unwrap()
        .then(() => {
          dispatch(getAllCompanyThunk({ pageSize, currentPage }));
        });
      resetForm();
      dispatch(policyListThunk());
    }
  });

  const formProps = { values, handleBlur, handleChange, touched, errors, setFieldValue };

  const policyList = useAppSelector((state) => state.rootReducer.common.policyList.data);

  useEffect(() => {
    dispatch(policyListThunk());
  }, []);

  return (
    <div className="w-full p-4">
      <Typography variant="h3">Company</Typography>
      <Typography className="text-gray-600 mb-4">List of companies</Typography>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
        <div className="col-span-1">
          <FormInput name="name" placeholder="Enter Company Name" label="Name *" formProps={formProps} />
        </div>
        <div className="col-span-1 md:col-span-2">
          <FormInput name="address" placeholder="Enter Company Address" label="Address *" formProps={formProps} />
        </div>
        <div className="col-span-1 md:col-span-1">
          <Typography className="font-normal mb-1">Policy Type *</Typography>
          <ReactSelect
            options={policyList}
            name="policyType"
            placeholder="Select Policy Type"
            getOptionLabel={(option) => option?.name}
            getOptionValue={(option) => option}
            value={values.policyType}
            onChange={(option) => {
              setFieldValue("policyType", option);
            }}
            styles={errors?.policyType && touched.policyType ? errorStyles : polyfixSelect}
            isSearchable={false}
          />
          {touched.policyType && errors?.policyType && <p className="text-red-600 font-semibold text-sm">{errors?.policyType}</p>}
        </div>
        <div className="col-span-1">
          <FormInput name="companyEmail" placeholder="Enter Company Email" label="Company Email *" formProps={formProps} />
        </div>
        <div className="col-span-1">
          <FormInput name="companyLevel1Email" placeholder="Enter Company Level1 Email" label="Company Level1 Email *" formProps={formProps} />
        </div>
      </div>
      <Divide className="my-6 mt-10 -mx-4 shadow-above-border" />
      <div className="block md:flex justify-center">
        <ButtonOutline className="bg-[#1BA785] text-white w-full md:w-auto" onClick={handleSubmit} title="Add" type="submit" />
      </div>
      <Divide className="my-6 mb-10 -mx-4 shadow-above-border" />
    </div>
  );
};

export default AddCompany;
