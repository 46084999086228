import React, { FC, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux_toolkit";
import ActionTable from "../component/ActionTable.company";
import { companyHeading, companyKeys } from "../../../component/table/TableConstants";
import { actionModalTypes } from "../../../helpers/constants/interfaceTypes";
import editCompany from "../../../assets/icons/allCompany/changeCompany.svg";
import deleteCompany from "../../../assets/icons/allCompany/delete.svg";
import UpdateCompany from "./UpdateCompany";
import DeleteCompany from "./DeleteConfirm";
import { deleteCompanyThunk, getAllCompanyThunk } from "../../../store/thunk/Company.thunk";
import { toast } from "react-toastify";
import Pagination from "../../../component/common/Pagination";

interface CompanyProps {
  setCurrentPage: any;
  currentPage: number;
  pageSize: number;
}

const CompanyTable: FC<CompanyProps> = ({ setCurrentPage, currentPage, pageSize }) => {
  const tableData = useAppSelector((state) => state.rootReducer.Company.companyTable.data);

  const [showEditCompany, setShowEditCompany] = useState(false);
  const [showDeleteCompany, setShowDeleteCompany] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<any>("");

  const dispatch = useAppDispatch();

  const actions: actionModalTypes = [
    { image: <img src={editCompany} className="cursor-pointer h-8 w-8" />, tooltip: "Change Company Details", onClickFunction: () => setShowEditCompany(true) },
    { image: <img src={deleteCompany} className="cursor-pointer h-8 w-8" />, tooltip: "Delete Company", onClickFunction: () => setShowDeleteCompany(true) }
  ];

  const deleteCompanyHandler = () => {
    dispatch(deleteCompanyThunk(selectedCompany?._id))
      .unwrap()
      .then((data: any) => {
        if (data?.success) {
          dispatch(getAllCompanyThunk({ pageSize, currentPage }));
          toast.success(data?.message);
          setShowDeleteCompany(false);
        }
      });
  };

  return (
    <>
      <ActionTable pageSize={pageSize} currentPage={currentPage} tableData={tableData?.companies} tableHeading={companyHeading} tableKeys={companyKeys} selectedRow={setSelectedCompany} actions={actions} />
      <Pagination itemsPerPage={pageSize} totalItems={tableData?.count || 0} setCurrentPage={setCurrentPage} currentPage={currentPage} />
      <UpdateCompany pageSize={pageSize} currentPage={currentPage} selectedCompany={selectedCompany} setShowEditCompany={setShowEditCompany} showEditCompany={showEditCompany} />
      <DeleteCompany setDeleteModal={setShowDeleteCompany} showDeleteModal={showDeleteCompany} deleteCompanyHandler={deleteCompanyHandler} />
    </>
  );
};

export default CompanyTable;
