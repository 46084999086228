import React, { useState } from "react";
import Wrapper from "./Wrapper";
import Typography from "../../../component/semantic/Typography";
import ButtonOutline from "../../../component/semantic/Button";
import { useNavigate } from "react-router-dom";
import CustomInput from "../../../component/semantic/Input";
import { toast } from "react-toastify";
import { useAppDispatch } from "../../../hooks/redux_toolkit";
import { recoverPasswordThunk } from "../../../store/thunk/Login.thunk";

const Recover = () => {
  const navigate = useNavigate();
  const [emailValue, setEmailValue] = useState("");
  const [isValid, setIsValid] = useState(true);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const dispatch = useAppDispatch();

  const handleChange = (e: any) => {
    setEmailValue(e.target.value);
  };

  const submitHandler = () => {
    if (emailRegex.test(emailValue)) {
      setIsValid(true);
    } else {
      setIsValid(false);
      return;
    }
    dispatch(recoverPasswordThunk({ email: emailValue }))
      .unwrap()
      .then((data: any) => {
        if (data?.success) {
          setEmailValue("");
          toast.success(data?.message);
        }
      });
  };

  return (
    <Wrapper>
      <div className="shadow-xl pb-4 p-5 px-8">
        <Typography variant="h3">RESET YOUR PASSWORD</Typography>
        <Typography>Enter your email to receive a temporary password</Typography>
      </div>
      <div className="p-8">
        <CustomInput label="Email *" type="email" className={`${isValid && "border-2 border-red-600"}`} value={emailValue} onChange={handleChange} />
        {!isValid && (
          <Typography variant="h2" className="text-xs text-red-600 mt-2 font-bold">
            Please Enter a valid email address
          </Typography>
        )}
      </div>
      <div className="px-8 shadow-top py-6">
        <div className="flex justify-between">
          <Typography className="text-polyfixTheme cursor-pointer" onClick={() => navigate("/auth/signup/")}>
            Create an account
          </Typography>
          <Typography className="text-polyfixTheme cursor-pointer" onClick={() => navigate("/login")}>
            Login to your account
          </Typography>
        </div>

        <div className="w-full mt-3">
          <ButtonOutline title="RESET YOUR PASSWORD" onClick={submitHandler} className="bg-polyfixTheme w-full" />
        </div>
      </div>
    </Wrapper>
  );
};

export default Recover;
