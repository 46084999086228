import { createSlice } from "@reduxjs/toolkit";
import { getLeadCostData, markPayoutDone } from "../thunk/leadCost";
interface ApiState {
    data: any;
    loading: "idle" | "pending" | "fulfilled" | "rejected";
    error: string | null;
    navigationUrl: string;
}

const initialState: ApiState = {
    data: [],
    loading: "idle",
    error: null,
    navigationUrl: ""
};

const leadCost = createSlice({
    name: "leadCost",
    initialState,
    reducers: {
        emptyNavigationUrl: (state) => {
            state.navigationUrl = "";
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getLeadCostData.pending, (state) => {
                state.loading = "pending";
            })
            .addCase(getLeadCostData.fulfilled, (state, action) => {
                if (action.payload?.data) {
                    state.data = action.payload?.data;
                }
                state.loading = "fulfilled";
                state.error = null
            })
            .addCase(getLeadCostData.rejected, (state, action) => {
                state.loading = "rejected";
                state.error = action.error.message ?? "An error occurred";
            })
            .addCase(markPayoutDone.pending, (state) => {
                state.loading = "pending";
            })
            .addCase(markPayoutDone.fulfilled, (state, action) => {
                if (action.payload?.data) {
                    state.data = action.payload?.data;
                }
                state.loading = "fulfilled";
                state.error = null
            })
            .addCase(markPayoutDone.rejected, (state, action) => {
                state.loading = "rejected";
                state.error = action.error.message ?? "An error occurred";
            });
    }
});

export const { emptyNavigationUrl } = leadCost.actions;

export default leadCost.reducer;
