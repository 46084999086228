import React, { FC, useEffect, useRef, useState } from "react";
import Modal from "../../../../component/common/Modal";
import ButtonOutline from "../../../../component/semantic/Button";
import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop } from "react-image-crop";
import { canvasPreview } from "./canvasPreview";
import { useDebounceEffect } from "../../../../hooks/useDebounceEffect";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux_toolkit";
import { uploadLogoThunk } from "../../../../store/thunk/Gallery.thunk";
import { toast } from "react-toastify";
import { updatePartnerLogo } from "../../../../store/slices/Common.slice";
import { ImSpinner10 } from "react-icons/im";
interface AddLogoProps {
  addLogoModal: boolean;
  setShowAddLogoModal: any;
  imgSource: any;
  setImgSource: any;
}

function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

const AddLogo: FC<AddLogoProps> = ({ addLogoModal, setShowAddLogoModal, imgSource, setImgSource }) => {
  const [imgSrc, setImgSrc] = useState("");
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  // eslint-disable-next-line
  const [aspect, setAspect] = useState<number | undefined>(1);
  const hiddenAnchorRef = useRef<HTMLAnchorElement>(null);

  const dispatch = useAppDispatch();

  useEffect(() => {
    setCrop(undefined);
    const reader = new FileReader();
    reader.addEventListener("load", () => setImgSrc(reader.result?.toString() || ""));
    reader.readAsDataURL(imgSource);
  }, [imgSource]);
  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  useDebounceEffect(
    async () => {
      if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop);
      }
    },
    100,
    [completedCrop]
  );
  async function onDownloadCropClick() {
    try {
      const image = imgRef.current;
      const previewCanvas = previewCanvasRef.current;
      if (!image || !previewCanvas || !completedCrop) {
        throw new Error("Crop canvas does not exist");
      }

      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;

      const offscreen: any = new OffscreenCanvas(completedCrop.width * scaleX, completedCrop.height * scaleY);
      const ctx = offscreen.getContext("2d");
      if (!ctx) {
        throw new Error("No 2d context");
      }

      ctx.drawImage(previewCanvas, 0, 0, previewCanvas.width, previewCanvas.height, 0, 0, offscreen.width, offscreen.height);

      const blob = await offscreen.convertToBlob({
        type: "image/png"
      });

      const formData = new FormData();
      formData.append("file", blob, "croppedImage.png");
      dispatch(uploadLogoThunk(formData))
        .unwrap()
        .then((data: any) => {
          if (data?.success) {
            toast.success(data?.message);
            dispatch(updatePartnerLogo(data.data?.partnerLogo));
            setImgSrc("");
            setShowAddLogoModal(false);
          }
        });
    } catch (error) {
      // eslint-disable-next-line
      console.error("Error downloading cropped image:", error);
    }
  }

  const loading = useAppSelector((state) => state.rootReducer.gallery.logoLoading);

  return (
    <Modal showModal={addLogoModal} onClose={() => setShowAddLogoModal(false)} className="flex flex-col justify-center z-50 items-center gap-4">
      <div className="flex flex-col justify-center gap-3">
        <div className="w-full h-full   ">
          {!!imgSource && (
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={aspect}
              // minHeight={100}
            >
              <img ref={imgRef} alt="logo" className="rounded-lg h-[26rem] w-full  object-contain" src={imgSrc} onLoad={onImageLoad} />
            </ReactCrop>
          )}
        </div>
        {!!completedCrop && <canvas ref={previewCanvasRef} className="hidden" />}
        <div className="flex gap-3 justify-center my-7">
          <a href="#hidden" ref={hiddenAnchorRef} download className="hidden" />
          <ButtonOutline
            title="Cancel"
            color="black"
            className="hover:bg-black hover:text-white"
            onClick={() => {
              setShowAddLogoModal(false);
              setImgSource();
            }}
          />
          <ButtonOutline onClick={onDownloadCropClick} title={loading ? "Saving" : "Save"} icon={loading && <ImSpinner10 className="animate-spin" />} type="submit" className={`bg-polyfixTheme hover:text-black  ${loading && "pointer-events-none   opacity-50"}`} />
        </div>
      </div>
    </Modal>
  );
};

export default AddLogo;
