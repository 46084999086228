import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../helpers/axios";
import { baseUrl } from "../../helpers/constants/defaultUrls";

export const userUpdate = createAsyncThunk("profile/userUpdate", async (payload: any) => {
  try {
    const response = await axios.put(baseUrl + "v1/user/update", payload);
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
});

export const profilePicThunk = createAsyncThunk("profile/profilePicThunk", async (payload: FormData) => {
  const response = await axios.put(baseUrl + "v1/user/profilepic", payload, {
    headers: { "Content-Type": "multipart/form-data" }
  });
  return response.data;
});

export const cancelledChequeThunk = createAsyncThunk("profile/cancelledCheque", async (payload: FormData) => {
  const response = await axios.put(baseUrl + "v1/user/cheque", payload, {
    headers: { "Content-Type": "multipart/form-data" }
  });
  return response.data;
});

export const agentDocThunk = createAsyncThunk("profile/agentDocThunk", async (payload: FormData) => {
  const response = await axios.put(baseUrl + "v1/user/agentDoc", payload, {
    headers: { "Content-Type": "multipart/form-data" }
  });
  return response.data;
});

export const natureOfBusiness = createAsyncThunk("profile/list/natureOfBusiness", async () => {
  const response = await axios.get(baseUrl + "v1/auth/list/natureOfBusiness");
  return response.data;
});

export const profileDocDelete = createAsyncThunk("profile/profileDocDelete", async (payload: { userId: string; file: string }) => {
  const response = await axios.patch(baseUrl + `v2/user/docDelete?userId=${payload.userId}&file=${payload.file}`);
  return response.data;
});
