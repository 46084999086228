import React, { ReactNode } from "react";
import { InputProps } from "./Input";

interface InputWithIconProps extends InputProps {
  icon: ReactNode;
  className?: string;
}

const InputWithIcon: React.FC<InputWithIconProps> = ({ icon, className, ...rest }) => {
  return (
    <div className={`relative ${className}`}>
      <input {...rest} className="appearance-none h-12 border focus:ring-2 focus:outline-none focus:ring-blue-300 rounded w-full py-2 px-3 pr-10 text-gray-700 leading-tight focus:shadow-outline" type="text" />
      <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">{icon}</div>
    </div>
  );
};

export default InputWithIcon;
