import React, { useEffect, useState } from "react";
import Container from "../../component/common/Container";
import CustomInput from "../../component/semantic/Input";
import ButtonOutline from "../../component/semantic/Button";
import { CiFilter } from "react-icons/ci";
import Typography from "../../component/semantic/Typography";
import ActionTable from "../../component/table/ActionTable";
import emptyLead from "../../assets/icons/lead/emptyLead.svg";
import { useDispatch, useSelector } from "react-redux";
import { getLeadCostData } from "../../store/thunk/leadCost";
import dayjs from "dayjs";

function PayoutMarking() {
  const tabs = [
    {
      id: "tab-1",
      label: "9th - 11th",
      value: "9-23"
    },
    {
      id: "tab-2",
      label: "24th - 8th",
      value: "24-8"
    }
  ];

  const dispatch = useDispatch();
  const state = useSelector((state: any) => state?.rootReducer?.LeadCost);

  const [selectedMonth, setSelectedMonth] = useState(dayjs().format("YYYY-MM"));
  // eslint-disable-next-line
  const [selectedRow, setSelectedRow] = useState<any>();
  const [activeTab, setActiveTab] = useState({
    id: "tab-1",
    label: "9th - 23th",
    value: "9-23"
  });

  useEffect(() => {
    const [year, month] = selectedMonth.split("-");
    if (!year) return;
    if (!month) return;
    dispatch(getLeadCostData({ dateFilter: activeTab.value, year, month: (Number(month) - 1)?.toString() }));
  }, [activeTab]);

  //   const handleLeadCostPaid = (data: any) => {
  //     const payload: any = {
  //       leadCostUpdateArray: [
  //         {
  //           leadId: data._id
  //         }
  //       ]
  //     };
  //     dispatch(markPayoutDone(payload));
  //   };

  const tableData: any = state?.data || [];
  const headings = ["S.No", "Name", "Email", "Level", "Lead Number", "Amount", "Mark Done"];
  const tableKeys: any = ["s.no", "name", "email", "level", "leadNumber", "leadCost", "action"];
  return (
    <div className="">
      <div className="mb-6 ">
        <div className="rounded-lg bg-inherit p-4 md:bg-white lg:bg-white xl:bg-white 2xl:bg-white :bg-inheri t">
          <div className="flex w-full">
            <div className="flex gap-5 justify-between mx-auto w-auto md:w-[800px] lg:w-[1000px] xl:w-[1000px] 2xl:w-[1200px]">
              <div className="md:flex lg:flex xl:flex 2xl:flex gap-4 items-center">
                <div className="flex justify-between mb-2">
                  <div className="flex">
                    <CiFilter className="text-[#FAAA52]" size={20} />
                    <Typography className="w-20">Filter By</Typography>
                  </div>
                  <Typography variant="h2" className="text-polyfixTheme cursor-pointer block md:hidden lg:hidden xl:hidden 2xl:hidden" onClick={() => setSelectedMonth("")}>
                    Clear Filters
                  </Typography>
                  {/* <ButtonOutline title="Clear Filter" color="polyfixTheme" className="hover:bg-polyfixTheme w-32 mb-2 p-0 block md:hidden lg:hidden xl:hidden 2xl:hidden" onClick={() => { setSelectedMonth("") }} /> */}
                </div>
                <div className="md:w-[280px] lg:w-[280px] xl:w-[280px] 2xl:w-[280px] w-[332px]">
                  {/* <input type="month" className=" appearance-none border focus:ring-2 focus:outline-none focus:ring-blue-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:shadow-outline" /> */}
                  <CustomInput placeholder="Enter Your Search" className="w-full" type="month" value={selectedMonth} onChange={(e) => setSelectedMonth(e.target.value)} />
                </div>
                <div>
                  {/* <Typography variant="h2" className="text-polyfixTheme cursor-pointer" onClick={() => console.log("fjnskndj")}>
                                        Clear Filters
                                    </Typography> */}
                </div>
              </div>
            </div>
            <ButtonOutline
              title="Clear Filter"
              color="polyfixTheme"
              className="hover:bg-polyfixTheme w-32 mb-2 d-block hidden md:block lg:block xl:block 2xl:block"
              onClick={() => {
                setSelectedMonth("");
              }}
            />
          </div>
        </div>
      </div>
      <Container>
        <div>
          <div className="font-medium text-3xl">
            <Typography>Lead Cost</Typography>
          </div>
          <div className="mt-3 text-[#666666]">
            <Typography>List of all partners, Associates and Advisors.</Typography>
          </div>
        </div>
        <div className="border-b-2">
          <ul className="flex flex-wrap -mb-px text-sm font-medium text-center" id="default-styled-tab" data-tabs-toggle="#default-styled-tab-content" data-tabs-active-classes="text-purple-600 hover:text-purple-600 dark:text-purple-500 dark:hover:text-purple-500 border-purple-600 dark:border-purple-500" data-tabs-inactive-classes="dark:border-transparent text-gray-500 hover:text-gray-600 dark:text-gray-400 border-gray-100 hover:border-gray-300 dark:border-gray-700 dark:hover:text-gray-300" role="tablist">
            {tabs?.map((res): any => {
              return (
                <li className="me-2 py-4" key={res.id} role="presentation">
                  <button className={`inline-block p-4 text-[1rem] md:text-[1.5rem] xl:text-[1.5rem] 2xl:text-[1.5rem] px-6 rounded-t-lg ${activeTab?.id == res.id ? "border-b-4 border-[#FAAA52] " : ""}`} id="profile-styled-tab" data-tabs-target="#styled-profile" type="button" role="tab" aria-controls="profile" aria-selected="false" onClick={() => setActiveTab(res)}>
                    {res.label}
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
        {!tableData?.length ? (
          <div className="h-72 flex flex-col justify-center gap-3 items-center">
            <img src={emptyLead} />
            <Typography variant="h3" className="text-[#666666]">
              No Case Settled
            </Typography>
          </div>
        ) : (
          <ActionTable actions={[]} selectedRow={{}} tableData={tableData} tableHeading={headings} tableKeys={tableKeys} />
        )}
      </Container>
    </div>
  );
}

export default PayoutMarking;
