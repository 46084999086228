import React, { useEffect, useState } from "react";
import Container from "../../../component/common/Container";
import ProfileDetails from "./ProfileDetails";
import ProfessionalDetails from "./ProfessionalDetails";
import BankDetails from "./BankDetails";
import { useAppDispatch } from "../../../hooks/redux_toolkit";
import { allCompanyList, getStateAndDistrict } from "../../../store/thunk/Common.thunk";
import { natureOfBusiness } from "../../../store/thunk/Profile.thunk";
import Tabpane from "../../../component/common/Tabpane";

const Details = () => {
  const [selectedTab, setSelectedTab] = useState<number>(1);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(allCompanyList());
    dispatch(getStateAndDistrict());
    dispatch(natureOfBusiness());
  }, []);

  const TabValues = ["Profile Details", "Professional Details", "Bank Details"];

  return (
    <Container customClassName="my-4">
      <div>
        <Tabpane selectedTab={selectedTab} setSelectedTab={setSelectedTab} values={TabValues} />
        {selectedTab == 1 && <ProfileDetails />}
        {selectedTab == 2 && <ProfessionalDetails />}
        {selectedTab == 3 && <BankDetails />}
      </div>
    </Container>
  );
};

export default Details;
