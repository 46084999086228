import React, { FC } from "react";
import Container from "../../../component/common/Container";
import { bucketsConstants, ombudsmanStatus } from "../../../helpers/constants/constants";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import Divide from "../../../component/semantic/Divide";
import HeaderIcon from "../../../assets/icons/newDashboard/sectionIcon.svg";
import Typography from "../../../component/semantic/Typography";
import { CiFilter } from "react-icons/ci";
import ReactSelect from "react-select";

interface SectionProps {
  setSelectedBucket: any;
  selectedBucket: any;
  setOmbudsmanFilter: any;
  ombudsmanFilter: { location: string; status: string };
}

const Section: FC<SectionProps> = ({ selectedBucket, setSelectedBucket, setOmbudsmanFilter, ombudsmanFilter }) => {
  return (
    <Container customClassName="mt-6">
      <div className="flex gap-3 flex-wrap">
        {bucketsConstants.map((data: any, i: number) => {
          return (
            <div
              key={i}
              onClick={() => setSelectedBucket(data)}
              className={`border-[1px]
             ${selectedBucket.index == i && "border-[#3CAFE3] text-[#3CAFE3]"} cursor-pointer flex gap-2 items-center rounded-3xl font-bold px-5 py-2`}
            >
              <p>{data.name}</p>
              <MdKeyboardDoubleArrowRight className="text-xl" />
            </div>
          );
        })}
      </div>
      <Divide className="my-6" />
      <div className="flex gap-3 items-center">
        <img src={HeaderIcon} />
        <Typography variant="h2">{selectedBucket.name} Section</Typography>
      </div>

      {selectedBucket.selectedSubBucket === "Partner Resend Cases" && (
        <div id="mob-view" className="flex md:hidden mt-4 flex-col md:flex-row gap-4 w-full md:w-auto items-start md:items-center">
          <div className="flex gap-3">
            <CiFilter className="text-[#FAAA52]" size={20} />
            <Typography>Filter By</Typography>
          </div>
          {/* <ReactSelect
            className="w-full md:w-56"
            options={nameOfStates.map((obj: any) => {
              return { name: obj };
            })}
            onChange={(option: any) => setOmbudsmanFilter((prev: any) => ({ ...prev, location: option.name }))}
            placeholder="Ombudsman Location"
            value={{ name: ombudsmanFilter.location }}
            isSearchable={false}
            getOptionLabel={(option: any) => option.name}
            getOptionValue={(option: any) => option.name}
          /> */}
          <ReactSelect
            className="w-full  md:w-56"
            options={ombudsmanStatus.map((obj: any) => {
              return { name: obj };
            })}
            placeholder="Ombudsman Status"
            onChange={(option: any) => setOmbudsmanFilter((prev: any) => ({ ...prev, status: option.name }))}
            value={{ name: ombudsmanFilter.status }}
            isSearchable={false}
            getOptionLabel={(option: any) => option.name}
            getOptionValue={(option: any) => option.name}
          />
        </div>
      )}

      <div className="flex flex-wrap gap-4 justify-between items-center">
        <div className="flex gap-3 mt-6 flex-wrap">
          {selectedBucket.subBucket.map((data: any, i: number) => {
            return (
              <div
                onClick={() =>
                  setSelectedBucket((prev: any) => ({
                    ...prev,
                    selectedSubBucket: data
                  }))
                }
                key={i}
                className={`border-[1px] ${selectedBucket.selectedSubBucket == data && "border-[#3CAFE3] text-[#3CAFE3]"} cursor-pointer flex gap-2 items-center rounded-3xl font-bold px-5 py-2`}
              >
                <p>{data}</p>
                <MdKeyboardDoubleArrowRight className="text-xl" />
              </div>
            );
          })}
        </div>

        {/* Ombudsman Filter */}
        {selectedBucket.selectedSubBucket === "Partner Resend Cases" && (
          <div id="web-view" className="hidden md:flex flex-col md:flex-row gap-4 w-full md:w-auto items-start md:items-center">
            <div className="flex gap-3">
              <CiFilter className="text-[#FAAA52]" size={20} />
              <Typography>Filter By</Typography>
            </div>
            {/* <ReactSelect
              className="w-full md:w-56"
              options={nameOfStates.map((obj: any) => {
                return { name: obj };
              })}
              onChange={(option: any) => setOmbudsmanFilter((prev: any) => ({ ...prev, location: option.name }))}
              placeholder="Ombudsman Location"
              value={{ name: ombudsmanFilter.location }}
              isSearchable={false}
              getOptionLabel={(option: any) => option.name}
              getOptionValue={(option: any) => option.name}
            /> */}
            <ReactSelect
              className="w-full  md:w-56"
              options={ombudsmanStatus.map((obj: any) => {
                return { name: obj };
              })}
              placeholder="Ombudsman Status"
              onChange={(option: any) => setOmbudsmanFilter((prev: any) => ({ ...prev, status: option.name }))}
              value={{ name: ombudsmanFilter.status }}
              isSearchable={false}
              getOptionLabel={(option: any) => option.name}
              getOptionValue={(option: any) => option.name}
            />
          </div>
        )}
      </div>
    </Container>
  );
};

export default Section;
