import { FormikTouched, FormikValues } from "formik";
import React from "react";

export type InputProps = {
  label?: string;
  name?: string;
  placeholder?: string;
  type?: string;
  className?: string;
  onClick?: (event: React.MouseEvent) => void;
  disabled?: boolean;
  min?: string;
  limit?: number;
  options?: string[];
  formProps: {
    handleChange: {
      (e: React.ChangeEvent<any>): void;
      <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void;
    };
    errors: any;
    touched: FormikTouched<FormikValues>;
    handleBlur: {
      (e: React.FocusEvent<any, Element>): void;
      <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
    };
    values: FormikValues;
  };
};

const FormSelect: React.FC<InputProps> = ({ label, limit, name = "", options, formProps, ...rest }) => {
  const { handleChange, handleBlur, values, touched, errors } = formProps;

  return (
    <div>
      {label && (
        <label className="font-semibold" htmlFor={name}>
          {label}
        </label>
      )}
      <select
        {...rest}
        onChange={(e: any) => {
          if (limit && e.target.value.length > limit) return;
          handleChange(e);
        }}
        onBlur={handleBlur}
        name={name}
        value={values?.[name]}
        className=" appearance-none border focus:ring-2 focus:outline-none focus:ring-blue-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:shadow-outline">
        {options?.map((obj, i) => (
          <option key={i} value={obj}>
            {obj}
          </option>
        ))}
      </select>
      {touched?.[name] && errors?.[name] && <p className="mt-1 text-red-600 font-semibold text-sm">{errors?.[name]}</p>}
    </div>
  );
};

export default FormSelect;
