import React, { FC, useEffect, useState } from "react";
import Modal from "../../../../component/common/Modal";
import CloseIcon from "../../../../assets/icons/gallery/close.svg";
import dayjs from "dayjs";

interface ModalProps {
  viewContract: boolean;
  setContractModal: any;
  selectedComplaint: any;
}

const ViewContract: FC<ModalProps> = ({ viewContract, setContractModal, selectedComplaint }) => {
  const [serviceRate, setServiceRate] = useState(12);
  const contractDate = selectedComplaint.contractSignedAt && dayjs(selectedComplaint.contractSignedAt * 1000).format("dddd, MMMM D, YYYY h:mm a");
  useEffect(() => {
    const userDetails = selectedComplaint?.complaintCreatedBy || false;
    if (["b0a51a79-9d30-4a21-a1db-5cde586227ad", "c4bea3f5-6996-4405-8581-5ce68d48af19"].includes(selectedComplaint?.leadId)) {
      setServiceRate(9.6);
    } else if (selectedComplaint?.leadId == "917c2f86-cbd3-4485-a569-83e1dc552e55") {
      setServiceRate(7);
    } else if (userDetails && userDetails?.customerContractSignedRate) {
      setServiceRate(userDetails.customerContractSignedRate);
    }
  }, [selectedComplaint]);

  return (
    <Modal showModal={viewContract} onClose={() => setContractModal(false)} className="relative p-8 w-[40%] z-[99] ">
      <img src={CloseIcon} className="absolute text-3xl text-gray-400 right-[-15px] top-[-20px] cursor-pointer z-[999]" onClick={() => setContractModal(false)} />
      <section className="section leading-10 text-xl h-[60vh] overflow-scroll">
        <div className="container mx-auto">
          <div className="row flex justify-center">
            <div className="col-md-10 col-sm-12 mx-auto">
              <div>
                <div className="text-black">
                  <h1 className="font-bold text-3xl">CONTRACT TERMS</h1>
                  <p className="card-category">Please check below and submit the contract to proceed.</p>
                </div>
                <div className="shadow-all mt-6">
                  <h2 className="text-center font-bold text-3xl py-5 pb-2">
                    <span style={{ borderBottom: "7px solid green" }}>SERVICE AGREEMENT</span>
                  </h2>
                  <hr />
                  <div className="p-10">
                    <p>
                      This service agreement made between &ldquo;
                      <strong>{selectedComplaint.name ? selectedComplaint.name : ""}</strong>
                      &rdquo; (which expression shall unless repugnant to the context or meaning thereof include its successors, legal representatives and permitted assigns) of the <strong>First Part</strong>;
                    </p>
                    <p className="text-center">
                      <strong>AND</strong>
                    </p>
                    <p>
                      <strong>M/s Fastsurance Consultants Private Limited</strong>, having its registered office at R-104 Basement, Greater Kailash-1, Delhi – 110048, India hereinafter called as “<strong>The Consultant</strong>” or “<strong>Insurance Samadhan</strong>” (which expression shall unless repugnant to the context or meaning thereof include its associates, successors, legal representatives and permitted assigns) of the <strong>Second Part</strong>;
                    </p>
                    <p className="my-3">
                      “<strong>The Consultant</strong>” and “<strong>The Client</strong>” are jointly referred to as “Parties” and individually as “Party”.
                    </p>
                    <p className="my-3">
                      <strong>BY CLICKING THE ACCEPTANCE BUTTON, THE CLIENT EXPRESSLY AGREES TO AND CONSENTS TO BE BOUND BY ALL OF THE TERMS OF THIS AGREEMENT.</strong>
                    </p>
                    <p>
                      <strong>Whereas</strong>, the Consultant is inter alia engaged in the business of providing services of various nature in the field of Life, Health and General Insurance, such as assistance in resolving consumer grievances by representing Client at Ombudsman and Insurance Companies and representing at Consumer Forums/ Courts.
                    </p>
                    <p>The overall mission of Insurance Samadhan is centered towards social cause and the betterment/upliftment of the general impression of the insurance companies.</p>
                    <p className="my-4">
                      <strong>And Whereas</strong>, the client has approached the Consultant to avail the services of Insurance Samadhan.
                    </p>
                    <h3 className="my-3">NOW, IN CONSIDERATION OF THE ABOVE PREMISES AND MUTUAL COVENANTS CONTAINED HEREINAFTER THIS AGREEMENT WITNESSETH AS UNDER:</h3>

                    <ol className="list-decimal ml-6">
                      <li>
                        <strong>SERVICES:</strong> The Client has communicated its requirement to the Consultant and after understanding all the requirements of the client the Consultant has agreed to provide its services for resolving issues related to consumer grievance :
                        <ol className="p-4" style={{ listStyleType: "lower-alpha" }}>
                          <li>To draft application(s) to the company or concerned authorities for various issues.</li>
                          <li>To use legal and quasi legal methods and to draft all the necessary legal documents like affidavits, indemnity bonds, contracts, complaints, written submissions etc.</li>
                          <li>To send legal notices and the replies, and helping Client with all the steps which are necessary to be undertaken by the Client.</li>
                        </ol>
                      </li>
                      <li>
                        <strong>TERMS &amp; TERMINATION</strong>
                        <ol className="p-4" style={{ listStyleType: "lower-alpha" }}>
                          <li>The Consultant after discussing the merits of the case(s) can decide internally to proceed with the case or reject it without assigning any reason(s) thereto. In case of rejection, by the Consultant the Agreement shall be considered null and void.</li>
                          <li>This Agreement shall commence on the date of acceptance of this Click and Wrap agreement and shall remain in effect until the completion of the services. The Consultant shall be entitled to terminate this Agreement immediately, in the event if the consultant becomes aware of the fact that the client has provided forged/bogus/false documents or information thereby showing the wrong intention of the client to mislead the Consultant.</li>
                        </ol>
                      </li>
                      <li>
                        <strong>COMPENSATION</strong>
                        <ol className="p-4" style={{ listStyleType: "lower-alpha" }}>
                          <li>The Client hereby agrees and undertakes to pay {serviceRate}% + GST (herein referred to as &quot;Fees&quot;) of the aggregate amount within 7 (seven) days from the date the money is credited from Insurance companies in the bank account of the Client. The same is applicable in case of termination of policy and resulting in recovery of money.</li>
                          <strong>OR</strong>
                          <li>The Client hereby agrees that In cases, where the ombudsman or the Insurance companies offer relief through conversion into single premium policy, in that case the Consultant shall charge only 50% of Fee i.e {serviceRate / 2} + GST as applicable from time to time.</li>
                          <strong>OR</strong>
                          <li>
                            In case the Client decides not to pursue with the consultant at any time even after positive progress of the assignment and positive response from the companies, RTA or concerned authorities, the consultant shall be free to charge the proportionate fees linked with the progress as well as the efforts involved. The following shall be the fees structure in such case :
                            <ol className="list-decimal ml-6 p-4">
                              <li>Immediately after registration and initial progress : 25% of Fee</li>
                              <li>After registering the issue with Insurance company & IGMS: 50% of Fee</li>
                              <li>After registering the case with respective Ombudsman: 100% of the Fee</li>
                            </ol>
                          </li>
                          <em>The capitalized term Fee mentioned above i.e from I to III shall have the same meaning as defined in Clause 3(a) above.</em>
                          <li>The compensation shall be paid in terms of this Agreement between the Parties post the successful completion of service. The mode of payment shall be an advance cheque or through money transfer.</li>
                          <li>The Client hereby agrees and undertakes that in case the Consultant decides to approach any court of law, the consent of Client shall be obtained by the Consultant in advance and the Client shall be apprised of the related expenses in approaching the Court of law. The actual incidental expenses can be in the form of court fee, making demand draft, notary, outstation travel expenses etc. The same shall be apprised to the Client before approaching any Court or relevant forum The Client hereby agrees that if the payment is not received within the period of seven days, then the Client shall be liable to pay interest on the value of fees which accrue to the Consultant at the rate of 24% per annum.</li>
                        </ol>
                      </li>
                      <li>
                        <strong>UNDERTAKING &amp; INDEMNITY</strong>
                        <ol className="p-4" style={{ listStyleType: "lower-alpha" }}>
                          <li>The Client hereby agrees and undertakes that Client is a legal policy holder / nominee / assignee or authorized representative.</li>
                          <li>The client hereby agrees and undertakes that he or she shall sign all the documents required for the successful completion of the services.</li>
                          <li>The client hereby agrees and undertakes to authorize The Consultant to assist the client at various forums after the registration.</li>

                          <li>The Client hereby agrees to comply with legal formalities/procedures and all the communication to be done between client and companies/ RTAs/ or any other concerned authorities shall be routed through the Consultant.</li>
                          <li>The client hereby agrees and undertakes that the entire scenario of case has been explained by him to the consultant and the duration of completion of service by the consultant is not time bound. The Client undertakes to provide the signatures and the required documents like Pan Card, Aadhar, Address Proof, ITR etc. of the Surety/ Guarantor required for processing the case.</li>
                          <li>The Client hereby agrees to defend, indemnify and hold harmless the Consultant and its directors, officers, and employees with respect to a claim arising from the Client’s actual or alleged act, failure to act, error, or omission in the performance of their obligations under this Agreement or any governing law or regulation.</li>
                          <li>The Clients consents to the use of the reviews/testimonial given by him for the sole purpose of spreading awareness and further promotion of the Consultant business.</li>
                        </ol>
                      </li>
                      <li>
                        <strong>ENTIRE AGREEMENT: </strong>
                        This Agreement constitutes the complete agreement and sets forth the entire understanding and agreement of the parties as to the subject matter of this Agreement and supersedes all prior discussions and negotiations or business dealing, customary practice evolved any previous business between them and the parties shall not be bound by any conditions, definitions, warranties, representations and negotiations whether oral or written or made earlier to executions of this agreement.
                      </li>
                      <li>
                        <strong>MODIFICATION: </strong>
                        This Agreement shall not be subject to change or modification except by the execution of an instrument in writing by the Parities.
                      </li>
                      <li>
                        <strong>ACTIONABLE CLAIM: </strong>
                        The consultant holds the right of taking any legal action against the client in the manner as the consultant may deem fit, in case, the client does not pay the agreed fees in the stipulated time frame as mentioned in Clause 3 of this Agreement, in spite of a written request of the Consultant.
                      </li>
                      <li>
                        <strong>JURISDICTION: </strong>
                        The Courts at New Delhi shall have exclusive jurisdiction to try and adjudicate all suits, actions and proceedings arising out of this Agreement the cost of which shall be entirely borne by the client.
                      </li>
                    </ol>
                  </div>
                  <hr />
                  {selectedComplaint?.userId?.isContractSigned ? (
                    <div className="mx-10 py-5 pb-10">
                      <p className="text-[#5DBD83] text-4xl">SUCCESSFULLY SIGNED</p>
                      <p className="">
                        Name: <strong>{selectedComplaint.name}</strong>
                      </p>
                      <p className="">Signed on : {contractDate}</p>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="space"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Modal>
  );
};

export default ViewContract;
