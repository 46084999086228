import React, { FC } from "react";
import Modal from "../../../../component/common/Modal";
import Typography from "../../../../component/semantic/Typography";
import CloseIcon from "../../../../assets/icons/gallery/close.svg";
import ButtonOutline from "../../../../component/semantic/Button";
import { useAppDispatch } from "../../../../hooks/redux_toolkit";
import { sendContractThunk } from "../../../../store/thunk/AllComplaint.thunk";
import { toast } from "react-toastify";

interface ModalProps {
  confirmationModal: boolean;
  setConfirmationModal: any;
  leadData: any;
}

const SendContract: FC<ModalProps> = ({ setConfirmationModal, confirmationModal, leadData }) => {
  const dispatch = useAppDispatch();

  const submitHandler = () => {
    if (leadData) {
      dispatch(sendContractThunk(leadData?._id))
        .unwrap()
        .then((data: any) => {
          console.log(data);
        });
    } else {
      toast.error("No lead found");
    }
  };

  return (
    <Modal showModal={confirmationModal} onClose={() => setConfirmationModal(false)} className="relative w-[35%] p-8">
      <img src={CloseIcon} className="absolute text-3xl text-gray-400 right-[-15px] top-[-20px] cursor-pointer" onClick={() => setConfirmationModal(false)} />
      <Typography>Are you sure you want to send the Contract Email?</Typography>
      <div className="flex justify-between items-center w-full mt-6">
        <ButtonOutline className=" bg-[#1BA785] outline-[none]" type="button" title="YES" onClick={submitHandler} />
        <ButtonOutline className=" bg-[#FF3C3CED] outline-[none]" type="button" title="NO" onClick={() => setConfirmationModal(false)} />
      </div>
    </Modal>
  );
};

export default SendContract;
