import React, { FC } from "react";
import LifeIcon from "../../../../assets/icons/newDashboard/Life.svg";
import GeneralIcon from "../../../../assets/icons/newDashboard/General.svg";
import HealthIcon from "../../../../assets/icons/newDashboard/Health.svg";
import { useAppSelector } from "../../../../hooks/redux_toolkit";
import { formatRupees } from "../../../../helpers/utils";

interface TableProps {
  tableData: any;
}

const DashboardTable: FC<TableProps> = ({ tableData }) => {
  const loading = useAppSelector((state) => state.rootReducer.newDashboard.loadingTable);

  const skeleton = <span className="h-4 bg-gray-300 flex justify-center rounded-full dark:bg-gray-700 w-10 mb-2.5 animate-pulse">&nbsp;</span>;
  const skeletonAmount = <span className="h-4 bg-gray-300 flex justify-center rounded-full dark:bg-gray-700 w-16 mb-2.5 animate-pulse">&nbsp;</span>;

  return (
    <div
      className="overflow-x-scroll w-full mr-6"
      style={{
        scrollbarWidth: "thin",
        scrollbarColor: "#D7DBEC transparent",
        msOverflowStyle: "none",
        overflowX: "scroll",
        overflowY: "hidden",
        borderRadius: "16px"
      }}
    >
      <table className=" w-full border-r-2 border-b-2 overflow-scroll">
        <thead>
          <tr className=" bg-[rgb(226,245,254)] text-xl ">
            <th className="border-r-2 border-b-2 p-4">&nbsp;</th>
            <th colSpan={3} className="border-r-2 p-4 border-b-2">
              <div className="flex gap-2 justify-center">
                <img src={LifeIcon} />
                <span>LIFE INSURANCE</span>{" "}
              </div>
            </th>
            <th colSpan={3} className="border-r-2 p-4 border-b-2">
              <div className="flex gap-2 justify-center">
                <img src={GeneralIcon} />
                <span>GENERAL INSURANCE</span>
              </div>
            </th>
            <th colSpan={3} className="border-r-2 p-4 border-b-2">
              <div className="flex gap-2 justify-center">
                <img src={HealthIcon} />

                <span>HEALTH INSURANCE</span>
              </div>
            </th>
            <th colSpan={3} className="border-r-2 p-4 border-b-2">
              TOTAL
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="bg-[#e2f5fe] p-2 text-lg font-bold text-center border-b-2">
            <td className="p-2 w-10 text-center border-r-2 ">Status</td>
            <td className="px-2">Cases</td>
            <td className="w-10 px-2 whitespace-nowrap">Claim Amount</td>
            <td className="border-r-2 px-2">Customers</td>
            <td className="px-2 ">Cases</td>
            <td className="w-10 px-2 whitespace-nowrap">Claim Amount</td>
            <td className="border-r-2 px-2">Customers</td>
            <td className="px-2">Cases</td>
            <td className="w-10 px-2 whitespace-nowrap">Claim Amount</td>
            <td className="border-r-2 px-2">Customers</td>
            <td className="px-2">Cases</td>
            <td className="w-10 px-2 whitespace-nowrap">Claim Amount</td>
            <td className="px-2">Customers</td>
          </tr>

          {tableData &&
            tableData.map((obj: any, i: number) => {
              return (
                <tr className="text-center border-b-2" key={i}>
                  <td className="text-center  bg-[#e2f5fe] border-r-2 p-1 border-b-2 ">{obj?.status}</td>
                  <td className="font-bold">
                    <div className="flex justify-center items-center h-full">{loading ? skeleton : obj?.count?.caseLi}</div>
                  </td>
                  <td className="text-[#00AC4F] font-bold">
                    <div className="flex justify-center items-center h-full">{loading ? skeletonAmount : obj?.count?.claimCaseLi?.includes("₹") ? formatRupees(+obj?.count?.claimCaseLi.split("₹")[1]) : formatRupees(+obj?.count?.claimCaseLi)}</div>
                  </td>
                  <td className="font-bold border-r-2">
                    <div className="flex justify-center items-center h-full ">{loading ? skeleton : obj?.count?.allCustomerMonthLi}</div>
                  </td>
                  <td className="font-bold">
                    <div className="flex justify-center items-center h-full">{loading ? skeleton : obj?.count?.caseGi}</div>
                  </td>
                  <td className="text-[#00AC4F] font-bold">
                    <div className="flex justify-center items-center h-full">{loading ? skeletonAmount : obj?.count?.claimCaseGi.includes("₹") ? formatRupees(+obj?.count?.claimCaseGi.split("₹")[1]) : formatRupees(+obj?.count?.claimCaseGi)}</div>
                  </td>
                  <td className="font-bold border-r-2">
                    <div className="flex justify-center items-center h-full">{loading ? skeleton : obj?.count?.allCustomerMonthGi}</div>
                  </td>
                  <td className="font-bold">
                    <div className="flex justify-center items-center h-full">{loading ? skeleton : obj?.count?.caseHi}</div>
                  </td>
                  <td className="text-[#00AC4F] font-bold">
                    <div className="flex justify-center items-center h-full">{loading ? skeletonAmount : obj?.count?.claimCaseHi.includes("₹") ? formatRupees(+obj?.count?.claimCaseHi.split("₹")[1]) : formatRupees(+obj?.count?.claimCaseHi)}</div>
                  </td>
                  <td className="font-bold border-r-2">
                    <div className="flex justify-center items-center h-full">{loading ? skeleton : obj?.count?.allCustomerMonthHi}</div>
                  </td>
                  <td className="font-bold">
                    <div className="flex justify-center items-center h-full">{loading ? skeleton : obj?.count?.case}</div>
                  </td>
                  <td className="text-[#00AC4F] font-bold">
                    <div className="flex justify-center items-center h-full">{loading ? skeletonAmount : obj?.count?.claimCase.includes("₹") ? formatRupees(+obj?.count?.claimCase.split("₹")[1]) : formatRupees(+obj?.count?.claimCase)}</div>
                  </td>
                  <td className="font-bold">
                    <div className="flex justify-center items-center h-full">{loading ? skeleton : obj?.count?.allCustomerMonth}</div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default DashboardTable;
