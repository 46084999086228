import React, { useEffect, useState } from "react";
import Container from "../../component/common/Container";
import Typography from "../../component/semantic/Typography";
import { useFormik } from "formik";
import FormInput from "../../component/semantic/FormInput";
import { useAppDispatch, useAppSelector } from "../../hooks/redux_toolkit";
import { getAllAgent, getAllCompanyName, getAllComplaintsType, getAllInsuranceType, submitHospitalCaseThunk } from "../../store/thunk/HospitalCase.thunk";
import ReactSelect from "react-select";
import { errorStyles, polyfixSelect } from "../../helpers/constants/styles";
import FileUpload from "../../component/common/FileUpload";
import ButtonOutline from "../../component/semantic/Button";
import CSVIcon from "../../assets/icons/basic/csvIcon.svg";
import Divide from "../../component/semantic/Divide";
import { FaFile } from "react-icons/fa";
import { linkUrl } from "../../helpers/constants/defaultUrls";
import { hospitalCaseSchema } from "../../helpers/schemas/Hospital.Schema";

const policyType = [{ name: "Individual/Family Floater" }, { name: "Group Mediclaim" }];

interface caseTypes {
  hospitalName: string;
  companyId: string;
  policyTypeId: any;
  policyTypeName: string;
  isThisGroupCase: string | { name: string };
  companyName: string;
  assignToExpert: any;
  complaintTypeId: any;
  complaintName: string;
  creationSrc: string;
}

const initialValues: caseTypes = {
  hospitalName: "",
  complaintTypeId: "",
  policyTypeId: "",
  policyTypeName: "",
  companyId: "",
  companyName: "",
  complaintName: "",
  isThisGroupCase: "",
  assignToExpert: "",
  creationSrc: "partner-web"
};

const HospitalCase = () => {
  const [selectedCSV, setSelectedCSV] = useState<any>("");
  const [signedContract, setSignedContract] = useState<any>("");
  const [csvError, setCsvError] = useState(false);

  const dispatch = useAppDispatch();

  const csvUrlIndividual = linkUrl + "uploads/hospital-individual-policy-format.csv";
  const csvUrlGroup = linkUrl + "uploads/hospital-group-policy-format.csv";

  const complaintType = useAppSelector((state) => state.rootReducer.HospitalCase.complaintTypeData.data);
  const companyNames = useAppSelector((state) => state.rootReducer.HospitalCase.companyData.data);
  const insuranceType = useAppSelector((state) => state.rootReducer.HospitalCase.insuranceType.data);
  const agentData = useAppSelector((state) => state.rootReducer.HospitalCase.agentData.data);

  const { values, handleSubmit, handleBlur, handleChange, touched, errors, resetForm, setFieldValue }: any = useFormik({
    initialValues,
    validationSchema: hospitalCaseSchema,
    onSubmit: async (values: any) => {
      const formData = new FormData();
      if (selectedCSV) {
        formData.append("csvDoc", selectedCSV);
        setCsvError(false);
      } else return setCsvError(true);
      formData.append("contractFile", signedContract);
      values.complaintTypeId = values.complaintTypeId?._id;
      values.policyTypeId = values.policyTypeId?._id;
      values.companyId = values.companyId?._id;
      values.assignToExpert = values.assignToExpert?.user_id;
      values.isThisGroupCase = values.isThisGroupCase?.name === "Individual/Family Floater" ? false : true;
      for (const key in values) {
        if (Object.prototype.hasOwnProperty.call(values, key)) {
          formData.append(key, values[key]);
        }
      }
      dispatch(submitHospitalCaseThunk(formData));
      setSelectedCSV("");
      setSignedContract("");
      resetForm();
    }
  });

  useEffect(() => {
    dispatch(getAllInsuranceType());
  }, []);

  useEffect(() => {
    if (values.policyTypeId) {
      dispatch(getAllComplaintsType(values.policyTypeId?._id));
      dispatch(getAllCompanyName(values.policyTypeId?._id));
    }
    if (values.policyTypeName) dispatch(getAllAgent(values.policyTypeName));
  }, [values.policyTypeName, values.policyTypeId]);

  const formProps = { values, handleBlur, handleChange, touched, errors, setFieldValue };

  return (
    <Container>
      <Typography variant="h3">Add a Hospital Case</Typography>
      <Typography className="text-gray-400 mt-1">Submit the following details for adding a new hospital case</Typography>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-x-16 gap-y-4 mt-6">
        <FormInput name="hospitalName" placeholder="Hospital Name" label="Hospital Name *" formProps={formProps} />
        <span>
          <Typography className="font-normal">Insurance Type *</Typography>
          <ReactSelect
            options={insuranceType}
            name="complaintTypeId"
            placeholder="Select Insurance Type"
            getOptionLabel={(option: any) => option?.name}
            getOptionValue={(option: any) => option?._id}
            value={values.policyTypeId}
            onChange={(option: any) => {
              setFieldValue("policyTypeId", option);
              setFieldValue("policyTypeName", option?.name);
            }}
            onBlur={handleBlur}
            styles={errors?.policyTypeId && touched.policyTypeId ? errorStyles : polyfixSelect}
            isSearchable
          />
          {touched.policyTypeId && errors?.policyTypeId && <p className=" text-red-600 font-semibold text-sm">{errors?.policyTypeId}</p>}
        </span>{" "}
        <span>
          <Typography className="font-normal">Insurance Company Name *</Typography>
          <ReactSelect
            options={companyNames}
            name="complaintTypeId"
            placeholder="Select Insurance Company"
            getOptionLabel={(option: any) => option?.name}
            getOptionValue={(option: any) => option?._id}
            value={values.companyId}
            onChange={(option: any) => {
              setFieldValue("companyId", option);
              setFieldValue("companyName", option?.name);
            }}
            styles={errors?.companyId && touched.companyId ? errorStyles : polyfixSelect}
            onBlur={handleBlur}
            className="border-red-400"
            isSearchable
          />
          {touched.companyId && errors?.companyId && <p className=" text-red-600 font-semibold text-sm">{errors?.companyId}</p>}
        </span>{" "}
        <span>
          <Typography className="font-normal">Complaint Type *</Typography>
          <ReactSelect
            options={complaintType}
            name="complaintTypeId"
            placeholder="Select Complaint Type"
            getOptionLabel={(option: any) => option?.name}
            getOptionValue={(option: any) => option?._id}
            value={values.complaintTypeId}
            onChange={(option) => {
              setFieldValue("complaintTypeId", option);
              setFieldValue("complaintName", option?.name);
            }}
            styles={errors?.complaintTypeId && touched.complaintTypeId ? errorStyles : polyfixSelect}
            isSearchable
            onBlur={handleBlur}
          />
          {touched.complaintTypeId && errors?.complaintTypeId && <p className=" text-red-600 font-semibold text-sm">{errors?.complaintTypeId}</p>}
        </span>
        <span>
          <Typography className="font-normal">Policy Type *</Typography>
          <ReactSelect
            options={policyType}
            name="isThisGroupCase"
            placeholder="Select Policy Type"
            getOptionLabel={(option: any) => option?.name}
            getOptionValue={(option: any) => option?.name}
            value={values.isThisGroupCase}
            onChange={(option: any) => {
              setFieldValue("isThisGroupCase", option);
            }}
            styles={errors?.isThisGroupCase && touched.isThisGroupCase ? errorStyles : polyfixSelect}
            onBlur={handleBlur}
            isSearchable
          />
          {touched.isThisGroupCase && errors?.isThisGroupCase && <p className=" text-red-600 font-semibold text-sm">{errors?.isThisGroupCase}</p>}
        </span>{" "}
        <span>
          <Typography className="font-normal">Assign to Expert *</Typography>
          <ReactSelect
            options={agentData}
            name="assignToExpert"
            placeholder="Select Expert"
            getOptionLabel={(option: any) => option?.user_id}
            getOptionValue={(option: any) => option?._id}
            value={values.assignToExpert}
            onChange={(option) => {
              setFieldValue("assignToExpert", option);
            }}
            styles={errors?.assignToExpert && touched.assignToExpert ? errorStyles : polyfixSelect}
            onBlur={handleBlur}
            isSearchable
          />
          {touched.assignToExpert && errors?.assignToExpert && <p className=" text-red-600 font-semibold text-sm">{errors?.assignToExpert}</p>}
        </span>{" "}
        <span>
          <Typography className="font-normal mb-1">Upload CSV file *</Typography>
          <FileUpload id="csv" accept=".csv" setSelectedFile={setSelectedCSV} />
          {selectedCSV && (
            <div className="text-gray-700 bg-uploadTheme flex px-4 rounded-lg mt-2 py-1 gap-1 items-center">
              <FaFile className="text-gray-400 opacity-50" />
              {selectedCSV.name}
            </div>
          )}
          {selectedCSV == "" && csvError && <p className=" text-red-600 font-semibold text-sm mt-1">Please select csv file</p>}
        </span>
        <div>
          <Typography className="font-normal mb-1">Download CSV file format</Typography>
          <div className="flex flex-col md:flex-row justify-between gap-5">
            <a href={csvUrlIndividual} className="w-full">
              <ButtonOutline title="For Individual Policy" icon={<img src={CSVIcon} />} className={`hover:bg-[#00733B] bg-white text-[#00733B] border-2 border-[#00733B] w-full `} />
            </a>
            <a href={csvUrlGroup} className="w-full">
              <ButtonOutline title="For Group Policy" icon={<img src={CSVIcon} />} className={`hover:bg-[#00733B] bg-white text-[#00733B] border-2 border-[#00733B] w-full`} />
            </a>
          </div>
        </div>{" "}
        <span>
          <Typography className="font-normal mb-1">Upload Signed Contract</Typography>
          <FileUpload id="pdf" accept=".pdf" setSelectedFile={setSignedContract} />
          {signedContract && (
            <div className="text-gray-700 bg-uploadTheme flex w-auto mt-2 px-4 rounded-lg py-1 gap-1 items-center">
              <FaFile className="text-gray-400 opacity-50" />
              {signedContract.name}
            </div>
          )}
        </span>{" "}
      </div>
      <Divide className="mb-6 mt-8 -mx-4 lg:-mx-9 " />
      <div className="block md:flex flex-row-reverse">
        <ButtonOutline className={`bg-polyfixTheme  w-full `} onClick={handleSubmit} title="Submit Case" type="submit" />
      </div>
    </Container>
  );
};

export default HospitalCase;
