import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../helpers/axios";
import { baseUrl } from "../../helpers/constants/defaultUrls";

interface BarStatsPayload {
  leadStatus: string;
  claimStatus: string;
  users?: string[] | string;
}

interface CardDataPayload {
  from?: string;
  to?: string;
  users?: string[] | string;
}

export const dashboardCardStats = createAsyncThunk("dashboard/cardStats", async (payload: CardDataPayload, { rejectWithValue }) => {
  try {
    const response = await axios.post<{ data: any }>(baseUrl + `v1/user/dashboard/statsV3`, payload);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});

export const userList = createAsyncThunk("dashboard/userList", async () => {
  const response = await axios.get(baseUrl + "v1/user/userList");
  return response.data;
});

export const dashboardBarStats = createAsyncThunk("dashboard/barStats", async (payload: BarStatsPayload, { rejectWithValue }) => {
  try {
    const response = await axios.post<{ data: any }>(baseUrl + `v1/user/dashboard/getStatForGraphV3`, payload);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});
