import React from "react";
import Typography from "../../../component/semantic/Typography";
import dayjs from "dayjs";
import { useAppSelector } from "../../../hooks/redux_toolkit";
import { s3Url } from "../../../helpers/constants/defaultUrls";
import { FaFile } from "react-icons/fa6";

interface ChatBubbleProps {
  profilePic?: string;
  content?: any;
  selectedChat: any;
}

const ChatBubble: React.FC<ChatBubbleProps> = ({ profilePic, content, selectedChat }) => {
  const userData: any = useAppSelector((state) => state.rootReducer.common.userProfile.data);

  const profilePicUrl = s3Url + userData?._id + "/" + userData?.profilePic;

  const formattedDate: string = dayjs(content?.createdAt).format("hh:mm A");

  const fileDisplay = (content: any) => {
    const fileSrc = s3Url + content.senderId + "/" + content.file?.name;
    const isImage = ["image/png", "image/jpeg", "image/jpg"].includes(content?.file?.type);
    if (isImage) {
      return (
        <div className="p-2">
          <img src={fileSrc} className="rounded-lg " />
        </div>
      );
    } else {
      return (
        <a href={fileSrc} target="_blank" rel="noreferrer" className="flex it gap-2 text-polyfixTheme cursor-pointer">
          <FaFile className="mt-1 text-sm" />
          {content.file?.name}
        </a>
      );
    }
  };

  const sender = content.senderId == userData?._id ? true : false;
  return (
    <div className={` gap-3 items-end flex py-2 ${sender && "flex-row-reverse"}  `}>
      <img src={sender ? profilePicUrl : profilePic} className="h-8 w-8 rounded-lg" />
      <div className={`${sender ? "bg-[#333333] text-white" : "bg-[#F5F5F5]"}  max-w-96 rounded-lg py-2 px-3`}>
        <div className="flex gap-5 justify-between items-center">
          <Typography variant="h2">{sender ? userData?.name : selectedChat?.name}</Typography>
          <Typography className={`${sender ? "text-white" : "text-gray-500"}  text-xs`}>
            <p>{formattedDate}</p>
          </Typography>
        </div>
        {!content?.isMedia ? <Typography className={`${sender ? "text-white" : "text-gray-500"} mt-2`}>{content.message}</Typography> : fileDisplay(content)}
      </div>
    </div>
  );
};

export default ChatBubble;
