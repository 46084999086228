export const profileInitialValues = {
  _id: "",
  name: "",
  companyName: [],
  areaOfOperation: [],
  email: "",
  mobile: "",
  natureOfBusiness: "",
  address: "",
  stateName: "",
  districtName: "",
  cancelledCheque: "",
  agentCodeDoc: "",
  yearsOfExperience: 0,
  pincode: 0,
  otherCompanyName: "",
  gender: "",
  dob: "",
  gstNumber: "",
  panNumber: "",
  aadhaarNumber: "",
  alternateEmails: "",
  agentCode: "",
  bank: {},
  bankName: "",
  ifsc: "",
  accountNumber: "",
  branch: "",
  createdAt: "",
  userType: ""
};

export const personalInitialDetails = {
  _id: "",
  name: "",
  email: "",
  mobile: "",
  address: "",
  stateName: "",
  districtName: "",
  pincode: 0,
  gender: "",
  dob: "",
  aadhaarNumber: "",
  alternateEmails: "",
  createdAt: "",
  userType: ""
};

export const professionalDetails = {
  companyName: [],
  areaOfOperation: [],
  natureOfBusiness: "",
  cancelledCheque: "",
  agentCodeDoc: "",
  yearsOfExperience: 0,
  otherCompanyName: "",
  panNumber: "",
  agentCode: "",
  createdAt: "",
  userType: ""
};

export const bankDetails = {
  _id: "",
  cancelledCheque: "",
  gstNumber: "",
  bank: {},
  bankName: "",
  ifsc: "",
  accountNumber: "",
  branch: "",
  createdAt: "",
  userType: ""
};
