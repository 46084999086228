import React, { FC, useEffect } from "react";
import Modal from "../../../../component/common/Modal";
import CloseIcon from "../../../../assets/icons/gallery/close.svg";
import Typography from "../../../../component/semantic/Typography";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux_toolkit";
import { getLeadDataThunk } from "../../../../store/thunk/AllComplaint.thunk";
import pdfIcon from "../../../../assets/icons/allcomplaints/pdf.svg";
import { policyDocs } from "../../../../helpers/constants/constants";
interface ModalProps {
  docModal: boolean;
  setDocModal: any;
  selectedComplaint: any;
}

const ViewDocument: FC<ModalProps> = ({ docModal, setDocModal, selectedComplaint }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (selectedComplaint.leadId) {
      dispatch(getLeadDataThunk(selectedComplaint.leadId._id));
    }
  }, [selectedComplaint]);

  const leadData = useAppSelector((state) => state.rootReducer.allComplaint.leadData.data);

  const docDownloadHandler = (doc: string) => {
    const url = "https://staging-insa.s3.ap-south-1.amazonaws.com/upload/lead_docs/";
    const downloadUrl = url + leadData._id + "/" + doc;
    window.open(downloadUrl);
  };

  return (
    <Modal showModal={docModal} onClose={() => setDocModal(false)} className="relative w-1/2 p-8">
      <img src={CloseIcon} className="absolute text-3xl text-gray-400 right-[-15px] top-[-20px] cursor-pointer" onClick={() => setDocModal(false)} />
      <div>
        <Typography variant="h1">View Documents</Typography>
        <Typography className="mt-3 font-extrabold">View your uploaded documents</Typography>

        <div className="custom-polifyx-scrollbar grid grid-cols-1  lg:grid-cols-2 mt-4  gap-6 max-h-[40vh] overflow-y-scroll w-full px-4">
          {leadData?.doc &&
            leadData?.doc.map((doc: any, i: number) => {
              const val = doc.split("_")[0];
              let docName = "";
              policyDocs.forEach((i: any) => {
                if (i.value === val) docName = i.name;
              });
              return (
                <div key={i} className="flex gap-1 flex-col flex-wrap">
                  <Typography>{docName}</Typography>
                  <div onClick={() => docDownloadHandler(doc)} className="underline bg-uploadTheme flex px-4 rounded-lg py-1 gap-2 cursor-pointer items-center">
                    <img src={pdfIcon} />
                    <Typography className="text-xs text-gray-600">{doc}</Typography>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </Modal>
  );
};

export default ViewDocument;
