import React from "react";

interface DivideProps {
  horizontal?: boolean;
  className?: string;
}

const Divide: React.FC<DivideProps> = ({ horizontal, className }) => {
  return <div className={`transition duration-500 ${horizontal ? "border-r-2  border-r-gray-200" : "border-t-2 border-t-gray-200"} ${className}`}></div>;
};

export default Divide;
