import * as Yup from "yup";

export const addCompanySchema = Yup.object({
  address: Yup.string()
    .matches(/^\S.*$/, "Address cannot start with a space")
    .required("Please enter address"),
  companyEmail: Yup.string()
    .test("isValidEmails", "Please enter valid email addresses separated by commas!", async (value) => {
      if (!value) return true;
      const emailList = value.split(",").map((email) => email.trim());
      for (let i = 0; i < emailList.length; i++) {
        const isValidEmail = await Yup.string()
          .matches(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/)
          .isValid(emailList[i]);
        if (!isValidEmail) {
          return false;
        }
      }
      return true;
    })
    .trim()
    .required("Please enter company email "),
  companyLevel1Email: Yup.string()
    .test("isValidEmails", "Please enter valid email addresses separated by commas!", async (value) => {
      if (!value) return true;
      const emailList = value.split(",").map((email) => email.trim());
      for (let i = 0; i < emailList.length; i++) {
        const isValidEmail = await Yup.string()
          .matches(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/)
          .isValid(emailList[i]);
        if (!isValidEmail) {
          return false;
        }
      }
      return true;
    })
    .trim()
    .required("Please enter level 1 email"),
  name: Yup.string()
    .min(6, "Name must be greater than 5 characters")
    .matches(/^\S.*$/, "Name cannot start with a space")
    .required("Please enter company name"),
  policyType: Yup.object().required("Option is required")
});
