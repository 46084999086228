export const isAuthenticatedUser = () => {
  const tokenString = localStorage.getItem("userInfo");

  if (tokenString) {
    const userInfo = JSON.parse(tokenString);
    if (userInfo && typeof userInfo === "object" && "token" in userInfo) {
      return true;
    }
  }
  return false;
};
