export const customStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected ? "white" : state.isFocused ? "#E5E5E5" : "transparent",
    color: state.isSelected && "black",
    borderRadius: "8px",
    marginInline: "5px",
    paddingBlock: "3px",
    width: "95%",
    ":active": {
      ...provided[":active"],
      backgroundColor: state.isSelected && "white"
    }
  }),
  multiValueRemove: (provided: any) => ({
    ...provided,
    display: "none"
  }),
  multiValue: (provided: any) => ({
    ...provided,
    backgroundColor: "white",
    fontWeight: "bold"
  }),
  placeholder: (provided: any) => ({
    ...provided,
    fontWeight: "bold",
    color: "black"
  })
};
export const customMultiValue = {
  multiValueRemove: (provided: any) => ({
    ...provided,
    display: "none"
  }),
  multiValue: (provided: any) => ({
    ...provided,
    backgroundColor: "white",
    fontWeight: "bold"
  })
};

export const filterStyles = {
  control: (provided: any) => ({
    ...provided,
    fontWeight: "bold"
  })
};

export const polyfixSelect = {
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#FAAA52" : "white" && (state.isFocused || state.data.select) ? "#F8D9B8" : "white",
    color: state.isSelected ? "white" : "black",
    borderRadius: "8px",
    marginInline: "2%",
    width: "96%",
    marginBottom: "2px",
    cursor: "pointer",
    ":active": {
      ...provided[":active"],
      backgroundColor: state.isSelected ? "#FAAA52" : "#FAAA52"
    }
  }),
  control: (provided: any) => ({
    ...provided,
    overflow: "hidden",
    height: "2.2rem"
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    position: "relative",
    height: "2.2rem",
    top: "1%"
  })
};

export const errorStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    borderColor: "red",
    boxShadow: state.isFocused ? "0 0 0 1px red" : provided.boxShadow,
    "&:hover": {
      borderColor: "red"
    }
  })
};
