import React, { FC } from "react";
import Typography from "../../../../component/semantic/Typography";
import { useAppSelector } from "../../../../hooks/redux_toolkit";
import { DashboardInsuranceConstants } from "../../../../helpers/constants/constants";
import { formatRupees } from "../../../../helpers/utils";

interface InsuranceProps {
  statsData: any;
  selectedStatus: string;
}

const InsuranceStats: FC<InsuranceProps> = ({ statsData, selectedStatus }) => {
  const loadingState = useAppSelector((state) => state.rootReducer.newDashboard.loadingInsurance);

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
      {DashboardInsuranceConstants.map((obj: any, index: number) => {
        return (
          <div key={index} className={`${obj.bg} w-full space-y-4 p-7 rounded-lg`}>
            <div className="flex gap-3">
              {obj?.icon && <img src={obj?.icon} />}
              <p className="text-xl font-bold">{obj.name}</p>
            </div>
            <div className="flex  md:flex-col gap-2 md:gap-0 flex-wrap md:flex-nowrap">
              {obj.section?.[selectedStatus].map((names: any) => {
                return (
                  <>
                    {names.amount ? (
                      loadingState ? (
                        <>
                          <div className="h-4 w-24 bg-gray-400 rounded-full dark:bg-gray-700 mb-2.5 animate-pulse"></div>
                          <div className="h-4 w-12 bg-gray-400 rounded-full dark:bg-gray-700 mb-2.5 animate-pulse"></div>
                        </>
                      ) : (
                        <>
                          <Typography>{names.title}&nbsp;</Typography>
                          <Typography variant="h2" className="text-green-500 ">
                            {statsData?.[names.key]?.includes("₹") ? formatRupees(+statsData?.[names.key].split("₹")[1]) : `₹ ${statsData?.[names.key]}` || 0}
                          </Typography>
                        </>
                      )
                    ) : loadingState ? (
                      <div className="h-4 w-[70%] bg-gray-400 rounded-full dark:bg-gray-700 mb-2.5 animate-pulse"></div>
                    ) : (
                      <Typography>
                        {names.title}
                        <span className="font-extrabold">{statsData?.[names.key] || 0}</span>
                      </Typography>
                    )}
                  </>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default InsuranceStats;
