import React from "react";
import Typography from "../../../component/semantic/Typography";
import Image from "../../../assets/icons/home/polifyxAppScreen.svg";
import PolifyxImg from "../../../assets/icons/home/polifyxApp.svg";
import CheckedIcon from "../../../assets/icons/home/checkedIcon.svg";
import MonitorICon from "../../../assets/icons/home/monitorIcon.svg";
import DocumentIcon from "../../../assets/icons/home/documentIcon.svg";
import GalleryIcon from "../../../assets/icons/home/galleryIcon.svg";
import PlayStoreIcon from "../../../assets/icons/home/playStoreIcon.svg";
import AppStoreIcon from "../../../assets/icons/home/appStoreIcon.svg";
import { appLink } from "../../../helpers/constants/defaultUrls";

const PolifyxAppRef = ({ polyfixRef }: any) => {
  return (
    <div ref={polyfixRef} className="flex flex-col md:grid bg-[url('/src/assets/icons/home/polifyxAppBG.svg')] px-20 pt-10 overflow-hidden grid-cols-2 gap-4 relative min-h-[30px] bg-cover pb-10">
      <div className="flex justify-between ">
        <div className="w-[70%]  absolute top-[50px] md:bottom-0">
          <img alt="image" src={Image} />
        </div>
        <div className="ml-auto">
          <img className="w-[70%] ml-auto" alt="polifyx-image" src={PolifyxImg} />
        </div>
      </div>
      <div className="">
        <div>
          <Typography className="text-5xl">Download Polifyx Partner App Today!</Typography>
        </div>
        <div className="grid grid-cols-2 my-6">
          <div className="flex gap-x-4 mt-6">
            <img alt="check icon" src={CheckedIcon} className="w-[10%] mb-auto" />
            <div className="w-[60%]">
              <Typography className="">Track status real-time on our app</Typography>
            </div>
          </div>
          <div className="flex gap-x-4 mt-6">
            <img alt="document icon" src={DocumentIcon} className="w-[10%] mb-auto" />
            <div className="w-[60%]">
              <Typography className="">Monitor your monthly earnings </Typography>
            </div>
          </div>
          <div className="flex gap-x-4 mt-6">
            <img alt="icon" src={MonitorICon} className="w-[10%] mb-auto" />
            <div className="w-[60%]">
              <Typography className="">Add cases and upload documents easily</Typography>
            </div>
          </div>
          <div className="flex gap-x-4 mt-6">
            <img alt="gallery icon" src={GalleryIcon} className="w-[10%] mb-auto" />
            <div className="w-[70%]">
              <Typography className="">Marketing templates designed by your name in Gallery section</Typography>
            </div>
          </div>
        </div>

        <div className="flex gap-x-6 z-10 relative mb-10">
          <img className="cursor-pointer absolute top-0 left-0" onClick={() => window.open(appLink.get("playstore"), "_blank")} alt="playStoreIcon" src={PlayStoreIcon} />
          <img className="cursor-pointer absolute top-0 left-36" onClick={() => window.open(appLink.get("ios"), "_blank")} src={AppStoreIcon} alt="App store Icon" />
        </div>
      </div>
    </div>
  );
};

export default PolifyxAppRef;
