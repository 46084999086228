import React from "react";
import { MdOutlineMailOutline, MdOutlinePhone } from "react-icons/md";
import SharkTankThumbnail from "../../../assets/icons/dashboard/Shark Tank V2.svg";

function Header() {
  return (
    <div className=" bg-black px-2 md:px-[9%] w-full flex items-center justify-between">
      <ul className="hidden md:flex w-full md:w-[70%] text-white">
        <li className="mr-4">
          <a href="mailto:corporate@insurancesamadhan.com" className="flex items-center">
            <div className="mr-1">
              <MdOutlineMailOutline className="text-lg" />
            </div>
            corporate@insurancesamadhan.com
          </a>
        </li>
        <li className="mr-4">
          <a href="tel:9513631312" className="flex items-center">
            <div className="mr-1">
              <MdOutlinePhone className="text-lg" />
            </div>
            95136-31312
          </a>
        </li>
        {/* <li>
          <div onClick={() => window.open("https://api.whatsapp.com/send?phone=+919910998252&text=Hi")} className="flex cursor-pointer items-center">
            <div>
              <img width={"24px"} height={"24px"} alt="whatsApp icon" src={WhatsAppIcon} />
            </div>
            99109-98252
          </div>
        </li> */}
      </ul>
      <div className="flex  w-[100%] md:w-auto justify-between">
        <img className="cursor-pointer" onClick={() => window.open("https://www.youtube.com/watch?v=e-NwnZvX4ns&ab_channel=SETIndia")} alt="shark-tank-thumbnail" src={SharkTankThumbnail} />
        {/* <div className="flex ml-2">
                    <img alt="google-translator-icon" src={GoogleTranslatorIcon} width={"20px"} height={"20px"} />
                    <select className="bg-inherit text-white outline-none">
                        <option>English</option>
                        <option>Hindi</option>
                    </select>
                </div> */}
      </div>
    </div>
  );
}

export default Header;
