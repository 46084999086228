import { createSlice } from "@reduxjs/toolkit";
import { createLeadWithoutTokenThunk, getAllAgentsThunk, getAllLeadThunk, leadCommentHistoryThunk, searchLeadCustomerThunk, searchLeadPartnerThunk } from "../thunk/AllLead.thunk";
import { toast } from "react-toastify";

interface responseData {
  data: any;
}

interface ApiState {
  data: any;
  loading: boolean;
  error: string | null;
  leadData: responseData;
  commentHistory: responseData;
  agentData: responseData;
  submitLoaderWithoutToken: boolean;
}

const initialState: ApiState = {
  data: null,
  loading: false,
  error: null,
  leadData: { data: { data: "" } },
  commentHistory: { data: "" },
  agentData: { data: [] },
  submitLoaderWithoutToken: false
};

const allLeadSlice = createSlice({
  name: "allLeadSlice",
  initialState,
  reducers: {
    setSortedDataLead: (state, action) => {
      state.leadData.data.data = [...action.payload];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllLeadThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllLeadThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.leadData = action.payload;
        state.error = null;
      })
      .addCase(getAllLeadThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "An error occurred";
      })

      .addCase(searchLeadCustomerThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(searchLeadCustomerThunk.fulfilled, (state, action: any) => {
        toast.success("Lead fetched Successfully");
        state.loading = false;
        state.leadData = action.payload.data;
        state.error = null;
      })
      .addCase(searchLeadCustomerThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "An error occurred";
      })

      .addCase(searchLeadPartnerThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(searchLeadPartnerThunk.fulfilled, (state, action) => {
        toast.success("Lead fetched Successfully");
        state.loading = false;
        state.leadData = action.payload.data;
        state.error = null;
      })
      .addCase(searchLeadPartnerThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "An error occurred";
      })

      .addCase(leadCommentHistoryThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(leadCommentHistoryThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.commentHistory = action.payload.data;
        state.error = null;
      })
      .addCase(leadCommentHistoryThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "An error occurred";
      })

      .addCase(getAllAgentsThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllAgentsThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.agentData = action.payload.data;
        state.error = null;
      })
      .addCase(getAllAgentsThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "An error occurred";
      })

      .addCase(createLeadWithoutTokenThunk.pending, (state) => {
        state.submitLoaderWithoutToken = true;
      })
      .addCase(createLeadWithoutTokenThunk.fulfilled, (state) => {
        state.submitLoaderWithoutToken = false;
        state.error = null;
      })
      .addCase(createLeadWithoutTokenThunk.rejected, (state, action) => {
        state.submitLoaderWithoutToken = false;
        state.error = action.error.message ?? "An error occurred";
      });
  }
});
export const { setSortedDataLead } = allLeadSlice.actions;

export default allLeadSlice.reducer;
