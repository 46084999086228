const env = process.env.REACT_APP_ENV || "local";

let apiUrl;
let isApiUrl;
let referralLink;
let s3URL;
// let socketUrl;
switch (env) {
  case "dev":
    apiUrl = "https://partnersapi.dev.insurancesamadhan.com/";
    isApiUrl = "https://api.dev.insurancesamadhan.com";
    referralLink = "https://partnersadmin.dev.insurancesamadhan.com/auth/signup/";
    s3URL = "https://insa-partners-stage.s3.ap-south-1.amazonaws.com/uploads/";
    // socketUrl = "https://partnersapi.dev.insurancesamadhan.com/";
    break;
  case "stage":
    apiUrl = "https://partnersapi.stage.insurancesamadhan.com/";
    isApiUrl = "https://api.stage.insurancesamadhan.com";
    referralLink = "https://partnersadmin.stage.insurancesamadhan.com/auth/signup/";
    s3URL = "https://insa-partners-stage.s3.ap-south-1.amazonaws.com/uploads/";
    // socketUrl = "https://partnersapi.stage.insurancesamadhan.com";

    break;
  case "prod":
    apiUrl = "https://partnersapi.insurancesamadhan.com/";
    isApiUrl = "https://api.insurancesamadhan.com";
    referralLink = "https://partnersadmin.insurancesamadhan.com/auth/signup/";
    s3URL = "https://insa-partners-prod.s3.ap-south-1.amazonaws.com/uploads/";
    // socketUrl = "https://partnersapi.insurancesamadhan.com";
    break;
  default:
    apiUrl = "http://localhost:3500/";
    isApiUrl = "http://localhost:3500";
    referralLink = "http://localhost:3000/auth/signup/";
    s3URL = "https://insa-partners-stage.s3.ap-south-1.amazonaws.com/uploads/";
}

export const baseUrl = apiUrl + "api/";
export const linkUrl = apiUrl;
export const backendUrl = isApiUrl;
export const referralUrl = referralLink;
export const socketIOUrl = apiUrl;
export const s3Url = s3URL;

// export const baseUrl = "http://localhost:3500/api/";
//  export const baseUrl = "http://192.168.29.94:3500/api/"

export const appLink: Map<string, string> = new Map([
  ["playstore", "https://play.google.com/store/apps/details?id=com.partner.polifyx&hl=en_IN&gl=US"],
  ["ios", "https://apps.apple.com/in/app/polifyx-partner/id6443657308"]
]);

export const polyfixAppLink: Map<string, string> = new Map([
  ["playstore", "https://play.google.com/store/apps/details?id=com.insurancesamadhan.polifyx"],
  ["ios", "https://apps.apple.com/in/app/polifyx/id1623606564"]
]);

export const referralLinkBase = "https://partnersadmin/partner-join/referral-link/polifyparterrjoin/";

export const appCommonLink = "https://insurancesamadhan.page.link/mVFa";

export const stagingUrl = "https://staging-insa.s3.ap-south-1.amazonaws.com/upload/";

// export const socketIOUrl = "https://partnersapi.dev.insurancesamadhan.com";
// export const socketIOUrl = "http://localhost:3999/"
