import InsaLogo from "../../assets/images/insaLogoWithoutName.svg";
import AllComplaints from "../../assets/icons/dashboard/Icon.AllComplaints.svg";
import AllLeads from "../../assets/icons/dashboard/Icon.AllLeads.svg";
import LeadCost from "../../assets/icons/dashboard/Icon.LeadCost.svg";
import NewCases from "../../assets/icons/dashboard/Icon.NewCases.svg";
import Requirement from "../../assets/icons/dashboard/Icon.Requirement.svg";
import Dashboard from "../../assets/icons/dashboard/Icon.Dashboard.svg";
import Report from "../../assets/icons/dashboard/Icon.Reports.svg";
import UserManagement from "../../assets/icons/dashboard/Icon.UserManagement.svg";
import ResolvedCases from "../../assets/icons/dashboard/Icon.ResolvedCases.svg";
import ContractHistory from "../../assets/icons/dashboard/Icon.ContractHistory.svg";
import DashboardActive from "../../assets/icons/dashboard/Icon.DashboardActive.svg";
import RequirementActive from "../../assets/icons/dashboard/Icon.RequirementActive.svg";
import React from "react";
import password from "../../assets/icons/dashboard/Icon.password.svg";
import refer from "../../assets/icons/dashboard/Icon.refer.svg";
import referActive from "../../assets/icons/dashboard/Icon.referActive.svg";
import gallery from "../../assets/icons/dashboard/Icon.gallery.svg";
import profile from "../../assets/icons/dashboard/Icon.profile.svg";
import profileActive from "../../assets/icons/dashboard/Icon.profileActive.svg";
import chat from "../../assets/icons/dashboard/Icon.chat.svg";
import query from "../../assets/icons/dashboard/Icon.query.svg";
import ReportActive from "../../assets/icons/dashboard/Icon.ActiveReport.svg";
import ChatActive from "../../assets/icons/dashboard/Icon.ChatActive.svg";
import GalleryActive from "../../assets/icons/dashboard/Icon.galleryActive.svg";
import NewCaseActive from "../../assets/icons/dashboard/Icon.NewCaseActive.svg";
import ResolveCaseActive from "../../assets/icons/dashboard/Icon.ResolveCaseActive.svg";
import FAQActive from "../../assets/icons/dashboard/Icon.FAQActive.svg";
import PasswordActive from "../../assets/icons/dashboard/Icon.PasswordActive.svg";
import AllLeadActive from "../../assets/icons/dashboard/Icon.AllLeadActive.svg";
import userManagementActive from "../../assets/icons/dashboard/Icon.userManagementActive.svg";
import PartnerIcon from "../../assets/icons/dashboard/Icon.partnerManagement.svg";
import PartnerActive from "../../assets/icons/dashboard/Icon.partnerActive.svg";
import NewDashboard from "../../assets/icons/dashboard/Icon.NewDashboard.svg";
import NewDashboardActive from "../../assets/icons/dashboard/Icon.NewDashboardActive.svg";
import AllUsers from "../../assets/icons/dashboard/Icon.AllUser.svg";
import AllUsersActive from "../../assets/icons/dashboard/Icon.AllUserActive.svg";
import PayoutMarking from "../../assets/icons/dashboard/Icon.Payout.svg";
import PayoutMarkingActive from "../../assets/icons/dashboard/Icon.PayoutActive.svg";
import Company from "../../assets/icons/dashboard/Icon.company.svg";
import CompanyActive from "../../assets/icons/dashboard/Icon.companyActive.svg";
import LeadCostActive from "../../assets/icons/dashboard/leadCostActive.svg";
import { PERMISSIONS } from "./constants";

interface RouteItem {
  name: string;
  icon: string;
  activeIcon?: string;
  route: string;
  authorizationRequired?: Array<any>;
  renderToAll?: boolean;
}
interface RouteItem2 {
  name: string;
  icon: React.ReactNode;
  route: string;
  activeIcon?: React.ReactNode;
  authorizationRequired?: Array<any>;
  renderToAll?: boolean;
}

export const routesMain: RouteItem[] = [
  {
    name: "Dashboard",
    renderToAll: true,
    icon: Dashboard,
    activeIcon: DashboardActive,
    route: "/dashboard"
  },
  {
    name: "New Dashboard",
    authorizationRequired: [PERMISSIONS.ADMIN],
    icon: NewDashboard,
    activeIcon: NewDashboardActive,
    route: "/newDashboard"
  },
  {
    name: "Requirement List",
    authorizationRequired: [PERMISSIONS.ADMIN, PERMISSIONS.PARTNER, PERMISSIONS.ASSOCIATE, PERMISSIONS.ADVISOR],
    icon: Requirement,
    activeIcon: RequirementActive,
    route: "/requirement"
  },
  {
    name: "New Case",
    authorizationRequired: [PERMISSIONS.ADMIN, PERMISSIONS.PARTNER, PERMISSIONS.ASSOCIATE, PERMISSIONS.ADVISOR],
    icon: NewCases,
    activeIcon: NewCaseActive,
    route: "/newCase"
  },
  // {
  //   name: "New Hospital Case",
  //   authorizationRequired: [PERMISSIONS.ADMIN, PERMISSIONS.PARTNER, PERMISSIONS.ASSOCIATE, PERMISSIONS.ADVISOR],
  //   icon: Hospital,
  //   activeIcon: HospitalActive,
  //   route: "/newHospitalCase"
  // },
  {
    name: "All Complaints",
    authorizationRequired: [PERMISSIONS.ADMIN, PERMISSIONS.PARTNER, PERMISSIONS.ASSOCIATE, PERMISSIONS.ADVISOR],
    icon: AllComplaints,
    activeIcon: NewCaseActive,
    route: "/allComplaints"
  },
  {
    name: "All Leads",
    authorizationRequired: [PERMISSIONS.ADMIN, PERMISSIONS.PARTNER, PERMISSIONS.ASSOCIATE, PERMISSIONS.ADVISOR],
    icon: AllLeads,
    activeIcon: AllLeadActive,
    route: "/allLeads"
  },
  {
    name: "All Users",
    authorizationRequired: [PERMISSIONS.ADMIN],
    icon: AllUsers,
    activeIcon: AllUsersActive,
    route: "/allUsers"
  },
  {
    name: "Lead Cost",
    authorizationRequired: [PERMISSIONS.ADMIN],
    activeIcon: LeadCostActive,
    icon: LeadCost,
    route: "/leadCost"
  },
  {
    name: "Payout Marking",
    authorizationRequired: [PERMISSIONS.ADMIN, PERMISSIONS.PARTNER, PERMISSIONS.ASSOCIATE, PERMISSIONS.ADVISOR],
    activeIcon: PayoutMarkingActive,
    icon: PayoutMarking,
    route: "/payoutMarking"
  },
  {
    name: "Resolved Cases",
    authorizationRequired: [PERMISSIONS.ADMIN, PERMISSIONS.PARTNER, PERMISSIONS.ASSOCIATE, PERMISSIONS.ADVISOR],
    icon: ResolvedCases,
    activeIcon: ResolveCaseActive,
    route: "/resolvedCases"
  },
  // {
  //   name: "All Issues",
  //   authorizationRequired: [PERMISSIONS.ADMIN, PERMISSIONS.PARTNER, PERMISSIONS.ASSOCIATE, PERMISSIONS.ADVISOR],
  //   icon: AllIssues,
  //   route: "/allIssues"
  // },
  {
    name: "Reports",
    authorizationRequired: [PERMISSIONS.ADMIN, PERMISSIONS.PARTNER, PERMISSIONS.ASSOCIATE, PERMISSIONS.ADVISOR],
    icon: Report,
    activeIcon: ReportActive,
    route: "/reports"
  },
  {
    name: "User Management",
    authorizationRequired: [PERMISSIONS.PARTNER, PERMISSIONS.ASSOCIATE, PERMISSIONS.ADVISOR],
    icon: UserManagement,
    activeIcon: userManagementActive,
    route: "/userManagement"
  },
  {
    name: "Partner Management",
    authorizationRequired: [PERMISSIONS.ADMIN],
    icon: PartnerIcon,
    activeIcon: PartnerActive,
    route: "/partnerManagement"
  },
  {
    name: "Company",
    authorizationRequired: [PERMISSIONS.ADMIN],
    icon: Company,
    activeIcon: CompanyActive,
    route: "/company"
  },
  // {
  //   name: "Contract",
  //   authorizationRequired: [PERMISSIONS.ADMIN, PERMISSIONS.PARTNER, PERMISSIONS.ASSOCIATE, PERMISSIONS.ADVISOR],
  //   icon: ContractHistory,
  //   route: "/contract"
  // },
  {
    name: "App Registered Initial User",
    authorizationRequired: [PERMISSIONS.ADMIN],
    icon: ContractHistory,
    activeIcon: PartnerActive,
    route: "/appInitialUser"
  },
  {
    name: "Pending Contract",
    authorizationRequired: [PERMISSIONS.ADMIN],
    icon: ContractHistory,
    activeIcon: PartnerActive,
    route: "/pendingContract"
  },
  {
    name: "Executive-partner Mapping",
    authorizationRequired: [PERMISSIONS.ADMIN],
    icon: AllUsers,
    activeIcon: AllUsersActive,
    route: "/executive-partner-mapping"
  }
  // {
  //   name: "Bulk Action",
  //   authorizationRequired: [PERMISSIONS.ADMIN],
  //   icon: BulkAction,
  //   activeIcon: BulkActionActive,
  //   route: "/bulkAction"
  // }
  // {
  //     name: 'Contract History',
  //     icon: AllIssues,
  //     route: '/contractHistory'
  // },
];

export const routesFooter: RouteItem2[] = [
  {
    name: "Profile",
    authorizationRequired: [PERMISSIONS.ADMIN, PERMISSIONS.PARTNER, PERMISSIONS.ASSOCIATE, PERMISSIONS.ADVISOR],
    icon: <img src={profile} />,
    route: "/profile",
    activeIcon: <img src={profileActive} />
  },
  {
    name: "Change Password",
    authorizationRequired: [PERMISSIONS.ADMIN, PERMISSIONS.PARTNER, PERMISSIONS.ASSOCIATE, PERMISSIONS.ADVISOR],
    icon: <img src={password} />,
    activeIcon: <img src={PasswordActive} />,
    route: "/changePassword"
  },
  {
    name: "Referral",
    authorizationRequired: [PERMISSIONS.PARTNER, PERMISSIONS.ASSOCIATE],
    icon: <img src={refer} />,
    route: "/referral",
    activeIcon: <img src={referActive} />
  },
  {
    name: "Chat",
    authorizationRequired: [PERMISSIONS.ADMIN, PERMISSIONS.PARTNER, PERMISSIONS.ASSOCIATE, PERMISSIONS.ADVISOR],
    icon: <img src={chat} />,
    activeIcon: <img src={ChatActive} />,
    route: "/chat"
  },
  {
    name: "Gallery",
    authorizationRequired: [PERMISSIONS.ADMIN, PERMISSIONS.PARTNER, PERMISSIONS.ASSOCIATE, PERMISSIONS.ADVISOR],
    icon: <img src={gallery} />,
    activeIcon: <img src={GalleryActive} />,
    route: "/gallery"
  },
  {
    name: "FAQ's",
    authorizationRequired: [PERMISSIONS.ADMIN, PERMISSIONS.PARTNER, PERMISSIONS.ASSOCIATE, PERMISSIONS.ADVISOR],
    icon: <img src={query} />,
    activeIcon: <img src={FAQActive} />,
    route: "/faq"
  },
  {
    name: "Visit Share Samadhan",
    authorizationRequired: [PERMISSIONS.ADMIN, PERMISSIONS.PARTNER, PERMISSIONS.ASSOCIATE, PERMISSIONS.ADVISOR],
    icon: <img src={InsaLogo} className="w-6 h-6" alt="insurance samadhan" />,
    route: "https://www.dev.insurancesamadhan.com/"
  }
];
