import React from "react";
import Typography from "../../../../component/semantic/Typography";
import { legalDashboardConstants } from "../../../../helpers/constants/constants";
import LegalSkeleton from "../skeletons/legal.skeleton";
import { useAppSelector } from "../../../../hooks/redux_toolkit";
import { formatRupees } from "../../../../helpers/utils";

const LegalStats = () => {
  const statsData = useAppSelector((state) => state.rootReducer.newDashboard);

  return (
    <div className="flex gap-8 flex-wrap">
      {legalDashboardConstants.map((obj: { name: string; amount: boolean; key: string }, index: number) => {
        return statsData.loading ? (
          <LegalSkeleton key={index} />
        ) : (
          <div key={index} className="bg-[#EAF6FA] space-y-1 w-full md:w-72 p-4 rounded-lg ">
            <Typography variant="h2" className="text-sm text-gray-600 ">
              {obj.name}
            </Typography>

            {obj.amount ? (
              <Typography variant="h1" className="text-green-600">
                {statsData.getPartnerCount.data && formatRupees(+statsData.getPartnerCount.data[obj.key].split("₹")[1])}
              </Typography>
            ) : (
              <Typography variant="h1">{statsData.getPartnerCount.data && statsData.getPartnerCount.data[obj.key]}</Typography>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default LegalStats;
