import React, { useState } from "react";
import Typography from "../../../component/semantic/Typography";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import iso from "../../../assets/images/iso.png";
import AppStoreIcon from "../../../assets/icons/home/INSA-icon.svg";
import LinkedinIcon from "../../../assets/icons/home/linkedin.svg";
import FacebookIcon from "../../../assets/icons/home/facebook.svg";
import YoutubeIcon from "../../../assets/icons/home/facebook (1).svg";
import Instagram from "../../../assets/icons/home/Instagram.svg";
import { FaArrowRight } from "react-icons/fa6";
import { useAppDispatch } from "../../../hooks/redux_toolkit";
import { newsLetterThunk } from "../../../store/thunk/Login.thunk";

function Footer() {
  const baseUrl = process.env.REACT_APP_ENV !== "prod" ? `https://www.${process.env.REACT_APP_ENV}.insurancesamadhan.com/` : "https://www.insurancesamadhan.com/";

  const [email, setEmail] = useState("");

  const dispatch = useAppDispatch();
  const submitHandler = () => {
    if (email.trim()) {
      dispatch(newsLetterThunk({ email }))
        .unwrap()
        .then((data) => {
          if (data.status === 200) {
            window.alert(data?.data?.message);
          }
        });
    }
  };

  return (
    <div>
      <div className="bg-black h-auto px-4 md:px-20 py-12 text-white border-b-2 border-gray-700">
        <div className="block md:flex justify-between">
          <div>
            <div>
              <img src={AppStoreIcon} alt="insa-logo" />
            </div>
            <div className="flex flex-col gap-y-6 w-[80%] mt-8">
              <Typography>A-31, 2nd floor, Infraline Tower, Sector 3, Noida - 201301</Typography>
              <Typography onClick={() => window.open("tel:9513631312")} className="cursor-pointer">
                95136-31312
              </Typography>
              <Typography onClick={() => window.open("mailto:corporate@insurancesamadhan.com")} className="cursor-pointer">
                corporate@insurancesamadhan.com
              </Typography>
              <img src={iso} className="h-20 w-56" />
            </div>
          </div>
          <div className="flex">
            <div className="flex flex-col text-gray-400 gap-y-3">
              <Typography className="text-2xl text-gray-600 pb-2">Services</Typography>
              <div onClick={() => window.open(baseUrl + "claim-rejection")} className="flex cursor-pointer">
                <Typography>Claim Rejection </Typography>
                <MdOutlineKeyboardArrowRight className="text-lg my-auto" size={"25px"} />
              </div>
              <div onClick={() => window.open(baseUrl + "misselling-of-insurance-policy")} className="flex cursor-pointer">
                <Typography>Mis-selling of Insurance Policy</Typography>
                <MdOutlineKeyboardArrowRight className="text-lg my-auto" size={"25px"} />
              </div>
              <div onClick={() => window.open(baseUrl + "delay-in-claim-process")} className="flex cursor-pointer">
                <Typography>Delay in Claim Process</Typography>
                <MdOutlineKeyboardArrowRight className="text-lg my-auto" size={"25px"} />
              </div>
              <div onClick={() => window.open(baseUrl + "claim-short-settled")} className="flex cursor-pointer">
                <Typography>Claim Short-settled</Typography>
                <MdOutlineKeyboardArrowRight className="text-lg my-auto" size={"25px"} />
              </div>
              <div onClick={() => window.open(baseUrl + "health-claim-reimbursement")} className="flex cursor-pointer">
                <Typography>Health Claim Reimbursement</Typography>
                <MdOutlineKeyboardArrowRight className="text-lg my-auto" size={"25px"} />
              </div>
              <div onClick={() => window.open(baseUrl + "know-your-policy")} className="flex cursor-pointer">
                <Typography>Know your policy</Typography>
                <MdOutlineKeyboardArrowRight className="text-lg my-auto" size={"25px"} />
              </div>
            </div>
            <div className="flex flex-col text-gray-400 gap-y-3">
              <Typography className="text-2xl text-gray-600 pb-2">Quick Links</Typography>
              <div onClick={() => window.open(baseUrl + "about")} className="flex cursor-pointer">
                <Typography>About Us</Typography>
                <MdOutlineKeyboardArrowRight className="text-lg my-auto" size={"25px"} />
              </div>
              {/* <div className="flex cursor-pointer">
                <Typography>Insights</Typography>
                <MdOutlineKeyboardArrowRight className="text-lg my-auto" size={"25px"} />
              </div> */}
              <div onClick={() => window.open(baseUrl + "testimonials")} className="flex cursor-pointer">
                <Typography>Testimonial</Typography>
                <MdOutlineKeyboardArrowRight className="text-lg my-auto" size={"25px"} />
              </div>

              <div onClick={() => window.open(baseUrl + "media-coverage")} className="flex cursor-pointer">
                <Typography>Media Coverage</Typography>

                <MdOutlineKeyboardArrowRight className="text-lg my-auto" size={"25px"} />
              </div>
              <div onClick={() => window.open(baseUrl + "career")} className="flex cursor-pointer">
                <Typography>Career</Typography>
                <MdOutlineKeyboardArrowRight className="text-lg my-auto" size={"25px"} />
              </div>

              <div onClick={() => window.open(baseUrl + "register")} className="flex cursor-pointer">
                <Typography>Contact up</Typography>
                <MdOutlineKeyboardArrowRight className="text-lg my-auto" size={"25px"} />
              </div>
            </div>
          </div>
          <div className="w-[30%]">
            <div className="w-full relative">
              <input value={email} onKeyDown={(e) => e.key == "Enter" && submitHandler()} onChange={(e) => setEmail(e.target.value)} className="w-full p-2 bg-inherit text-white border-b-2" placeholder="Email Id*" />
              {email && (
                <button onClick={submitHandler} type="button" className="absolute right-0 top-1/2 transform -translate-y-1/2 flex items-center pr-3">
                  <FaArrowRight className="w-5 h-5 text-white" />
                </button>
              )}
            </div>

            <div className="flex gap-x-4 py-[7%]">
              <img className="cursor-pointer" onClick={() => window.open("https://www.linkedin.com/company/insurancesamadhan", "_blank")} alt="linkedin" src={LinkedinIcon} />
              <img className="cursor-pointer" onClick={() => window.open("https://www.instagram.com/insurancesamadhan/", "_blank")} alt="insta" src={Instagram} />
              <img className="cursor-pointer" onClick={() => window.open("https://www.facebook.com/insurancesamadhan", "_blank")} alt="fb" src={FacebookIcon} />
              <img className="cursor-pointer" onClick={() => window.open("https://www.youtube.com/channel/UCnlbMVTuDrZq97QNDfnCKwA", "_blank")} alt="youtube" src={YoutubeIcon} />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-black px-20">
        <div className="flex text-gray-500 py-2 gap-x-10">
          <Typography>© 2024 Insurance Samadhan. All right reserved.</Typography>
          <Typography className="cursor-pointer" onClick={() => window.open(baseUrl + "privacy-policy")}>
            {" "}
            Privacy policy
          </Typography>
          <Typography className="cursor-pointer" onClick={() => window.open(baseUrl + "terms-and-conditions")}>
            Terms of service
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default Footer;
