import React, { FC, useState } from "react";
import Toggle from "../../../component/common/Toggle";
import { useAppDispatch } from "../../../hooks/redux_toolkit";
import { companyStatusThunk, getAllCompanyThunk } from "../../../store/thunk/Company.thunk";
import { toast } from "react-toastify";
interface statusProps {
  data: boolean;
  id: string;
  pageSize: number;
  currentPage: number;
}

const StatusHandler: FC<statusProps> = ({ data, id, pageSize, currentPage }) => {
  const [enabled, setEnabled] = useState(data);

  const dispatch = useAppDispatch();

  const toggle = (status: boolean) => {
    if (status !== data) {
      dispatch(companyStatusThunk({ id, body: { id, isActive: status } }))
        .unwrap()
        .then((data: any) => {
          if (data?.success) {
            toast.success(data?.message);
            dispatch(getAllCompanyThunk({ pageSize, currentPage }));
          }
        });
    }
    setEnabled(!enabled);
  };

  return (
    <td>
      <Toggle enabled={enabled} onClick={() => toggle(!enabled)} />
    </td>
  );
};

export default StatusHandler;
