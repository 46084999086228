import React from "react";
import withNavbarSidebar from "./withNavbarSidebar";
import Dashboard from "../../pages/dashboard/Dashboard.component";
import Referral from "../../pages/referral/Referral.component";
import Requirement from "../../pages/requirement/Requirement.component";
import Profile from "../../pages/profile/Profile.component";
import Notification from "../../pages/notification/Notification.component";
import Reports from "../../pages/reports/Reports.component";
import AllLeads from "../../pages/allLeads/AllLead.component";
import Chat from "../../pages/chat/Chat.component";
import Gallery from "../../pages/gallery/Gallery.component";
import FAQ from "../../pages/faq/FAQ.component";
import NewCase from "../../pages/newCase/NewCase.component";
import ChangePassword from "../../pages/password/Password.component";
import ResolveCase from "../../pages/resolveCase/ResolveCase.component";
import UserManagement from "../../pages/userManagement/userManagement.component";
import AllComplaints from "../../pages/allComplaints/AllComplaints.component";
import PartnerManagement from "../../pages/partnerManagement/partnerManagement.component";
import NewDashboard from "../../pages/newDashboard/newDashboard.component";
import AllUsers from "../../pages/allUsers/AllUsers.component";
import AppInitialUser from "../../pages/appInitialUser/AppInitialUser.component";
import HospitalCase from "../../pages/newHospitalCase/HospitalCase.component";
import PendingContract from "../../pages/pendingContract/PendingContract.component";
import PayoutMarking from "../../pages/payoutMarking";
import LeadCost from "../../pages/leadCost";
import Company from "../../pages/company/Company.component";
import ExecutivePartnerMapping from "../../pages/executivePartnerMapping/executivePartnerMapping.component";
import BulkAction from "../../pages/bulkAction";
// import ExecutivePartnerMapping from "../../pages/executivePartnerMapping/executivePartnerMapping.component";

// name for route,component fro imported component,title for navbar title
const AllComponents: { name: string; component: any; title: string }[] = [
  { name: "Dashboard", component: Dashboard, title: "Dashboard" },
  { name: "Referral", component: Referral, title: "Referral" },
  { name: "Requirement", component: Requirement, title: "Requirement" },
  { name: "Profile", component: Profile, title: "Profile" },
  { name: "Notification", component: Notification, title: "Notification" },
  { name: "Reports", component: Reports, title: "Generate or Download Report" },
  { name: "AllLeads", component: AllLeads, title: "All Leads" },
  { name: "Chat", component: Chat, title: "Chat" },
  { name: "Gallery", component: Gallery, title: "Insurance Samadhan Gallery" },
  { name: "FAQ", component: FAQ, title: "FAQ's" },
  { name: "NewCase", component: NewCase, title: "New Case" },
  { name: "ChangePassword", component: ChangePassword, title: "Change Password" },
  { name: "ResolveCase", component: ResolveCase, title: "Resolved Cases" },
  { name: "UserManagement", component: UserManagement, title: "User Management" },
  { name: "AllComplaints", component: AllComplaints, title: "All Complaints" },
  { name: "PartnerManagement", component: PartnerManagement, title: "Partner Management" },
  { name: "NewDashboard", component: NewDashboard, title: "New Dashboard" },
  { name: "AllUsers", component: AllUsers, title: "All Users" },
  { name: "AppInitialUser", component: AppInitialUser, title: "App Registered Initial Users" },
  { name: "PendingContract", component: PendingContract, title: "Pending Contracts" },
  { name: "HospitalCase", component: HospitalCase, title: "New Hospital Case" },
  { name: "PayoutMarking", component: PayoutMarking, title: "Payout Marking" },
  { name: "LeadCost", component: LeadCost, title: "Lead Cost" },
  { name: "Company", component: Company, title: "Company" },
  { name: "HospitalCase", component: HospitalCase, title: "New Hospital Case" },
  { name: "ExecutivePartnerMapping", component: ExecutivePartnerMapping, title: "Executive Partner Mapping" },
  { name: "BulkAction", component: BulkAction, title: "Bulk Action" },
  { name: "HospitalCase", component: HospitalCase, title: "New Hospital Case" },
  // { name: "ExecutivePartnerMapping", component: ExecutivePartnerMapping, title: "Executive Partner Mapping" }

];

const wrapComponentsWithLayout = () => {
  const wrappedComponents: Record<string, React.ComponentType<any>> = {};

  AllComponents.map((obj: any) => {
    wrappedComponents[obj.name] = withNavbarSidebar(obj.component, obj.title);
  });

  return wrappedComponents;
};

export default wrapComponentsWithLayout();
