import Filter from "./components/Filter";
import BarChart from "./components/BarChart";
import CardSection from "./components/CardSection";
import React, { useEffect, useState } from "react";
import { dashboardBarStats } from "../../store/thunk/Dashboard.thunk";
import { useAppDispatch, useAppSelector } from "../../hooks/redux_toolkit";
import AgentDetails from "./components/AgentDetails";
import { getCurrentUser } from "../../helpers/currentUser";
import { PERMISSIONS } from "../../helpers/constants/constants";

interface BarChartData {
  labels: string;
  values: number;
}
interface OptionType {
  value: string;
  label: string;
}

function Dashboard() {
  const dispatch = useAppDispatch();

  const currentUser = getCurrentUser();

  const [barGraphOptionLead, setBarGraphOptionLead] = useState<OptionType>({ label: "Total", value: "" });
  const [barGraphOptionClaimAmount, setBarGraphOptionClaimAmount] = useState<OptionType>({ label: "Total", value: "" });
  const [barGraph, setBarGraph] = useState<{ count: { lead: number; claimAmount: number }; value: { lead: BarChartData[]; claimAmount: BarChartData[] } }>({ count: { lead: 0, claimAmount: 0 }, value: { lead: [], claimAmount: [] } });

  useEffect(() => {
    dispatch(dashboardBarStats({ leadStatus: barGraphOptionLead.value, claimStatus: barGraphOptionClaimAmount.value }));
  }, [barGraphOptionLead, barGraphOptionClaimAmount]);

  const dashboardBarData = useAppSelector((state) => state.rootReducer.dashboard.barData);

  useEffect(() => {
    if (dashboardBarData && dashboardBarData?.data) {
      const dataObject: { lead: BarChartData[]; claimAmount: BarChartData[] } = { lead: [], claimAmount: [] };
      for (let i = 0; i < 12; i++) {
        dataObject.lead.push({ labels: dashboardBarData.data?.monthList[i], values: dashboardBarData.data?.monthlyLeadDetails[i] });
        dataObject.claimAmount.push({ labels: dashboardBarData.data?.monthList[i], values: dashboardBarData.data?.monthlyClaimAmountDetails[i] });
      }
      setBarGraph({ count: { lead: dashboardBarData.data?.yearlyLeadsCount, claimAmount: dashboardBarData.data?.yearlyClaimAmount }, value: dataObject });
    }
  }, [dashboardBarData]);

  // const loginData = useAppSelector((state) => state.rootReducer.login);

  // useEffect(() => {
  //   if (loginData.roles.length == 0) {
  //     dispatch(getUserRoleThunk());
  //   }
  // }, [loginData]);

  return (
    <>
      <Filter />
      <CardSection />
      {currentUser?.roles.includes(PERMISSIONS.ADMIN) && <AgentDetails />}
      <BarChart data={barGraph.value.lead} count={barGraph.count.lead} color="#7FA4F3" barGraphOption={barGraphOptionLead} setBarGraphOption={setBarGraphOptionLead} title="Lead Count" />
      <BarChart data={barGraph.value.claimAmount} count={barGraph.count.claimAmount} color="#AF7AB3" barGraphOption={barGraphOptionClaimAmount} setBarGraphOption={setBarGraphOptionClaimAmount} title="Claim Amount" />
    </>
  );
}

export default Dashboard;
