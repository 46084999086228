import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../helpers/axios";
import { baseUrl } from "../../helpers/constants/defaultUrls";

interface PayoutMarking {
    dateFilter: string;
    month: number,
    year: number
}

export const getPayoutMarkingList: any = createAsyncThunk("payoutMarking/getDataList", async (payload: PayoutMarking) => {
    const response = await axios.post(baseUrl + "v1/lead/getTotalRemunerationForPAA", payload);
    return response.data;
});

export const markPaidPayoutMarking: any = createAsyncThunk("payoutMarking/markPaidPayoutMarking", async (payload: PayoutMarking) => {
    const response = await axios.post(baseUrl + "v1/lead/markPayoutDoneOfPAA", payload);
    return response.data;
});
