import React from "react";
import Logo from "../../../assets/images/partnerLogo.svg";
import cover from "../../../assets/images/coverSignup.svg";
import { useNavigate } from "react-router-dom";

const Wrapper = ({ children }: any) => {
  const navigate = useNavigate();
  return (
    <div>
      <nav className="bg-white flex justify-center py-4 shadow-2xl w-full fixed z-10">
        <img src={Logo} onClick={() => navigate("/login")} className="cursor-pointer" />
      </nav>
      <img src={cover} className="w-full" />
      <div className="flex justify-center ">
        <div className="bg-white w-[90%] md:w-[50%] lg:w-[40%] xl:w-[30%] absolute top-32 rounded-2xl  shadow-2xl border-t-[1rem] border-polyfixTheme">{children}</div>
      </div>
    </div>
  );
};

export default Wrapper;
