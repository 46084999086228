import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../helpers/axios";
import { baseUrl } from "../../helpers/constants/defaultUrls";

interface LoginProps {
  email: string;
  password: string;
}

export const loginThunk = createAsyncThunk("auth/login", async (payload: LoginProps) => {
  const response = await axios.post(baseUrl + "v1/auth/login", payload);
  return response.data;
});

export const referralViaThunk = createAsyncThunk("auth/referralViaThunk", async () => {
  const response = await axios.get(baseUrl + "v1/auth/list/referralVia");
  return response.data;
});

export const signUpThunk = createAsyncThunk("auth/signUpThunk", async (payload: any) => {
  const response = await axios.post(baseUrl + "v1/auth/signup", payload);
  return response.data;
});

export const recoverPasswordThunk = createAsyncThunk("auth/recoverPasswordThunk", async (payload: { email: string }) => {
  const response = await axios.post(baseUrl + "v1//auth/password/temporary", payload);
  return response.data;
});

export const getUserRoleThunk = createAsyncThunk("user/getroles", async () => {
  const response = await axios.get(baseUrl + "v1/user/getroles");
  return response;
});

export const getExecutiveList = createAsyncThunk("user/getExecutiveList", async () => {
  const response = await axios.get(baseUrl + "v1/user/executiveList");
  return response;
});

export const newsLetterThunk = createAsyncThunk("user/newsLetterThunk", async (payload: { email: string }) => {
  const response = await axios.post(baseUrl + "v1/lead/newsLetterAdd", payload);
  return response;
});

export const addReachThunk = createAsyncThunk("user/addReachThunk", async (payload: { email: string; name: string; phone: string; natOfBusi: string }) => {
  const response = await axios.post(baseUrl + "/v1/lead/addReachData", payload);
  return response;
});
