import React from "react";
import uploadIcon from "../../assets/images/profile/upload.svg";

interface FileProps {
  setSelectedFile: (file: File) => void;
  apiFunction?: (e: File) => void;
  accept?: string;
  id?: string;
}

const FileUpload: React.FC<FileProps> = ({ setSelectedFile, accept, id = "fileInput" }) => {
  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  return (
    <div>
      <input type="file" className="hidden" id={id} accept={accept ? accept : ".jpg, .jpeg, .png, .pdf"} onChange={handleFileChange} multiple />
      <div className="flex gap-8 flex-col lg:flex-row">
        <label htmlFor={id} className="bg-uploadTheme border-[2px] rounded-md py-4 gap-4 border-polyfixTheme border-dashed w-full flex justify-center items-center cursor-pointer font-semibold text-gray-400">
          <img src={uploadIcon} className="w-8" /> <span>Select File to upload</span>
        </label>
      </div>
    </div>
  );
};

export default FileUpload;
