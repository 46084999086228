import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../helpers/axios";
import { baseUrl } from "../../helpers/constants/defaultUrls";

interface UserNotificationProps {
  userId: string;
  page: number;
  size: number;
  date: number;
}

interface ReadNotificationProps {
  id: string;
}

export const userNotificationThunk = createAsyncThunk("notification/getUserNotification", async ({ userId, page, size, date }: UserNotificationProps) => {
  const response = await axios.get<{ data: any }>(baseUrl + `v1/notification/allv2?userId=${userId}&page=${page}&size=${size}&date=${date}`);
  return response.data;
});

export const readSingleNotificationThunk = createAsyncThunk("notification/readNotification", async (id: ReadNotificationProps) => {
  const response = await axios.patch<{ data: any }>(baseUrl + `v1/notification/update/read/single/${id}`);
  return response.data;
});

export const clearNotificationThunk = createAsyncThunk("notification/clearNotification", async () => {
  const response = await axios.patch<{ data: any }>(baseUrl + `v1/notification/clearNotification`);
  return response.data;
});
