import React, { FC } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

interface InputWithIconProps {
  formProps: any;
  name: string;
  showPassword: boolean;
  toggle: any;
  placeholder?: string;
  label: string;
}

const InputWithIcon: FC<InputWithIconProps> = ({ formProps, name, showPassword, toggle, placeholder, label }) => {
  const { handleChange, handleBlur, values, touched, errors } = formProps;

  return (
    <div className="relative">
      <label className="font-semibold" htmlFor={name}>
        {label}
      </label>
      <input onBlur={handleBlur} name={name} type={showPassword ? "text" : "password"} onChange={handleChange} placeholder={placeholder} value={values?.[name]} className={`appearance-none border mt-2 mb-1 focus:ring-2 focus:outline-none focus:ring-blue-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:shadow-outline ${errors?.[name] && touched?.[name] && "border-red-400 focus:ring-red-200"}`} />
      {showPassword ? <AiOutlineEyeInvisible onClick={toggle} className="absolute top-11 right-4 cursor-pointer text-gray-500" /> : <AiOutlineEye onClick={toggle} className="absolute top-11 right-4 cursor-pointer text-gray-500" />}
      {touched?.[name] && errors?.[name] ? <p className="mt-1 text-red-600 font-semibold text-xs">{errors?.[name]}</p> : ""}
    </div>
  );
};

export default InputWithIcon;
