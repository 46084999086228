import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../helpers/axios";
import { baseUrl } from "../../helpers/constants/defaultUrls";

interface notificationBellProps {
  id: string;
  type: string;
  date: number;
}

interface userListProps {
  type: "Partner" | "Associate" | "Advisor" | "Manager";
  parentId?: string;
  level?: number;
}

export const allCompanyList = createAsyncThunk("common/companyList", async () => {
  const response = await axios.get(baseUrl + "v1/ins-comp/getAllCompany");
  return response.data;
});

export const getStateAndDistrict = createAsyncThunk("common/getStateAndDistrict", async () => {
  const response = await axios.get(baseUrl + "v1/app/getStateAndCityList");
  return response.data;
});

export const userProfile = createAsyncThunk("common/userProfile", async (id: string) => {
  const response = await axios.get(baseUrl + `v1/user/single/${id}`);
  return response.data;
});

export const notificationBell = createAsyncThunk("commom/notificationBell", async ({ id, date, type }: notificationBellProps) => {
  const response = await axios.get(baseUrl + `v1/notification/count?userId=${id}&type=${type}&date=${date}`);
  return response.data;
});

export const userListAllThunk = createAsyncThunk("common/userListAllThunk", async ({ type, parentId }: userListProps) => {
  let response;
  if (type == "Manager") {
    response = await axios.get(baseUrl + `v1/user/all`);
  } else if (type == "Partner") {
    response = await axios.get(baseUrl + `v1/user/all?userType=Vendor&hierarchicalLevel=0`);
  } else if (type == "Associate") {
    response = await axios.get(baseUrl + `v1/user/all?userType=Vendor&hierarchicalLevel=1&parentId=${parentId}`);
  } else if (type == "Advisor") {
    //Baad me
  }

  return response?.data;
});

export const getUserDropdownThunk = createAsyncThunk("common/getUserDropdownThunk", async ({ type, parentId }: userListProps) => {
  let response;
  if (type == "Manager") {
    response = await axios.get(baseUrl + `v1/user/getAllUsersDropdown`);
  } else if (type == "Partner") {
    response = await axios.get(baseUrl + `v1/user/getAllUsersDropdown?userType=Vendor&hierarchicalLevel=0`);
  } else if (type == "Associate") {
    response = await axios.get(baseUrl + `v1/user/getAllUsersDropdown?userType=Vendor&hierarchicalLevel=1&parentId=${parentId}`);
  }

  return response?.data;
});

export const userListAllThunkv2 = createAsyncThunk("common/userListAllThunkv2", async ({ type, level }: userListProps) => {
  let response;
  if (type == "Manager") {
    response = await axios.get(baseUrl + `v1/user/allv2`);
  } else response = await axios.get(baseUrl + `v1/user/allv2?userType=Vendor&hierarchicalLevel=${level}`);

  // else if(type=="Partner"){
  //     response = await axios.get(baseUrl+`v1/user/all?userType=Vendor&hierarchicalLevel=0`)
  // }else if(type=="Associate"){
  //     response = await axios.get(baseUrl+`v1/user/all?userType=Vendor&hierarchicalLevel=1&parentId=${parentId}`)
  // }else if(type=="Advisor"){
  //     //Baad me
  // }

  return response?.data;
});

export const policyListThunk = createAsyncThunk("common/policyList", async () => {
  const response = await axios.get(baseUrl + "v1/policy-type/getall");
  return response.data;
});

export const complaintListThunk = createAsyncThunk("common/complaintListThunk", async (id: any) => {
  const response = await axios.get(baseUrl + `v1/com-type/getall/${id}`);
  return response.data;
});

export const addNewCompany = createAsyncThunk("common/addCompany", async (payload: any) => {
  const response = await axios.post(baseUrl + "v1/ins-comp/new", payload);
  return response.data;
});

export const updateCompany = createAsyncThunk("common/update", async (payload: any) => {
  const response = await axios.put(baseUrl + `v1/ins-comp/update/${payload.id}`, payload);
  return response.data;
});
