import { createSlice } from "@reduxjs/toolkit";
import { commentHistoryComplaint, getAllComplaintThunk, getLeadDataThunk, searchComplaintCustomerThunk, searchComplaintPartnerAdmin, searchComplaintPartnerThunk } from "../thunk/AllComplaint.thunk";

interface responseData {
  data: any;
}

interface ApiState {
  data: any;
  loading: boolean;
  error: string | null;
  complaintData: responseData;
  commentHistory: responseData;
  leadData: responseData;
}

const initialState: ApiState = {
  data: null,
  loading: false,
  error: null,
  complaintData: { data: { data: "" } },
  commentHistory: { data: "" },
  leadData: { data: "" }
};

const allComplaintSlice = createSlice({
  name: "allComplaintSlice",
  initialState,
  reducers: {
    setSortedDataComplaint: (state, action) => {
      state.complaintData.data.data = [...action.payload];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllComplaintThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllComplaintThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.complaintData = action.payload;
        state.error = null;
      })
      .addCase(getAllComplaintThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "An error occurred";
      })

      .addCase(searchComplaintCustomerThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(searchComplaintCustomerThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.complaintData = action.payload;
        state.error = null;
      })
      .addCase(searchComplaintCustomerThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "An error occurred";
      })

      .addCase(searchComplaintPartnerThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(searchComplaintPartnerThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.complaintData = action.payload;
        state.error = null;
      })
      .addCase(searchComplaintPartnerThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "An error occurred";
      })

      .addCase(commentHistoryComplaint.pending, (state) => {
        state.loading = false;
      })
      .addCase(commentHistoryComplaint.fulfilled, (state, action) => {
        state.loading = false;
        state.commentHistory = action.payload;
        state.error = null;
      })
      .addCase(commentHistoryComplaint.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "An error occurred";
      })

      .addCase(getLeadDataThunk.pending, (state) => {
        state.loading = false;
      })
      .addCase(getLeadDataThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.leadData = action.payload;
        state.error = null;
      })
      .addCase(getLeadDataThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "An error occurred";
      })

      .addCase(searchComplaintPartnerAdmin.pending, (state) => {
        state.loading = true;
      })
      .addCase(searchComplaintPartnerAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.complaintData = action.payload;
        state.error = null;
      })
      .addCase(searchComplaintPartnerAdmin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "An error occurred";
      });
  }
});

export const { setSortedDataComplaint } = allComplaintSlice.actions;

export default allComplaintSlice.reducer;
