import React from 'react';
import { FaVideo } from "react-icons/fa6";

const arr = [1, 2, 3, 4, 5, 6];

const ReelsSkeleton = () => {
    return (
        <>
            {arr.map((obj:any,i:number) => (
                <div key={i} className=' border-2 shadow-lg rounded-xl w-full md:w-64 '>
                    <div key={obj} role="status" className="flex items-center w-full justify-center h-[21.6rem]  bg-gray-300 animate-pulse ">
                        <FaVideo className='w-16 h-16 text-bg-gray-300' />
                    </div>
                    <div className='flex justify-center p-5'>
                        <div className="h-2 bg-gray-200 rounded-full w-32"></div>
                    </div>
                </div>
            ))}
        </>
    );
};

export default ReelsSkeleton;
