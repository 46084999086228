import { createSlice } from "@reduxjs/toolkit";
import { loginThunk, referralViaThunk, getUserRoleThunk, getExecutiveList } from "../thunk/Login.thunk";
interface ApiState {
  data: any;
  loading: "idle" | "pending" | "fulfilled" | "rejected";
  error: string | null;
  login: object;
  navigationUrl: string;
  referralVia: responseData;
  roles: Array<any>;
  loadingRoles: boolean;
  isNetworkError: boolean;
  executiveList: responseData;
}

interface responseData {
  data: any;
}
const initialState: ApiState = {
  data: null,
  loading: "idle",
  error: null,
  login: {},
  navigationUrl: "/dashboard",
  loadingRoles: false,
  referralVia: { data: [] },
  roles: [],
  isNetworkError: false,
  executiveList: { data: "" }
};

const loginSlice = createSlice({
  name: "loginSlice",
  initialState,
  reducers: {
    emptyNavigationUrl: (state) => {
      state.navigationUrl = "";
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginThunk.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(loginThunk.fulfilled, (state, action) => {
        if (action.payload?.data) {
          localStorage.setItem("userInfo", JSON.stringify(action.payload.data));
          state.navigationUrl = "/dashboard";
        }
        state.loading = "fulfilled";
        state.login = action.payload;
        state.error = null;
        if (action.payload && action.payload.data && action.payload.data.roles) {
          state.roles = action.payload.data.roles;
        } else {
          state.roles = [];
        }
      })
      .addCase(loginThunk.rejected, (state, action) => {
        state.loading = "rejected";
        state.error = action.error.message ?? "An error occurred";
      })

      .addCase(referralViaThunk.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(referralViaThunk.fulfilled, (state, action) => {
        state.loading = "fulfilled";
        state.referralVia = action.payload;
        state.error = null;
      })
      .addCase(referralViaThunk.rejected, (state, action) => {
        state.loading = "rejected";
        state.error = action.error.message ?? "An error occurred";
      })
      .addCase(getUserRoleThunk.pending, (state) => {
        state.loadingRoles = true;
      })
      .addCase(getUserRoleThunk.fulfilled, (state, action) => {
        state.loadingRoles = false;
        state.error = null;
        if (action.payload && action.payload.data?.data && action.payload?.data.data.roles) {
          state.roles = action.payload?.data?.data.roles;
        }
      })
      .addCase(getUserRoleThunk.rejected, (state, action) => {
        if (action.error.name === "AxiosError") state.isNetworkError = true;
        state.loadingRoles = false;
        state.error = action.error.message ?? "An error occurred";
      })

      .addCase(getExecutiveList.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getExecutiveList.fulfilled, (state, action) => {
        state.loading = "fulfilled";
        state.error = null;
        state.executiveList = action.payload;
      })
      .addCase(getExecutiveList.rejected, (state, action) => {
        if (action.error.name === "AxiosError") state.isNetworkError = true;
        state.loading = "rejected";
        state.error = action.error.message ?? "An error occurred";
      });
  }
});

export const { emptyNavigationUrl } = loginSlice.actions;

export default loginSlice.reducer;
