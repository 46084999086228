import React from "react";

const LegalSkeleton = () => {
  return (
    <div className="w-72 h-[5.2rem] bg-[#EAF6FA]  space-y-1 rounded-lg p-4">
      <div className="h-4 w-[85%] bg-gray-400 rounded-full dark:bg-gray-700 mb-2.5 animate-pulse"></div>
      <div className="h-4 bg-gray-400 rounded-full dark:bg-gray-700 w-32 mb-2.5 animate-pulse"></div>
    </div>
  );
};

export default LegalSkeleton;
