import React, { FC } from "react";
import { FaFile } from "react-icons/fa";
import Typography from "../../../component/semantic/Typography";
import deleteDoc from "../../../assets/icons/newCase/deleteDoc.svg";

interface ShowFileProps {
  fileArr: any;
  setFileArr: any;
  docName: string;
}

const ShowFile: FC<ShowFileProps> = ({ fileArr, setFileArr, docName }) => {
  const removeFileHandler = (i: number) => {
    const temp: any = { ...fileArr };
    temp[docName].splice(i, 1);
    setFileArr(temp);
  };

  return (
    <span className=" items-center mt-2 flex flex-wrap justify-between gap-2">
      {fileArr &&
        docName &&
        fileArr[docName].map((obj: any, i: number) => {
          return (
            <div key={i} className=" flex  ">
              <div className="text-gray-700 bg-uploadTheme flex px-4 rounded-lg py-1 gap-1 items-center">
                <FaFile className="text-gray-400 opacity-50" />
                <Typography className="w-48 truncate">{obj?.name}</Typography>
              </div>
              <img src={deleteDoc} onClick={() => removeFileHandler(i)} className="ml-2 cursor-pointer" />
            </div>
          );
        })}
    </span>
  );
};

export default ShowFile;
