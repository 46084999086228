import React from 'react'
import Typography from '../../../component/semantic/Typography'

function Card({ src = "", heading = "", content = "", className = "" }: any) {
    return (
        <div className={`bg-[#F0F4FD] px-8 py-14 md:w-[30%]`}>
            <div className='flex'>
                <div className='px-4 pt-4 pb-8 bg-white rounded-2xl min-h-[510px]'>
                    <div className={`py-2 px-6 bg-[#DFEAE7] flex justify-center rounded-lg h-[222px] ${className}`}>
                        <img alt='image' src={src} />
                    </div>

                    <div className='py-3'>
                        <Typography variant='h2' className='text-xl'>{heading}</Typography>
                    </div>
                    <div>
                        <Typography>{content}</Typography>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Card