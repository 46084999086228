import { FormikTouched, FormikValues } from "formik";
import React from "react";

export type InputProps = {
  label?: string;
  name?: string;
  placeholder?: string;
  type?: string;
  className?: string;
  onClick?: (event: React.MouseEvent) => void;
  disabled?: boolean;
  min?: string;
  limit?: number;
  value?: any;
  formProps: {
    handleChange: {
      (e: React.ChangeEvent<any>): void;
      <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void;
    };
    errors: any;
    touched: FormikTouched<FormikValues>;
    handleBlur: {
      (e: React.FocusEvent<any, Element>): void;
      <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
    };
    values: FormikValues;
  };
};

const FormTextArea: React.FC<InputProps> = ({ label, name = "", className, placeholder, value, formProps, ...rest }) => {
  const { handleChange, handleBlur, values, touched, errors } = formProps;

  return (
    <>
      {label && (
        <label className="font-semibold" htmlFor={name}>
          {label}
        </label>
      )}
      <textarea {...rest} onChange={handleChange} placeholder={placeholder} onBlur={handleBlur} name={name} value={value || values?.[name]} className={` appearance-none border focus:ring-2 focus:outline-none ${touched?.[name] && errors?.[name] ? "focus:ring-red-200 border-red-400" : "focus:ring-blue-300"}  h-[70%] lg:h-[90%] rounded w-full py-2 px-3 text-gray-700 leading-tight focus:shadow-outline ${className}`} />
      {touched?.[name] && errors?.[name] && <p className="mt-1 text-red-600 font-semibold text-sm">{errors?.[name]}</p>}
    </>
  );
};

export default FormTextArea;
