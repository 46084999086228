import dayjs from "dayjs";
import React from "react";
import { hierarchicalLevelHandler } from "../../../helpers/utils";

interface TypographyProps {
  data?: any;
}

const UserDetails: React.FC<TypographyProps> = ({ data }) => {
  const details = data.data;
  return (
    <div className="flex flex-wrap justify-between mt-6">
      <div>
        <div className="font-bold text-gray-500">Total leads</div>
        <div className="font-bold">{details.totalLeads}</div>
      </div>
      <div>
        <div className="font-bold text-gray-500">Total Claim Amount</div>
        <div className="font-bold">{details.claimAmount}</div>
      </div>
      <div>
        <div className="font-bold text-gray-500">Total Earnings</div>
        <div className="font-bold">{details.totalPendingActual}</div>
      </div>
      <div>
        <div className="font-bold text-gray-500">Leads in this month</div>
        <div className="font-bold">{details.leadsLastMonth}</div>
      </div>
      <div>
        <div className="font-bold text-gray-500">Leads in last 1 year </div>
        <div className="font-bold">{details.leadsLastYearly}</div>
      </div>
      <div>
        <div className="font-bold text-gray-500">Level</div>
        <div className="font-bold">{hierarchicalLevelHandler(details.hierarchicalLevel)}</div>
      </div>
      <div>
        <div className="font-bold text-gray-500">Onboarding Date</div>
        <div className="font-bold">{dayjs(details.createdAt).format("DD MMM YYYY")}</div>
      </div>
    </div>
  );
};

export default UserDetails;
