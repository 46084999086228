import { createSlice } from "@reduxjs/toolkit";
import { getAllUserPendingContractThunk, updateAndSendContractThunk, sendPartnerContractThunk, signPartnerContractThunk } from "../thunk/PendingContract.thunk";

interface ApiState {
  data: any;
  loading: boolean;
  error: string | null;
  usersList: responseData;
}

interface responseData {
  data: any;
  count: number;
}

const initialState: ApiState = {
  data: null,
  loading: false,
  error: null,
  usersList: { data: "", count: 0 }
};

const PendingContract = createSlice({
  name: "pendingContractSlice",
  initialState,
  reducers: {
    updateZeroPercentManual: (state, action) => {
      const copyOfUserListData = [...state.usersList.data];
      const index = action.payload;
      copyOfUserListData[index].zeroPercentManual = !copyOfUserListData[index].zeroPercentManual;
      state.usersList.data = [...copyOfUserListData];
    },
    updatePromoteLevelManual: (state, action) => {
      const copyOfUserListData = [...state.usersList.data];
      const index = action.payload;
      copyOfUserListData[index].promoteLevelManual = !copyOfUserListData[index].promoteLevelManual;
      state.usersList.data = [...copyOfUserListData];
    },
    setSortedData: (state, action) => {
      state.usersList.data = [...action.payload];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllUserPendingContractThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllUserPendingContractThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.usersList = action.payload;
        state.error = null;
      })
      .addCase(getAllUserPendingContractThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "An error occurred";
      })
      .addCase(updateAndSendContractThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateAndSendContractThunk.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(updateAndSendContractThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "An error occurred";
      })
      .addCase(sendPartnerContractThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(sendPartnerContractThunk.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(sendPartnerContractThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "An error occurred";
      })
      .addCase(signPartnerContractThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(signPartnerContractThunk.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(signPartnerContractThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "An error occurred";
      });
  }
});
export const { updateZeroPercentManual, updatePromoteLevelManual, setSortedData } = PendingContract.actions;
export default PendingContract.reducer;
