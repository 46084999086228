import React, { useEffect, useState } from "react";
import Typography from "../../../component/semantic/Typography";
import Select from "react-select";
import { ImSpinner2 } from "react-icons/im";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux_toolkit";
import { isEmpty, toInputDate } from "../../../helpers/utils";
import { FaInfoCircle } from "react-icons/fa";
import Tooltip from "../../../component/common/Tooltip";
import { polyfixSelect } from "../../../helpers/constants/styles";
import FormInput from "../../../component/semantic/FormInput";
import FormSelect from "../../../component/semantic/FormSelect";
import FormTextArea from "../../../component/semantic/FormTextArea";
import { getCurrentUser } from "../../../helpers/currentUser";
import ButtonOutline from "../../../component/semantic/Button";
import { useFormik } from "formik";
import { personalSchema } from "../../../helpers/schemas/Profile.Schema";
import { userUpdate } from "../../../store/thunk/Profile.thunk";
import { showToast } from "../../../store/slices/Common.slice";
import { personalInitialDetails } from "../../../helpers/initialValues";

const ProfileDetails = () => {
  const [selectedState, setSelectedState] = useState<any>();
  const [selectedDistrict, setSelectedDistrict] = useState<any>();
  const [districtValues, setDistrictValues] = useState<any>();
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [formValues, setFormValue] = useState<any>(personalInitialDetails);

  const profileData: any = useAppSelector((state) => state.rootReducer.common.userProfile?.data);
  const loading = useAppSelector((state) => state.rootReducer.profile.userLoading);

  useEffect(() => {
    if (profileData) {
      setFormValue({
        _id: profileData?._id,
        name: profileData?.name,
        email: profileData?.email,
        mobile: profileData?.mobile,
        pincode: profileData?.pincode,
        address: profileData?.address?.fullAddress,
        stateName: profileData?.stateName,
        districtName: profileData?.districtName,
        gender: profileData?.gender,
        dob: profileData?.dob,
        aadhaarNumber: profileData?.aadhaarNumber,
        alternateEmails: profileData?.alternateEmails && profileData?.alternateEmails.join(","),
        userType: profileData?.userType,
        createdAt: profileData?.createdAt
      });
    }
  }, [profileData]);

  const userDetails = getCurrentUser();

  const dispatch = useAppDispatch();

  const allStates = useAppSelector((state) => state.rootReducer.common.stateDistrict?.data);

  const { values, handleSubmit, handleBlur, handleChange, touched, errors, setFieldValue } = useFormik({
    initialValues: formValues,
    validationSchema: personalSchema,
    enableReinitialize: true,
    onSubmit: async (values: any) => {
      values = {
        ...values,
        address: {
          fullAddress: values.address
        },
        dob: new Date(values.dob).toISOString(),
        alternateEmails: values.alternateEmails && values.alternateEmails.split(",")
      };

      const updateNotification = await dispatch(userUpdate(values)).unwrap();

      if (updateNotification.status == 200) dispatch(showToast({ message: "Profile Updated Successfully", method: "success" }));
    }
  });

  useEffect(() => {
    if (allStates.length > 0 && values.stateName) {
      const initialSelectedState = allStates.find((obj: any) => obj.name === values.stateName);
      setSelectedState(initialSelectedState);
      const initialSelectedDistrict = initialSelectedState.districts
        .filter((obj: any) => {
          return obj === values.districtName;
        })
        .map((obj: any) => ({ label: obj, value: obj }));

      setSelectedDistrict(initialSelectedDistrict);
    }
  }, [allStates, values.stateName]);

  useEffect(() => {
    const districtsOptions = selectedState?.districts?.map((district: any) => ({
      label: district,
      value: district
    }));
    setDistrictValues(districtsOptions);
  }, [selectedState]);

  const formProps = { values, handleBlur, handleChange, touched, errors, setFieldValue };

  return (
    <>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <FormInput name="name" disabled={userDetails?.userType == "Vendor"} formProps={formProps} label="Name *" placeholder="Enter Your Name" />
        <FormInput name="mobile" disabled limit={10} type="number" formProps={formProps} label="Mobile Number *" placeholder="Enter Your Mobile Number" />
        <FormInput name="email" disabled type="email" formProps={formProps} label="Email ID *" placeholder="Enter Your Email ID" />
        <span>
          <Typography className="flex items-center gap-3">
            Alternate Email
            <Tooltip text="Multiple email addresses can be added comma-separated" showTooltip={showTooltip}>
              <FaInfoCircle className="text-gray-400 cursor-pointer" onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)} />
            </Tooltip>
          </Typography>
          <FormInput name="alternateEmails" formProps={formProps} placeholder="Enter Your Alternate Email" />
        </span>
        <FormInput name="dob" type="date" max="today" formProps={formProps} value={toInputDate(formProps.values.dob)} label="Date of Birth *" />
        <FormSelect name="gender" label="Gender" formProps={formProps} options={["None", "Male", "Female"]} />
        <span className="row-span-3 h-full">
          <FormTextArea label="Address (as per Aadhaar Card) *" name="address" formProps={formProps} />
        </span>
        <span>
          <Typography className="font-normal">State *</Typography>
          <Select
            options={allStates}
            isSearchable={true}
            value={selectedState}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.name}
            styles={polyfixSelect}
            name="stateName"
            onChange={(data: any) => {
              setSelectedState(data);
              formProps.setFieldValue("stateName", data.name);
            }}
          />
        </span>
        <span>
          <Typography className="font-normal">District *</Typography>
          <Select
            options={districtValues}
            isSearchable={true}
            value={selectedDistrict}
            styles={polyfixSelect}
            onChange={(data: any) => {
              setSelectedDistrict(data);
              formProps.setFieldValue("districtName", data.label);
            }}
          />
          {formProps.errors?.districtName && <span className="mt-1 text-red-600 font-semibold text-sm">Please Select District</span>}
        </span>
        <FormInput name="pincode" type="number" limit={6} formProps={formProps} label="Pincode *" />
        <FormInput name="aadhaarNumber" type="number" limit={12} formProps={formProps} label="Aadhar Number *" />
        <FormInput name="createdAt" type="date" disabled formProps={formProps} value={toInputDate(formProps.values.createdAt)} label="Partner with Insurance Samadhan since" />
      </div>
      <div className="md:flex block justify-stretch md:flex-row-reverse py-4 mt-6 border-t-2 ">
        <ButtonOutline icon={loading ? <ImSpinner2 className="animate-spin ml-2 -mt-1" /> : ""} onClick={handleSubmit} className={`bg-polyfixTheme  w-full md:w-32 ${!isEmpty(errors) && "opacity-50 pointer-events-none"}`} title="SAVE" type="submit" />
      </div>
    </>
  );
};

export default ProfileDetails;
