import { createSlice } from "@reduxjs/toolkit";
import { getAllCompanyThunk } from "../thunk/Company.thunk";

interface ApiState {
  data: any;
  loading: "idle" | "pending" | "fulfilled" | "rejected";
  error: string | null;
  companyTable: responseData;
}

interface responseData {
  data: any;
}

const initialState: ApiState = {
  data: null,
  loading: "idle",
  error: null,
  companyTable: { data: [] }
};

const CompanySlice = createSlice({
  name: "CompanySlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllCompanyThunk.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getAllCompanyThunk.fulfilled, (state, action) => {
        state.loading = "fulfilled";
        state.companyTable = action.payload;
        state.error = null;
      })
      .addCase(getAllCompanyThunk.rejected, (state, action) => {
        state.loading = "rejected";
        state.error = action.error.message ?? "An error occurred";
      });
  }
});

export default CompanySlice.reducer;
