import { createSlice } from "@reduxjs/toolkit";
import { getAllAgent, getAllCompanyName, getAllComplaintsType, getAllInsuranceType, submitHospitalCaseThunk } from "../thunk/HospitalCase.thunk";
import { toast } from "react-toastify";

interface ApiState {
  data: any;
  loading: "idle" | "pending" | "fulfilled" | "rejected";
  error: string | null;
  complaintTypeData: responseData;
  companyData: responseData;
  insuranceType: responseData;
  agentData: responseData;
  submittedData: responseData;
}

interface responseData {
  data: any;
}

const initialState: ApiState = {
  data: null,
  loading: "idle",
  error: null,
  complaintTypeData: { data: [] },
  companyData: { data: [] },
  insuranceType: { data: [] },
  agentData: { data: [] },
  submittedData: { data: "" }
};

const HospitalCaseSlice = createSlice({
  name: "HospitalCaseSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllComplaintsType.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getAllComplaintsType.fulfilled, (state, action) => {
        state.loading = "fulfilled";
        state.complaintTypeData = action.payload;
        state.error = null;
      })
      .addCase(getAllComplaintsType.rejected, (state, action) => {
        state.loading = "rejected";
        state.error = action.error.message ?? "An error occurred";
      });

    builder
      .addCase(getAllCompanyName.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getAllCompanyName.fulfilled, (state, action) => {
        state.loading = "fulfilled";
        state.companyData = action.payload;
        state.error = null;
      })
      .addCase(getAllCompanyName.rejected, (state, action) => {
        state.loading = "rejected";
        state.error = action.error.message ?? "An error occurred";
      });

    builder
      .addCase(getAllInsuranceType.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getAllInsuranceType.fulfilled, (state, action) => {
        state.loading = "fulfilled";
        state.insuranceType = action.payload;
        state.error = null;
      })
      .addCase(getAllInsuranceType.rejected, (state, action) => {
        state.loading = "rejected";
        state.error = action.error.message ?? "An error occurred";
      });

    builder
      .addCase(getAllAgent.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getAllAgent.fulfilled, (state, action) => {
        state.loading = "fulfilled";
        state.agentData = action.payload;
        state.error = null;
      })
      .addCase(getAllAgent.rejected, (state, action) => {
        state.loading = "rejected";
        state.error = action.error.message ?? "An error occurred";
      });

    builder
      .addCase(submitHospitalCaseThunk.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(submitHospitalCaseThunk.fulfilled, (state, action) => {
        if (action.payload?.success) toast.success(action.payload.message);
        state.loading = "fulfilled";
        state.submittedData = action.payload;
        state.error = null;
      })
      .addCase(submitHospitalCaseThunk.rejected, (state, action) => {
        state.loading = "rejected";
        state.error = action.error.message ?? "An error occurred";
      });
  }
});

export default HospitalCaseSlice.reducer;
