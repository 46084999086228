import React, { HTMLAttributes } from "react";

interface ContainerTwoProps extends HTMLAttributes<HTMLDivElement> {
  customClassName?: string;
}

const Container: React.FC<ContainerTwoProps> = ({ children, customClassName, ...props }) => {
  const className = `bg-white rounded-lg px-4 py-6 lg:px-9 mx-6 lg:mx-0 mb-6 lg:mb-0 ${customClassName}`;

  return (
    <div className={className} {...props}>
      {children}
    </div>
  );
};

export default Container;
