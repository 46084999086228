import React from "react";

interface TypographyProps {
  variant?: "h1" | "h2" | "h5" | "h3" | "link" | "reg";
  className?: string;
  children: React.ReactNode;
  onClick?: () => void;
}

const Typography: React.FC<TypographyProps> = ({ variant, className, children, onClick }) => {
  let typographyClasses = "";

  if (variant === "h1") typographyClasses += " font-extrabold text-xl";
  else if (variant === "h2") typographyClasses += " font-bold";
  else if (variant === "h3") typographyClasses += " font-bold text-xl";
  else if (variant === "link") typographyClasses += " text-blue-500 hover:text-blue-700";

  if (!variant) typographyClasses += " font-semibold";

  if (className) typographyClasses += ` ${className}`;

  return (
    <p className={typographyClasses.trim()} onClick={onClick}>
      {children}
    </p>
  );
};

export default Typography;
