import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../helpers/axios";
import { baseUrl } from "../../helpers/constants/defaultUrls";

interface getUserChatProps {
  senderId: string;
  receiverId: string;
}

interface PinChatProps extends getUserChatProps {
  toPin: boolean;
}

interface ArchiveChatProps extends getUserChatProps {
  toArchive: boolean;
}

export const getAllContactsThunk = createAsyncThunk("chat/getAllContactsThunk", async () => {
  const response = await axios.get(baseUrl + "v1/chat/contacts");
  return response.data;
});

export const searchContactsThunk = createAsyncThunk("chat/searchContactsThunk", async (search: string) => {
  const response = await axios.get(baseUrl + `v1/chat/contacts/search?search=${search}`);
  return response.data;
});

export const getLastMsgThunk = createAsyncThunk("chat/getLastMsg", async () => {
  const response = await axios.get(baseUrl + `v1/chat/last/list`);
  return response.data;
});

export const getUserChatThunk = createAsyncThunk("chat/getUserChat", async ({ receiverId, senderId }: getUserChatProps) => {
  const response = await axios.get(baseUrl + `v1/chat/get?senderId=${senderId}&receiverId=${receiverId}`);
  return response.data;
});

export const getUserChatThunkNoLoading = createAsyncThunk("chat/getUserChat", async ({ receiverId, senderId }: getUserChatProps) => {
  const response = await axios.get(baseUrl + `v1/chat/get?senderId=${senderId}&receiverId=${receiverId}`);
  return response.data;
});

export const pinUserChatThunk = createAsyncThunk("chat/pinUserChatThunk", async (payload: PinChatProps) => {
  const response = await axios.patch(baseUrl + `v1/chat/pinChat`, payload);
  return response.data;
});

export const archiveChatThunk = createAsyncThunk("chat/archiveChatThunk", async (payload: ArchiveChatProps) => {
  const response = await axios.patch(baseUrl + `v1/chat/archiveChat`, payload);
  return response.data;
});

export const clearChatThunk = createAsyncThunk("chat/clearChatThunk", async (payload: getUserChatProps) => {
  const response = await axios.patch(baseUrl + `v1/chat/clearChat`, payload);
  return response.data;
});

export const deleteChatThunk = createAsyncThunk("chat/deleteChatThunk", async (payload: getUserChatProps) => {
  const response = await axios.patch(baseUrl + `v1/chat/deleteChat`, payload);
  return response.data;
});

export const searchLastMsgThunk = createAsyncThunk("chat/deleteChatThunk", async (query: string) => {
  const response = await axios.get(baseUrl + `v1/chat/last/list/search?query=${query}`);
  return response.data;
});

export const sendFileThunk = createAsyncThunk("chat/sendFileThunk", async (payload: any) => {
  const response = await axios.post(baseUrl + `v1/chat/uploadFile/${payload.receiverId}`, payload.formData, {
    headers: { "Content-Type": "multipart/form-data" }
  });
  return response.data;
});

export const getUnreadCount = createAsyncThunk("chat/getUnreadCount", async () => {
  const response = await axios.get(baseUrl + `v1/chat/count/unread`);
  return response.data;
});
