import React, { useEffect, useState } from "react";
import Container from "../../component/common/Container";
import Typography from "../../component/semantic/Typography";
import { useAppDispatch, useAppSelector } from "../../hooks/redux_toolkit";
import { complaintListThunk, notificationBell, policyListThunk } from "../../store/thunk/Common.thunk";
import { useFormik } from "formik";
import FormInput from "../../component/semantic/FormInput";
import FormTextArea from "../../component/semantic/FormTextArea";
import Divide from "../../component/semantic/Divide";
import ButtonOutline from "../../component/semantic/Button";
import { isEmpty } from "../../helpers/utils";
import { newCaseSchema } from "../../helpers/schemas/Simple.Schema";
import ReactSelect from "react-select";
import { polyfixSelect } from "../../helpers/constants/styles";
import { createCaseThunk } from "../../store/thunk/NewCase.thunk";
import UploadDocs from "./components/UploadDocs";
import { getCurrentUser } from "../../helpers/currentUser";
import dayjs from "dayjs";
import { daysTimestamp8, generalCaseType } from "../../helpers/constants/constants";
import { toast } from "react-toastify";
import Heading1 from "../../component/semantic/Heading";
import { useNavigate } from "react-router-dom";

interface caseTypes {
  name: string;
  email: string;
  phone: any;
  problemStatement: string;
  policyTypeId: any;
  generalPolicyType: string;
  complaintTypeId: any;
  policyTypeName: string;
  complaintTypeName: string;
}

const initialValues: caseTypes = {
  name: "",
  email: "",
  phone: "",
  problemStatement: "",
  policyTypeId: "",
  generalPolicyType: "",
  complaintTypeId: "",
  policyTypeName: "",
  complaintTypeName: ""
};

const NewCase = () => {
  const [showDocModal, setShowDocModal] = useState(false);
  const [createdLeadInfo, setCreatedLeadInfo] = useState<any>();
  const [completeProfile, setCompleteProfile] = useState<any>(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const policyList = useAppSelector((state) => state.rootReducer.common.policyList.data);
  const complaintList = useAppSelector((state) => state.rootReducer.common.complaintList.data);
  const userProfile = useAppSelector((state) => state.rootReducer.common.userProfile.data);
  const isLoading = useAppSelector((state) => state.rootReducer.newCase.createCaseLoading);

  useEffect(() => {
    dispatch(policyListThunk());
    if (userProfile.dob && userProfile.panNumber && userProfile.aadhaarNumber && userProfile.gender && userProfile.stateName && userProfile.districtName) {
      setCompleteProfile(true);
    }
  }, []);
  const currentUser = getCurrentUser();

  const { values, handleSubmit, handleBlur, handleChange, touched, errors, resetForm, setFieldValue } = useFormik({
    initialValues,
    validationSchema: newCaseSchema,
    onSubmit: async (values: any) => {
      const payload = {
        name: values.name,
        email: values.email.trim(),
        phone: values.phone.toString(),
        problemStatement: values.problemStatement,
        policyTypeId: values.policyTypeId?._id,
        complaintTypeId: values.complaintTypeId?._id,
        policyTypeName: values.policyTypeId?.name,
        generalPolicyType: values.policyTypeId?.name === "General Insurance" ? values.generalPolicyType?.value : "",
        complaintTypeName: values.complaintTypeId?.name,
        creationSrc: "partner-web"
      };
      dispatch(createCaseThunk(payload))
        .unwrap()
        .then((data: any) => {
          if (data?.status == 201) {
            if (data?.data?.message) toast.success(data?.data?.message);
            setCreatedLeadInfo({ policyInfo: values.policyTypeId, complaintInfo: values.complaintTypeId, leadId: data?.data?.data._id });
            dispatch(notificationBell({ id: currentUser?._id, type: "unread", date: dayjs().startOf("day").valueOf() - daysTimestamp8 }));
            setShowDocModal(true);
            resetForm();
          }
        });
    }
  });

  useEffect(() => {
    if (values.policyTypeId._id) {
      dispatch(complaintListThunk(values.policyTypeId?._id));
      setFieldValue("complaintTypeId", "");
    }
  }, [values.policyTypeId]);

  const formProps = { values, handleBlur, handleChange, touched, errors, setFieldValue };

  const complaintTypeValidation = () => {
    if (values.policyTypeId.name == "Motor Insurance") return false;
    if (formProps.values.complaintTypeId.length == 0 && formProps.touched["complaintTypeId"]) return true;
    return false;
  };
  if (!completeProfile) {
    return (
      <Container>
        <Heading1 className="text-red-500 font-semibold border-2 p-10 rounded-lg border-red-400 bg-red-100"> We can see that your profile is not complete, please complete your profile first to add new cases. To complete your profile click on the button.</Heading1>
        <div className="  w-[20] flex justify-end mt-4">
          <ButtonOutline
            className={`bg-polyfixTheme ${!isEmpty(errors) && "opacity-50 pointer-events-none"} `}
            onClick={() => {
              navigate("/profile");
            }}
            title="Complete Profile"
          ></ButtonOutline>
        </div>
      </Container>
    );
  }
  return (
    <>
      <div id="mobs" className="font-semibold px-2 mx-4 mb-4 block lg:hidden">
        <p className="font-extrabold text-xl">Submit New Case</p>
        <Typography className="text-customgray text-xs md:text-base">Please provide the details of insurance claim complaint</Typography>
      </div>
      <Container>
        <header className="hidden lg:block">
          <Typography variant="h3">Submit New Case</Typography>
          <Typography className="text-customgray mt-1">Please provide the details of insurance claim complaint</Typography>
        </header>
        <div className="grid grid-cols-1 md:grid-cols-2 mt-4 gap-x-16 gap-y-4">
          <FormInput name="name" placeholder="Enter Name" label="Policyholder's/Nominee's Name *" formProps={formProps} />
          <span>
            <Typography className="font-normal mb-1">Insurance Type *</Typography>
            <ReactSelect
              options={policyList}
              name="policyTypeId"
              placeholder="Select Insurance Type"
              getOptionLabel={(option: any) => option?.name}
              getOptionValue={(option: any) => option}
              value={values.policyTypeId}
              onChange={(option) => {
                setFieldValue("policyTypeId", option);
              }}
              styles={polyfixSelect}
              isSearchable={false}
            />
            {formProps.values.policyTypeId.length == 0 && formProps.touched["policyTypeId"] && <span className="mt-1 text-red-600 font-semibold text-sm">Please Select Policy</span>}
          </span>
          {values.policyTypeId?.name === "General Insurance" && (
            <span>
              <Typography className="font-normal mb-1">Policy Type *</Typography>
              <ReactSelect
                options={generalCaseType}
                name="generalPolicyType"
                placeholder="Select Policy Type"
                value={values.generalPolicyType}
                onChange={(option: any) => {
                  setFieldValue("generalPolicyType", option);
                }}
                styles={polyfixSelect}
              />
              {values.policyTypeId?.name === "General Insurance" && !values.generalPolicyType && formProps.touched["generalPolicyType"] && <span className="mt-1 text-red-600 font-semibold text-sm">Please Select Policy</span>}
            </span>
          )}
          <FormInput name="email" onKeyDown={(e: any) => (e.code !== "Space" ? e.target.value : e.preventDefault())} type="email" label="Policyholder's Email *" placeholder="Enter Email id" formProps={formProps} />
          <span>
            <Typography className="font-normal mb-1">Complaint Type *</Typography>
            <ReactSelect
              options={complaintList || []}
              name="complaintTypeId"
              placeholder="Select Complaint Type"
              getOptionLabel={(option: any) => option?.name}
              getOptionValue={(option: any) => option}
              value={values.complaintTypeId}
              onChange={(option) => {
                setFieldValue("complaintTypeId", option);
              }}
              styles={polyfixSelect}
              isSearchable={false}
            />
            {complaintTypeValidation() && <span className="mt-1 text-red-600 font-semibold text-sm">Please Select Complaint</span>}
          </span>
          <FormInput name="phone" type="number" limit={10} label="Policyholder's Mobile *" formProps={formProps} placeholder="Enter Mobile Number" />
          <span className="col-span-1 md:col-span-2 h-full row-span-4  md:row-span-2">
            <FormTextArea label="Problem Statement *" className="h-full" placeholder="Tell us more about your problem, this will help us to analyze your case" name="problemStatement" formProps={formProps} />
          </span>
        </div>
        <Divide className="my-12 -mx-4 lg:-mx-9 " />
        <div className="block md:flex flex-row-reverse">
          <ButtonOutline className={`bg-polyfixTheme  w-full ${(!isEmpty(errors) || isLoading) && "opacity-50 pointer-events-none"} `} onClick={handleSubmit} title={isLoading ? "Submitting" : "Submit Case"} type="submit" />
        </div>
      </Container>
      <UploadDocs setShowModal={setShowDocModal} showModal={showDocModal} createdLeadInfo={createdLeadInfo} setCreatedLeadInfo={setCreatedLeadInfo} />
    </>
  );
};

export default NewCase;
