import React, { useEffect, useState } from "react";
import Typography from "../../../component/semantic/Typography";
import FileUpload from "../../../component/common/FileUpload";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux_toolkit";
import { cancelledChequeThunk, profileDocDelete, userUpdate } from "../../../store/thunk/Profile.thunk";
import { FaFile } from "react-icons/fa";
import FormInput from "../../../component/semantic/FormInput";
import { bankDetails } from "../../../helpers/initialValues";
import { useFormik } from "formik";
import { bankSchema } from "../../../helpers/schemas/Profile.Schema";
import { showToast } from "../../../store/slices/Common.slice";
import { isEmpty } from "../../../helpers/utils";
import ButtonOutline from "../../../component/semantic/Button";
import { ImSpinner2 } from "react-icons/im";
import { getCurrentUser } from "../../../helpers/currentUser";
import { userProfile } from "../../../store/thunk/Common.thunk";
import { toast } from "react-toastify";
import deleteDoc from "../../../assets/icons/newCase/deleteDoc.svg";

const BankDetails = () => {
  const profileData: any = useAppSelector((state) => state.rootReducer.common.userProfile?.data);
  const loading = useAppSelector((state) => state.rootReducer.profile.userLoading);

  const [selectedFile, setSelectedFile] = useState<any>(profileData.cancelledCheque);
  const [formValues, setFormValue] = useState<any>(bankDetails);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (profileData) {
      setFormValue({
        _id: profileData?._id,
        gstNumber: profileData?.gstNumber,
        bankName: profileData?.bank?.bankName,
        accountNumber: profileData?.bank?.accountNumber,
        branch: profileData?.bank?.branch,
        ifsc: profileData?.bank?.ifsc,
        cancelledCheque: profileData?.cancelledCheque,
        userType: profileData?.userType,
        createdAt: profileData?.createdAt
      });
    }
  }, [profileData]);

  const { values, handleSubmit, handleBlur, handleChange, touched, errors, setFieldValue } = useFormik({
    initialValues: formValues,
    validationSchema: bankSchema,
    enableReinitialize: true,
    onSubmit: async (values: any) => {
      values = {
        ...values,
        bank: { bankName: values.bankName, branch: values.branch, ifsc: values.ifsc, accountNumber: values.accountNumber }
      };
      delete values.bankName;
      delete values.branch;
      delete values.accountNumber;
      delete values.ifsc;

      const updateNotification = await dispatch(userUpdate(values)).unwrap();

      if (updateNotification.status == 200) dispatch(showToast({ message: "Profile Updated Successfully", method: "success" }));
    }
  });

  useEffect(() => {
    if (selectedFile && selectedFile !== profileData.cancelledCheque) {
      const formData = new FormData();
      formData.append("image", selectedFile);
      dispatch(cancelledChequeThunk(formData))
        .unwrap()
        .then(() => {
          dispatch(userProfile(currentUser?._id));
        });
    }
  }, [selectedFile]);

  const formProps = { values, handleBlur, handleChange, touched, errors, setFieldValue };

  const currentUser = getCurrentUser();

  const s3url = "https://insa-partners-stage.s3.ap-south-1.amazonaws.com/uploads/" + currentUser?._id;

  const deleteHandler = () => {
    dispatch(profileDocDelete({ userId: profileData?._id, file: "agentCodeDoc" }))
      .unwrap()
      .then((data: any) => {
        if (data?.success) {
          toast.success(data?.message);
          dispatch(userProfile(currentUser?._id));
          setSelectedFile(null);
        }
      });
  };

  return (
    <>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <FormInput name="bankName" formProps={formProps} label="Bank Name" placeholder="Enter Bank Name" />
        <FormInput name="accountNumber" formProps={formProps} label="Account Number" placeholder="Enter Account Number" />
        <FormInput name="ifsc" limit={11} formProps={formProps} label="IFSC Code" placeholder="Enter IFSC Code" />
        <FormInput name="branch" formProps={formProps} label="Branch Name" placeholder="Enter Branch Name" />
        <FormInput name="gstNumber" limit={15} formProps={formProps} label="GST Number" placeholder="Enter GST Number" />
        <span></span>
        <span>
          <Typography className="font-normal">Cancelled Cheque</Typography>
          <FileUpload setSelectedFile={setSelectedFile} />
        </span>
        <span className="items-center flex">
          {selectedFile && (
            <>
              <div onClick={() => window.open(s3url + "/" + values.cancelledCheque)} className="text-gray-700 bg-uploadTheme flex px-4 rounded-lg py-1 gap-1 items-center cursor-pointer">
                <FaFile className="text-gray-400 opacity-50" />
                {selectedFile === values.cancelledCheque ? values.cancelledCheque : selectedFile.name}
              </div>
              <img src={deleteDoc} onClick={deleteHandler} className="ml-2 cursor-pointer" />
            </>
          )}
        </span>
      </div>
      <div className="md:flex block justify-stretch md:flex-row-reverse py-4 mt-6 border-t-2 ">
        <ButtonOutline icon={loading ? <ImSpinner2 className="animate-spin ml-2 -mt-1" /> : ""} onClick={handleSubmit} className={`bg-polyfixTheme  w-full md:w-32 ${!isEmpty(errors) && "opacity-50 pointer-events-none"}`} title="SAVE" type="submit" />
      </div>
    </>
  );
};

export default BankDetails;
