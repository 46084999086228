import React, { FC } from "react";
import Menu from "../../../../component/common/Menu";
import { emojis } from "../../../../helpers/constants/constants";

interface EmojiProps {
  showEmojiMenu: boolean;
  handleEmojiClick: any;
}

const EmojiMenu: FC<EmojiProps> = ({ showEmojiMenu, handleEmojiClick }) => {
  return (
    <Menu showMenu={showEmojiMenu} className="bottom-12  w-[24rem] md:w-[25rem]  z-0 -right-16 md:right-0  shadow-2xl">
      <div className="text-3xl md:text-4xl flex flex-wrap gap-3 transition-all">
        {emojis.map((emoji, index) => (
          <p key={index} onClick={() => handleEmojiClick(emoji)} className="cursor-pointer hover:scale-110">
            {emoji}
          </p>
        ))}
      </div>
    </Menu>
  );
};

export default EmojiMenu;
