import React, { useState } from "react";
import Container from "../../component/common/Container";
import Typography from "../../component/semantic/Typography";
import ButtonOutline from "../../component/semantic/Button";
import FileUpload from "../../component/common/FileUpload";
import { useDispatch } from "react-redux";
import FileIcon from "../../assets/icons/dashboard/file.svg";
import CSVIcon from "../../assets/icons/dashboard/csvIcon.svg";
import { uploadBulkBlockUsersFile, uploadBulkSignupFile } from "../../store/thunk/BulkAction.thunk";

function BulkAction() {
  const [selectedFileSignUp, setSelectedFileSignUp] = useState("");
  const [selectedFileBlockUsers, setSelectedFileBlockUsers] = useState("");
  const dispatch = useDispatch();

  const handleUploadSignUpFile = () => {
    const formData = new FormData();
    formData.append(selectedFileSignUp.name, selectedFileSignUp);
    dispatch(uploadBulkSignupFile(formData));
  };

  const handleUploadBloackUsersFile = () => {
    const formData = new FormData();
    formData.append(selectedFileBlockUsers.name, selectedFileBlockUsers);
    dispatch(uploadBulkBlockUsersFile(formData));
  };

  return (
    <div>
      <Container>
        <div className="block md:flex lg:flex xl:flex 2xl:flex justify-between mb-6">
          <div>
            <Typography variant="h1" className="text-left font-semibold">
              Bulk Sign Up
            </Typography>
            <Typography className="text-gray-600 text-sm">Create Multiple Sign up Via CSV upload</Typography>
          </div>
          <div className="ml-auto mt-3 md:mt-0 lg:mt-0 xl:mt-0 2xl:mt-0">
            <Typography className="text-xs hidden md:block">Download Bluk Sign Up CSV Format</Typography>
            <button type="button" className="mt-4 w-full text-green-800 ml-auto flex justify-center flex-row items-center gap-1 border border-undefined border-green-800  focus:ring-4 focus:outline-none font-semibold rounded-md text-sm px-2  lg:px-5 py-2.5 text-center me-2">
            <img alt="csv icon" src={CSVIcon} />Download CSV Format
            </button>
          </div>
        </div>
        <hr />
        <div className="block md:flex lg:flex xl:flex w-full md:w-8/12 lg:w-8/12 xl:w-8/12 2xl:w-8/12 mt-3 pb-5">
          <div className="w-full md:m-0 lg:m-0 xl:m-0 2xl:m-0 mb-4">
            <Typography className="font-bold my-3">Upload File for Bulk Sign Up</Typography>
            <FileUpload accept=".csv" id="signup users" setSelectedFile={setSelectedFileSignUp} />
          </div>
          <div className="mt-auto w-full">
            <ButtonOutline type="button" className={`mt-auto md:ml-3 lg:ml-3 xl:ml-3 2xl:ml-3 bg-polyfixTheme w- mb-2 md:w-56 lg:w-56 xl:w-56 2xl:w-56 my-auto w-full mx-auto`} title="Submit" onClick={handleUploadSignUpFile} />
          </div>
        </div>
        {selectedFileSignUp ? (
          <div className="w-auto md:w-max flex bg-[#ffe6be] rounded px-3 text-sm">
            <img src={FileIcon} />
            <span className=" px-3 py-1 rounded-md">{selectedFileSignUp?.name}</span>
          </div>
        ) : null}
      </Container>
      <div className="my-6"></div>
      <Container>
        <div className="block md:flex lg:flex xl:flex 2xl:flex justify-between mb-6">
          <div>
            <Typography variant="h1" className="text-left font-semibold">
              Block Bulk Users
            </Typography>
            <Typography>Block Multiple Users Via CSV upload</Typography>
          </div>
          <div className="ml-auto mt-3 md:mt-0 lg:mt-0 xl:mt-0 2xl:mt-0">
            <Typography className="text-xs">Download Bluk Blocking CSV Format</Typography>
            <button type="button" className="mt-4 w-full text-green-800 ml-auto flex justify-center flex-row items-center gap-1 border border-undefined border-green-800  focus:ring-4 focus:outline-none focus:ring-blue-300 font-semibold rounded-md text-sm px-2  lg:px-5 py-2.5 text-center me-2">
            <img alt="csv icon" src={CSVIcon} />Download CSV Format
            </button>
          </div>
        </div>
        <hr />
        <div className="block md:flex lg:flex xl:flex w-full md:w-8/12 lg:w-8/12 xl:w-8/12 2xl:w-8/12 mt-3 pb-5">
          <div className="w-full md:m-0 lg:m-0 xl:m-0 2xl:m-0 mb-4">
            <Typography className="font-bold my-3">Upload File for Bulk Blocking</Typography>
            <FileUpload accept=".csv" id="blockUsers" setSelectedFile={setSelectedFileBlockUsers} />
          </div>
          <div className="mt-auto w-full">
            <ButtonOutline type="button" className={`mt-auto md:ml-3 lg:ml-3 xl:ml-3 2xl:ml-3 bg-polyfixTheme w- mb-2 md:w-56 lg:w-56 xl:w-56 2xl:w-56 my-auto w-full mx-auto`} title="Submit" onClick={handleUploadBloackUsersFile} />
          </div>
        </div>
        {selectedFileBlockUsers ? (
          <div className="flex bg-[#ffe6be] w-max rounded px-3 text-sm">
            <img src={FileIcon} />
            <span className=" px-3 py-1 rounded-md">{selectedFileBlockUsers?.name}</span>
          </div>
        ) : null}
      </Container>
    </div>
  );
}

export default BulkAction;
